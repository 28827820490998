import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import App from '../common/app'
import EmailCompose from './components/index'
import { saveAttachment, saveMessage ,saveEmail} from './actions'
import { searchUsers } from '../users/actions'

let unsubscribe = function () { }

function render() {
    ReactDOM.render(
        <App {...getState()}>
            <EmailCompose
                {...getState()}
                saveEmail={saveEmail}
                saveAttachment={saveAttachment} />
        </App>,
        document.querySelector('#app')
    )
}

export function enter(context, next) {
    unsubscribe = subscribe(render)
    searchUsers("",1,3000)
    render()
}

export function exit(context, next) {
    unsubscribe()
    next()
}
