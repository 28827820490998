import {
  GET_PRODUCTS,
  RECEIVE_PRODUCTS,
  CHANGE_SEARCH_QUERY,
  CLEAR_PRODUCTS,
  CLEAR_CLEARANCE_PRODUCTS,
  RECEIVE_CLEARANCE_PRODUCTS
} from '../constants/action-types'
import { dispatch, getState } from '../store'
import page from 'page'
import throttle from 'lodash/throttle'

let lastQuery
let lastPath
let lastCategoryId

function receiveProducts (response) {
  return {
    type: RECEIVE_PRODUCTS,
    response
  }
}

function receiveClearanceProducts (response) {
  return {
    type: RECEIVE_CLEARANCE_PRODUCTS,
    response
  }
}

export function changeSearchQuery (query, pagenum, pageSize) {
  query = query || ''
  pagenum = pagenum || 1
  dispatch({
    type: CHANGE_SEARCH_QUERY,
    query,
    pagenum
  })
  if (query && window && window.location && window.location.pathname === '/') {
    page.redirect(`/?q=${query}&page=${pagenum || 1}`)
  } else if (query){
    page(`/?q=${query}&page=${pagenum || 1}`)
  }
  if (!query) {
    page(`${window.location.search.split("&page=")[0]}&page=${pagenum || 1}`)

  }
  searchProducts(query, pagenum, pageSize)
  getClearance()
}

function fetchProducts (query, offset, path) {
  fetch(path, {
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(function (response) {
      if (response.ok) {
        return response.json()
      }
      throw new Error('Product search failed')
    }).then(function (response) {
      const isSamePath = path === lastPath
      if (isSamePath) {
        dispatch(receiveProducts(response))
      }
    })
    .catch(function (err) {
      console.log('parsing failed', err)
    })
}
const fetchProductsThrottled = throttle(fetchProducts, 500, {
  leading: true,
  trailing: true
})

export function getClearance () {
  dispatch({
    type: CLEAR_CLEARANCE_PRODUCTS
  })
  const path = `/api/clearance-product-search?q=&categoryId=ffefca7d-a309-4a7c-8b93-34c7d76a9712`

  fetch(path, {
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(function (response) {
      if (response.ok) {
        return response.json()
      }
      throw new Error('Product search failed')
    }).then(function (response) {
      dispatch(receiveClearanceProducts(response))
    })
    .catch(function (err) {
      console.log('parsing failed', err)
    })
}

export function searchProducts (query = '', pagenum, pageSize) {
  if (!pagenum) pagenum = (parseInt(getState().browser.query.page)) || 1;
  if (!pageSize) pageSize = 25;
  dispatch({
    type: CLEAR_PRODUCTS
  })
  let offset = pageSize * (+pagenum - 1)
  if (!query) {
    query = (getState().browser.query.q || '').trim()
  }
  let categoryId = (getState().browser.query.categoryId || '').trim()
  // const isSameOffset = offset === getState().products.meta.offset

  if (!query && !categoryId) {
    categoryId = '6b86b273-f34f-419d-8804-ff5a3f5747ad'
  }

  const isSameQuery = query.toLowerCase() === (lastQuery || '').toLowerCase()
  const isSameCategoryId = categoryId === lastCategoryId

  if (isSameQuery && isSameCategoryId) {
    if (getState().products.isLoading) {
      return
    }
    //offset = getState().products.meta.offset + getState().products.meta.limit
  } 
  //window.scrollTo(0, 0)
  const path = `/api/product-search?q=${query}&categoryId=${categoryId}&offset=${offset}&size=${pageSize}`
  lastQuery = query
  lastPath = path
  lastCategoryId = categoryId

  if (query) {
    dispatch({
      type: GET_PRODUCTS,
      offset
    })
  } 
  fetchProductsThrottled(query, offset, path)
}
