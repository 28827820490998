import React from 'react'

export default function ContactUs () {
  return (
    <div style={{ margin: 18 }}>
      <h2>Contact Us</h2>
      <div>
        <h3>Vancouver</h3>
        <a href="http://goo.gl/DpV0D">
          5517 Production Blvd. Surrey, BC V3S 8P6
        </a>
        <br />
        Phone: (604) 530-4002<br />
        Fax: (604) 530-4022<br />
        Toll Free Fax: 1-800-663-9233<br />
        Email:{' '}
        <a href="mailto:d.st.germain@atlastrailer.com">
          d.st.germain@atlastrailer.com
        </a>

        <h3>Calgary</h3>

        <a href="http://goo.gl/ShK1I">
          2530 - 21st Street N.E. Calgary, AB T2E 7L3
        </a>
        <br />
        Phone: (403) 291-1225<br />
        Fax: (403) 250-9260<br />
        Toll Free Fax: 1-800-661-8388<br />
        Email:{' '}
        <a href="mailto:john.tamming@atlastrailer.com">
          john.tamming@atlastrailer.com
        </a>

        <h3>London</h3>

        <a href="http://goo.gl/5igt6">3026 Page St, London, ON N5V 4J3</a>
        <br />
        Phone: (519) 680-1450<br />
        Fax: (519) 680-1275<br />
        Toll Free Fax: 1-800-263-1277<br />
        Email:{' '}
        <a href="mailto:rob.down@atlastrailer.com">
          rob.down@atlastrailer.com
        </a>

        <h3>Montreal</h3>

        <a href="http://goo.gl/5RY4h">
          3375 1e-Rue, Saint-Hubert, Que J3Y 8Y6
        </a>
        <br />
        Phone: (450) 445-4422<br />
        Fax: (450) 445-4421<br />
        Toll Free Fax: 1-877-682-4421<br />
        Email:{' '}
        <a href="mailto:tom.masseau@atlastrailer.com">
          tom.masseau@atlastrailer.com
        </a>
      </div>
    </div>
  )
}