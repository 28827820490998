import React from 'react'
import Image from './image'
import TranslatedField from './translated-field'
import getCategoryWithAncestors from '../../../common/get-category-with-ancestors'
import Select from 'react-select'
// import 'react-select/dist/react-select.css' // TODO: use webpack to import this
import createReactClass from 'create-react-class'

const languages = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'French' }
]

function getCategoryEnglish (category) {
  return typeof category.name === 'string'
    ? category.name
    : category.name.find(entry => entry.languageCode === 'en').value
}

export default createReactClass({
  displayName: 'EditProduct',

  getInitialState () {
    return {
      product: this.props.product.data,
      categories: [],
      apiLog: []
    }
  },

  componentDidMount () {
    fetch('/api/categories', {
      headers: {
        // 'x-session-id': getState().session.id,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(json => {
        if (json && json.data) {
          this.setState({
            categories: json.data
              .filter(record => {
                return record.attributes._id && record.attributes.name
              })
              .map(record => {
                const categoriesInPath = getCategoryWithAncestors(
                  json.data,
                  record.attributes._id
                )
                const names = categoriesInPath.map(category =>
                  getCategoryEnglish(category.attributes)
                )
                return {
                  value: record.attributes._id || 'error',
                  label: names.join(' / ')
                }
              })
          })
        }
      })

    fetch(`/api/log/${this.props.product.data.distributorPartNumber}`, {
      headers: {
        'x-session-id': this.props.session.id,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(json => {
        if (json && json.data) {
          this.setState({ apiLog: json.data })
        }
      })
  },

  //will shift the image up if direction is true, else will shift image down
  handleImageShift (imageToShift, direction) {
    const product = this.state.product
    const selectedImage = product.images[imageToShift]
    const switchTarget = direction ? product.images[imageToShift - 1] : product.images[imageToShift + 1]
    if (direction) {
      product.images[imageToShift - 1] = selectedImage
      product.images[imageToShift] = switchTarget
    } else {
      product.images[imageToShift + 1] = selectedImage
      product.images[imageToShift] = switchTarget
    }
    this.setState({ product })
  },

  handleImageRemove (imageToRemove) {
    const product = {
      ...this.state.product,
      images: this.state.product.images.filter(image => {
        return imageToRemove !== image
      })
    }
    this.setState({ product })
  },

  handleSubmit (event) {
    event.preventDefault()
    this.props.onSave(this.state.product)
  },

  handleDeleteClick (event) {
    event.preventDefault()
    if (!window.confirm('Are you sure you want to delete this product?')) {
      return
    }
    this.props.onDelete(this.state.product.id || this.state.product._id)
  },

  handleTranslatedFieldChange (fieldName, languageCode, value) {
    const product = this.state.product
    const translatedField = (this.state.product[fieldName] || [])
      .filter(translatedField => {
        return translatedField.languageCode !== languageCode
      })
      .concat({
        languageCode,
        value
      })
      product[fieldName] = translatedField
    this.setState({ product })
  },

  handleCategoriesChange (categories) {
    const product = {
      ...this.state.product,
      categoryIds: categories.map(category => category.value)
    }
    this.setState({ product })
  },

  handleClearanceQtyChange (qty) {
    const product = {
      ...this.state.product,
      clearanceQty : Number(qty.target.value)
    }
    this.setState({product})
  },

  render () {
    const product = this.state.product
    const imageArrayLength = product.images.length
    const imageComponents =
      product.images &&
      product.images.map((image, index) => {
        return (
          <Image
            data={image}
            index={index}
            length={imageArrayLength}
            key={image.name}
            onRemove={this.handleImageRemove}
            onShift={this.handleImageShift}
          />
        )
      })

    const apiLogEntries = (this.state.apiLog || []).map(entry => {
      return (
        <tr>
          <th>{entry.createdAt}</th>
          <td>
            <pre>{JSON.stringify(entry, null, 2)}</pre>
          </td>
        </tr>
      )
    })

    return (
      <main style={{ margin: 24 }}>
        <form onSubmit={this.handleSubmit}>
          <h1>{product.distributorPartNumber}</h1>
          <fieldset>
            <legend>Title</legend>
            <TranslatedField
              languages={languages}
              onChange={this.handleTranslatedFieldChange.bind(null, 'title')}
              values={product.title}
            />
          </fieldset>
          <fieldset>
            <legend>Categories</legend>
            <div>
              <div className="form-group row">
                <label className="col-xs-2 col-form-label" />
                <div className="col-xs-10">
                  {Boolean(
                    this.state.product && this.state.categories.length
                  ) &&
                    <Select
                      id="categories-select"
                      name="categories"
                      value={this.state.product.categoryIds}
                      options={this.state.categories}
                      onChange={this.handleCategoriesChange}
                      multi={true}
                    />}
                </div>
              </div>
            </div>
          </fieldset>
          {Boolean(this.state.product.categoryIds.includes("ffefca7d-a309-4a7c-8b93-34c7d76a9712")) &&
          <fieldset>
            <legend>Remove from Clearance at</legend>
            <div>
              <div className="form-group row">
                <label className="col-xs-2 col-form-label" />
                <div className="col-xs-10">
                  
                    <input
                      type="number"
                      className="form-control"
                      id="clearanceQty"
                      name="clearanceQty"
                      value={this.state.product.clearanceQty}
                      onChange={this.handleClearanceQtyChange}
                    />
                </div>
              </div>
            </div>
          </fieldset>}
          <fieldset>
            <legend>Short description</legend>
            <TranslatedField
              languages={languages}
              onChange={this.handleTranslatedFieldChange.bind(
                null,
                'shortDescription'
              )}
              values={product.shortDescription}
            />
          </fieldset>
          <fieldset>
            <legend>Long description</legend>
            <TranslatedField
              languages={languages}
              onChange={this.handleTranslatedFieldChange.bind(
                null,
                'longDescription'
              )}
              values={product.longDescription}
            />
          </fieldset>
          {Boolean(product.images && product.images.length) &&
            <fieldset>
              <legend>Images</legend>
              {imageComponents}
            </fieldset>}
          <button
            className="btn btn-danger"
            onClick={this.handleDeleteClick}
            style={{ float: 'right' }}
          >
            Delete product
          </button>
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </form>

        <details>
          <summary>
            <h3 style={{ display: 'inline-block' }}>Raw data</h3>
          </summary>
          <div>
            <pre>{JSON.stringify(product, null, 2)}</pre>
          </div>
        </details>

        {Boolean(this.state.apiLog && this.state.apiLog.length) &&
          <div style={{ marginTop: '16px' }}>
            <h3>Latest API updates</h3>
            <table>
              <tbody>
                {apiLogEntries}
              </tbody>
            </table>
          </div>}
      </main>
    )
  }
})
