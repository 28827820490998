import { useSessionId, destroySession } from '../actions/session'

export default function (user) {
  const scriptTag = document.querySelector('script[src*=index-]')
  if (scriptTag) {
    const currentSrc = scriptTag.getAttribute('src')
    const headers = new Headers()
    headers.append('pragma', 'no-cache')
    headers.append('cache-control', 'no-cache')
    fetch('/', {
      method: 'get',
      headers
    })
      .then(response => response.text())
      .then(text => {
        if (user) {
          fetch('/api/users/?q='+user.email+'&exact=false', {
            methods:'get'
          })
          .then(response => {return response.json()})
          .then(data => {
            if (data.data[0].attributes.disabled) {
              destroySession()
            }
          })
        }
        const hasLatest = text.indexOf(currentSrc) > -1
        if (hasLatest) {
          return
        }
        const lastScriptTryAt = localStorage.getItem('lastScriptTryAt')
        if (lastScriptTryAt === currentSrc) {
          return // do not retry; avoid infinite loop
        }
        localStorage.setItem('lastScriptTryAt', currentSrc)
        window.location.reload()
      })
  }
}
