import { GET_PRODUCT, RECEIVE_PRODUCT } from '../constants/action-types'
import { dispatch, getState } from '../store'

export function getProduct (partNumber) {
  dispatch({
    type: GET_PRODUCT
  })

  fetch(`/api/product-by-distributor-part-number/${partNumber}`, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: RECEIVE_PRODUCT,
          response: json
        })
      }
    })
    .catch(function (err) {
      console.log('parsing failed', err)
    })
}
