import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import App from '../common/app'
import EmailReports from './components/index'
import { getUndeliveredMessages, getUnReadMessages } from './actions'

let unsubscribe = function () { }

function render() {
  ReactDOM.render(
      <App {...getState()}>
          <EmailReports
              {...getState()}
               />
      </App>,
      document.querySelector('#app')
  )
}

export function enter(context, next) {
    unsubscribe = subscribe(render)
    getUndeliveredMessages()
    //getUnReadMessages()
    render()
}

export function exit(context, next) {
    unsubscribe()
    next()
}
