import React from 'react'
import createReactClass from 'create-react-class'
import EmailAdminMenu from '../../email-admin-menu/components'
import moment from 'moment'

export default createReactClass({
    displayName: 'EmailAdminSentMessage',

    getFormattedDate() {
        return moment(this.props.message.data.modified).format('YYYY-MM-DD')
    },

    displayAttachments() {
        if (this.props.message.data.attachments) {
            return this.props.message.data.attachments.map(attachment => {
                const url = "/api/attachments/" + attachment.attachmentId
                return (
                    <tr key={url}>
                        <td><a href={url} download>{attachment.attachmentName}</a>
                        </td>
                    </tr>
                );
            });
        }
    },

    render() {
        let url
        if (this.props.message.data.attachmentId) {
            url = "/api/attachments/" + this.props.message.data.attachmentId
        }

        return (
            <div>
                <EmailAdminMenu></EmailAdminMenu>
                <div className="atlas-inbox-message-main">
                    <div className="atlas-inbox-grid-container">
                        <div className="atlas-inbox-grid-item atlas-inbox-green-heading">Date</div>
                        <div className="atlas-inbox-grid-item">{this.getFormattedDate()}</div>
                        <div className="atlas-inbox-grid-item"></div>
                        <div className="atlas-inbox-grid-item atlas-inbox-green-heading">Subject</div>
                        <div className="atlas-inbox-grid-item">{this.props.message.data.subject}</div>
                        <div className="atlas-inbox-grid-item"></div>
                        <div className="atlas-inbox-grid-item atlas-inbox-green-heading">Attachments</div>
                        <div className="atlas-inbox-grid-item">
                            <table className="accounting-address-table">
                                {this.displayAttachments()}
                            </table>
                        </div>
                        <div className="atlas-inbox-grid-item"></div>
                        <div className="atlas-inbox-grid-item atlas-inbox-green-heading">Body</div>
                        <div dangerouslySetInnerHTML={{ __html: this.props.message.data.body }} className="grid-item inbox-message-textarea" />
                    </div>
                </div>
            </div >

        )
    }
})
