import {
  GET_STATS_PENDING,
  GET_STATS_SUCCESSFUL,
  GET_STATS_ERROR
} from './constants'
import { dispatch, getState } from '../store'

export function getStats (data) {
  dispatch({
    type: GET_STATS_PENDING
  })

  fetch('/api/stats', {
    headers: {
      'x-session-id': getState().session.id,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_STATS_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: GET_STATS_ERROR,
        error
      })
    })
}
