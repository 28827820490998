import {
  GET_PRODUCT,
  RECEIVE_PRODUCT,
  SUBMIT_CART_SUCCESS
} from '../constants/action-types'

var initialState = {
  data: {},
  isLoading: false,
  wasLoadSuccessful: false
}

export default function products (
  state = initialState,
  { type, offset, response }
) {
  switch (type) {
    case GET_PRODUCT:
      return {
        ...state,
        wasLoadSuccessful: false,
        isLoading: true,
        data: {}
      }

    case RECEIVE_PRODUCT:
      return {
        ...state,
        wasLoadSuccessful: true,
        isLoading: false,
        data: response.data.attributes
      }

    case SUBMIT_CART_SUCCESS:
      return initialState

    default:
      return state
  }
}
