import {
    GET_EMAILS_PENDING,
    GET_EMAILS_SUCCESSFUL,
    GET_EMAILS_ERROR,
    RECEIVE_EMAILS,
    CLEAR_EMAILS,
    GET_EMAILS,
    CHANGE_EMAIL_SEARCH_QUERY,
} from './constants'
import { dispatch, getState } from '../store'

let lastPath

export function getEmails() {
    dispatch({
        type: GET_EMAILS_PENDING
    })

    fetch(`/api/emails`, {
        headers: {
            'x-session-id': getState().session.id
        }
    })
        .then(async function (response) {
            if (response.ok) {
                const json = await response.json()
                dispatch({
                    type: GET_EMAILS_SUCCESSFUL,
                    response: json
                })
            }
        })
        .catch(function (error) {
            console.log('parsing failed', error)
            dispatch({
                type: GET_EMAILS_ERROR,
                error
            })
        })
}

export function searchMessages(pagenum, pageSize, searchType, searchValue) {

    if (!pagenum) pagenum = 1;
    if (!pageSize) pageSize = 8;
    if (!searchType) searchType = 'byEmail';
    if (!searchValue) searchValue = '';
    
    dispatch({
        type: CLEAR_EMAILS
    })
    let offset = pageSize * (+pagenum - 1)

    //const path = `/api/messages?q=${query}&offset=${offset}&limit=${pageSize}&sortBy=${sortBy}`
    const path = `/api/messages?offset=${offset}&limit=${pageSize}&searchType=${searchType}&searchValue=${searchValue}`
    //console.log(path)
    lastPath = path

    dispatch({
        type: GET_EMAILS,
        offset
    })
    fetchMessages(path)
}

function fetchMessages(path) {
    fetch(path, {
        headers: {
            'x-session-id': getState().session.id
        }
    }).then(function (response) {
        if (response.ok) {
            return response.json()
        }
        throw new Error('Message search failed')
    }).then(function (response) {
        const isSamePath = path === lastPath
        if (isSamePath) {
            dispatch({
                type: RECEIVE_EMAILS,
                response
            })
        }
    }).catch(function (err) {
        console.log('parsing failed', err)
    })
}

export function changeSearchQuery(query, pagenum, pageSize = 25) {
    pageSize = 8
    query = query || ''
    dispatch({
        type: CHANGE_EMAIL_SEARCH_QUERY,
        query
    })
    searchMessages( pagenum, pageSize)
}


