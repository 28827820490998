// polyfills
import "whatwg-fetch"
import "event-source-polyfill"

import React from "react"
import page from "page"
import ReactDOM from "react-dom"
import productRoutes from "./products/routes"
import productRoute from "./product/routes"
import reportRoute from "./reports/routes"
import cartRoutes from "./cart/routes"
import webOrdersRoutes from "./web-orders/routes"
import webOrderRoutes from "./web-order/routes"
import invoicesRoutes from "./invoices/routes"
import vendorListRoutes from "./vendor-list/routes"
import newVendorRoutes from "./new-vendors/routes"
import statementsRoutes from "./statements/routes"
import comingSoonRoutes from "./pages/coming-soon"
import pagesRoutes from "./pages/routes"
import activityRoutes from "./activity/routes"
import editProductRoutes from "./edit-product/routes"
import { useSessionId, destroySession } from "./actions/session"
import { changeBrowserState } from "./actions/browser"
import * as profile from "./profile/routes"
import * as admin from "./admin/routes"
import * as categories from "./categories/routes"
import * as category from "./category/routes"
import * as dealer from "./dealer/routes"
import * as dealers from "./dealers/routes"
import * as emails from "./email-admin/routes"
import * as file from "./file/routes"
import * as files from "./files/routes"
import * as inbox from "./inbox/routes"
import * as inboxMessage from "./inbox-message/routes"
import * as users from "./users/routes"
import * as emailAdmin from "./email-admin/routes"
import * as emailAdminCompose from "./email-admin-compose/routes"
import * as emailAdminSent from "./email-admin-sent/routes"
import * as emailAdminSentMessage from "./email-admin-sent-message/routes"
import * as emailAdminInsight from "./email-admin-insight/routes"
import * as emailAdminInsightMessages from "./email-admin-insight-messages/routes"
import * as emailAdminInsightDelMessages from "./email-admin-insight-del-messages/routes"
import * as emailAdminReports from "./email-admin-reports/routes"
import * as user from "./user/routes"
import * as carts from "./carts/routes"
import * as requestPasswordReset from "./request-password-reset/routes"
import * as resetPassword from "./reset-password/routes"
import * as dealerAnalytics from "./dealer-analytics/routes"
import * as AllDealers  from "./all-dealers/routes"
import usermanagement from "./user-management/routes"
import ensureLatestAppVersion from "./common/ensure-latest-app-version"

ensureLatestAppVersion(localStorage)
setInterval(function () {
  ensureLatestAppVersion(localStorage)
}, 30000) // 30 seconds //1800000) // 30 minutes

var mountNode = document.getElementById("app")

if (localStorage.sessionId) {
  useSessionId(localStorage.sessionId)
}

window.addEventListener(
  "storage",
  function (event) {
    if (event.key === "sessionId") {
      useSessionId(localStorage.sessionId)
    }
  },
  false
)

// middleware
page((context, next) => {
  changeBrowserState(context)
  next()
})

// routes
productRoute(page, mountNode)
reportRoute(page, mountNode)
productRoutes(page, mountNode)
cartRoutes(page, mountNode)
webOrderRoutes(page, mountNode)
webOrdersRoutes(page, mountNode)
invoicesRoutes(page, mountNode)
vendorListRoutes(page, mountNode)
newVendorRoutes(page, mountNode)
statementsRoutes(page, mountNode)
activityRoutes(page, mountNode)
editProductRoutes(page, mountNode)
usermanagement(page, mountNode)

page("/users/login", () => {
  page.redirect("/")
})

// reset password
page("/request-password-reset", requestPasswordReset.enter)
page.exit("/request-password-reset", requestPasswordReset.exit)
page("/reset-password/:token", resetPassword.enter)
page.exit("/reset-password/:token", resetPassword.exit)

// carts
page("/carts", carts.enter)
page.exit("/carts", carts.exit)

// files
page("/files/:fileId", file.enter)
page.exit("/files/:fileId", file.exit)
page("/files", files.enter)
page.exit("/files", files.exit)

// dealers
page("/dealers/:dealerId", dealer.enter)
page.exit("/dealers/:dealerId", dealer.exit)
page("/dealers", dealers.enter)
page.exit("/dealers", dealers.exit)

page("/all-dealers", AllDealers.enter)
page.exit("all-dealers", AllDealers.exit)

// users
page("/users/:userId", user.enter)
page.exit("/users/:userId", user.exit)
page("/users", users.enter)
page.exit("/users", users.exit)

// emails for admin section
page("/emails", emails.enter)
page.exit("/emails", emails.exit)

// inbox
page("/inbox", inbox.enter)
page.exit("/inbox", inbox.exit)
page("/inbox-message/:messageId", inboxMessage.enter)
page.exit("/inbox-message/:messageId", inboxMessage.exit)

// email admin
page("/email-admin", emailAdmin.enter)
page.exit("/email-admin", emailAdmin.exit)
page("/email-admin-compose", emailAdminCompose.enter)
page.exit("/email-admin-compose", emailAdminCompose.exit)
page("/email-admin-sent", emailAdminSent.enter)
page.exit("/email-admin-sent", emailAdminSent.exit)
page("/email-admin-sent-message/:messageId", emailAdminSentMessage.enter)
page.exit("/email-admin-sent-message/:messageId", emailAdminSentMessage.exit)
page("/email-admin-insight", emailAdminInsight.enter)
page.exit("/email-admin-insight", emailAdminInsight.exit)
page("/email-admin-insight-messages/:id", emailAdminInsightMessages.enter)
page.exit("/email-admin-insight-messages/:id", emailAdminInsightMessages.exit)
page(
  "/email-admin-insight-del-messages/:id",
  emailAdminInsightDelMessages.enter
)
page.exit(
  "/email-admin-insight-del-messages/:id",
  emailAdminInsightDelMessages.exit
)
page("/email-admin-reports", emailAdminReports.enter)
page.exit("/email-admin-reports", emailAdminReports.exit)

// categories
page("/categories", categories.enter)
page.exit("/categories", categories.exit)
page("/categories/:categoryId", category.enter)
page.exit("/categories/:categoryId", category.exit)

page("/profile", profile.enter)
page.exit("/profile", profile.exit)

page("/dealer-analytics", dealerAnalytics.enter)
page.exit("/dealer-analytics", dealerAnalytics.exit)

page("/admin", admin.enter)
page.exit("/admin", admin.exit)

page("/logout", () => {
  destroySession()
  page("/")
})

// pages
pagesRoutes(page, mountNode)
comingSoonRoutes(page, mountNode)

// 404
page("*", (context) => {
  ReactDOM.render(
    <div style={{ margin: 32, fontSize: "1.3rem" }}>Not found</div>,
    mountNode
  )
})

if (!global.Intl) {
  // eslint-disable-next-line no-restricted-properties
  require.ensure(
    ["intl", "intl/locale-data/jsonp/en.js", "intl/locale-data/jsonp/fr.js"],
    function (require) {
      require("intl")
      require("intl/locale-data/jsonp/en.js")
      require("intl/locale-data/jsonp/fr.js")
      page({ click: true })
    }
  )
} else {
  page({ click: true })
}

if (!window.EventSource) {
  console.error("EventSource not supported on this browser.")
}
