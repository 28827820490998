import throttle from 'lodash/throttle'
import { dispatch, getState } from '../store'
import { GET_AGGREGATED_CATEGORIES } from '../constants/action-types'



function receiveAggregatedCategories (response) {
    return {
        type: GET_AGGREGATED_CATEGORIES,
        response
    }
}


function fetchAggregatedCategories (categoryType) {
    fetch(`/api/categories/aggregate?categoryType=${categoryType}`, {
        headers: {
            'x-session-id': getState().session.id
        }
    }).then(function (response) {
        if (response.ok) {
            return response.json()
        }
        throw new Error('Network response was not ok.')
    }).then(function (response) {
        dispatch(receiveAggregatedCategories(response))
    }).catch(function (error) {
        console.log('There has been a problem with your fetch operation: ', error.message)
    })
}

export const getAggregatedCategories = throttle(fetchAggregatedCategories, 1000, {   
    leading: true,
    trailing: true
})