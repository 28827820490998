import React from 'react'
import { findTranslation } from '../../../common/translation'
import numeral from 'numeral'
import { changeCartItemQuantity } from '../actions'
import makeProductDataPresentable from '../../../common/make-product-data-presentable'
import getRelevantWarehouses from '../../../common/get-relevant-warehouses'
import warehouses from '../../../constants/warehouses'
import imageToSrcset from '../../../transforms/image-to-srcset'
import classnames from 'classnames'
import Availability from '../../common/availability'
import { FormattedMessage } from 'react-intl'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayItem: 'CartItem',

  getInitialState () {
    return {
      isUpdating: false,
      isDeleting: false,
      error: false,
      quantity: this.props.item.quantity
    }
  },

  componentWillUpdate (nextProps, nextState) {
    const isQuantitySynced = nextProps.item.quantity === this.state.quantity
    if (this.state.error && !isQuantitySynced) {
      this.refs.quantity.value = nextProps.item.quantity
      this.setState({
        quantity: this.props.item.quantity,
        isUpdating: false
      })
      return
    }

    if (this.state.isUpdating && isQuantitySynced) {
      this.setState({ isUpdating: false })
      return
    }

    if (!this.state.isUpdating && !isQuantitySynced) {
      this.refs.quantity.value = nextProps.item.quantity
      this.setState({ quantity: this.props.item.quantity })
    }
  },

  resetInput () {
    this.refs.quantity.value = this.props.item.quantity
    this.setState({
      quantity: this.props.item.quantity
    })
  },

  handleQuantityChange (event) {
    let quantity = parseInt(event.target.value, 10) || 1
    if (!quantity) {
      event.preventDefault()
      return false
    }

    this.setState({
      isUpdating: true,
      error: false,
      quantity
    })
    clearTimeout(this.timeout)
    changeCartItemQuantity(
      this.props.item.distributorPartNumber,
      quantity
    ).catch(() => {
      this.setState({
        isUpdating: false,
        error: true
      })
      this.resetInput()
    })
  },

  componentWillUnmount () {
    clearTimeout(this.timeout)
  },

  handleKeyUp (event) {
    if (!parseInt(event.target.value, 10)) {
      event.target.value = 1
    }
  },

  handleKeyPress (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      const currentInput = event.target
      const inputs = document.querySelectorAll('#atlas-cart input')
      const indexOfCurrentInput = Array.prototype.indexOf.call(
        inputs,
        currentInput
      )
      if (indexOfCurrentInput < inputs.length - 1) {
        inputs[indexOfCurrentInput + 1].focus()
      }
    }
  },

  handleDelete (event) {
    event.preventDefault()
    this.setState({
      isDeleting: true
    })
    this.props.onDelete().catch(() => {
      this.setState({
        isDeleting: false,
        error: true
      })
    })
  },

  render () {
    const isOnline = this.props.isOnline
    const isReviewing = this.props.isReviewing
    const isAdding = this.props.item.isAdding
    const item = this.props.item
    const product = item.product.attributes
    const language = this.props.language
    const imageSrcset = imageToSrcset(product.images && product.images[0])
    const inventory = product.inventory || []
    const warehouseCode = this.props.warehouseCode
    const isPreview = this.props.isPreview
    // const isAdministrator = this.props.isAdministrator
    const clearanceQty = product.categoryIds.indexOf("ffefca7d-a309-4a7c-8b93-34c7d76a9712") > -1?product.clearanceQty:0
    // Identify if product is new by checking if it is in the new category
  const isNewProduct = product?.categoryIds?.includes('9daf56fb-e712-4daf-81bb-e28d09377a0d')

  // Identify if product is on clearance by checking if it is in the clearance category
  const isClearanceProduct = product?.categoryIds?.includes('ffefca7d-a309-4a7c-8b93-34c7d76a9712')
    const {
      title,
      shortDescription,
      manufacturerName
    } = makeProductDataPresentable({
      title: findTranslation(product.title, language.code),
      shortDescription: findTranslation(
        product.shortDescription,
        language.code
      ),
      longDescription: findTranslation(product.longDescription, language.code),
      manufacturerName: product.manufacturerName,
      manufacturerPartNumber: product.manufacturerPartNumber
    })
    const dealerPrice =
      product.dealerPrice && numeral(product.dealerPrice).format('$0,0.00')
    const FXSurcharge =
      product.FXSurcharge && numeral(product.FXSurcharge).format('$0,0.00')
    const clearancePrice =
      product.clearancePrice &&
      numeral(product.clearancePrice).format('$0,0.00')
    const flyerPrice =
      product.flyerPrice && numeral(product.flyerPrice).format('$0,0.00')
    // const retailPrice = product.retailPrice && numeral(product.retailPrice).format('$0,0.00')
    const unitOfMeasure =
      (clearancePrice || flyerPrice || dealerPrice) &&
      product.unitOfMeasure &&
      `(${product.unitOfMeasure})`

    const relevantWarehouses = getRelevantWarehouses({
      primaryWarehouseCode: warehouseCode,
      quantity: item.quantity,
      inventory,
      warehouses
    })

    const trClasses = classnames('atlas-cart-item', {
      'atlas-cart-item--deleting': this.state.isDeleting,
      'atlas-cart-item--error': this.props.item.error || this.state.error,
      'atlas-cart-item--updating':
        isAdding || this.props.item.isUpdating || this.state.isUpdating
    })

    return (
      <tr className={trClasses} key={item.id}>
        {!isReviewing &&
          <td className="atlas-cart-item-delete">
            {this.props.onDelete &&
              !isAdding &&
              !this.state.isDeleting &&
              isOnline &&
              <button
                className="btn btn-danger btn-sm"
                onClick={this.handleDelete}
              >
                <FormattedMessage defaultMessage="Remove" id="Remove" />
              </button>}
          </td>}
        <td className="atlas-cart-item-part-description-cell">
          <div className="atlas-cart-item-part-description-container">
            <div className="atlas-cart-item-image-container">
            {
              isNewProduct && <div className='new-badge small'/>
            }
            {
              isClearanceProduct && <div className='clearance-badge small'>
              </div>
            }
              {imageSrcset &&
                <img
                  className="atlas-detailed-list-product-image"
                  sizes="80px"
                  srcSet={imageSrcset}
                  alt={title || shortDescription}
                  style={{ maxHeight: 100 }}
                />}
            </div>
            <div className="atlas-cart-item-product-info">
              <div className="atlas-cart-item-part-description-title">
                {title || shortDescription}
              </div>
              <div className="atlas-cart-item-part-number">
                Atlas #: {item.distributorPartNumber}
              </div>
              <div>
                {manufacturerName &&
                  product.manufacturerPartNumber &&
                  `${manufacturerName} #: ${product.manufacturerPartNumber}`}
              </div>
            </div>
          </div>
        </td>
        {!isReviewing &&
          <td className="atlas-cart-item-availability">
            <Availability
              language={language}
              primaryWarehouseCode={warehouseCode}
              quantity={item.quantity}
              warehouses={relevantWarehouses}
              clearanceQty={clearanceQty}
            />
          </td>}
        <td className="atlas-cart-item-quantity">
          {(clearancePrice || flyerPrice || dealerPrice) &&
            !isReviewing &&
            !isPreview &&
            isOnline &&
            <div>
              <input
                data-part-number={item.distributorPartNumber}
                defaultValue={item.quantity}
                min={1}
                onChange={this.handleQuantityChange}
                onKeyPress={this.handleKeyPress}
                onKeyUp={this.handleKeyUp}
                ref="quantity"
                step={1}
                style={{ width: 60 }}
                type="number"
              />
              {(this.props.item.error || this.state.error) &&
                <div style={{ fontSize: '0.7rem', color: 'red' }}>
                  Error updating
                </div>}
            </div>}

          {(isReviewing || !isOnline) &&
            <span>
              {item.quantity}
            </span>}
        </td>
        <td className="atlas-cart-item-unit-price-cell">
          <span className="atlas-cart-item-unit-price">
            {clearancePrice || flyerPrice || dealerPrice || ''}
          </span>
          {unitOfMeasure && ` ${unitOfMeasure}`}
        </td>
        <td className="atlas-cart-item-unit-price-cell">
          <span className="atlas-cart-item-unit-price">
            {product.FXSurcharge ? (numeral(product.FXSurcharge * (item.quantity || 0)).format('$0,0.00')) : ""}
          </span>
        </td>
        <td className="atlas-cart-item-extended-price">
          {Boolean(item.quantity) &&
            numeral(
              (product.clearancePrice ||
                product.flyerPrice ||
                product.dealerPrice) * (item.quantity || 0) +
              (product.FXSurcharge || 0) * (item.quantity || 0)
            ).format('$0,0.00')}
        </td>
      </tr>
    )
  }
})
