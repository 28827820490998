import {
    LOAD_INSIGHTS_PENDING,
    GET_INSIGHTS_SUCCESSFUL,
    GET_INSIGHTS_ERROR,
    CLEAR_INSIGHTS,
    LOAD_INSIGHTS_SUCCESSFUL,
    INSIGHTS_SEARCH_LIMIT
} from './constants'
import moment from 'moment'

var initialState = {
    data: [],
    meta: {
        total: 0,
        limit: 0,
        offset: 0
    },
    totalData:0,
    isLoading: false,
    wasLoadSuccessful: false,
    error: null
}

function hasMore(response) {
    return (
        response.data.length !== 1 &&
        response.meta.offset + INSIGHTS_SEARCH_LIMIT < response.meta.total
    )
}

export default function insights(state = initialState, { type, offset, response }) {
    switch (type) {
        case LOAD_INSIGHTS_PENDING:
            return {
                ...state,
                isLoading: true,
                wasLoadSuccessful: false,
                error: null
            }

        case GET_INSIGHTS_SUCCESSFUL:
            return {
                ...state,
                ...response,
                isLoading: false,
                wasLoadSuccessful: true,
                data: response
            }

        case LOAD_INSIGHTS_SUCCESSFUL:
            return {
                ...state,
                ...response,
                isLoading: false,
                wasLoadSuccessful: true,
                groupedData: response,
                // totalData: response.data,
                // data: groupAndCount(response.data),
                totalData: response,
                data: response.slice(0,8),
            }

        case GET_INSIGHTS_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                wasLoadSuccessful: false,
                error: response.error
            }

        case CLEAR_INSIGHTS:
            return {
                ...state,
                data: []
            }

        default:
            return state
    }
}

function groupAndCount(data) {
    // group and count emails
    let map = new Map()
    for (const item of data) {
        const date = item.data.BouncedAt ? moment( item.data.BouncedAt).format('YYYY-MM-DD') : 'undefined'
        const s = item.data.Subject + date
        if (!map.has(s)) {
            map.set(s, {
                Subject: item.data.Subject,
                BouncedAt: date,
                Email: item.data.Email,
                count: 1,
                groupedId: s
            });
        } else {
            map.get(s).count++;
        }
    }
    const groupedEmails = Array.from(map.values())
    return groupedEmails
}
