import React from 'react'
import ReactDOM from 'react-dom'
import appActions from '../actions/app'

import App from '../common/app'
import ProductComponent from './component'

function render (mountNode, partNumber) {
  return function () {
    ReactDOM.render(
      <App active="products" {...atlas.getState()} {...appActions}>
        <ProductComponent
          {...atlas.getState()}
          {...appActions}
          partNumber={partNumber}
        />
      </App>,
      mountNode
    )
  }
}

let unsubscribe = function () {}

export default function (app, mountNode) {
  app('/products/:partNumber', function (context) {
    unsubscribe = atlas.subscribe(render(mountNode, context.params.partNumber))
    // getProducts()
    render(mountNode, context.params.partNumber)()
  })

  app.exit('/products/:partNumber', (context, next) => {
    unsubscribe()
    next()
  })
}
