import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import App from '../common/app'
import CartComponent from './components/index'

function render (mountNode) {
  return function () {
    ReactDOM.render(
      <App active="cart" {...getState()} {...appActions}>
        <CartComponent {...getState()} {...appActions} />
      </App>,
      mountNode
    )
  }
}

let unsubscribe = function () {}

export default function (app, mountNode) {
  app('/cart', context => {
    unsubscribe = subscribe(render(mountNode))

    render(mountNode)()
  })

  app.exit('/cart', (context, next) => {
    unsubscribe()
    next()
  })

  app('/order-successful', () => {
    ReactDOM.render(
      <App active="cart" {...getState()} {...appActions}>
        <div
          style={{
            padding: 68,
            textAlign: 'center',
            fontSize: '1.5rem'
          }}
        >
          Order successful.
        </div>
      </App>,
      mountNode
    )
  })
}
