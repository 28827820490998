import Qs from 'qs'
import {
  CHANGE_SEARCH_QUERY,
  CHANGE_BROWSER_STATE,
  CHANGE_ONLINE_STATUS
} from '../constants/action-types'

const initialState = {
  query: {},
  pathname: null,
  isOnline: navigator.onLine
}

export default function browser (state = initialState, action) {
  switch (action.type) {
    case CHANGE_SEARCH_QUERY:
      const newState = {
        ...state,
        query: {
          ...state.query,
          q: action.query,
          pagenum: action.pagenum
        }
      }
      return newState

    case CHANGE_BROWSER_STATE:
      const query = Qs.parse(action.context.querystring)
      return {
        ...state,
        query,
        pathname: action.context.pathname
      }

    case 'GET_CART_SUCCESSFUL':
      return {
        ...state,
        isOnline: true
      }

    case CHANGE_ONLINE_STATUS:
      return {
        ...state,
        isOnline: action.isOnline
      }

    default:
      return state
  }
}
