import React from 'react'
import { confirmAlert } from 'react-confirm-alert'


export default class Dealers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: '',
            filteredBy: 'all'
        }
    }

    onActivateAll = () => {
        confirmAlert({
            title: 'Activate all Dealers?',
            message: 'Are you sure you want to activate all dealers?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.onActivateAll && this.props.onActivateAll()
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }

            ]
        })
    }

    onImport = () => {
        // open file dialog
        document.getElementById('file').click()
        // when file is selected, upload it
        document.getElementById('file').onchange = () => {
            const file = document.getElementById('file').files[0]
            this.props.onImport && this.props.onImport(file)
        }
    }

    onDeactivateAll = () => {
        confirmAlert({
            title: 'Deactivate all dealers?',
            message: 'Are you sure want to deactivate all dealers?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.onDeactivateAll && this.props.onDeactivateAll()
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]

        })
    }

    onSwitch = (dealer) => {
        const disabled = (dealer.attributes.disabled === true || dealer.attributes.disabled === 'true') ? false : true
        const keyword = (dealer.attributes.disabled === true || dealer.attributes.disabled === 'true') ? 'activate' : 'deactivate'
        confirmAlert({
            title: `${keyword} ${dealer.attributes.name}?`,
            message: `Are you sure you want to ${keyword} ${dealer.attributes.name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.onSwitch && this.props.onSwitch(dealer.id, disabled)
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        })
    }

    onSearch = (e) => {
        this.setState({ searchTerm: e.target.value })
    }

    onFilter = (e) => {
        this.setState({ filteredBy: e.target.value })
    }

    render() {
        return (
            <div className="container-fluid" style={{ marginTop: '20px' }}>
                <div className="row">

                    <div className="col-md-6 text-right" style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                        <a href="/dealers/new" className="btn btn-primary">Create Dealer</a>
                        <button className="btn btn-secondary" onClick={this.onActivateAll}>Activate All</button>
                        <button className="btn btn-secondary" onClick={this.onDeactivateAll}>Deactivate All</button>
                        <button className="btn btn-outline-success" onClick={this.onImport}>Import</button>
                        {this.props.isUpdating && <span>Uploading...</span>}
                        <a className="btn btn-success" href="/api/consumernet-dealers/export" target="_blank">Export</a>
                        <input type="file" id="file" style={{ display: 'none' }} />
                    </div>
                    <div className="col-md-2">
                        <input type="text" className="form-control" placeholder="Search" onChange={this.onSearch} />
                    </div>
                    <div className="col-md-4" style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                        <p> Active Dealers: {this.props.data.filter((dealer) => dealer.attributes.disabled === false || dealer.attributes.disabled === 'false').length} &nbsp; - &nbsp;
                            Inactive Dealers: {this.props.data.filter((dealer) => dealer.attributes.disabled === true || dealer.attributes.disabled === 'true').length}</p>
                        <select className="form-control" onChange={this.onFilter}>
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Cover Photo</th>
                                    <th>Dealer Logo</th>
                                    <th>Dealer Name</th>
                                    <th>Catalog URL</th>
                                    <th>Primary URL</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.data.filter((dealer) => {
                                    if (this.state.filteredBy === 'all') {
                                        return true
                                    } else if (this.state.filteredBy === 'active') {
                                        return dealer.attributes.disabled === false || dealer.attributes.disabled === 'false'
                                    } else if (this.state.filteredBy === 'inactive') {
                                        return dealer.attributes.disabled === true || dealer.attributes.disabled === 'true'
                                    }
                                }
                                ).filter((dealer) => {
                                    return dealer.attributes.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) >= 0
                                })
                                    .map((dealer, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <img src={dealer.attributes.coverImageUrl} alt="" style={{ width: '50px' }} />
                                                </td>
                                                <td>
                                                    <img src={dealer.attributes.logoUrl} alt="" style={{ width: '50px' }} />
                                                </td>
                                                <td>
                                                    <div>
                                                        <a href={`/dealers/${dealer.id}`}>
                                                            {dealer.attributes.name}
                                                        </a>
                                                        {dealer.attributes.rvpf &&
                                                            <span className="label label-default" style={{ marginLeft: 6 }}>
                                                                RVPF
                                                            </span>}
                                                    </div>
                                                    <div>
                                                        {dealer.attributes.accountNumber}
                                                    </div>
                                                </td>
                                                <td>
                                                    <a href={dealer.attributes.catalogUrl} target="_blank" rel="noreferrer">{dealer.attributes.catalogUrl}</a>
                                                </td>
                                                <td>
                                                    <a href={dealer.attributes.primaryUrl} target="_blank" rel="noreferrer">{dealer.attributes.primaryUrl}</a>
                                                </td>
                                                <td>
                                                    {dealer.attributes.disabled === true || dealer.attributes.disabled === 'true' ? 'Inactive' : 'Active'}
                                                </td>
                                                <td>
                                                    <button className={"btn " + (dealer.attributes.disabled === true || dealer.attributes.disabled === 'true' ? 'btn-success' : 'btn-danger')} onClick={() => this.onSwitch(dealer)}>
                                                        {(dealer.attributes.disabled === true || dealer.attributes.disabled === 'true') ? 'Activate' : 'Deactivate'}
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}