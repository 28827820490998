import React from 'react'
import createReactClass from 'create-react-class'
import EmailAdminMenu from '../../email-admin-menu/components'

import { getUndeliveredMessages } from '../actions'

export default createReactClass({
  displayName: 'EmailReports',


  getInitialState() {
    return {
    }
  },

  downloadFile(event) {
    event.preventDefault()
    const data = this.props.reports.data
    
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })

    saveAs(blob, `bounced-${new Date().toISOString().slice(0, 10)}.csv`);
  },

  render() {
    //console.log(this.props.reports)
    return (
      <div>
        <EmailAdminMenu></EmailAdminMenu>
        <div className="atlas-email-admin-main">
          <div className="nice-outline">
            <div className="centre-screen">
            <label for="cars" className="report-label">Choose a report:</label>
            <select name="reports" id="repors">
              <option value="volvo">All Undelivered Emails</option>
            </select>
            </div>
            <div className="slide-right">
              <span className="atlas-email-admin-button" onClick={this.downloadFile}>Download</span>
            </div>
          </div>
        </div>
      </div>

    )
  }
})
