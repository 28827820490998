import {
  GET_MY_INVOICES_PENDING,
  GET_MY_INVOICES_SUCCESSFUL,
  GET_MY_INVOICES_ERROR
} from './constants'
import { dispatch, getState } from '../store'
import moment from 'moment'
export function getMyInvoices (options = {}) {
  dispatch({
    type: GET_MY_INVOICES_PENDING
  })
  let query = '?type=invoice'
  if (options.invNum) query += '&invNum=' + options.invNum
  if (options.acctNo) query += '&acctNo=' + options.acctNo
  if (options.searchPO) query += '&custPO=' + options.searchPO
  if (options.partNum) query += '&partNum=' + options.partNum
  if (options.ordDateFrom) query += '&ordDateFrom=' + moment(options.ordDateFrom).toISOString(true).slice(0,23)
  if (options.ordDateTo) query += '&ordDateTo=' + moment(options.ordDateTo).toISOString(true).slice(0,23)
  if (options.invDateFrom) query += '&invDateFrom=' + moment(options.invDateFrom).toISOString(true).slice(0,23)
  if (options.invDateTo) query += '&invDateTo=' + moment(options.invDateTo).toISOString(true).slice(0,23)
  if (options.shipDateFrom) query += '&shipDateFrom=' + moment(options.shipDateFrom).toISOString(true).slice(0,23)
  if (options.shipDateTo) query += '&shipDateTo=' + moment(options.shipDateTo).toISOString(true).slice(0,23)
  fetch('/api/getFiles/' + query, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_MY_INVOICES_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.error(error)
      dispatch({
        type: GET_MY_INVOICES_ERROR,
        error
      })
    })
    
}
