import React from 'react'
import CartItem from './item'
import { removeCartItem } from '../actions'
import numeral from 'numeral'
import QuickAdd from './quick-add'
import { FormattedMessage } from 'react-intl'

export default class CartTable extends React.Component {
  componentDidMount () {
    if (this.props.browser.query.part) {
      const input = document.querySelector(
        `input[data-part-number='${this.props.browser.query.part}']`
      )
      if (!input) {
        return
      }
      input.focus()
      input.select()
    }
  }

  render () {
    const isOnline = this.props.browser.isOnline
    const cart = this.props.cart
    const language = this.props.session.language
    const warehouseCode = this.props.session.user.warehouseCode
    const isReviewing = this.props.isReviewing
    const isAdministrator = this.props.session.user.roles.includes(
      'Administrator'
    )

    const cartItemComponents = cart.data.items.map(item => {
      return (
        <CartItem
          isAdministrator={isAdministrator}
          isOnline={isOnline}
          isReviewing={isReviewing}
          item={item}
          key={item.distributorPartNumber}
          language={language}
          onDelete={removeCartItem.bind(null, item.distributorPartNumber)}
          warehouseCode={warehouseCode}
        />
      )
    })

    const hasQuickOrderQuery = this.props.cart.quickOrderQuery
    let quickOrderResult
    let quickOrderResultIsExactMatch = false
    if (this.props.products.data.length) {
      quickOrderResult = {
        distributorPartNumber: this.props.products.data[0].attributes
          .distributorPartNumber,
        quantity: 1,
        product: this.props.products.data[0]
      }
      quickOrderResultIsExactMatch =
        quickOrderResult &&
        this.props.cart.quickOrderQuery &&
        (quickOrderResult.distributorPartNumber ===
          this.props.cart.quickOrderQuery ||
          quickOrderResult.distributorPartNumber.replace('-', '') ===
            this.props.cart.quickOrderQuery.replace('-', ''))
    }
    const showCartItem = hasQuickOrderQuery && quickOrderResultIsExactMatch

    let surchargeSubtotal = 0.0
    for (let i in cart.data.items) {
      if (cart.data.items[i].product.attributes.FXSurcharge) {
      surchargeSubtotal = surchargeSubtotal + (cart.data.items[i].quantity * cart.data.items[i].product.attributes.FXSurcharge)
      }
    }
    const subtotal = numeral(cart.orderSubtotal).format('$0,0.00')
    const fillableSubtotal = cart.data.items.reduce((total, item) => {
      const quantityAvailable = getQuantityOfWarehouseCode(
        (item.product.attributes && item.product.attributes.inventory) || [],
        warehouseCode
      )
      let quantityFillable
      if (item.quantity > quantityAvailable) {
        quantityFillable = quantityAvailable
      } else {
        quantityFillable = item.quantity
      }
      if (quantityFillable < 0) quantityFillable = 0
      const fillableSubtotalForItem =
        (quantityFillable || 0) *
        ((item.product.attributes && item.product.attributes.dealerPrice) || 0)
      return total + fillableSubtotalForItem
    }, 0)
    const formattedFillableSubtotal = numeral(fillableSubtotal).format(
      '$0,0.00'
    )

    return (
      <table
        className="atlas-cart-table table table-striped table-sm table-bordered"
        id="atlas-cart-table"
      >
        <thead>
          <tr>
            {!isReviewing && <th style={{ minWidth: 87 }} />}
            <th>
              <FormattedMessage defaultMessage="Product" id="Product" />
            </th>
            {!isReviewing &&
              <th>
                <FormattedMessage
                  defaultMessage="Availability"
                  id="Availability"
                />
              </th>}
            <th>
              <FormattedMessage defaultMessage="Quantity" id="Quantity" />
            </th>
            <th style={{ textAlign: 'right' }}>
              <FormattedMessage defaultMessage="Unit price" id="Unit price" />
            </th>
            <th style={{ textAlign: 'right' }}>
              <FormattedMessage defaultMessage="FX ($)" id="FX ($)" />
            </th>
            <th style={{ textAlign: 'right' }}>
              <FormattedMessage
                defaultMessage="Extended price"
                id="Extended price"
              />
            </th>
          </tr>
        </thead>
        <tbody id="atlas-cart-items-tbody">
          {cartItemComponents}
        </tbody>
        <tbody>
          {!isReviewing &&
            isOnline &&
            <tr>
              <td />
              <td style={{ padding: 16 }}>
                <QuickAdd {...this.props} />
              </td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>}
          {showCartItem
            ? <CartItem
                isPreview={true}
                isReviewing={false}
                item={quickOrderResult}
                key={quickOrderResult.id}
                language={language}
                warehouseCode={warehouseCode}
              />
            : <tr />}
        </tbody>
        <tfoot>
          <tr>	
            {!isReviewing && <th />}	
            <th colSpan={isReviewing ? 3 : 4} style={{ textAlign: 'right' }}>	
              <FormattedMessage	
                defaultMessage="FX Subtotal:"	
                id="FX Surcharge subtotal:"	
              />	
            </th>	
            <th style={{ textAlign: 'right' }}>	
              {numeral(surchargeSubtotal).format('$0,0.00')}	
            </th>	
            <th/>	
          </tr>
          <tr>
            {!isReviewing && <th />}
            <th colSpan={isReviewing ? 3 : 4} style={{ textAlign: 'right' }}>
              <FormattedMessage
                defaultMessage="Fillable subtotal:"
                id="Fillable subtotal:"
              />
            </th>
            <th style={{ textAlign: 'right' }}>
              {formattedFillableSubtotal}
            </th>
            <th/>
          </tr>
          <tr>
            {!isReviewing && <th />}
            <th colSpan={isReviewing ? 3 : 4} style={{ textAlign: 'right' }}>
              <FormattedMessage defaultMessage="Total:" id="Subtotal:" />
            </th>
            <th style={{ textAlign: 'right' }}>
              {subtotal}
            </th>
            <th/>
          </tr>
        </tfoot>
      </table>
    )
  }
}

function getQuantityOfWarehouseCode (inventory, warehouseCode) {
  return inventory.reduce((quantity, warehouse) => {
    if (warehouse.warehouseCode === warehouseCode) {
      return warehouse.quantity
    }
    return quantity
  }, 0)
}
