import {
  GET_DEALERS_PENDING,
  GET_DEALERS_SUCCESSFUL,
  GET_DEALERS_ERROR,
  CLEAR_DEALERS,
  UPDATE_SOME_DEALERS,
  UPDATING_DEALERS,
} from './constants'
import { dispatch, getState } from '../store'

let lastPath

export function getDealers (options = {}) {
  dispatch({
    type: GET_DEALERS_PENDING
  })

  fetch('/api/dealers', {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_DEALERS_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      dispatch({
        type: GET_DEALERS_ERROR,
        error
      })
    })
}

export function searchDealers(query, pagenum, pageSize, lang) {
    if (!pagenum) pagenum = 1;
    if (!pageSize) pageSize = 25;
    if (!lang) lang = ""
    let sortBy = 'date';
    dispatch({
        type: CLEAR_DEALERS
    })
    let offset = pageSize * (+pagenum - 1)

    const path = `/api/dealers-search?q=${JSON.stringify(query)}&offset=${offset}&limit=${pageSize}&sortBy=${sortBy}&lang=${lang}`
    lastPath = path
    
    dispatch({
        type: GET_DEALERS_PENDING
    })
    fetchDealers(path)
}

function fetchDealers(path) {
    fetch(path, {
        headers: {
            'x-session-id': getState().session.id
        }
    }).then(function (response) {
        if (response.ok) {
            return response.json()
        }
        throw new Error('Message search failed')
    }).then(function (response) {
        const isSamePath = path === lastPath
        if (isSamePath) {
            dispatch({
                type: GET_DEALERS_SUCCESSFUL,
                response
            })
        }
    }).catch(function (err) {
        console.log('parsing failed', err)
    })
}


export function switchDealerStatus (dealerId, disabled = false){
  fetch(`/api/switch-dealer?dealerId=${dealerId}`, {
    method: 'PUT',
    headers: {
      'x-session-id': getState().session.id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      disabled
    })
  }).then(function (response) {
    if (response.ok) {
      getDealers()
    }
  }).catch(function (err) {
    console.log('parsing failed', err)
  }
  )

}

export function activateAllDealers () {
  fetch('/api/enable-dealers', {
    method: 'PUT',
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(function (response) {
    if (response.ok) {
      getDealers()
    }
  }).catch(function (err) {
    console.log('parsing failed', err)
  }
  )
}

export function deactivateAllDealers () {
  fetch('/api/disable-dealers', {
    method: 'PUT',
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(function (response) {
    if (response.ok) {
      getDealers()
    }
  }).catch(function (err) {
    console.log('parsing failed', err)
  }
  )
}

export function uploadDealers (file, callback = () => {}) {
  dispatch({
    type: UPDATING_DEALERS
  })
  const formData = new FormData()
  formData.append('file', file)
  fetch('/api/consumernet-dealers/digest', {
    method: 'POST',
    body: formData,
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(res => res.json()).catch(err => console.log(err))
    .then(json => 
      dispatch({
        type: UPDATE_SOME_DEALERS,
        response: json
      })
    ).finally(() => callback())
}