import React, { Component } from 'react'
import { findTranslation } from '../../common/translation'
import PropTypes from 'prop-types'

export default class Availability extends Component {
  constructor (props) {
    super(props)
    this.state = {
      processingnotification: false
    }
  }
  handleRequestNotification = async () => {
    // console.log(
    //   { processingnotification: this.state.processingnotification,
    //     id: this.props.productId,
    //     subscribeToProductUpdates: this.props.subscribeToProductUpdates
    //    }
    // )
    if(this.state.processingnotification) return
    await this.props.subscribeToProductUpdates(this.props.productId)
    this.setState({ processingnotification: true })
  }
  render () {
    const compact = this.props.compact
    const language = this.props.language
    const quantity = this.props.quantity
    const primaryWarehouseCode = this.props.primaryWarehouseCode
    const isPrivileged = this.props.isPrivileged
    const isCumulativeSummary = this.props.isCumulativeSummary
    const clearanceQty = this.props.clearanceQty
    const showNotifyMe = this.props.showNotifyMe
    let availableAtPrimaryWarehouse = true
    // show notify me button only if the window is not on the cart page
    // const showNotifyMe = window.location.pathname.toLowerCase() !== '/cart'

    if (isCumulativeSummary) {
      const cumulativeAvailable = this.props.warehouses.reduce(
        (state, warehouse) => {
          return state + (warehouse.quantity || 0)
        },
        0
      )    
      let icon
      if (cumulativeAvailable - (clearanceQty || 0) > 0) {
        icon = (
          <img
            alt="Available"
            className="atlas-cart-item-availability-check"
            src="/img/check.svg"
          />
        )
      } else {
        icon = (
          <img
            alt="Not available"
            className="atlas-cart-item-availability-times"
            src="/img/times.svg"
          />
        )
      }
      let quantityAvailableLabel
      if (cumulativeAvailable - (clearanceQty || 0)  > 0) {
        quantityAvailableLabel = (
          <span className="atlas-cart-item-availability-quantity">
            {cumulativeAvailable  - (clearanceQty || 0)}
            {!compact && ' available'}
          </span>
        )
      }
      return (
        <div>
          <div className="atlas-cart-item-availability-warehouse">
            {icon}
            &nbsp;
            {quantityAvailableLabel}
          </div>
        </div>
      )
    }

    const warehouseComponents = this.props.warehouses.map(warehouse => {
      const isPrimaryWarehouse =
        warehouse.warehouseCode === primaryWarehouseCode
      const name = findTranslation(warehouse.name, language.code)
      const isQuantityOverAvailability = quantity > (warehouse.quantity - clearanceQty)

      if (isPrimaryWarehouse && warehouse.quantity <= 0) {
        availableAtPrimaryWarehouse = false
      }

      if (!isPrivileged) {
        if (compact && !isPrimaryWarehouse && quantity <= 0) {
          return null
        }

        if (
          availableAtPrimaryWarehouse &&
          !isPrimaryWarehouse &&
          quantity <= 0
        ) {
          return null
        }

        if (
          !availableAtPrimaryWarehouse &&
          !isPrimaryWarehouse &&
          quantity <= 0 &&
          warehouse.quantity <= 0
        ) {
          return null
        }

        // if (
        //   !quantity &&
        //   isPrimaryWarehouse &&
        //   compact &&
        //   warehouse.quantity <= 0
        // ) {
        //   // replace later with third icon?
        //   return <div>null3</div>
        // }
      }

      let icon
      if (warehouse.quantity > 0) {
        icon = (
          <img
            alt="Available"
            className="atlas-cart-item-availability-check"
            src="/img/check.svg"
          />
        )
      } else {
        icon = (
          <img
            alt="Not available"
            className="atlas-cart-item-availability-times"
            src="/img/times.svg"
          />
        )
      }

      let quantityAvailableLabel
      if (
        isPrivileged ||
        (isPrimaryWarehouse &&
          warehouse.quantity > 0 &&
          isQuantityOverAvailability)
      ) {
        quantityAvailableLabel = (
          <span className="atlas-cart-item-availability-quantity">
            (
            {Math.max(warehouse.quantity - clearanceQty,0)}
            {!compact && !isPrivileged && ' available'}
            )
          </span>
        )
      }

      return (
        <div
          className="atlas-cart-item-availability-warehouse"
          key={warehouse.warehouseCode}
        >
          {icon}
          {(isPrivileged || !compact || isQuantityOverAvailability) && name}
          &nbsp;
          {quantityAvailableLabel}
        </div>
      )
    })

    const isNotAvailable = !isPrivileged && !availableAtPrimaryWarehouse

    return (
      <div>
        {warehouseComponents}
        {isNotAvailable && showNotifyMe && (
          <div className="atlas-cart-item-availability-warehouse">
            <button className="btn btn-danger btn-sm" onClick={this.handleRequestNotification} disabled={this.state.processingnotification}>
              <span style={{ display: 'flex', alignItems: 'center' ,fontSize: 'clamp(0.5rem, 1.5vw, 0.65rem)' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
                </svg> &nbsp;
                Notify me when available
              </span>
            </button>
          </div>
        )}
      </div>
    )
  }
}

Availability.propTypes = {
  // compact
  // language
  // quantity
  isPrivileged: PropTypes.bool,
  isCumulativeSummary: PropTypes.bool
}


/* Here is the explanation for the code above:
1. If the user is not privileged, we only want to show the availability at the primary warehouse. 
  So we first check if the user is privileged. 
  If not, we check if the warehouse is the primary warehouse. 
  If it is not, we do not show the availability. 
  If it is, we show the availability if the quantity is greater than 0. 
  If it is not, we do not show the availability.
2. If the user is privileged, we show the availability at all warehouses. 
   If the warehouse is not the primary warehouse, but the quantity is greater than 0, we show the availability. 
   If the warehouse is the primary warehouse, but the quantity is greater than the availability, we show the availability.
3. If the user is privileged, but the quantity is 0, we still show the availability at the primary warehouse. 
  If the quantity is 0, but the warehouse is not the primary warehouse, we do not show the availability.
   If the warehouse is the primary warehouse, but the quantity is 0, we still show the availability. 
*/