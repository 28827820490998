import trim from 'lodash/trim'

export default function makeProductDataPresentable (options) {
  let title = options.title
  let shortDescription = options.shortDescription
  let longDescription = options.longDescription
  let manufacturerName = options.manufacturerName
  // let manufacturerPartNumber = options.manufacturerPartNumber

  if (
    manufacturerName === 'No Manufacturer' ||
    manufacturerName === 'No Manucaturer'
  ) {
    manufacturerName = ''
  }

  title = title || ''
  shortDescription = shortDescription || ''
  longDescription = longDescription || ''
  // manufacturerPartNumber = manufacturerPartNumber || ''

  title = title
    .replace(/""/g, '"')
    .replace(/\*\*/g, '')
    // .replace(manufacturerPartNumber, '')
    .trim()
  title = trim(title, ' -')

  shortDescription = shortDescription
    .replace(/""/g, '"')
    .replace(/\*\*/g, '')
    // .replace(manufacturerPartNumber, '')
    .trim()
  shortDescription = trim(shortDescription, ' -')

  longDescription = longDescription.replace(/”“”“/g, '—').replace(/”“/g, '-')

  const longDescriptionMirrorsTitle = title
    .toLowerCase()
    .replace(/[- ]/g, '')
    .includes(longDescription.toLowerCase().replace(/[- ]/g, ''))
  const longDescriptionMirrorsShortDescription = shortDescription
    .toLowerCase()
    .replace(/[- ]/g, '')
    .includes(longDescription.toLowerCase().replace(/[- ]/g, ''))
  const longDescriptionMirrorsManufacturerName =
    manufacturerName &&
    manufacturerName
      .toLowerCase()
      .replace(/[- ]/g, '')
      .includes(longDescription.toLowerCase().replace(/[- ]/g, ''))
  const longDescriptionIsRedundant =
    longDescriptionMirrorsTitle ||
    longDescriptionMirrorsShortDescription ||
    longDescriptionMirrorsManufacturerName

  if (longDescriptionIsRedundant) {
    longDescription = ''
  }

  if (shortDescription && title) {
    const shortDescriptionInTitle = title
      .toLowerCase()
      .replace(/[- \*]/g, '')
      .includes(shortDescription.toLowerCase().replace(/[- \*]/g, ''))
    const titleInShortDescription = shortDescription
      .toLowerCase()
      .replace(/[- \*]/g, '')
      .includes(title.toLowerCase().replace(/[- \*]/g, ''))
    if (shortDescriptionInTitle) {
      shortDescription = ''
    } else if (titleInShortDescription) {
      title = shortDescription
      shortDescription = ''
    }
  }

  return {
    title,
    shortDescription,
    longDescription,
    manufacturerName
  }
}
