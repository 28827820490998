import {
  GET_PASSWORD_RESET_TOKEN_PENDING,
  GET_PASSWORD_RESET_TOKEN_SUCCESSFUL,
  GET_PASSWORD_RESET_TOKEN_ERROR,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESSFUL,
  RESET_PASSWORD_ERROR
} from './constants'
import { dispatch } from '../store'
import page from 'page'

export function postPasswordReset (token, password) {
  dispatch({
    type: RESET_PASSWORD_PENDING
  })

  fetch(`/api/password-reset/${token}`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      password
    })
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: RESET_PASSWORD_SUCCESSFUL,
          response: json
        })
        setTimeout(() => {
          page('/')
        }, 2000)
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: RESET_PASSWORD_ERROR,
        error
      })
    })
}

export function getPasswordResetToken (token) {
  dispatch({
    type: GET_PASSWORD_RESET_TOKEN_PENDING
  })

  fetch(`/api/password-reset/${token}`, {
    headers: {
      Accept: 'application/json'
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        if (json) {
          dispatch({
            type: GET_PASSWORD_RESET_TOKEN_SUCCESSFUL,
            response: json
          })
        } else {
          dispatch({
            type: GET_PASSWORD_RESET_TOKEN_ERROR
          })
        }
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: GET_PASSWORD_RESET_TOKEN_ERROR,
        error
      })
    })
}
