import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import { saveProduct, deleteProduct } from './actions'
import { getProduct } from '../actions/product'
import App from '../common/app'
import EditProductComponent from './components/index'

function render (mountNode) {
  return function () {
    const state = getState()
    ReactDOM.render(
      <App {...state} {...appActions}>
        {state.product.wasLoadSuccessful &&
          <EditProductComponent
            {...getState()}
            onSave={saveProduct}
            onDelete={deleteProduct}
          />}
      </App>,
      mountNode
    )
  }
}

let unsubscribe = function () {}

export default function (app, mountNode) {
  app('/edit-product/:partNumber', context => {
    getProduct(context.params.partNumber)
    unsubscribe = subscribe(render(mountNode))

    render(mountNode)()
  })

  app.exit('/edit-product/:partNumber', (context, next) => {
    unsubscribe()
    next()
  })
}
