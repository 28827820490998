import {
  GET_CATEGORY_PENDING,
  GET_CATEGORY_SUCCESSFUL,
  GET_CATEGORY_ERROR,
  SAVE_CATEGORY_PENDING,
  SAVE_CATEGORY_SUCCESSFUL,
  SAVE_CATEGORY_ERROR,
  DELETE_CATEGORY_PENDING,
  DELETE_CATEGORY_SUCCESSFUL,
  DELETE_CATEGORY_ERROR
} from './constants'
import { dispatch, getState } from '../store'
import { getCategories } from '../categories/actions'
import page from 'page'

export function saveCategory (data) {
  dispatch({
    type: SAVE_CATEGORY_PENDING
  })

  fetch(data.id ? `/api/categories/${data.id}` : '/api/categories', {
    method: 'post',
    headers: {
      'x-session-id': getState().session.id,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: SAVE_CATEGORY_SUCCESSFUL,
          response: json
        })
        getCategories()
        page(data.id ? `/?categoryId=${data.id}` : '/categories')
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: SAVE_CATEGORY_ERROR,
        error
      })
    })
}

export function getCategory (categoryId) {
  dispatch({
    type: GET_CATEGORY_PENDING
  })

  fetch(`/api/categories/${categoryId}`, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_CATEGORY_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: GET_CATEGORY_ERROR,
        error
      })
    })
}

export function deleteCategory (categoryId) {
  dispatch({
    type: DELETE_CATEGORY_PENDING
  })

  fetch(`/api/categories/${categoryId}`, {
    method: 'delete',
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        page('/categories')
        dispatch({
          type: DELETE_CATEGORY_SUCCESSFUL
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: DELETE_CATEGORY_ERROR,
        error
      })
    })
}
