import {
  GET_CATEGORY_PENDING,
  GET_CATEGORY_SUCCESSFUL,
  GET_CATEGORY_ERROR,
  DELETE_CATEGORY_SUCCESSFUL
} from './constants'
var initialState = {
  data: {
    attributes: {}
  },
  meta: {
    total: 0,
    limit: 0,
    offset: 0
  },
  isLoading: false,
  wasLoadSuccessful: false,
  error: null
}

export default function dealers (state = initialState, { type, response }) {
  switch (type) {
    case GET_CATEGORY_PENDING:
      return {
        ...state,
        data: { attributes: {} },
        isLoading: true,
        wasLoadSuccessful: false,
        error: null
      }

    case GET_CATEGORY_SUCCESSFUL:
      return {
        ...state,
        data: response.data,
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }

    case GET_CATEGORY_ERROR:
      return {
        ...state,
        data: { attributes: {} },
        isLoading: false,
        wasLoadSuccessful: false,
        error: response.error
      }

    case DELETE_CATEGORY_SUCCESSFUL:
      return initialState

    default:
      return state
  }
}
