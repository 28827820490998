import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'File',

  handleDeleteClick (event) {
    event.preventDefault()
    if (
      prompt(
        'Are you sure you want to delete this file? Type YES to proceed.'
      ) === 'YES'
    ) {
      this.props.deleteFile(this.props.data.id)
    }
  },

  render () {
    const file = this.props.data

    if (this.props.isLoading) {
      return <div>Loading...</div>
    }

    const sizes = [32, 64, 128, 256, 512, 1024, 2048]

    const thumbnails = sizes.map(size => {
      const url = `https://atlastrailer.s3.amazonaws.com/${size}/${encodeURIComponent(
        file.id
      )}`
      return (
        <li key={size}>
          <a href={url} target="_blank">
            {url}
          </a>
        </li>
      )
    })

    const srcset = sizes
      .map(size => {
        const url = `https://atlastrailer.s3.amazonaws.com/${size}/${encodeURIComponent(
          file.id
        )}`
        return `${url} ${size}w`
      })
      .join(', ')

    const url = `https://atlastrailer.s3.amazonaws.com/${encodeURIComponent(
      file.id
    )}`

    return (
      <div style={{ padding: 24 }}>
        <div style={{ marginBottom: 12 }}>
          <a href="/files">Back to files</a>
        </div>
        <form ref="form">
          <h1>
            {file.id}
          </h1>

          <div>
            <pre>{JSON.stringify(file, null, 2)}</pre>
          </div>

          <div>
            <a href={url} target="_blank">
              {url}
            </a>
          </div>

          {file.type.includes('image') &&
            <div>
              <div style={{ width: 256 }}>
                <img srcSet={srcset} style={{ width: 256 }} />
              </div>
              <div>
                <h3>Thumbnails</h3>
                <ul>
                  {thumbnails}
                </ul>
              </div>
            </div>}

          <div>
            <button
              className="btn btn-danger"
              onClick={this.handleDeleteClick}
              type="button"
            >
              Delete
            </button>
          </div>
        </form>
      </div>
    )
  }
})
