import React from 'react'

export default function TermsOfSale () {
  return (
    <div style={{ margin: 18 }}>
      <h1>Terms of Sale</h1>
      <ul>
        <li>
          <a
            href="https://e-flyers.s3.us-west-2.amazonaws.com/RGA+Terms+%26+Conditions+English+2023.pdf"
            target="_blank"
            rel='noreferrer'
          >
            Terms and Conditions of Sale (PDF) <font color="red"> (Updated)</font>
          </a>
        </li>
        <li>
          <a
            href="https://e-flyers.s3.us-west-2.amazonaws.com/RGA+Terms+%26+Conditions+French+2023.pdf"
            target="_blank"
            rel='noreferrer'
          >
            Termes Et Conditions De Ventes (PDF) <font color="red"> (Mis à jour)</font>
          </a>
        </li>
        <li>
          <a
            href="https://s3.amazonaws.com/atlastrailer/Return-Goods-Authorization-fillin.pdf"
            target="_blank"
            rel='noreferrer'
          >
            Return Goods Authorization Form (PDF)
          </a>
        </li>
        <li>
          <a
            href="https://rvcatalogue.s3.amazonaws.com/Return%2BGoods%2BAuthorization%2BFRENCH%2BAug%2B2017.pdf"
            target="_blank"
            rel='noreferrer'
          >
            Formulaires de retour de pièces (PDF)
          </a>
        </li>
      </ul>
    </div>
  )
}