import {
  GET_PASSWORD_RESET_TOKEN_PENDING,
  GET_PASSWORD_RESET_TOKEN_SUCCESSFUL,
  GET_PASSWORD_RESET_TOKEN_ERROR,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESSFUL,
  RESET_PASSWORD_ERROR
} from './constants'

var initialState = {
  email: null,
  passwordResetToken: null,
  getTokenPending: false,
  getTokenSuccessful: false,
  getTokenError: null,
  resetPasswordPending: false,
  resetPasswordSuccessful: false,
  resetPasswordError: null
}

export default function dealers (state = initialState, { type, response }) {
  switch (type) {
    case GET_PASSWORD_RESET_TOKEN_PENDING:
      return {
        ...state,
        email: null,
        passwordResetToken: null,
        getTokenPending: true,
        getTokenSuccessful: false,
        getTokenError: null
      }

    case GET_PASSWORD_RESET_TOKEN_SUCCESSFUL:
      return {
        ...state,
        email: response.data.email,
        passwordResetToken: response.data.passwordResetToken,
        getTokenPending: false,
        getTokenSuccessful: false,
        getTokenError: null
      }

    case GET_PASSWORD_RESET_TOKEN_ERROR:
      return {
        ...state,
        email: null,
        passwordResetToken: null,
        getTokenPending: false,
        getTokenSuccessful: false,
        getTokenError: true
      }

    case RESET_PASSWORD_PENDING:
      return {
        ...state,
        resetPasswordPending: true,
        resetPasswordSuccessful: false,
        resetPasswordError: null
      }

    case RESET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordSuccessful: true,
        resetPasswordError: null
      }

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordSuccessful: false,
        resetPasswordError: response
      }
    default:
      return state
  }
}
