import React from 'react'
import CompactList from '../compact-list'
import DetailedList from '../detailed-list'
import ListToggle from '../list-toggle'


export default function AllProducts ({ browser , products, session, user, onSwitchListView }) {

  const { aggregateData } = products
  const { language, listView } = session
  const { isRetailMode } = user
  const { isOnline } = browser
  const { warehouseCode } = user
  const showDetailed = aggregateData.length === 1 || listView === 'detailed'
  
  if (aggregateData.length === 0) {
    return null
  }
  
  const mappedProducts = aggregateData.map((product) => {
          return {
            attributes: {
              ...product
            },
            type : 'product',
            _id: product._id
          }})


  return (
    <div style={{ display: 'flex', backgroundColor: '#F7F7F9' }}>
    <main className='atlas-products'>
      <ListToggle
        listView={listView}
        onSwitchListView={onSwitchListView}
      />
      <div style={{ marginTop: '2rem' }}>
        {
          showDetailed &&
          <DetailedList
            session={session}
            isOnline={isOnline}
            isRetailMode={isRetailMode}
            language={language}
            products={mappedProducts}
            userRoles={user.roles}
            warehouseCode={warehouseCode}
          />
        }
        {
          !showDetailed &&
          <CompactList
            session={session}
            isOnline={isOnline}
            isRetailMode={isRetailMode}
            language={language}
            products={mappedProducts}
            userRoles={user.roles}
            warehouseCode={warehouseCode}
          />
        }

      </div>
    </main>
  </div>
  )
}
