import React from 'react'
import { findTranslation } from '../../../../common/translation'

function sortCategoriesByName (a, b) {
  return a.name[0].value < b.name[0].value ? -1 : 1
    // return a.attributes.aimsId < b.attributes.aimsId ? -1 : 1
}

function sortByParentCategoryName (a, b) {
  return a.parentCategory?.name[0].value < b.parentCategory?.name[0].value ? -1 : 1
}

/**
 * AggregatedCategoryList
 * @returns 
 */
export default function AggregatedSubCategoryList ({ categoryType, categories, session, parentId }) {
  const language = session.language
  
  const topLevelCategories = categories.aggregate?.data?.meta?.top_level_categories.sort(sortCategoriesByName) || []
  const aggregateCategories = categories.aggregate?.data?.result.sort(sortByParentCategoryName) || []

  const parentCategory = aggregateCategories.find((category) => category.parentCategory?._id === parentId)
  const topLevelParentCategory = topLevelCategories.find((category) => category?._id === parentCategory?.parentCategory?.parentId)

  const filteredAggregateCategories = []
  const filteredAggregateCategoriesIds = []

    aggregateCategories.forEach((category) => {
        if (category.category.parentId === parentId && !filteredAggregateCategoriesIds.includes(category.category?._id)) {
            filteredAggregateCategories.push(category)
            filteredAggregateCategoriesIds.push(category.category?._id)
        }
    })

  // If a categoryId is provided, filter the aggregateCategories to only include the category with the provided parent

  if(!parentId) return <div>
        <h1>Invalid Link</h1>
  </div>
  return (
    <div style={{ zIndex: '100000' }}>
      <a href={`/products/list/${categoryType}/`} style={{ marginLeft: '1.5rem', fontWeight: 'normal', fontSize: '0.95rem' }}> &lt; / {( topLevelParentCategory && <span>{findTranslation(topLevelParentCategory.name, language.code)}</span>)} / {( parentCategory && <span>{findTranslation(parentCategory.parentCategory.name, language.code)}</span>)}</a>
      <ul className='CategoryChildren-list' >
                {
                filteredAggregateCategories.map((aggregateCategory) => {
                    return (
                      <li key={aggregateCategory?._id} className='CategoryChildren-list-item'>
                        <a href={`/products/aggregation/${categoryType}/${aggregateCategory?.category?._id}`}>
                          {findTranslation(aggregateCategory.category.name, language.code)} 
                        </a>                     
                      </li>
                    )
                })}
        </ul>       
    </div>
  )
}
