// import React from 'react'
// import { FormattedMessage } from 'react-intl'
// import createReactClass from 'create-react-class'

// export default createReactClass({
//   propTypes: {
//     addCartItem: React.PropTypes.func,
//     onQuantityChange: React.PropTypes.func
//   },

//   getInitialState () {
//     return {
//       showQuantity: atlas.getState().browser.query.quantity,
//       success: false
//     }
//   },

//   handleToggle () {
//     this.setState({ showQuantity: true }, () => {
//       this.refs.quantity.focus()
//       this.refs.quantity.select()
//     })
//   },

//   handleAdd (event) {
//     event.preventDefault()
//     this.setState({
//       success: true
//     })
//     this.props.addCartItem(this.refs.quantity.value)
//   },

//   handleKeyDown (event) {
//     if (event.key === 'Enter') {
//       this.handleAdd(event)
//     } else if (event.key === 'Escape') {
//       this.toggleOff()
//     }
//   },

//   toggleOff () {
//     this.setState({
//       showQuantity: false
//     })
//   },

//   handleChange (event) {
//     if (this.props.onQuantityChange) {
//       this.props.onQuantityChange(this.refs.quantity.value)
//     }
//   },

//   render () {
//     if (this.state.success) {
//       return (
//         <div>
//           <img
//             className="atlas-cart-item-availability-check"
//             src="/img/check.svg"
//             alt='check'
//           />
//         </div>
//       )
//     }

//     const queryParamQuantity =
//       atlas.getState().browser.query.quantity &&
//       parseInt(atlas.getState().browser.query.quantity, 10)

//     if (this.state.showQuantity) {
//       return (
//         <div>
//           <input
//             autoFocus={true}
//             className="atlas-add-to-cart-button-quantity"
//             defaultValue={queryParamQuantity || 1}
//             onChange={this.handleChange}
//             onKeyDown={this.handleKeyDown}
//             ref="quantity"
//             style={{ width: 50 }}
//           />
//           <button className="atlas-add-to-cart-button" onClick={this.handleAdd}>
//             <span className="atlas-add-to-cart-button-label">
//               Add
//             </span>
//           </button>
//         </div>
//       )
//     }

//     return (
//       <div>
//         <button
//           className="atlas-add-to-cart-button"
//           onClick={this.handleToggle}
//         >
//           <span className="atlas-add-to-cart-button-label">
//           <FormattedMessage defaultMessage="Add to cart" id="Add to cart" />
//           </span>
//         </button>
//       </div>
//     )
//   }
// })

// Rewrite the above code to the standard react functional component
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

export default function AddToCartButton (props) {

  const [showQuantity, setShowQuantity] = useState(atlas.getState().browser.query.quantity)
  const [success, setSuccess] = useState(false)
  const quantityRef = React.createRef()

  const handleToggle = () => {
    setShowQuantity(true)
  }

  const handleAdd = (event) => {
    event.preventDefault()
    setSuccess(true)
    if (props.addCartItem){
      props.addCartItem(quantityRef.current.value)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAdd(event)
    } else if (event.key === 'Escape') {
      toggleOff()
    }
  }

  const toggleOff = () => {
    setShowQuantity(false)
  }

  const handleChange = (event) => {
    if (props.onQuantityChange) {
      props.onQuantityChange(quantityRef.current.value)
    }
  }

  if (success) {
    return (
      <div>
        <img
          className="atlas-cart-item-availability-check"
          src="/img/check.svg"
          alt='check'
        />
      </div>
    )
  }

  const queryParamQuantity =
    atlas.getState().browser.query.quantity &&
    parseInt(atlas.getState().browser.query.quantity, 10)

  if (showQuantity) {
    return (
      <div>
        <input
          autoFocus={true}
          className="atlas-add-to-cart-button-quantity"
          defaultValue={queryParamQuantity || 0}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          ref={quantityRef}
          style={{ width: 50 }}
        />
        <button className="atlas-add-to-cart-button" onClick={handleAdd}>
          <span className="atlas-add-to-cart-button-label">
            Add
          </span>
        </button>
      </div>
    )
  }

  return (
    <div>
      <button
        className="atlas-add-to-cart-button"
        onClick={handleToggle}
      >
        <span className="atlas-add-to-cart-button-label">
          <FormattedMessage defaultMessage="Add to cart" id="Add to cart" />
        </span>
      </button>
    </div>
  )
}

AddToCartButton.propTypes = {
  addCartItem: PropTypes.func,
  onQuantityChange: PropTypes.func
}
