import React from 'react'
import createReactClass from 'create-react-class'
import EmailAdminMenu from '../../email-admin-menu/components'
import SearchBar from './search-bar'
import moment from 'moment'

import page from 'page'

export default createReactClass({
    displayName: 'EmailAdminInsight',

    gotoUnDeliveredMessage(e) {
        const messageId = e.currentTarget.getAttribute('data-item');
        page('/email-admin-insight-messages/' + messageId)
    },
    gotoDeliveredMessage(e) {
        const messageId = e.currentTarget.getAttribute('data-item');
        page('/email-admin-insight-del-messages/' + messageId)
    },

    insights(data) {
        if (data) {
            return data.map(insight => {
                //console.log(insight)
                const key = insight.data._id
                const date = moment(insight.data.modified).format('YYYY-MM-DD')
                return (
                    <tr key={key} data-item={key} >
                        <td>{date}</td>
                        <td>{insight.data.subject}</td>
                        <td>{insight.data.recipientLanguage} ({insight.data.noOfRecipients})</td>
                        <td data-item={key} onClick={this.gotoDeliveredMessage}>{insight.data.deliveredCnt}</td>
                        <td data-item={key} onClick={this.gotoUnDeliveredMessage}>{insight.data.undeliveredCnt}</td>
                    </tr>
                );
            });
        } else {
            <tr>
                <td>No Data Found</td>
            </tr>
        }
    },

    render() {
        return (
            <div>
                <EmailAdminMenu></EmailAdminMenu>
                <div className="atlas-email-admin-main">
                    <SearchBar changeSearchQuery={this.props.changeSearchQuery} isOnline={true}></SearchBar>
                    <div className="atlas-inbox-grid-main">
                        <table className="atlas-inbox-table table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Subject</th>
                                    <th>Recipient</th>
                                    <th>Delivered</th>
                                    <th>Undelivered</th>
                                </tr>
                            </thead>
                            <tbody hidden={this.props.insights.isLoading}>
                                {this.insights(this.props.insights.data)}
                            </tbody>
                        </table>
                        <div hidden={!this.props.insights.isLoading} className="insight-spinner">
                            <img
                                alt="Loading"
                                src="/img/loading-ring.gif"
                                style={{ width: 40, height: 40 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
})