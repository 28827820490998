import React from 'react'
import page from 'page'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'ResetPassword',

  handleFormSubmit (event) {
    event.preventDefault()
    this.props.requestPasswordReset(this.refs.email.value)
  },

  handleCancelClick (event) {
    event.preventDefault()
    page('/')
  },

  render () {
    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          paddingTop: 150
        }}
      >
        <form
          className="form form-horizontal"
          onSubmit={this.handleFormSubmit}
          style={{
            padding: 12,
            width: 300,
            margin: 'auto',
            backgroundColor: 'white',
            boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.05)',
            border: '1px solid #d3e0e9',
            borderRadius: 4
          }}
        >
          <fieldset style={{ margin: 6 }}>
            <div style={{ textAlign: 'center', padding: 12 }}>
              <img className="atlas-login-logo" src="/img/logo.svg" />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                Your e-mail address
              </label>
              <input
                autoFocus
                className="form-control"
                ref="email"
                required
                type="email"
              />
            </div>

            {!this.props.wasSuccessful &&
              !this.props.error &&
              <div>
                <button
                  className="btn btn-primary"
                  disabled={this.props.isPending}
                  style={{ marginRight: 8 }}
                  type="submit"
                >
                  <span>Reset password</span>
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={this.handleCancelClick}
                  type="button"
                >
                  Cancel
                </button>
              </div>}

            {this.props.wasSuccessful &&
              <div className="btn btn-success">
                Confirmation e-mail sent ✓
              </div>}

            {this.props.error &&
              <div className="btn btn-danger">
                {`${this.props.error} ✗`}
              </div>}
          </fieldset>
        </form>
      </div>
    )
  }
})
