import React from 'react'
import { FormattedMessage } from 'react-intl'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'Categories',

  handleUserSearch (event) {
    event.preventDefault()
    this.props.getCategories({ query: this.refs.categoryQuery.value })
  },

  render () {
    const categoryComponents = this.props.data
      .map(category => category.attributes)
      .sort(
        (a, b) => (parseInt(a.aimsId, 10) < parseInt(b.aimsId, 10) ? -1 : 1)
      )
      .map(category => {
        const englishName = typeof category.name === 'string'
          ? category.name
          : category.name.find(tr => tr.languageCode === 'en').value
        const frenchName = typeof category.name === 'string'
          ? ''
          : (category.name.find(tr => tr.languageCode === 'fr') || {}).value
        return (
          <tr key={category._id}>
            <td>
              <a href={`/categories/${category._id}`}>
                {englishName}
              </a>
            </td>
            <td>{frenchName}</td>
            <td>{category.parentId}</td>
            <td>{category.aimsId}</td>
            <td>{category.parentAimsId}</td>
          </tr>
        )
      })
    return (
      <div style={{ padding: 24 }}>
        <h1>
          <FormattedMessage defaultMessage="Categories" id="Categories" />
        </h1>
        <div>
          <a className="btn btn-primary" href="/categories/new">Add category</a>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>English</th>
              <th>French</th>
              <th>Parent ID</th>
              <th>AIMS ID</th>
              <th>Parent AIMS ID</th>
            </tr>
          </thead>
          <tbody>
            {categoryComponents}
          </tbody>
        </table>
      </div>
    )
  }
})
