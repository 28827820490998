import {
  GET_CARTS_PENDING,
  GET_CARTS_SUCCESSFUL,
  GET_CARTS_ERROR,
  GET_ALL_CART_DEALERS
} from './constants'

const initialState = {
  data: [],
  dealers: [],
  isLoading: false,
  wasLoadSuccessful: false,
  error: null
}

export default function dealers (state = initialState, { type, response }) {
  switch (type) {
    case GET_CARTS_PENDING:
      return {
        ...state,
        isLoading: true,
        wasLoadSuccessful: false,
        error: null
      }
    case GET_ALL_CART_DEALERS:
      return {
        ...state,
        dealers: response,
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }
    case GET_CARTS_SUCCESSFUL:
      const value = response.change.new_val || response.change.old_val
      return {
        ...state,
        data: state.data
          .filter(cart => cart.id !== value.id)
          .concat(value)
          .sort((a, b) => {
            return a.modified > b.modified ? -1 : 1
          }),
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }

    case GET_CARTS_ERROR:
      return {
        ...state,
        data: [],
        isLoading: false,
        wasLoadSuccessful: false
        // error: response.error
      }

    default:
      return state
  }
}
