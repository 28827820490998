import { GET_ALL_DEALERS, GET_ALL_DEALERS_SUCCESSFUL, GET_ALL_DEALERS_ERROR } from "./constants"
import { dispatch, getState } from "../store"

export const getDealers = () => {

  dispatch({
    type: GET_ALL_DEALERS,
    response: []
  })

  if (!getState().session.id) {
    return
  }

  fetch(`/api/user-data`, {
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(async function (response) {
    if (response.ok) {
      const json = await response.json()
      dispatch({
        type: GET_ALL_DEALERS_SUCCESSFUL,
        response: json
      })
    }
  }).catch(function (error) {
    console.log('parsing failed', error)
    dispatch({
      type: GET_ALL_DEALERS_ERROR,
      error
    })
  })   
}

export const enableAllUsersInAccount = (accountNumber) => {
  fetch(`/api/user-management/enable-all/${accountNumber}`, {
    method: 'PUT',
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(async function (response) {
    if (response.ok) {
      alert('All users in account - ' + accountNumber + ' have been enabled.')
      getDealers()
    }
  }).catch(function (error) {
    console.log('parsing failed', error)
  })   
}

export const disableAllUsersInAccount = (accountNumber) => {
  fetch(`/api/user-management/disable-all/${accountNumber}`, {
    method: 'PUT',
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(async function (response) {
    if (response.ok) {
      alert('All users in account - ' + accountNumber + ' have been disabled.')
      getDealers()
    }
  }).catch(function (error) {
    console.log('parsing failed', error)
  })   
}

export const disableUser = (customerId) => {
  fetch(`/api/user-management/disable/${customerId}`, {
    method: 'PUT',
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(async function (response) {
    if (response.ok) {
      alert('User has been disabled.')
      getDealers()
    }
  }).catch(function (error) {
    console.log('parsing failed', error)
  })   
}

export const enableUser = (customerId) => {
  fetch(`/api/user-management/enable/${customerId}`, {
    method: 'PUT',
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(async function (response) {
    if (response.ok) {
      alert('User has been enabled.')
      getDealers()
    }
  }).catch(function (error) {
    console.log('parsing failed', error)
  })   
}