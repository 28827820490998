import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'

import App from '../common/app'
import ComingSoonComponent from './components/coming-soon'

function render (mountNode) {
  return function () {
    ReactDOM.render(
      <App active="coming-soon" {...getState()} {...appActions}>
        <ComingSoonComponent />
      </App>,
      mountNode
    )
  }
}

let unsubscribe = function () {}

export default function (app, mountNode) {
  app('/new-products', function (context) {
    unsubscribe = subscribe(render(mountNode))
    render(mountNode)()
  })

  app.exit('/new-products', (context, next) => {
    unsubscribe()
    next()
  })

  app('/clearance', function (context) {
    unsubscribe = subscribe(render(mountNode))
    render(mountNode)()
  })

  app.exit('/clearance', (context, next) => {
    unsubscribe()
    next()
  })

  app('/pages/suppliers', function (context) {
    unsubscribe = subscribe(render(mountNode))
    render(mountNode)()
  })

  app.exit('/pages/suppliers', (context, next) => {
    unsubscribe()
    next()
  })
}
