import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'NewVendors',

  render () {
    return (
      <div style={{ padding: 24 }}>
        <h2>New Vendors</h2>
      <ul style={{columns: 1}}>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/2EZE_SKIN_COVERS_S0062.pdf" target="_blank">2EZE SKIN COVERS</a>  - Covers for Tires, Propane Tanks, Jacks, TVs and Accessories</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/ADCO_U0057.pdf" target="_blank">ADCO COVERS</a> - RV, Tire, Propane Tanks, A/C, Windshield</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/B_%26_B_MOLDERS_U0229.pdf" target="_blank">B & B MOLDERS</a>  - Utility Hatches and Doors</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/BLACKSTONE_GRILLS_U4378.pdf" target="_blank">BLACKSTONE</a>  - Barbeque Grills and Accessories</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/BRIGHTSOURCE_S3523.pdf" target="_blank">BRIGHTSOURCE</a>  - Portable LED Lighting Products</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/AIRXCEL_COLMAN_MACH+U3874.pdf" target="_blank">COLEMAN</a>  - Coleman Mach Air Conditioners</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/DUO_FORM_PLASTICS_U1512.pdf" target="_blank">DUO FORM</a>  - Skylight Vent Shade</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/FLAME_KING_U2038.pdf" target="_blank">FLAME KING</a>  - Propane Tanks and Accessories</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/FLEMING_SALES_U4644.pdf" target="_blank">FLEMING SALES</a>  - Barbeque Grills and Accessories</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/FURRION_U5676.pdf" target="_blank">FURRION</a>  - Air Conditioners, Electronics, Observation Systems</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/GCI_OUTDOOR_U2426.pdf" target="_blank">GCI OUTDOOR</a>  - Camp Chairs and Tables</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/IRVINE_SHADE_U2958.pdf" target="_blank">IRVINE SHADE</a>  - Shower Doors and Folding Doors</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/JACK_E_UP_U2922.pdf" target="_blank">JACK-E-UP</a>  - Removeable Jack System</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/KITTRICH_CORP_U6385.pdf" target="_blank">KITTRICH</a>  - Camping Mats and Accessories</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/MILENCO_U4154.pdf" target="_blank">MILENCO</a>  - Extension Mirrors</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/OBECO_U4340.pdf" target="_blank">OBECO</a>  - Storage Organizers</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/RV_SNAP_PAD_U5201.pdf" target="_blank">RV SNAP PAD</a>  - Snap on Jack Pads</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/SAFE_T_PLUS+U5218.pdf" target="_blank">SAFE T PLUS</a>  - Steering Stabilizers, Shocks</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/SOLO_STOVE_U5388.pdf" target="_blank">SOLO STOVE</a>  - Portable Camping Stoves & Fire Pits</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/TELESTEPS_S5005.pdf" target="_blank">TELESTEPS</a>  - Telescoping Ladders</li>
<li><a href="https://atlas-show-case-2019.s3.amazonaws.com/Work_Sheets/VIAIR_U6422.pdf" target="_blank">VIAIR</a>  - RV Designed Air Compressors</li>
 </ul>
      </div>
   )
  }
})
