import React from 'react'
import { FormattedMessage } from 'react-intl'
import CompactListItem from './compact-list-item'
import { sortByNewOrClearance } from './utils/sort-by-new-or-clearance'

export default class CompactList extends React.Component {
  render () {
    const isRetailMode = this.props.isRetailMode
    const products = this.props.products
    const language = this.props.language
    const warehouseCode = this.props.warehouseCode
    const userRoles = this.props.userRoles
    const orderBy = this.props.orderBy
    const { subscribeToProductUpdates } = this.props

    const listStlye = {
      marginTop:'80px',
      overflow: 'scroll'
    }
    var productComponents = products.sort( (a,b) => sortByNewOrClearance(a,b,orderBy))
    .map(product => {
      return (
        <CompactListItem
          session={this.props.session}
          isOnline={this.props.isOnline}
          isRetailMode={isRetailMode}
          key={product.id}
          language={language}
          product={product}
          userRoles={userRoles}
          warehouseCode={warehouseCode}
          subscribeToProductUpdates={subscribeToProductUpdates}
        />
      )
    })

    if (!productComponents.length) {
      return <div />
    }

    return (
      <table
        className="table table-striped table-bordered table-sm atlas-products-compact"
        style={listStlye}
      >
        <thead>
          <tr>
            <th scope="col" style={{ width: '60px' }} />
            <th scope="col" style={{ width: '100px' }}>
              Atlas #
            </th>
            <th scope="col" style={{ width: '130px' }}>
              Mfg
            </th>
            <th scope="col" style={{ Width: '130px' }}>
              Description
            </th>
            <th scope="col" style={{ Width: '130px' }}>
              <FormattedMessage defaultMessage="Catalogue Page #" id="Catalogue Page #" />
            </th>
            {!isRetailMode &&
              <th scope="col" style={{ width: '130px' }}>
                <FormattedMessage defaultMessage="Dealer" id="Dealer" /> ($)
              </th>}
            {!isRetailMode &&
              <th scope="col" style={{ width: '130px' }}>
                FX ($)
              </th>}
            <th scope="col" style={{ width: '130px', color: 'red' }}>
              <FormattedMessage defaultMessage="Retail" id="Retail" /> ($)
            </th>
            <th scope="col" style={{ width: '120px' }}>
            <FormattedMessage defaultMessage="Availability" id="Availability" /> 
            </th>
            <th scope="col" style={{ width: '130px' }} />
          </tr>
        </thead>
        <tbody>
          {productComponents}
        </tbody>
      </table>
    )
  }
}
