import React from 'react'
import createReactClass from 'create-react-class'
import { getState } from '../../store'


export default createReactClass({
  displayName: 'StatementsListItem',

  filter (search, date) {
    if (!search) return true
    if (search.split("/")[0] !== new Date(date).getMonth() + 1) return false
    return true
  },

  handleDownload (e) {
    if(window.navigator.userAgent.indexOf("Edge") > -1) {
      let data = new FormData()
      data.append('id', this.props._id)
      fetch('/api/fileDownload/', {
        method: 'POST',
        headers: {
          'x-session-id': getState().session.id
        },
        body: data
      })
      .then(response => {
        var name = this.props.metadata.filename
        response.blob().then(blob => {
          blob = new Blob([blob], { type: "application/pdf" })
          var a = document.createElement("a")
          document.body.appendChild(a)
          a.style = "display: inital"
          var url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = name
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob)  
          }
          else {
            window.open(url)  
          }
          /* The following code downloads the file instead of opens it
          a.click();
          window.URL.revokeObjectURL(url); */
        })
      })
    } else {
      let data = new FormData()
      data.append('id', this.props._id)
      var newWindow = window.open('about:blank', '_blank')
      fetch('/api/fileDownload/', {
        method: 'POST',
        headers: {
          'x-session-id': getState().session.id
        },
        body: data
      })
      .then(response => {
        var name = this.props.metadata.filename
        response.blob().then(blob => {
          blob = new Blob([blob], { type: "application/pdf" })
          var a = document.createElement("a")
          document.body.appendChild(a)
          a.style = "display: inital"
          var url = window.URL.createObjectURL(blob)
          newWindow.location = url
          a.href = url
          a.download = name
          /* The following code downloads the file instead of opens it
          a.click();
          window.URL.revokeObjectURL(url); */
        })
      })
    }
  },

showFile (blob){
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type: "application/pdf" })
 
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  } 
 
  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  var link = document.createElement('a')
  link.href = data
  link.click()
  setTimeout(function (){ window.URL.revokeObjectURL(data)} ,100)
  },

  handleOpenInNewTab (fileUrl = '') {
    if(fileUrl.indexOf('https://') === -1) {
      fileUrl = 'https://' + fileUrl
    }
    var win = window.open(fileUrl, '_blank')
    win.focus()
  }
  ,

  render () {
    const {
      filename,
      fileUrl,
      acctNo
    } = this.props.metadata
    const user = this.props.session.user
    let actualFileUrl = fileUrl
    if(!actualFileUrl || actualFileUrl === undefined) {
      actualFileUrl = ''
    }
    if(actualFileUrl.indexOf('https://') === -1) {
      actualFileUrl = 'https://' + actualFileUrl
    }
      return (
        <tr className="StatementsItem">
          <td className="StatementsItem-cell">
            <button className="hover"  onClick={this.handleOpenInNewTab.bind(this, actualFileUrl)}>
              {filename}
            </button>
            {/* <a href={actualFileUrl} target={'_blank'} rel='noreferrer'>
              {filename}
            </a> */}
          </td>
          {user.managesMultipleLocales ? 
            (<td className="StatementsItem-cell">
              {acctNo}
            </td>)
            :
            null
          }
        </tr>
      )
  }
})
