import React from 'react'
import createReactClass from 'create-react-class'
import EmailAdminMenu from '../../email-admin-menu/components'

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';

import {
    handleDraftEditorPastedText,
} from "draftjs-conductor";

export default createReactClass({
    displayName: 'EmailCompose',

    handleEditorChange(state) {
        this.setState({ editorState: state })
        this.convertContentToHTML();
    },

    convertContentToHTML() {
        const currentContentAsHTML = convertToHTML({
            blockToHTML: (block) => {
                if (block.type === 'PARAGRAPH') {
                    return <p />;
                }
            },
            entityToHTML: (entity, originalText) => {
                if (entity.type === 'LINK') {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                return originalText;
            }
        })(this.state.editorState.getCurrentContent());
        this.setState({ convertedContent: currentContentAsHTML });
    },

    getInitialState() {
        return {
            //files: [new Blob([], { type: "application/text" })],
            files: [],
            fields: {
                area: 'parts',
                recipients: ['No addresses chosen']
            },
            errors: {},
            noOfRecipients: null,
            addresses: [],
            editorState: EditorState.createEmpty(),
            convertedContent: '',
        }
    },

    onAreaSelect(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;

        // set or unset recipient language for accounting
        if (e.target.value === 'accounting') {
            fields['recipientLanguage'] = "accounting"
        } else {
            fields['recipientLanguage'] = ""
        }

        this.setState({ fields });

        // remove attachment according to Michael request
        let event = {
            target: {
                value: "",
                files: [""]
            },
        }
        this.onFileChange(event)

        // to disable form checking as this field
        // is not visible during accounting
        //fields['recipientLanguage'] = "value";
    },

    onRecipientSelect(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields });

        // get number of recipients
        let emails
        switch (e.target.value) {

            case 'english':
                emails = this.props.users.data.filter(user => {
                    return !user.attributes.customer_id.startsWith("7")
                })
                break
            case 'french':
                emails = this.props.users.data.filter(user => {
                    return user.attributes.customer_id.startsWith("7")
                })
                break
            default:
                emails = this.props.users.data
        }

        // make an array of recipient emails
        emails = emails.map(user => {
            return {
                email: user.attributes.email,
                accountNumber: user.attributes.customer_id,
                accountName: user.attributes.name,
            }
        })
        fields["recipients"] = emails
        this.setState({ fields })
        this.setState({ noOfRecipients: emails.length })
    },

    onFileChange(e) {
      if(e.target.value !== ''){
        let fields = this.state.fields;
        fields["files"] = e.target.files[0];
        this.setState({
            files: [...this.state.files, ...e.target.files],
            fields,
        })
      }
    },

    
    removeFile(e) {
      let index = e.currentTarget.getAttribute('data-item');
      const clone = [...this.state.files]
      clone.splice(index,1)
      this.setState({
        files: clone
      })   
  },

    onAccountingFileChange(e) {
        let fields = this.state.fields;
        const reader = new FileReader();
        reader.onload = () => {
            //file should be of form |email, accountName, accountNumber|
            const emailLines = reader.result.split(/\r\n|\n/);
            const emails = emailLines.map(line => line.split(','))
            const addressesForDisplay = emails.map(email => email[0])

            const addressObjects = emails.reduce((filtered, email) => {
                if (!email || !(email[0] === '')) {
                    filtered.push({
                        email: email[0],
                        accountNumber: email[1],
                        accountName: email[2],
                    })
                }
                return filtered
            }, [])
            fields["recipients"] = addressObjects;
            this.setState({ fields })
            this.setState({ addresses: addressesForDisplay })
        };
        reader.readAsBinaryString(e.target.files[0]);
    },

    displayAddresses() {
        return this.state.addresses.map(address => {
            return (
                <tr key={address}>
                    <td>{address}</td>
                </tr>
            );
        });
    },

    displayFiles() {
        return this.state.files.map((file,i) => {
            return (
                <tr key={i} data-item={i}>
                    <td>{file.name}</td><td  data-item={i}  onClick={this.removeFile} className="delete-x">X</td>
                </tr>
            );
        });
    },

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields });
    },

    handleValidation() {
        let fields = this.state.fields;
        let errors = [];

        if (!fields["subject"]) {
            errors.push("Subject cannot be empty")
        }

        if (!fields["recipientLanguage"] || fields["recipientLanguage"] === '') {
            errors.push("Recipients cannot be empty");
        }

        if (!this.state.convertedContent || (this.state.convertedContent === '')) {
            errors.push("Body cannot be empty");
        }

        if (!fields["files"] && fields['area'] === 'parts') {
            errors.push("Please attach a file to continue");
        }

        return errors;
    },

    handleSubmit(event) {
        event.preventDefault()
        const errors = this.handleValidation()

        if (errors.length === 0) {
            this.submitForm()
        } else {
            let message = "";
            for (const error of errors) {
                message += "\t" + error + "\n"
            }
            alert("Form has errors: \n" + message)
        }
    },

    submitForm() {

        //this.refs.form.body.value = `${this.refs.form.body.value}`
        let form = new FormData(this.refs.form)
        form.append('recipients', JSON.stringify(this.state.fields.recipients))
        form.set('recipientLanguage', this.state.fields.recipientLanguage)
        for (const file of this.state.files) {
            form.append('files', file)
        }
        form.append('body', this.state.convertedContent)
        this.props.saveEmail(form)
    },

    handlePastedText(text, html, editorState) {
        let newState = handleDraftEditorPastedText(html, editorState);

        if (newState) {
            this.onChange(newState);
            return true;
        }

        return false;
    },


    render() {
        return (
            <div>
                <EmailAdminMenu></EmailAdminMenu>
                <div className="atlas-email-admin-main">

                    <form onSubmit={this.handleSubmit} ref="form">
                        <div className="grid-container">

                            <label className="grid-item">Area: </label>
                            <div>
                                <select name="area" className="atlas-med-length grid-item grid-item-height" onChange={this.onAreaSelect}>
                                    <option value="parts">&nbsp; Parts</option>
                                    <option value="accounting">&nbsp; Accounting</option>
                                </select>
                            </div>
                            <div className="grid-item"></div>

                            <label className="grid-item">Subject: </label>
                            <input className="grid-item" type="text" size="40" id="subject" name="subject" onChange={this.handleChange} />
                            <div className="grid-item"></div>
                            <label className="grid-item">Recipients: </label>
                            <div hidden={this.state.fields.area === 'accounting'}>
                                <select name="recipientLanguage" className="atlas-med-length grid-item grid-item-height" onChange={this.onRecipientSelect}>
                                    <option disabled selected value>&nbsp; Select </option>
                                    <option value="all">&nbsp; All</option>
                                    <option value="english">&nbsp; English</option>
                                    <option value="french">&nbsp; French</option>
                                </select>
                                <span hidden={!this.props.users.isLoading}>
                                    <img
                                        alt="Loading"
                                        src="/img/loading-ring.gif"
                                        style={{ width: 40, height: 40 }}
                                    />
                                </span>
                                <span hidden={!this.state.noOfRecipients}>&nbsp;{this.state.noOfRecipients} Customers Selected</span>
                            </div>
                            <div hidden={this.state.fields.area === 'parts'}>
                                <label className="grid-item" htmlFor="accounting-input"></label>
                                <input id="accounting-input" type="file" onChange={this.onAccountingFileChange} />
                            </div>
                            <div className="grid-item"></div>

                            <div className="grid-item" hidden={this.state.fields.area === 'parts'}></div>
                            <div className="accounting-address-table" hidden={this.state.fields.area === 'parts'}>
                                <table className="accounting-address-table">
                                    <tbody>
                                        {this.displayAddresses()}
                                    </tbody>
                                </table>
                            </div>
                            <div className="grid-item" hidden={this.state.fields.area === 'parts'}></div>

                            <label className="grid-item">Body: </label>
                            <div className="atlas-compose-editor">
                                <Editor
                                    handlePastedText={this.handlePastedText}
                                    editorState={this.state.editorState}
                                    onEditorStateChange={this.handleEditorChange}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                            bold: { className: 'bordered-option-classname' },
                                            italic: { className: 'bordered-option-classname' },
                                            underline: { className: 'bordered-option-classname' },
                                            strikethrough: { className: 'bordered-option-classname' },
                                            code: { className: 'bordered-option-classname' },
                                        },
                                        blockType: {
                                            className: 'bordered-option-classname',
                                        },
                                        fontSize: {
                                            className: 'bordered-option-classname',
                                        },
                                        fontFamily: {
                                            className: 'bordered-option-classname',
                                        },
                                    }}
                                />
                            </div>
                            <div className="image-upload grid-item paper-clip">
                                <label className="grid-item" htmlFor="file-input">
                                    <img className="paper-clip" src="/img/paper-clip.svg" style={{ height: 30 }} />
                                </label>
                                <input id="file-input" type="file" accept=".pdf, .jpg, .png, .jpeg" onChange={this.onFileChange} />
                            </div>

                            <div className="grid-item"></div>
                            <div className="align-end attachment-label ">
                                <table className="accounting-address-table">
                                    <tbody>
                                        {this.displayFiles()}
                                    </tbody>
                                </table></div>
                            <div className="align-end attachment-label ">{this.props.dealers.error}</div>
                            <div className="grid-item"></div>


                            <div className="grid-item align-end">
                              <span hidden={!this.props.emails.isSaving}>
                                    <img
                                        alt="Loading"
                                        src="/img/loading-ring.gif"
                                        style={{ width: 40, height: 40 }}
                                    />
                                </span>
                                <input type="submit" value="Send" className={"atlas-email-admin-button " + (this.props.isSaving ? 'send-disabled' :'')}></input>
                                <a href="/email-admin" className="atlas-email-admin-button">Cancel</a>
                            </div>
                        </div>

                    </form>
                </div>
            </div >
        )
    }
})
