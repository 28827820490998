import {
    GET_EMAILS_PENDING,
    GET_EMAILS_SUCCESSFUL,
    GET_EMAILS_ERROR,
    CLEAR_EMAILS,
    GET_EMAILS,
    RECEIVE_EMAILS,
    EMAIL_SEARCH_LIMIT,
    CHANGE_EMAIL_SEARCH_QUERY,
    SAVE_MESSAGE_PENDING,
  SAVE_MESSAGE_SUCCESSFUL,

} from './constants'

var initialState = {
    data: [],
    meta: {
        total: 0,
        limit: 10,
        offset: 0
    },
    isLoading: false,
    wasLoadSuccessful: false,
    isSaving: false,
    wasSaveSuccessful: false,
    hasMore: false,
    error: null
}

function hasMore(response) {
    return (
        response.data.length !== 1 &&
        response.meta.offset + EMAIL_SEARCH_LIMIT < response.meta.total
    )
}

export default function emails(state = initialState, { type, offset, response }) {
    switch (type) {

        case GET_EMAILS:
            return {
                ...state,
                wasLoadSuccessful: offset !== 0,
                isLoading: true,
                data: offset === 0 ? [] : state.data,
            }

        case GET_EMAILS_PENDING:
            return {
                ...state,
                isLoading: true,
                wasLoadSuccessful: false,
                error: null
            }

        case GET_EMAILS_SUCCESSFUL:
            return {
                ...state,
                data: response.data,
                isLoading: false,
                wasLoadSuccessful: true,
                error: null
            }

        case GET_EMAILS_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                wasLoadSuccessful: false,
                error: response.error
            }

        case RECEIVE_EMAILS:
            return {
                ...state,
                ...response,
                isLoading: false,
                wasLoadSuccessful: true,
                hasMore: hasMore(response),
                data: response.meta.offset === 0
                    ? response.data
                    : state.data.concat(response.data)
            }

        case CLEAR_EMAILS:
            return {
                ...state,
                data: []
            }

        case CHANGE_EMAIL_SEARCH_QUERY:
            return {
                ...state,
                ...response,
                query: {
                    ...state.query,
                    q: response
                }
            }
            case SAVE_MESSAGE_PENDING:
              return {
                ...state,
                isSaving: true,
                wasSaveSuccessful: false,
                error: null
              }
        
            case SAVE_MESSAGE_SUCCESSFUL:
              return {
                ...state,
                isSaving: false,
                wasSaveSuccessful: true,
                error: null
              }
        
        default:
            return state
    }
}
