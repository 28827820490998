import {
    GET_INSIGHT_MESSAGE_SUCCESSFUL,
    LOAD_INSIGHT_MESSAGES_SUCCESSFUL,
    LOAD_INSIGHT_MESSAGES_PENDING,
    LOAD_RECIPIENTS_WITHOUT_BOUNCES
} from './constants'
import { dispatch, getState } from '../store'
import moment from 'moment'

export async function getInsightMessages(page, pageSize, id) {
}
export async function loadInsightMessages(page, pageSize, id) {
    if (!page) page = 1;
    if (!pageSize) pageSize = 8;

    dispatch({
        type: LOAD_INSIGHT_MESSAGES_PENDING,
        response: id
    })

    const offset = pageSize * (+page - 1)

    // get bounces from postmark
    //const bouncesResponse = await fetch(`/api/insights?id=${id}`, {
    const bouncesResponse = await fetch(`/api/insights`, {
        headers: {
            'x-session-id': getState().session.id
        },

    })
    const bounces = await bouncesResponse.json()
    // console.log(bounces.data)
    // for (const bounce of bounces.data) {
    //     console.log(bounce.data.Email)
    // }

    // get read status from postmark
    const readStatsResponse = await fetch(`/api/email-read-stats?id=${id}&offset=0&limit=500`, {
        headers: {
            'x-session-id': getState().session.id
        },

    })
    const readStats = await readStatsResponse.json()

    // get sent messages
    let query = ""
    const path = `/api/messages/${id}`
    const messageResponse = await fetch(path, {
        headers: {
            'x-session-id': getState().session.id
        }
    })
    const message = await messageResponse.json()

    let lines = []

    // remove bounces
    let recipientsWithoutBounces = []

    for (let recipient of message.data.recipients) {
        //console.log(recipient)
        let bounce = bounces.data.find(bounceItem => {
            //console.log(bounceItem)
            return bounceItem.data.Email === recipient.email
        })
        if (!bounce) {
            recipientsWithoutBounces.push(recipient)
        }
    }
    //console.log(recipientsWithoutBounces)
    // combine message recipients and read stats
    const recipients = recipientsWithoutBounces.slice(offset, offset + pageSize)
    dispatch({
        type: LOAD_RECIPIENTS_WITHOUT_BOUNCES,
        response: recipientsWithoutBounces
    })
    //console.log(recipients)
    for (let recipient of recipients) {
        let readStat = readStats.data.find(readStatItem => {
            return readStatItem.data.Recipient === recipient.email
        })

        // create the line
        const line = {
            _id: message.data._id,
            date: moment(message.data.modified).format('YYYY-MM-DD'),
            subject: message.data.subject,
            accountNumber: recipient.accountNumber,
            accountName: recipient.accountName,
            response: 'unread',
            email: recipient.email
        }

        if (readStat) {
            line.response = 'read'
        }
        lines.push(line)
    }

    dispatch({
        type: LOAD_INSIGHT_MESSAGES_SUCCESSFUL,
        response: lines
    })
}





