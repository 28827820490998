import React, { useState } from "react"
import { DataType, Table } from "ka-table"
import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators"
import { SortingMode, PagingPosition } from "ka-table/enums"
import { debounce } from "lodash"
import { v4 as uuidv4 } from "uuid"
import moment from 'moment'

const DetailsButton = ({ dispatch, rowKeyValue, isDetailsRowShown }) => {
  return (
    <button
      className="btn btn-secondary"
      onClick={() => {
        dispatch(
          isDetailsRowShown
            ? hideDetailsRow(rowKeyValue)
            : showDetailsRow(rowKeyValue)
        )
      }}
    >
      {isDetailsRowShown ? "Hide" : "Show"} Dealer Details
    </button>
  )
}

const DetailsRow = ({ rowData, onEnableAllUsers, onDisableAllUsers, onDisableUser, onEnableUser }) => {
  const [loading, setLoading] = useState(false)
  const handleEnableAllUsers = () => {
    setLoading(true)
    onEnableAllUsers(rowData.acctNum)
    setLoading(false)
  }
  const handleDisableAllUsers = () => {
    setLoading(true)
    onDisableAllUsers(rowData.acctNum)
    setLoading(false)
  }

  const activeUsersCount = rowData.users.filter((user) => !user.disabled).length
  const deactivatedUsersCount = rowData.users.length - activeUsersCount
  const showActivateAllButton = activeUsersCount !== rowData.users.length
  const showDeactivateAllButton = activeUsersCount !== 0
  return (
    <div>
      <div>{rowData.name} Users</div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div>Active Users: {activeUsersCount}</div>
        <div>Deactivated Users: {deactivatedUsersCount} </div>
      </div>
      <div style={{ display: 'flex', gap: '10px', margin: '10px 0'}}>
       {showActivateAllButton &&  <button className="btn btn-success" disabled={loading} onClick={handleEnableAllUsers}>
          Activate All
        </button>}
        {showDeactivateAllButton && <button className="btn btn-danger" disabled={loading} onClick={handleDisableAllUsers}>
          Deactivate All
        </button>}
      </div>
      <div>
        <ol className="list-group list-group-flush">
          {rowData.users.map((user, index) => (
            <li key={index} className="list-group-item">
              <div>
                <div> <strong>Name:</strong> {user.name} </div>
                <div> <strong>Department:</strong> {user.contactCode?.includes("AR") ? "Accounting" : "Parts"} </div>
                <div> <strong>Email:</strong> {user.email} &nbsp;
                 <a className="btn btn-success" href={`/users/${user._id}`}> Edit </a>
                </div>
                <div>
                  <strong>
                    Status:
                  </strong>
                  &nbsp;
                  <span className={user.disabled ? 'text-danger' :''}>
                  <strong>{user.disabled ? "Deactivated" : "Active"}</strong> 
                  </span>
                   &nbsp; {
                    user.disabled ? (
                      <button className="btn btn-success" disabled={loading} onClick={() => onEnableUser(user._id)}>
                        Activate
                      </button>
                    ) : (
                      <button className="btn btn-danger" disabled={loading} onClick={() => onDisableUser(user._id)}>
                        Deactivate
                      </button>
                    )
                    }
                </div>
                <div> <strong>Browser:</strong> {(user.browser && user.os ) && `${user.browser },${user.os}` }</div>
                <div> <strong>Last Login:</strong> { (user.lastLogin || user.last_login) ? moment(user.lastLogin || user.last_login).format('MMMM Do YYYY, h:mm a') : 'N/A'} </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

const Dealers = ({ allDealersData, session, onEnableAllUsers, onDisableUser, onDisableAllUsers, onEnableUser }) => {
  const [searchString, setSearchString] = useState()
  const [filter, setFilter] = useState('all')
  const { isLoading = true, data = [] } = allDealersData

  const dataArrayWithIds = data.map((obj) => {
    return { ...obj, uniqueId: uuidv4() , active: (obj.users.filter(user=> user.disabled).length === obj.users.length) ? 'No' : 'Yes' }
  })

  const filteredData = dataArrayWithIds
  .filter((dealer) => {
    if (filter === 'all') return true
    if (filter === 'active') return !dealer.users.some((user) => user.disabled)
    if (filter === 'inactive') return dealer.users.some((user) => user.disabled)
    return false
  })
  .filter((dealer) => {
    if (!searchString) return true

    const dealerMatches =
      dealer.acctNum?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
      dealer.name?.toString().toLowerCase().includes(searchString.toLowerCase())

    if (dealerMatches) return true

    const userMatches = dealer.users.some(
      (user) =>
        user.name?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        user.email?.toString().toLowerCase() === searchString.toLowerCase()
    )

    return userMatches
  })

  const onFilter = (e) => {
    setFilter(e.target.value)
  }


  const deactivatedDealerCount = dataArrayWithIds.filter(
    (dealer) => dealer.users.filter((user) => user.disabled).length === dealer.users.length
  ).length

  // console.log({ dataArrayWithIdsFirst: dataArrayWithIds ? dataArrayWithIds[0] : null })

  const activeDealerCount = dataArrayWithIds.length - deactivatedDealerCount

  const debounceFunction = debounce((input) => {
    setSearchString(input)
  }, 500)

  const search = (value) => {
    debounceFunction(value)
  }

  return (
    <div style={{ padding: "16px 20px" }}>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', flex: 1 }}>
      <div>
        <div style={{ width: "100%", padding: "0px 16px" }}>
          <label>
            <div>Search: </div>
            <input
              onChange={(e) => search(e.target.value)}
              type="text"
              name="search"
            />
          </label>
        </div>
        <div style={{ width: "100%", padding: "5px 16px" , display: 'flex', gap: '10px' }}>
          <a className="btn btn-primary" href="/users/new">
            New user
          </a>
          <a
            className="btn btn-info"
            href={`/api/users.csv?x-session-id=${session?.id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Export
          </a>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div>Active Dealers: {activeDealerCount}</div>
          <div>Deactivated Dealers: {deactivatedDealerCount} </div>
          <div>
          <select className="form-control" onChange={onFilter}>
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
          </select>
          </div>
        </div>
      </div>

    </div>

      <Table
        columns={[
          {
            key: "acctNum",
            title: "Account Number",
            dataType: DataType.String
          },
          { key: "name", title: "Name", dataType: DataType.String },
          { key: "active", title: "Active", dataType: DataType.String },
          { key: "lastLogin", title: "Last Login", dataType: DataType.Date },
          { key: "show-hide-details-row" }
        ]}
        data={filteredData}
        // detailsRows={[2]}
        rowKeyField={"uniqueId"}
        sortingMode={SortingMode.Single}
        loading={{ enabled: isLoading, text: "Loading" }}
        paging={{
          enabled: true,
          pageIndex: 0,
          pageSize: 500,
          pageSizes: [100, 500, 1000],
          position: PagingPosition.TopAndBottom
        }}
        childComponents={{
          cellText: {
            content: (props) => {
              switch (props.column.key) {
                case "show-hide-details-row":
                  return <DetailsButton {...props} />
                default:
                  return null
              }
            }
          },
          detailsRow: {
            elementAttributes: () => ({
              style: {
                backgroundColor: "#f0f0f0"
              }
            }),
            content: (props) =>  <DetailsRow {...props} onEnableAllUsers={onEnableAllUsers} onDisableAllUsers={onDisableAllUsers} onDisableUser={onDisableUser} onEnableUser={onEnableUser} />
          }
        }}
      />
    </div>
  )
}

export default Dealers
