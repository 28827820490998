import {
  GET_MY_WEB_ORDERS_PENDING,
  GET_MY_WEB_ORDERS_SUCCESSFUL,
  GET_MY_WEB_ORDERS_ERROR
} from './constants'
import { dispatch, getState } from '../store'

export function getMyWebOrders (options = {}) {
  dispatch({
    type: GET_MY_WEB_ORDERS_PENDING
  })

  fetch('/api/web-orders', {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_MY_WEB_ORDERS_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.error(error)
      dispatch({
        type: GET_MY_WEB_ORDERS_ERROR,
        error
      })
    })
}

export function getWebOrdersByDate (startDate, endDate) {
  dispatch({
    type: GET_MY_WEB_ORDERS_PENDING
  })

  fetch(`/api/web-orders?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`, { // TODO: fix this
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_MY_WEB_ORDERS_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.error(error)
      dispatch({
        type: GET_MY_WEB_ORDERS_ERROR,
        error
      })
    })
}