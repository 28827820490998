import {
    GET_USERS_PENDING,
    GET_USERS_SUCCESSFUL,
    GET_USERS_ERROR,
    CLEAR_USERS,
    GET_USERS
} from './constants'
import { dispatch, getState } from '../store'

export function getUsers(options = {}) {
    dispatch({
        type: GET_USERS_PENDING
    })

    fetch(`/api/users?q=${options.query || ''}`, {
        headers: {
            'x-session-id': getState().session.id
        }
    })
        .then(async function (response) {
            if (response.ok) {
                const json = await response.json()
                dispatch({
                    type: GET_USERS_SUCCESSFUL,
                    response: json
                })
            }
        })
        .catch(function (error) {
            console.log('parsing failed', error)
            dispatch({
                type: GET_USERS_ERROR,
                error
            })
        })
}

function fetchUsers(path) {
    fetch(path, {
        headers: {
            'x-session-id': getState().session.id
        }
    }).then(function (response) {
        if (response.ok) {
            return response.json()
        }
        throw new Error('Message search failed')
    }).then(function (response) {
        dispatch({
            type: GET_USERS_SUCCESSFUL,
            response
        })
    }).catch(function (error) {
        dispatch({
            type: GET_USERS_ERROR,
            error
        })
    })
}

export function searchUsers(query, pagenum, pageSize, lang, sortBy, sortDir) {

    if (!pagenum) pagenum = 1;
    if (!pageSize) pageSize = 8;
    if (!query) query = "";
    if (!lang) lang = '';
    if (!sortBy) sortBy = '';

    dispatch({
        type: GET_USERS_PENDING
    })

    let offset = pageSize * (+pagenum - 1)

    const path = `/api/users-search?q=${JSON.stringify(query)}&offset=${offset}&limit=${pageSize}&lang=${lang}&sortBy=${sortBy}&sortDir=${sortDir}`
    console.log(path)

    dispatch({
        type: GET_USERS,
        offset
    })

    fetchUsers(path)
}