import React from 'react'
import { findTranslation } from '../../../common/translation'
import numeral from 'numeral'
import warehouses from '../../../constants/warehouses'
import getRelevantWarehouses from '../../../common/get-relevant-warehouses'
import imageToSrcset from '../../../transforms/image-to-srcset'
import { addCartItem } from '../../cart/actions'
import makeProductDataPresentable from '../../../common/make-product-data-presentable'
import AddToCartButton from './add-to-cart-button'
import Availability from '../../common/availability'
import { FormattedMessage } from 'react-intl'
import createReactClass from 'create-react-class'

import Modal from 'react-modal'
Modal.setAppElement('#app')

export default createReactClass({
  displayName: 'DetailedListItem',

  getInitialState () {
    return {
      defaultImgSrcset: imageToSrcset(this.props.product.attributes.images && this.props.product.attributes.images[0]),
      showLightbox: false,
      quantity: 0
    }
  },

  handleQuantityChange (quantity) {
    this.setState({ quantity })
  },

  handleDefaultImageChange (defaultImgSrcset) {
    this.setState({ defaultImgSrcset: defaultImgSrcset })
  },

  calculateCustomMarkup (dealerPrice, dealer) {
    dealerPrice = dealerPrice.replace(',','')
    var markup
    if (dealerPrice >= 2000) markup = dealer.customMarkup[9] || 30
    else if (dealerPrice >= 1000) markup = dealer.customMarkup[8] || 35
    else if (dealerPrice >= 500) markup = dealer.customMarkup[7] || 40
    else if (dealerPrice >= 300) markup = dealer.customMarkup[6] || 50
    else if (dealerPrice >= 100) markup = dealer.customMarkup[5] || 65
    else if (dealerPrice >= 50) markup = dealer.customMarkup[4] || 75
    else if (dealerPrice >= 10) markup = dealer.customMarkup[3] || 80
    else if (dealerPrice >= 5) markup = dealer.customMarkup[2] || 85
    else if (dealerPrice >= 1) markup = dealer.customMarkup[1] || 90
    else if (dealerPrice >= 0) markup = dealer.customMarkup[0] || 100
    var retailPrice = (((parseFloat(markup) + 100) / 100) * dealerPrice)
    retailPrice = numeral(retailPrice).format('0,0.00')
    return retailPrice
  },

  handleTriggerLightbox (event) {
    event.preventDefault()
    this.setState({
      defaultImgSrcset: event.target.srcset
    })
    this.setState({
      showLightbox: true
    })
  },

  handleCloseModal (event) {
    this.setState({
      showLightbox: false
    })
  },

  onMouseEnterThumbnail (event) {
    console.log(event.target.parentElement)
    event.target.parentElement.style.border = "solid 2px orange"
    this.setState({ 
      defaultImgSrcset: event.target.srcset
    })
  },

  onMouseExitThumbnail (event) {
    event.target.parentElement.style.border = "solid 2px #aaaaaa"

  },

  render () {
    const isOnly = this.props.isOnly
    const session = this.props.session
    const isOnline = this.props.isOnline
    const product = this.props.product
    const language = this.props.language
    const isRetailMode = this.props.isRetailMode
    const inventory = product.attributes.inventory || []
    const warehouseCode = this.props.warehouseCode
    const { subscribeToProductUpdates } = this.props
    const clearanceQty = this.props.product.attributes.categoryIds.indexOf("ffefca7d-a309-4a7c-8b93-34c7d76a9712") > -1?this.props.product.attributes.clearanceQty:0
    let singleView =
      this.props.singleView || atlas.getState().browser.query.quantity
    const userRoles = this.props.userRoles
    const isAdmin = userRoles && userRoles.includes('Administrator')
    const isPrivileged = Boolean(
      userRoles &&
        (userRoles.includes('Administrator') || userRoles.includes('Sales'))
    )
    // Identify if product is new by checking if it is in the new category
    const isNewProduct = product.attributes.categoryIds?.includes('9daf56fb-e712-4daf-81bb-e28d09377a0d')

    // Identify if product is on clearance by checking if it is in the clearance category
    const isClearanceProduct = product.attributes.categoryIds?.includes('ffefca7d-a309-4a7c-8b93-34c7d76a9712')

    const isScratchAndDentProduct = product.attributes.categoryIds?.includes('19818b31-8ff8-4a31-a4c3-bdbaf8524b66')

    let relevantWarehouses
    if (this.props.product.attributes.categoryIds.indexOf("ffefca7d-a309-4a7c-8b93-34c7d76a9712") > -1) {
      relevantWarehouses = inventory
    } else {
      relevantWarehouses = getRelevantWarehouses({
        primaryWarehouseCode: warehouseCode,
        quantity: this.state.quantity || 0,
        inventory,
        warehouses,
        isPrivileged
      })
    }

    let defaultImageSrcset = imageToSrcset(
      product.attributes.images && product.attributes.images[0]
    )
    let allImageSrcsets =
      product.attributes.images &&
      product.attributes.images.map(image => {
        return imageToSrcset(image)
      })
    let lightboxImageComponents
    if (allImageSrcsets) {
      lightboxImageComponents = allImageSrcsets.map(imageSrcset => {
        return (
          <img
            alt=""
            className="Lightbox-background-image"
            key={imageSrcset}
            sizes="600px"
            srcSet={imageSrcset}
            style={{ maxHeight: 600, margin: 12 }}
          />
        )
      })
    }
    const isDiscontinued = product.attributes.isDiscontinued || false
    var hasInv = false
    if (inventory === undefined || inventory.length === 0) {hasInv = false}
    else {
      hasInv = !(inventory[0].quantity <= 0 && inventory[1].quantity <= 0 && inventory[2].quantity <= 0 && inventory[3].quantity <= 0)
    }

    const {
      title,
      shortDescription,
      longDescription,
      manufacturerName
    } = makeProductDataPresentable({
      title: findTranslation(product.attributes.title, language.code),
      shortDescription: findTranslation(
        product.attributes.shortDescription,
        language.code
      ),
      longDescription: findTranslation(
        product.attributes.longDescription,
        language.code
      ),
      manufacturerName: product.attributes.manufacturerName,
      manufacturerPartNumber: product.attributes.manufacturerPartNumber
    })
    const dealerPrice =
      product.attributes &&
      product.attributes.dealerPrice &&
      numeral(product.attributes.dealerPrice).format('0,0.00')
    const FXSurcharge =
      product.attributes &&
      product.attributes.FXSurcharge &&
      numeral(product.attributes.FXSurcharge).format('0,0.00')
    const clearancePrice =
      product.attributes &&
      product.attributes.clearancePrice &&
      numeral(product.attributes.clearancePrice).format('0,0.00')
    const flyerPrice =
      product.attributes &&
      product.attributes.flyerPrice &&
      numeral(product.attributes.flyerPrice).format('0,0.00')
    var retailPrice
    if (product.attributes.FXSurcharge > 0) {
      retailPrice =
        product.attributes &&
        product.attributes.retailPrice &&
        numeral(product.attributes.retailPrice + product.attributes.FXSurcharge).format('0,0.00')
    } else { 
      retailPrice =
        product.attributes &&
        product.attributes.retailPrice &&
        numeral(product.attributes.retailPrice).format('0,0.00')
    }
    const unitOfMeasure =
      dealerPrice &&
      product.attributes &&
      product.attributes.unitOfMeasure &&
      `(${product.attributes.unitOfMeasure})`
    var thumbnailList = allImageSrcsets.map(image => {
      return (
        <div key={image} style={{ width:"40px", height: "72px",marginBottom:"10px", border: "solid 2px #aaaaaa", textAlign: "center" }}>
          <img
            onMouseEnter={this.onMouseEnterThumbnail}
            onMouseLeave={this.onMouseExitThumbnail}
            alt=""
            className="atlas-detailed-list-product-image"
            sizes="250px"
            srcSet={image}
            style={{ maxWidth: "32px", maxHeight:"72px", cursor:"pointer" }}
          />
        </div>
      )
    })

    return (
      <div className="atlas-detailed-list-product" key={product.id}>
        {(isDiscontinued && !hasInv) && <div style={{ position:"absolute", width:"100%", color:"#B6D2D2" , opacity:"0.75", fontSize:100, fontWeight: 900, textAlign:"center",marginTop:"15px", transform: "rotate(-10deg)" }}>No Longer Available</div>}
        {(isOnly && product.attributes.images.length > 1) && <div className="atlas-detailed-thumbail-list-container" style={{ width:"40px", height:"100%" }}>
          {thumbnailList}
        </div>}
        {
          isNewProduct && <div className='new-badge'/>
        }
        {
          isClearanceProduct && <div className='clearance-badge'>
          </div>
        }
        {
          isScratchAndDentProduct && <div className='scratch-and-dent-badge' />
        }
        <div className="atlas-detailed-list-product-image-container">
          {defaultImageSrcset &&
            <button
              className="button-reset-style"
              onClick={this.handleTriggerLightbox}
            >
              <img
                alt=""
                className="atlas-detailed-list-product-image"
                sizes="250px"
                srcSet={this.state.defaultImgSrcset}
                style={{ maxHeight: 250 , maxWidth:250 }}
              />
            </button>}
          <Modal
            isOpen={this.state.showLightbox}
            onRequestClose={this.handleCloseModal}
            style={{}}
          >

            <div className="Lightbox">
              <button
                className="Lightbox-close-button"
                onClick={this.handleCloseModal} 
              >
                Close
              </button>
              <div className="Lightbox-background">
                <img
                  alt=""
                  className="Lightbox-background-image"
                  sizes="800px"
                  srcSet={this.state.defaultImgSrcset}
                  style={{ maxHeight: 800, margin: 12 }}
                />
                <div style={{ float:"left" }}>
                  {thumbnailList}
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="atlas-detailed-list-product-info">
          {isAdmin &&
            <div style={{ float: 'right' }}>
              <a
                className="atlas-small-button"
                href={`/edit-product/${product.attributes
                  .distributorPartNumber}`}
              >
                <FormattedMessage
                  defaultMessage="Edit product"
                  id="Edit product"
                />
              </a>
            </div>}
          {(title || shortDescription) &&
            <h3 className="atlas-detailed-result-title">
              {title || shortDescription}
            </h3>}
          {title &&
            shortDescription &&
            <div className="atlas-detailed-result-short-description">
              {shortDescription}
            </div>}
          {manufacturerName &&
            <div className="atlas-detailed-result-mfg">
              {manufacturerName}
            </div>}
          <div className="atlas-detailed-result-description">
            {longDescription}
          </div>

          <table className="table table-striped table-sm table-bordered atlas-detailed-result-table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage defaultMessage="Stock #" id="Stock #" />
                </th>
                <th>
                  <FormattedMessage defaultMessage="Mfg #" id="Mfg #" />
                </th>
                <th>
                  <FormattedMessage defaultMessage="Catalogue Page #" id="Catalogue Page #" />
                </th>
                {(!isRetailMode && !(isDiscontinued && !hasInv)) &&
                  <th>
                    <FormattedMessage defaultMessage="Dealer" id="Dealer" /> ($)
                  </th>}
                {(!isRetailMode && !(isDiscontinued && !hasInv)) &&
                  <th>
                  FX ($)
                  </th>}
                {!(isDiscontinued && !hasInv) && 
                  <th style={{ color: 'red' }}>
                    <FormattedMessage defaultMessage="Retail" id="Retail" /> ($)
                  </th>
                }
                <th>
                  <FormattedMessage
                    defaultMessage="Availability"
                    id="Availability"
                  />
                </th>
                <th style={{ width: 130 }} />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    {singleView &&
                      <span>
                        {product.attributes.distributorPartNumber}
                      </span>                    
                    }

                    {!singleView &&
                      <span>
                        {product.attributes.distributorPartNumber}
                      </span>}
                  </div>
                </td>
                <td>
                  {product.attributes.manufacturerPartNumber}
                </td>
                <td>
                  {product.attributes.cataloguePageNum}
                </td>
                {(!isRetailMode && !(isDiscontinued && !hasInv)) &&
                  <td>
                    {!clearancePrice &&
                      !flyerPrice &&
                      <span>
                        <span className="atlas-dealer-price">
                          {dealerPrice || ''}
                        </span>
                        {' '}
                        {unitOfMeasure || ''}
                      </span>}
                    {(clearancePrice || flyerPrice || '') &&
                      <div>
                        <div>
                          <span className="atlas-clearance-price">
                            {clearancePrice || flyerPrice || ''}
                          </span>
                          {' '}
                          {unitOfMeasure || ''}
                        </div>
                        <div className="atlas-regular-price">
                          {dealerPrice || ''}
                        </div>
                      </div>}
                  </td>}
                  {!isRetailMode && 
                    <td style={{ color: 'blue' }}>
                      {FXSurcharge || ""} 
                    </td>
                  }
                {!(isDiscontinued && !hasInv) &&  
                  <td className="atlas-retail-price">
                    {session.dealer.usesCustomPrices ? this.calculateCustomMarkup(dealerPrice, session.dealer) : (retailPrice || '')}
                  </td>
                }
                <td>
                  <Availability
                    isPrivileged={isPrivileged}
                    language={language}
                    primaryWarehouseCode={warehouseCode}
                    isCumulativeSummary={Boolean(clearancePrice)}
                    quantity={this.state.quantity}
                    warehouses={relevantWarehouses}
                    clearanceQty={clearanceQty}
                    subscribeToProductUpdates={subscribeToProductUpdates}
                    productId={product.attributes.distributorPartNumber}
                    showNotifyMe={true}
                  />
                </td>
                <td style={{ textAlign: 'center', lineHeight: '1rem' }}>
                  {(dealerPrice || '') &&
                    isOnline &&
                    <AddToCartButton
                      addCartItem={addCartItem.bind(null, product)}
                      onQuantityChange={this.handleQuantityChange}
                    />}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
})
