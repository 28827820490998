import React from 'react'
import classNames from 'classnames'
import { findTranslation } from '../../common/translation'
import createReactClass from 'create-react-class'

// function sortCategoriesByAimsId (a, b) {
//   return a.attributes.aimsId < b.attributes.aimsId ? -1 : 1
// }

function sortCategoriesByName (a, b) {
  return a.attributes.name[0].value < b.attributes.name[0].value ? -1 : 1
    // return a.attributes.aimsId < b.attributes.aimsId ? -1 : 1
}


export default createReactClass({
  displayName: 'CategoryChildren',

  render () {
    const language = this.props.language
    const categories = this.props.categories.data
    const categoryId = this.props.categoryId
    let descendantCount = 0



    const listItems = categories
      .filter(category => {
        return category.attributes.parentId === categoryId
      })
      .sort(sortCategoriesByName)
      .map(childCategory => {
        descendantCount++
        const grandchildren = categories
          .filter(category => {
            return category.attributes.parentId === childCategory.id
          })
          .sort(sortCategoriesByName)
          .map(grandchildCategory => {
            descendantCount++
            return (
              <li key={grandchildCategory.id}>
                <a href={`/?categoryId=${grandchildCategory.id}`}>
                  {findTranslation(
                    grandchildCategory.attributes.name,
                    language.code
                  )}
                </a>
              </li>
            )
          })

        const listItemClasses = classNames('CategoryChildren-list-item', {
          'CategoryChildren-filled-list-item': grandchildren.length
        })
        const listItemLinkClasses = classNames({
          'CategoryChildren-filled-list-item-link': grandchildren.length
        })

        return (
          <li className={listItemClasses} key={childCategory.id}>
            <a
              className={listItemLinkClasses}
              href={`/?categoryId=${childCategory.id}`}
            >
              {findTranslation(childCategory.attributes.name, language.code)}
            </a>
            <ul className="CategoryGrandchildren-list">
              {grandchildren}
            </ul>
          </li>
        )
      })

    if (!listItems.length) {
      return null
    }

    const classnames = classNames('CategoryChildren-list', {
      'CategoryChildren-columned-list': descendantCount > 10
    })

    return (
      <ul className={classnames}>
        {listItems}
      </ul>
    )
  }
})
