import React, { Component } from "react"
import Item from "./list-item"
import * as XLSX from "xlsx"
import moment from "moment"
import numeral from "numeral"
import { DateTime } from 'luxon'

class WebOrders extends Component {
  constructor (props) {
    super(props)
    this.inputRef1 = React.createRef()
    this.inputRef2 = React.createRef()
    this.state = {
      fromDate: null,
      toDate: new Date()
    }
  }

  render () {
    const user = this.props.session.user
    const hasPrivilegedRole =
      user.roles &&
      user.roles.some((role) => {
        return ["DataProvider", "Administrator", "Sales"].includes(role)
      })

      const hasAdminRole = user.roles && user.roles.includes("Administrator")

    const webOrders = this.props.webOrders.data
      .filter((webOrder) => {
        if (user.roles.includes("Sales")) {
          if (user.dealerPatterns && user.dealerPatterns.length > 0) {
            for (let i = 0; i < user.dealerPatterns.length; i++) {
              let validPattern = true
              if (
                user.dealerPatterns[i].length ===
                webOrder.attributes.customerId.length
              ) {
                for (
                  let j = 0;
                  j < webOrder.attributes.customerId.length;
                  j++
                ) {
                  //Letter is !(valid)
                  if (
                    !(
                      user.dealerPatterns[i][j] ===
                        webOrder.attributes.customerId[j] ||
                      user.dealerPatterns[i][j] === "#"
                    )
                  ) {
                    validPattern = false
                  }
                }
              } else validPattern = false

              if (validPattern) {
                return true
              }
            }
            return false
          } else return true
        } else return true
      })
      .filter((webOrder) => {
        const { fromDate, toDate } = this.state
        if (fromDate) {
          const submittedAt = new Date(webOrder.attributes.submittedAt)
          const startDate = new Date(fromDate)
          startDate.setHours(0, 0, 0, 0)
          // endDate should be the end of the day
          const endDate = new Date(toDate || this.state.toDate)
          endDate.setHours(23, 59, 59, 999)
          return submittedAt >= startDate && submittedAt <= endDate
        }

        return true
      })
      .map((webOrder) => {
        return (
          <Item
            {...webOrder.attributes}
            hasPrivilegedRole={hasPrivilegedRole}
            key={webOrder.id}
          />
        )
      })

    const total = webOrders.reduce(
      (acc, order) => acc + order.props.orderSubtotal,
      0
    )

    const handleDateFilter = (e) => {
      e.preventDefault()
      const fromDate = e.target.from.value
      const toDate = e.target.to.value || this.state.toDate
      const startDate = DateTime.fromISO(fromDate).toISO()
      const endDate = DateTime.fromISO(toDate).plus({ days: 1 }).minus({ milliseconds: 1 }).toISO()

      // check if any of the orders are within the date range else fetch new data
      // const ordersWithinDateRange = webOrders.some((order) => {
      //   const submittedAt = new Date(order.props.submittedAt)
      //   return submittedAt >= startDate && submittedAt <= endDate
      // })
      // const formattedStartDate = moment(startDate).utc().format("YYYY-MM-DD")
      // const formattedEndDate = moment(endDate).utc().format("YYYY-MM-DD")
      // console.log({ formattedStartDate, formattedEndDate })
      this.props.getWebOrdersByDate(startDate, endDate)
      // this.setState({ fromDate, toDate })
      // if (!ordersWithinDateRange) {
      // }else {
      // }


    }

    const handleClearClick = () => {
      if (this.inputRef1.current) {
        this.inputRef1.current.value = ""
      }
      if (this.inputRef2.current) {
        this.inputRef2.current.value = ""
      }
    }

    const clearDateFilter = () => {
      this.setState({ fromDate: null, toDate: new Date() })
    }

    const handleDownload = () => {
      const data = webOrders.map((order) => ({
        Reference: order.props.referenceNumber,
        Account: order.props.customerId,
        "Dealer Name": order.props.userName,
        Name: order.props.userName, // User's name
        "Purchase order": order.props.purchaseOrder,
        Subtotal: order.props.orderSubtotal,
        "Submitted at": moment(order.props.submittedAt).format(
          "MMMM Do YYYY, h:mm a"
        )
      }))

      // Create an array with the total row, two empty rows, and the headers
      const rows = [
        { Reference: "Total:", Subtotal: numeral(total).format("$0,0.00") },
        {}, // First empty row
        {}, // Second empty row
        {
          Reference: "Reference",
          Account: "Account",
          "Dealer Name": "Dealer Name",
          "Name": "Name", // User's name
          "Purchase order": "Purchase order",
          Subtotal: "Subtotal",
          "Submitted at": "Submitted at"
          // Add any additional header columns here if needed
        },
        ...data
      ]

      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(rows, {
        skipHeader: true // Skip the header row while adding data
      })

      const sheetName = "Web Orders"
      const columnWidths = [
        { wch: 15 }, // referenceNumber
        { wch: 12 }, // customerId
        { wch: 30 }, // userName
        { wch: 20 }, // purchaseOrder
        { wch: 15 }, // orderSubtotal
        { wch: 25 } // submittedAt
      ]

      columnWidths.forEach((width, colIndex) => {
        worksheet["!cols"] = worksheet["!cols"] || []
        worksheet["!cols"][colIndex] = width
      })

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

      // Export the workbook to the Excel file
      XLSX.writeFile(workbook, "web_orders.xlsx")
    }

    return (
      <div style={{ postion: 'relative', height: '80dvh', overflow: 'auto' }} className="WebOrders">
        <div style={{  position: "sticky", top: 0,   
                backdropFilter: 'blur(10px)' , 
                padding: '10px',
                backgroundColor: 'rgba(255, 255, 255, 0.8)', zIndex: 1000  }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "32px"
            }}
          >
            <div>
              <h1>Web Orders</h1>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap"
              }}
            >
              <form onSubmit={(e) => handleDateFilter(e)} className="form-inline">
                <div class="form-group mb-2 row">
                  <label for="staticfrom2" class="col-sm-2 col-form-label">
                    From
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="date"
                      class="form-control"
                      name="from"
                      id="staticfrom2"
                      ref={this.inputRef1}
                    />
                  </div>
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <label for="inputto2" class="col-sm-2 col-form-label">
                    To
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="date"
                      class="form-control"
                      name="to"
                      id="inputto2"
                      ref={this.inputRef2}
                    />
                  </div>
                </div>
                <button type="submit" class="btn btn-success mb-2">
                  Find
                </button>
              </form>

              <button
                onClick={() => {
                  clearDateFilter()
                  handleClearClick()
                }}
                class="btn mb-2"
              >
                Clear Date
              </button>
            {hasAdminRole && <button onClick={() => handleDownload()} class="btn mb-2">
                Export
              </button>}
            </div>
          </div>
          {total && hasAdminRole ? (
            <h2>Total Transactions - {numeral(total).format("$0,0.00")}</h2>
          ) : (
            ""
          )}
        </div>
        <table className="table table-striped table-sm table-bordered atlas-detailed-result-table" style={{  position: 'relative' }}>
          <thead className="WebOrdersItem" style={{ position: 'sticky', top: "15%",    backdropFilter: 'blur(10px)' , 
                backgroundColor: 'rgba(255, 255, 255, 0.8)', zIndex: 1000 }}>
            <tr>
              <th className="WebOrdersItem-cell">Reference</th>
              {hasPrivilegedRole && (
                <th className="WebOrdersItem-cell">Account</th>
              )}
              {hasPrivilegedRole && (
                <th className="WebOrdersItem-cell">Dealer Name</th>
              )}
              <th className="WebOrdersItem-cell">Name</th> {/* User's name */}
              <th className="WebOrdersItem-cell">Purchase order</th>
              <th className="WebOrdersItem-cell">Subtotal</th>
              <th className="WebOrdersItem-cell">Submitted at</th>
            </tr>
          </thead>
          <tbody>{webOrders}</tbody>
        </table>
      </div>
    )
  }
}
export default WebOrders
