import React from 'react'
import ReactS3Uploader from 'react-s3-uploader'
import FileListItem from './list-item'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'Files',

  handleUploadProgress () {
    console.log('handleUploadProgress', arguments)
    return true
  },

  handleUploadError () {
    console.log('handleUploadError', arguments)
  },

  handleUploadFinish (s3result, file) {
    this.props.postFile(s3result, file)
  },

  render () {
    const fileComponents = this.props.data.map(file => {
      return <FileListItem key={file.id} file={file} />
    })
    return (
      <div>
        <div>
          <ReactS3Uploader
            accept="image/*"
            contentDisposition="auto"
            multiple
            onProgress={this.handleUploadProgress}
            onError={this.handleUploadError}
            onFinish={this.handleUploadFinish}
            signingUrl="/s3/sign"
          />
        </div>
        <table className="table">
          <tbody>
            {fileComponents}
          </tbody>
        </table>
      </div>
    )
  }
})
