import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../../../../store'
import appActions from '../../../../actions/app'
import App from '../../../../common/app'
import AggregatedCategoryList from '../list'
import AggregatedSubCategoryList from '../sub-list'
import AllProducts from '../per-category'
import { getAggregatedCategories } from '../../../../actions/aggregate-product-categories'
import { aggregateProducts } from '../../../../actions/products'


function renderList (mountNode, categoryType) {
    return function () {
        ReactDOM.render(
        <App active="/products/list" {...getState()} {...appActions}>
            <AggregatedCategoryList {...getState()} {...appActions}  categoryType={categoryType}/>
        </App>,
        mountNode
        )
    }
}

function renderSubList (mountNode, categoryType, parentId) {
    return function () {
        ReactDOM.render(
        <App active="/products/list" {...getState()} {...appActions}>
            <AggregatedSubCategoryList {...getState()} {...appActions}  categoryType={categoryType} parentId={parentId}/>
        </App>,
        mountNode
        )
    }
}

function renderPerCategory (mountNode) {
    return function () {
        ReactDOM.render(
        <App active="/products/aggregation" {...getState()} {...appActions}>
            <AllProducts {...getState()} {...appActions}  />
        </App>,
        mountNode
        )
    }
}

let unsubscribe1 = function () {}
let unsubscribe2 = function () {}
let unsubscribe3 = function () {}

export default function Aggregation (app, mountNode) {
    // app is a Page.js instance https://github.com/visionmedia/page.js
    app('/products/list/:category', context => {
        const { category } = context.params
        getAggregatedCategories(category)
        unsubscribe1 = subscribe(renderList(mountNode, category))  
        renderList(mountNode, category)()
    })

    // app.exit('/products/list', (context, next) => {
    //     unsubscribe1()
    //     next()
    // })

    app.exit('/products/list/:category', (context, next) => {
        unsubscribe1()
        next()
    })

    app('/products/aggregation/:categoryType/:categoryId', context => {
        const { categoryType, categoryId } = context.params
        aggregateProducts(categoryType, categoryId)
        unsubscribe2 = subscribe(renderPerCategory(mountNode))  
        renderPerCategory(mountNode)()
    })

    app.exit('/products/aggregation', (context, next) => {
        unsubscribe2()
        next()
    })

    app('/products/list/:category/:parentId', context => {
        const { category, parentId } = context.params
        getAggregatedCategories(category)
        unsubscribe3 = subscribe(renderSubList(mountNode, category, parentId))  
        renderSubList(mountNode, category, parentId)()
    })    

    app.exit('/products/list/:category/:parentId', (context, next) => {
        unsubscribe3()
        next()
    })


}