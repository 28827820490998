import React from "react";
import createReactClass from "create-react-class";

function fromFormDataToObject(formData) {
  return Array.from(formData).reduce((previousValue, currentValue) => {
    const value =
      typeof currentValue[1] === "string"
        ? currentValue[1].trim()
        : currentValue[1];
    return Object.assign({}, previousValue, {
      [currentValue[0]]: value,
    });
  }, {});
}

export default createReactClass({
  displayName: "Dealer",

  handleSubmit(event) {
    event.preventDefault();
    const data = fromFormDataToObject(new FormData(this.refs.form));
    data.rvpf = Boolean(data.rvpf);
    this.props.saveDealer(data);
  },

  handleDeleteClick(event) {
    event.preventDefault();
    if (
      prompt(
        "Are you sure you want to delete this dealer? Type YES to proceed."
      ) === "YES"
    ) {
      this.props.deleteDealer(this.props.data.id);
    }
  },

  handleCatalogUrlBlur(event) {
    if (
      this.refs.catalogUrl.value &&
      !this.refs.catalogUrl.value.startsWith("http")
    ) {
      this.refs.catalogUrl.value = `https://${this.refs.catalogUrl.value}`;
    }
  },

  render() {
    const dealer = this.props.data.attributes;

    if (this.props.isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div style={{ padding: 24 }}>
        <div style={{ marginBottom: 12 }}>
          <a href="/dealers">Back to dealers</a>
        </div>
        <form onSubmit={this.handleSubmit} ref="form">
          {dealer._id && (
            <input defaultValue={dealer._id} name="id" type="hidden" />
          )}
          <fieldset>
            <div>
              <label>
                <div>Account number</div>
                <input
                  defaultValue={dealer.accountNumber}
                  name="accountNumber"
                  required
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Dealer name</div>
                <input
                  defaultValue={dealer.name}
                  name="name"
                  required
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Logo URL</div>
                <input
                  defaultValue={dealer.logoUrl}
                  name="logoUrl"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>

            <div>
              {dealer.logoUrl && (
                <img
                  alt="Logo"
                  src={dealer.logoUrl}
                  style={{ maxHeight: 120 }}
                />
              )}
            </div>

            <div style={{ marginTop: 12 }}>
              <label>
                <div>
                  Primary URL
                  <span> </span>
                  <small style={{ color: "#aaa" }}>
                    Example: http://www.roulotte.ca/
                  </small>
                </div>
                <input
                  defaultValue={dealer.primaryUrl}
                  name="primaryUrl"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>

            <div>
              <label>
                <div>RVPF</div>
                <input
                  defaultChecked={dealer.rvpf}
                  name="rvpf"
                  style={{ width: 450 }}
                  type="checkbox"
                  value="true"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Email</div>
                <input
                  defaultValue={dealer.email}
                  name="email"
                  style={{ width: 450 }}
                  type="email"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Phone</div>
                <input
                  defaultValue={dealer.phone}
                  name="phone"
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Fax</div>
                <input
                  defaultValue={dealer.fax}
                  name="fax"
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Address</div>
                <input
                  defaultValue={dealer.address}
                  name="address"
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>

            <div>
              <label>
                <div>City / town</div>
                <input
                  defaultValue={dealer.city}
                  name="city"
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Province</div>
                <input
                  defaultValue={dealer.province}
                  name="province"
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Postal code</div>
                <input
                  defaultValue={dealer.postalCode}
                  name="postalCode"
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>

            <div>
              <label>
                <div>
                  Catalog URL (English)
                  <span> </span>
                  <small style={{ color: "#aaa" }}>
                    Example: https://aslevesque.rvcatalogue.ca
                  </small>
                </div>
                <input
                  defaultValue={dealer.catalogUrl}
                  name="catalogUrl"
                  onBlur={this.handleCatalogUrlBlur}
                  ref="catalogUrl"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>
            <div>
              <label>
                <div>
                  Catalog URL (French)
                  <span> </span>
                  <small style={{ color: "#aaa" }}>
                    Example: https://aslevesque.rvcatalogue.fr
                  </small>
                </div>
                <input
                  defaultValue={dealer.catalogUrlFr}
                  name="catalogUrlFr"
                  // onBlur={this.handleCatalogUrlBlur}
                  // ref="catalogUrlFr"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Cover image URL (English)</div>
                <input
                  defaultValue={dealer.coverImageUrl}
                  name="coverImageUrl"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>
            <div>
              <label>
                <div>Cover image URL (French)</div>
                <input
                  defaultValue={dealer.coverImageUrlFr}
                  name="coverImageUrlFr"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>

            <div>
              {dealer.coverImageUrl && (
                <img
                  alt="Cover"
                  src={dealer.coverImageUrl}
                  style={{ maxHeight: 120 }}
                />
              )}
            </div>

            <div>
              <label>
                <div>Catalogue Image URL (English)</div>
                <input
                  defaultValue={dealer.catalogPDFCover}
                  name="catalogPDFCover"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>

            <div>
              <label>
                <div>Catalogue Image URL (French)</div>
                <input
                  defaultValue={dealer.catalogPDFCoverFr}
                  name="catalogPDFCoverFr"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>

            <div>
              <label>
                <div>PDF Catalogue Link (English)</div>
                <input
                  defaultValue={dealer.PDFCatalogLink}
                  name="PDFCatalogLink"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>
            <div>
              <label>
                <div>PDF Catalogue Link (French)</div>
                <input
                  defaultValue={dealer.PDFCatalogLinkFr}
                  name="PDFCatalogLinkFr"
                  style={{ width: 450 }}
                  type="url"
                />
              </label>
            </div>
          </fieldset>

          <div style={{ display: "flex", marginTop: 12, gap: "20px" }}>
            <div style={{}}>
              <button className="btn btn-primary" type="submit">
                Save
              </button>
            </div>
            <div style={{}}>
              <button
                className="btn btn-danger"
                onClick={this.handleDeleteClick}
                type="button"
              >
                Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  },
});
