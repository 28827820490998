import { GET_ALL_RELATED_USERS, GET_ALL_RELATED_USERS_ERROR, GET_ALL_RELATED_USERS_SUCCESSFUL } from "./constants"
import { dispatch, getState } from "../store"


export function getAllRelatedUsers (email) {
  dispatch({
    type: GET_ALL_RELATED_USERS,
    email
  }) 
    if (!getState().session.id) {
        return
    }
    if(!email) {
        email = getState().session.user.email
    }
    fetch(`/api/users/related/?email=${email}`, {
        headers: {
            'x-session-id': getState().session.id
        }
    })
    .then(async function (response) {
            if (response.ok) {
                const json = await response.json()
                dispatch({
                    type: GET_ALL_RELATED_USERS_SUCCESSFUL,
                    response: json
                })
            }
        }).catch(function (error) {
            console.log('parsing failed', error)
            dispatch({
                type: GET_ALL_RELATED_USERS_ERROR,
                error
            })
        })  
}

export function requestDeleteUser (userId){
    try {
       return fetch(`/api/user-management/${userId}`, {
            method: 'DELETE',
            headers: {
                'x-session-id': getState().session.id
            }
        }).then(async function (response) {
            if (response.ok) {
                return await response.json()
            }
        }).catch(function (error) {
            console.log('parsing failed', error)
        })

    } catch (err) {
        console.log(err)
    }

    return { error: 'Something went wrong' }
}


export function requestAccount ( userObject ) {
    try {
        const { email, name, customer_id, additionalAccts, requesterEmail, requesterName, department } = userObject
        return fetch(`/api/users-management/?email=${email}&name=${name}&customer_id=${customer_id}&additionalAccts=${additionalAccts}&requesterEmail=${requesterEmail}&requesterName=${requesterName}&department=${department}`, {
            method: 'POST',
            headers: {
                'x-session-id': getState().session.id
            }
        }).then(async function (response) {
            if (response.ok) {
                return await response.json()
            }
        }).catch(function (error) {
            console.log('parsing failed', error)
        })

    } catch (err) {
        console.log(err)
    }

    return { error: 'Something went wrong' }
}

export function confirmDataIsUpToDate (email){
    try {
        return fetch(`/api/user-management/confirm/?email=${email}`, {
            method: 'POST',
            headers: {
                'x-session-id': getState().session.id
            }
        }).then(async function (response) {
            if (response.ok) {
                return await response.json()
            }
        }).catch(function (error) {
            console.log('parsing failed', error)
        })

    } catch (err) {
        console.log(err)
    }

    return { error: 'Something went wrong' }
}