import {
  GET_FILE_PENDING,
  GET_FILE_SUCCESSFUL,
  GET_FILE_ERROR,
  DELETE_FILE_SUCCESSFUL
} from './constants'
var initialState = {
  data: {},
  isLoading: false,
  wasLoadSuccessful: false,
  error: null
}

export default function file (state = initialState, { type, response }) {
  switch (type) {
    case GET_FILE_PENDING:
      return {
        ...state,
        data: { attributes: {} },
        isLoading: true,
        wasLoadSuccessful: false,
        error: null
      }

    case GET_FILE_SUCCESSFUL:
      return {
        ...state,
        data: response.data,
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }

    case GET_FILE_ERROR:
      return {
        ...state,
        data: { attributes: {} },
        isLoading: false,
        wasLoadSuccessful: false,
        error: response.error
      }

    case DELETE_FILE_SUCCESSFUL:
      return initialState

    default:
      return state
  }
}
