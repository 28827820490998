import { GET_ALL_RELATED_USERS, GET_ALL_RELATED_USERS_ERROR, GET_ALL_RELATED_USERS_SUCCESSFUL } from "./constants"

var initialState = {
    data: [],
    isLoading: false,
    wasLoadSuccessful: false,
    error: null,
    dealer: null
}


export default function relatedUsers (state = initialState, { type, response }) {
    switch (type) {
        case GET_ALL_RELATED_USERS:
            return {
                ...state,
                data: [],
                dealer: null,
                isLoading: true,
                wasLoadSuccessful: false,
                error: null
            }

        case GET_ALL_RELATED_USERS_SUCCESSFUL:
            return {
                ...state,
                data: response ? response.records : [],
                dealer: response && response.dealer ? response.dealer : null,
                isLoading: false,
                wasLoadSuccessful: true,
                error: null
            }

        case GET_ALL_RELATED_USERS_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                wasLoadSuccessful: false,
                error: response.error
            }

        default:
            return state
    }
}