import {
  GET_INSIGHT_MESSAGE_PENDING,
  GET_INSIGHT_MESSAGE_SUCCESSFUL,
  GET_INSIGHT_MESSAGE_ERROR,
  LOAD_INSIGHT_MESSAGES_PENDING,
  LOAD_INSIGHT_MESSAGES_SUCCESSFUL,
  LOAD_RECIPIENTS_WITHOUT_BOUNCES
} from './constants'

var initialState = {
  data: [{subject: "", body: "", read: false}],
  recipientsWithoutBounces: 0,
  id:'',
  meta: {
    total: 0,
    limit: 0,
    offset: 0
  },
  isLoading: false,
  wasLoadSuccessful: false,
  error: null
}

export default function insightDelMessages (state = initialState, { type, response }) {
  switch (type) {
    case LOAD_INSIGHT_MESSAGES_PENDING:
      return {
        ...state,
        id: response,
        isLoading: true,
        wasLoadSuccessful: false,
        error: null
      }

    case LOAD_INSIGHT_MESSAGES_SUCCESSFUL:
      return {
        ...state,
        data: response,
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }
      case LOAD_RECIPIENTS_WITHOUT_BOUNCES:
        return {
          ...state,
          recipientsWithoutBounces: response,
        }
    case GET_INSIGHT_MESSAGE_ERROR:
      return {
        ...state,
        data: [],
        isLoading: false,
        wasLoadSuccessful: false,
        error: response.error
      }

    default:
      return state
  }
}
