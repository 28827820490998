import React from 'react'
import { findTranslation } from '../../../../common/translation'

const CLEARANCE_CATEGORY = 'ffefca7d-a309-4a7c-8b93-34c7d76a9712'
const NEW_PRODUCTS_CATEGORY = '9daf56fb-e712-4daf-81bb-e28d09377a0d' 

function sortCategoriesByName (a, b) {
  return a.name[0].value < b.name[0].value ? -1 : 1
    // return a.attributes.aimsId < b.attributes.aimsId ? -1 : 1
}

function sortByParentCategoryName (a, b) {
  return a.parentCategory?.name[0].value < b.parentCategory?.name[0].value ? -1 : 1
}

/**
 * AggregatedCategoryList
 * @returns 
 */
export default function AggregatedCategoryList ({ categoryType, categories, session, parentId = null }) {
  const language = session.language
  
  const topLevelCategories = categories.aggregate?.data?.meta?.top_level_categories.sort(sortCategoriesByName) || []
  const aggregateCategories = categories.aggregate?.data?.result.sort(sortByParentCategoryName) || []
  const renderedParentCategories = [] // Used to keep track of which parent categories have been rendered
  let categoryTypeToId =  categoryType === 'clearance' ? CLEARANCE_CATEGORY : NEW_PRODUCTS_CATEGORY
  categoryTypeToId = categoryType === 'scratch' ? '19818b31-8ff8-4a31-a4c3-bdbaf8524b66' : categoryTypeToId
  let clearanceText = categoryType === 'clearance' ? 'Clearance' : 'New Products'
  clearanceText = categoryType === 'scratch' ? 'Scratched and Dented Products' : clearanceText

  // If a categoryId is provided, filter the aggregateCategories to only include the category with the provided parent

  return (
    <div style={{ zIndex: '100000' }}>

      <div style={{ padding: '1rem' }}>
        <a href={`/?categoryId=${categoryTypeToId}`} className='CategoryChildren-list-item-link'> View All {clearanceText} </a>
      </div>
      <ul className='CategoryChildren-list CategoryChildren-columned-list'>
        {topLevelCategories.map((category) => {
          return (
            <li key={category._id} className='CategoryChildren-list-item CategoryChildren-filled-list-item'>
              <a href={'##'} className='CategoryChildren-filled-list-item-link'>
                {findTranslation(category.name, language.code)} 
              </a>
              <ul className='CategoryGrandchildren-list' >
                {
                aggregateCategories.map((aggregateCategory) => {
                  if (aggregateCategory?.parentCategory?.parentId === category._id) {
                    // Render the parent category if it hasn't been rendered yet
                    if (!renderedParentCategories.includes(aggregateCategory.parentCategory._id)){
                      renderedParentCategories.push(aggregateCategory.parentCategory._id)
                    return (
                      <li key={aggregateCategory._id}>
                        <a href={`/products/list/${categoryType}/${aggregateCategory.parentCategory._id}`}>
                          {findTranslation(aggregateCategory.parentCategory.name, language.code)} 
                        </a>                     
                      </li>
                    )
                  }}
                  // Render nothing if the category is not a child of the current category
                  return null
                })}
              </ul>
            </li>
          )
        })}
      </ul>

       
    </div>
  )
}
