import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
  render () {
    return (
      <div style={{ margin: 18 }}>
        <h1>Coming soon!</h1>
      </div>
    )
  }
})
