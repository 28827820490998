import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'DealerAnalytics',

  getInitialState () {
    return {
      Count: 0,
      Items: []
    }
  },

  componentDidMount () {
    fetch('/api/dealer-analytics', {
      headers: {
        'x-session-id': this.props.session.id,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error()
      })
      .then(json => {
        this.setState(json.data)
      })
      .catch(() => {
        console.log('Error when fetching dealer analytics')
      })
  },

  render () {
    const dealer = (this.props.session && this.props.session.dealer) || {}
    const rows = this.state.Items.map((item, index) => {
      return (
        <tr key={item.date}>
          <th>{item.date}</th>
          <td>{item.uniqueIps}</td>
          <td>{item.pageviews}</td>
        </tr>
      )
    })
    return (
      <div style={{ padding: 24 }}>
        <h2>B2C Analytics</h2>
        <div style={{ marginBottom: '12px' }}>
          <a target="_blank" href={dealer.catalogUrl}>{dealer.catalogUrl}</a>
        </div>
        {Boolean(rows.length) &&
          <shaf-chart>
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Unique visitors</th>
                  <th>Pageviews</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </shaf-chart>}
      </div>
    )
  }
})
