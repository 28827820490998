export default function getCategoryWithAncestors (
  categories,
  categoryId,
  depth = 0
) {
  const category = categories.find(category => category.id === categoryId)
  if (depth < 6 && category && category.attributes.parentId) {
    return getCategoryWithAncestors(
      categories,
      category.attributes.parentId,
      depth + 1
    ).concat(category)
  }
  return category ? [category] : []
}
