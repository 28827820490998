import React from 'react'
import Item from './list-item'
import createReactClass from 'create-react-class'

const searchDiv = {
  'margin': 'auto',
  'width': '100%',
  'padding': '10px'
}
const header = {
  'paddingLeft': '10px',
  'float' : 'left'
}
const input = {
  'borderRadius': '5px',
  'height' : '30px'
}

export default createReactClass({
  displayName: 'Statements',

  handleSearch (e) {
    e.preventDefault()
    this.forceUpdate()
  },

  getDates () {
    let returnVals = []
    returnVals[0] = ""
    for (var i = 1; i <= 12; i++) {
      returnVals[i] = ((new Date().getMonth()-i+2 > 0) ? new Date().getMonth() - i + 2: new Date().getMonth() - i + 14) + "/" + ((new Date().getMonth()-i+1 >= 0) ? new Date().getFullYear() : new Date().getFullYear() - 1)
    }
    return returnVals
  },

  filter (searchAcct, search, acct, date) {
    date = new Date(date)
    if (!searchAcct && !search) return true
    if (search.length > 0 && search !== ((+date.getMonth()+1) + "/" + date.getUTCFullYear())) return false
    if (! (acct.indexOf(searchAcct)> -1)) return false
    return true
  },

  render () {
    const monthNames = new Array(12)
    monthNames[0] = "January"
    monthNames[1] = "February"
    monthNames[2] = "March"
    monthNames[3] = "April"
    monthNames[4] = "May"
    monthNames[5] = "June"
    monthNames[6] = "July"
    monthNames[7] = "August"
    monthNames[8] = "September"
    monthNames[9] = "October"
    monthNames[10] = "November"
    monthNames[11] = "December"

    const session = this.props.session
    const user = this.props.session.user
    const hasPrivilegedRole =
      user.roles &&
      user.roles.some(role => {
        return ['DataProvider', 'Administrator', 'Sales'].includes(role)
      })
    const Statements = this.props.statements.data
      .sort((a,b) => a.attributes.metadata.date < b.attributes.metadata.date)
      .filter(statement => {
        if (this.filter(
          (this.refs.searchAcct?this.refs.searchAcct.value:""),
          (this.refs.search?this.refs.search.value:""),
          statement.attributes.metadata.acctNo,
          statement.attributes.metadata.date)
        ) {
          return true
        }
        else return false
      })
      .map(statement => {
      return (
        <Item
          key={statement.id}
          {...statement.attributes}
          hasPrivilegedRole={hasPrivilegedRole}
          session={session}
          search={(this.refs.search?this.refs.search.value:null)}
        />
      )
    })
    const Dates = this.getDates().map(months => {
      return (
        <option key={months} value={months}>{months == ""? "Date" : monthNames[months.split('/')[0]-1] + " " + months.split('/')[1]}</option>
      )
    })
    return (
      <div className="statements">
        <br/>
        <h1 style={header}>Statements</h1>
        <br/>

        <div className="row form-group" style={searchDiv}>
              <div className="col-lg-2 col-xs-12">Statement Date: <select className="atlas-select form-control" onChange={this.handleSearch} ref="search">
                  {Dates}
                </select></div> 
                {user.managesMultipleLocales ? <div className="col-lg-2 col-xs-12">Account #: <input className="form-control" style={input} onChange={this.handleSearch} ref="searchAcct"/></div> : null}
        </div>

        <table className="table table-striped table-sm table-bordered atlas-detailed-result-table">
          <thead className="statementsItem">
            <tr>
              <th className="statementsItem-cell">
                Statement Name
              </th>
              {user.managesMultipleLocales ? 
              (<th className="statementsItem-cell">
                Account #
              </th>)
              :
              null
              }
            </tr>
          </thead>
          <tbody>
            {Statements}
          </tbody>
        </table>
      </div>
    )
  }
})

// import React from 'react'

// export default function Statements () {
//   return (
//     <div>
//       <p>
//         Statements are currently not available. If you require any copies, please contact your local branch.
//       </p>
    
//     </div>
//   )
// }

