import React from 'react'
import createReactClass from 'create-react-class'
import EmailAdminMenu from '../../email-admin-menu/components'
import SearchBar from './search-bar'
import saveAs from 'file-saver'

export default createReactClass({
    displayName: 'EmailAdminInsightMessages',
    getInitialState() {
        return {
            groupedId: ""
        }
    },

    downloadFile(event) {
        event.preventDefault()
        const data = this.props.insightMessages.data.map(insight => {
            return {
                accountNumber: insight.accountNumber,
                name: insight.accountName,
                email: insight.email
            }
        })

        const replacer = (key, value) => value === null ? '' : value;
        const header = Object.keys(data[0]);
        let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');

        var blob = new Blob([csvArray], { type: 'text/csv' })

        saveAs(blob, `bounced-${new Date().toISOString().slice(0, 10)}.csv`);
    },

    insights(data) {
        if (data) {
            return data.map(insight => {
                console.log(insight)
                const key = insight.email
                return (
                    <tr key={key} data-item={key} onClick={this.gotoMessage}>
                        <td>{insight.date}</td>
                        <td>{insight.subject}</td>
                        <td>{insight.accountNumber}</td>
                        <td>{insight.accountName}</td>
                        <td>{insight.email}</td>
                    </tr>
                );
            });
        }
    },

    render() {
        return (
            <div>
                <EmailAdminMenu></EmailAdminMenu>
                <div className="atlas-email-admin-main">
                    <SearchBar changeSearchQuery={this.props.changeSearchQuery} groupedId={this.state.groupedId} isOnline={true}></SearchBar>
                    <div className="atlas-inbox-grid-main">
                        <table hidden={!this.props.insightMessages.data} className="atlas-inbox-table table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Subject</th>
                                    <th>Account</th>
                                    <th>Account Name</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.insights(this.props.insightMessages.data)}
                            </tbody>
                        </table>
                        <div hidden={!this.props.insightMessages.isLoading} className="insight-spinner">
                            <img
                                alt="Loading"
                                src="/img/loading-ring.gif"
                                style={{ width: 40, height: 40 }}
                            />
                        </div>
                    </div>
                    <div id="wrapper">
                        <span id="right" className="atlas-email-admin-button" onClick={this.downloadFile}>Download</span>
                    </div>
                </div>
            </div>
        )
    }
})