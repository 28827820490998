import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import App from '../common/app'
import WebOrdersComponent from './components/index'
import { getMyWebOrders, getWebOrdersByDate } from './actions'

function render (mountNode) {
  return function () {
    ReactDOM.render(
      <App active="web-orders" {...getState()} {...appActions}>
        <WebOrdersComponent {...getState()} {...appActions}  getWebOrdersByDate={getWebOrdersByDate}/>
      </App>,
      mountNode
    )
  }
}

let unsubscribe = function () {}

export default function renderWebOrders (app, mountNode) {
  app('/web-orders', context => {
    unsubscribe = subscribe(render(mountNode))
    if (getState().session.id) {
      getMyWebOrders()
    }

    render(mountNode)()
  })

  app.exit('/web-orders', (context, next) => {
    unsubscribe()
    next()
  })
}
