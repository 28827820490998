// import React from 'react'
// import numeral from 'numeral'
// import moment from 'moment'
// import createReactClass from 'create-react-class'
// import { getState } from '../../store'
// import { openInBlob } from '../../../common/open-in-blob'



// export default createReactClass({
//   displayName: 'InvoicesListItem',

//   handleDownload () {
//     let data = new FormData()
//     data.append('id', this.props._id)
//     console.log({ data })

//     fetch('/api/fileDownload/', {
//       method: 'POST',
//       headers: {
//         'x-session-id': getState().session.id
//       },
//       body: data
//     }).then(response => {
//       console.log(response)
//       if(response.status !== 200 && response.status !== 201 && response.status !== 204) {
//         alert('Error downloading file')
//         return
//       }

//       response.blob().then(blob => {
//         openInBlob(blob, "application/pdf", this.props.metadata.filename)
//       }).catch(err => {
//         alert('Error downloading file')
//         console.log(err)
//       })
//     }).catch(err => {
//       alert('Error downloading file')
//       console.log(err)
//     })
//   },

//   render () {
//     const {
//       invNum,
//       totalPrice,
//       shipDate,
//       orderDate,
//       custPO,
//       acctNo
//     } = this.props.metadata
//     const user = this.props.session.user
//     return (
//       <tr className="InvoicesItem">
//         <td className="InvoicesItem-cell">
//           <button type='button' className="hover" onClick={this.handleDownload}>
//             # {invNum}
//           </button>
//         </td>
//         {user.managesMultipleLocales ?
//           (<td className="InvoicesItem-cell">
//             {acctNo}
//           </td>)
//           :
//           null
//         }
//         <td className="InvoicesItem-cell">
//           # {custPO}
//         </td>
//         <td className="InvoicesItem-cell">
//           {custPO !== "CREDIT" ? moment(orderDate).format('MM/DD/YYYY') : ""}
//         </td>
//         <td className="InvoicesItem-cell">
//           {moment(shipDate).format('MM/DD/YYYY')}
//         </td>
//         <td>
//           {numeral(totalPrice).format('$0,0.00')}
//         </td>
//       </tr>
//     )
//   }
// })

// Rewrite the following code to use the new React API

import React from 'react'
import numeral from 'numeral'
import moment from 'moment'
import { getState } from '../../store'
import { openInBlob } from '../../../common/open-in-blob'

export default class InvoicesListItem extends React.Component {
  constructor (props) {
    super(props)
    this.handleDownload = this.handleDownload.bind(this)
  }

  handleDownload () {
    let data = new FormData()
    data.append('id', this.props._id)
    fetch('/api/fileDownload/', {
      method: 'POST',
      headers: {
        'x-session-id': getState().session.id
      },
      body: data
    }).then(async response => {
      if(response.status !== 200 && response.status !== 201 && response.status !== 204) {
        alert('Invoices are not available at the moment. Please try again later.')
        return
      }
      var _blob = await response.blob()
      openInBlob(_blob, "application/pdf", this.props.metadata.filename)
      return
    }).catch(err => {
      alert('Error downloading file')
      console.log(err)
    })
  }

  handleOpenInNewTab = (url) => {
    var win = window.open(url, '_blank')
    win.focus()
  }

  render () {
    const {
      invNum,
      totalPrice,
      shipDate,
      orderDate,
      custPO,
      acctNo,
      fileUrl
    } = this.props.metadata
    const user = this.props.session.user

    let actualFileUrl = fileUrl
    if(!actualFileUrl || actualFileUrl === undefined) {
      actualFileUrl = ''
    }
    if(actualFileUrl.indexOf('https://') === -1) {
      actualFileUrl = 'https://' + actualFileUrl
    }

    
    return (
      <tr className="InvoicesItem">
        <td className="InvoicesItem-cell">
          <button type='button' className="hover" onClick={this.handleOpenInNewTab.bind(this, actualFileUrl)}>
            # {invNum}
          </button>
          {/* <a href={actualFileUrl} className='hover' target={'_blank'} rel='noreferrer'>
            # {invNum}
          </a> */}
        </td>
        {user.managesMultipleLocales ?
          (<td className="InvoicesItem-cell">
            {acctNo}
          </td>)
          :
          null
        }
        <td className="InvoicesItem-cell">
          # {custPO}
        </td>
        <td className="InvoicesItem-cell">
          {custPO !== "CREDIT" ? moment(orderDate).format('MM/DD/YYYY') : ""}
        </td>
        <td className="InvoicesItem-cell">
          {moment(shipDate).format('MM/DD/YYYY')}
        </td>
        <td>
          {numeral(totalPrice).format('$0,0.00')}
        </td>
      </tr>
    )
  }
}