import {
  GET_FILES_PENDING,
  GET_FILES_SUCCESSFUL,
  GET_FILES_ERROR
} from './constants'

var initialState = {
  data: [],
  isLoading: false,
  wasLoadSuccessful: false,
  error: null
}

export default function files (state = initialState, { type, response }) {
  switch (type) {
    case GET_FILES_PENDING:
      return {
        ...state,
        isLoading: true,
        wasLoadSuccessful: false,
        error: null
      }

    case GET_FILES_SUCCESSFUL:
      const value = response.change.new_val || response.change.old_val
      return {
        ...state,
        data: state.data
          .filter(cart => cart.id !== value.id)
          .concat(value)
          .sort((a, b) => {
            return a.modified > b.modified ? -1 : 1
          }),
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }

    case GET_FILES_ERROR:
      return {
        ...state,
        data: [],
        isLoading: false,
        wasLoadSuccessful: false
        // error: response.error
      }

    default:
      return state
  }
}
