import React from 'react'
import numeral from 'numeral'
import moment from 'moment'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'WebOrdersListItem',

  render () {
    const {
      customerId,
      userName,
      dealerName,
      referenceNumber,
      purchaseOrder,
      processed,
      orderSubtotal,
      submittedAt
    } = this.props
    return (
      <tr className="WebOrdersItem">
        <td className="WebOrdersItem-cell">
          <a href={`/web-orders/${referenceNumber}`}>
            #{referenceNumber}
            {' '}
            {processed &&
              <span className="btn btn-success btn-sm">
                Processed
              </span>}
          </a>
        </td>
        {this.props.hasPrivilegedRole &&
          <td className="WebOrdersItem-cell">
            {customerId}
          </td>}
        {this.props.hasPrivilegedRole &&
          <td className="WebOrdersItem-cell">
            {dealerName}
          </td>}
        <td className="WebOrdersItem-cell">
        {userName}
      </td>
        <td className="WebOrdersItem-cell">
          {purchaseOrder}
        </td>
        <td className="WebOrdersItem-cell">
          {numeral(orderSubtotal).format('$0,0.00')}
        </td>
        <td>
          {moment(submittedAt).format('MMMM Do YYYY, h:mm a')}
        </td>
      </tr>
    )
  }
})
