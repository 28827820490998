import React from 'react'
import createReactClass from 'create-react-class'
import ClearanceCardItem from './clearance-card-item'

export default createReactClass({   
  displayName: 'ClearanceCards',

    handleScrollRight () {
        let maxScroll = this.refs.scrollRef.scrollWidth - this.refs.scrollRef.clientWidth
        let scrollTo = Math.floor(((window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth)* 0.95) / 350) * 350
        
        this.refs.scrollRef.scrollTo({
            left: scrollTo + this.refs.scrollRef.scrollLeft,
            behavior: 'smooth'
        })
        this.refs.scrollRef.onscroll = e => {
            if (this.refs.scrollRef.scrollLeft > 5) {
                this.refs.buttonLeft.style.opacity = "1"
            }
            if (this.refs.scrollRef.scrollLeft <= 5) {
                this.refs.buttonLeft.style.opacity = "0"
            }

            if (this.refs.scrollRef.scrollLeft >= maxScroll - 1) {
                this.refs.buttonRight.style.opacity = "0"
            }
            if (this.refs.scrollRef.scrollLeft < maxScroll - 1) {
                this.refs.buttonRight.style.opacity = "1"
            }
        }
    },

    handleScrollLeft() {
       let scrollTo = Math.floor(((window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth)* 0.95 ) / 350) * 350

        this.refs.scrollRef.scrollTo({
            left: this.refs.scrollRef.scrollLeft - scrollTo,
            behavior: 'smooth'
        })
    },

render () {
    const isRetailMode = this.props.isRetailMode
    const clearanceProducts = this.props.clearanceProducts
    const language = this.props.language
    const warehouseCode = this.props.warehouseCode
    const singleView = this.props.singleView
    const userRoles = this.props.userRoles
    const { subscribeToProductUpdates } = this.props

    const listStyle = {
        display:"block",
        maxWidth:Math.floor(((window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth)* 0.95 ) / 350) * 350 +'px',
        margin:"0 auto",
        marginBottom:"10px",
        backgroundColor: 'white',
        height:'150px',
        boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.05)',
        overflow:'hidden',

    };
    const clearanceCardsStyle = {
        borderCollapse: "collapse",
        width:Math.floor(((window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth)* 0.95 ) / 350) * 350 + 'px',
        margin:"0 auto"
    }
    const clearanceContents = {
        marginBottom: '75px',
        overflow:'visible',
        paddingTop:'100px',        
        height:'200px',
        whiteSpace:'nowrap'
    }
    const leftButtonStyle = {
        float:"left",
        margin:"0 auto",
        opacity:'0',
        height:'30px',
        width:'40px'
    }
    const rightButtonStyle = {
        float:"right",
        margin:"0 auto",
        height:'30px',
        width:'40px'
    }
    const scrollNav = {
        width:"15%",
        margin:"auto"
    }

    var productComponents = clearanceProducts.map(product => {
      return (
        <ClearanceCardItem
          isOnline={this.props.isOnline}
          isRetailMode={isRetailMode}
          key={product.id}
          language={language}
          product={product}
          singleView={singleView}
          userRoles={userRoles}
          warehouseCode={warehouseCode}
          subscribeToProductUpdates={subscribeToProductUpdates}
          productId={product.attributes.distributorPartNumber}
        />
      )
    })
    if (isRetailMode) return (<div/>)
    return ( 
        <div style={clearanceCardsStyle}>
            <div style={clearanceContents}>
                <h2><b style={{color:"red"}}>CLEARANCE ITEMS</b></h2>

                <div className="atlas-detailed-list" ref="scrollRef" style={listStyle}>
                    {productComponents}
                </div>

                <div style={scrollNav}>
                    <button
                        ref="buttonLeft"
                        className="atlas-add-to-cart-button"
                        onClick={() => this.handleScrollLeft()}
                        style={leftButtonStyle}>
                        <span className="atlas-add-to-cart-button-label">
                            &lt;
                        </span>
                    </button>
                    <button
                        ref="buttonRight"
                        className="atlas-add-to-cart-button"
                        onClick={() => this.handleScrollRight()}
                        style={rightButtonStyle}>
                        <span className="atlas-add-to-cart-button-label">
                            &gt;
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
  }
})
