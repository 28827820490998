import {
  GET_USER_PENDING,
  GET_USER_SUCCESSFUL,
  GET_USER_ERROR,
  DELETE_USER_SUCCESSFUL,
  CREATE_USER
} from './constants'

var initialState = {
  data: {
    attributes: {}
  },
  meta: {
    total: 0,
    limit: 0,
    offset: 0
  },
  isNew: false,
  isLoading: false,
  wasLoadSuccessful: false,
  error: null
}

export default function dealers (state = initialState, { type, response }) {
  switch (type) {
    case GET_USER_PENDING:
      return {
        ...state,
        data: { attributes: {} },
        isLoading: true,
        wasLoadSuccessful: false,
        error: null
      }

    case GET_USER_SUCCESSFUL:
      return {
        ...state,
        data: response.data,
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }

    case GET_USER_ERROR:
      return {
        ...state,
        data: { attributes: {} },
        isLoading: false,
        wasLoadSuccessful: false,
        error: response.error
      }

    case DELETE_USER_SUCCESSFUL:
      return initialState

    case CREATE_USER:
      return Object.assign({}, state, {
        ...initialState,
        isNew: true
      })

    default:
      return state
  }
}
