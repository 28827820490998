import {
  SAVE_MESSAGE_PENDING,
  SAVE_MESSAGE_SUCCESSFUL,
  SAVE_MESSAGE_ERROR,
} from './constants'

import page from 'page'

import { dispatch, getState } from '../store'

// send to api/attachments to save the attachment. 
// From that route send the email broadcast. When the attachment save return
//
export function saveEmail(message) {
  saveAttachment(message)
}

// function saveMessage(message, attachmentId) {
//     const url = '/api/messages'

//     dispatch({
//         type: SAVE_MESSAGE_PENDING
//     })

//     message.attachmentId = attachmentId

//     fetch(url, {
//         method: 'post',
//         headers: {
//             'x-session-id': getState().session.id,
//             Accept: 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(message)
//     })
//         .then(async function (response) {
//             if (response.ok) {
//                 const json = await response.json()
//                 dispatch({
//                     type: SAVE_MESSAGE_SUCCESSFUL,
//                     response: json
//                 })
//                 //page('/email-admin-sent')
//             }
//         })
//         .catch(function (error) {
//             console.log('parsing failed', error)
//             dispatch({
//                 type: SAVE_MESSAGE_ERROR,
//                 error
//             })
//             alert("Message not sent: " + error)
//         })
// }

export function saveAttachment(message) {
  const url = '/api/attachments'

  dispatch({
    type: SAVE_MESSAGE_PENDING
  })


  fetch(url, {
    method: 'post',
    headers: {
      'x-session-id': getState().session.id,
    },
    body: message
  })
    .then(async function (response) {
      if (response.ok) {
        dispatch({
          type: SAVE_MESSAGE_SUCCESSFUL
        })
        alert("Email sent successfully")
        page('/email-admin-sent')

      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      alert("Saving message failed")
    })
}

