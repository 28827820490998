import {
  GET_FILE_PENDING,
  GET_FILE_SUCCESSFUL,
  GET_FILE_ERROR,
  DELETE_FILE_PENDING,
  DELETE_FILE_SUCCESSFUL,
  DELETE_FILE_ERROR
} from './constants'
import { dispatch, getState } from '../store'
import page from 'page'

export function getFile (fileId) {
  dispatch({
    type: GET_FILE_PENDING
  })

  fetch(`/api/files/${fileId}`, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_FILE_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: GET_FILE_ERROR,
        error
      })
    })
}

export function deleteFile (fileId) {
  dispatch({
    type: DELETE_FILE_PENDING
  })

  fetch(`/api/files/${fileId}`, {
    method: 'delete',
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        page('/files')
        dispatch({
          type: DELETE_FILE_SUCCESSFUL
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: DELETE_FILE_ERROR,
        error
      })
    })
}
