import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import { getPasswordResetToken, postPasswordReset } from './actions'
import ResetPassword from './components/index'
let unsubscribe = function () {}

function render () {
  ReactDOM.render(
    <ResetPassword
      {...getState().resetPassword}
      postPasswordReset={postPasswordReset}
    />,
    document.querySelector('#app')
  )
}

export function enter (context, next) {
  getPasswordResetToken(context.params.token)
  unsubscribe = subscribe(render)
  render()
}

export function exit (context, next) {
  unsubscribe()
  next()
}
