import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import App from '../common/app'
import UserManagement from './components'
import { getAllRelatedUsers, requestDeleteUser, requestAccount, confirmDataIsUpToDate } from './actions'

function render (mountNode) {
  return function () {
    ReactDOM.render(
      <App active="user-management" {...getState()} {...appActions}>
        <UserManagement {...getState()} {...appActions} loadUsers={getAllRelatedUsers} deleteUser={requestDeleteUser} onRequestUser={requestAccount} onConfirmUpToDate={confirmDataIsUpToDate} />
      </App>,
      mountNode
    )
  }
}

let unsubscribe = function () {}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (app, mountNode) {
  app('/dealer-users', function (context) {
    // const queryParams = qs.parse(context.querystring)
    unsubscribe = subscribe(render(mountNode))
    render(mountNode)()
  })


  app.exit('/', (context, next) => {
    unsubscribe()
    next()
  })
}
