/* eslint-disable import/no-anonymous-default-export */
import React from "react"
import ReactDOM from "react-dom"
import App from "../common/app"
import ReportTable from "./components"
import { getState, subscribe } from "../store"
import appActions from "../actions/app"
import { getReports } from "./actions"

function render (mountNode) {
  return function () {
    ReactDOM.render(
      <App active="reports" {...getState()} {...appActions}>
        <ReportTable {...getState()} {...appActions} getReports={getReports} />
      </App>,
      mountNode
    )
  }
}
let unsubscribe = function () {}
export default function (app, mountNode) {
  app("/report", (context) => {
    getReports()
    unsubscribe = subscribe(render(mountNode))
    render(mountNode)()
  })

  app.exit("/report", (context, next) => {
    unsubscribe()
    next()
  })
}
