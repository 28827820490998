import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import { searchProducts, getClearance } from '../actions/product-search'
import { subscribeToProductUpdates } from '../actions/products'

import App from '../common/app'
import ProductsComponent from './components'
import { searchMessages } from '../inbox/actions'
import Aggregation from  './components/aggregation/app/route'
function render (mountNode) {
  return function () {
    ReactDOM.render(
      <App active="products" {...getState()} {...appActions}>
        <ProductsComponent {...getState()} {...appActions} subscribeToProductUpdates={subscribeToProductUpdates} />
      </App>,
      mountNode
    )
  }
}

let unsubscribe = function () {}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (app, mountNode) {
  Aggregation(app, mountNode)
  app('/', function (context) {
    // const queryParams = qs.parse(context.querystring)
    unsubscribe = subscribe(render(mountNode))
    if (getState().session.id) {
      searchProducts() // queryParams.q
      getClearance()
      // populate inbox
      searchMessages(getState().session.user.email, 1,200, "byEmail", getState().session.user.email)  
    }

    render(mountNode)()
  })


  app.exit('/', (context, next) => {
    unsubscribe()
    next()
  })
}
