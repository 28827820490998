import React from 'react'
import OrderConfirmationItem from './order-item'
import numeral from 'numeral'
import { FormattedMessage } from 'react-intl'
import createReactClass from 'create-react-class'

export default createReactClass({
  dipslayName: 'OrderConfirmation',
  calculateFXsubtotal(items) {
    let fxsubtotal = 0
    for(let i in items) {
      fxsubtotal += items[i].quantity * (items[i].product.attributes.FXSurcharge || 0)
    }
    return fxsubtotal
  },
  render () {
    var dateOptions = {year: 'numeric', month: 'long', day: 'numeric'};
    const webOrder = this.props.webOrder.data.attributes
    return (
      <div style={{ padding: '16px' }}>
        <h1>Order Confirmation #{webOrder.referenceNumber}</h1>
        <table>
          <tbody>
            <tr>
              <th>Customer #:</th>
              <td>{webOrder.customerId}</td>
            </tr>
            {webOrder.submittedAt &&
              <tr>
                <th>Order date:</th>
                <td>{new Date(webOrder.submittedAt).toLocaleDateString("en-US", dateOptions)}</td>
              </tr>}

            <tr>
              <th>Purchase order:</th>
              <td>{webOrder.purchaseOrder}</td>
            </tr>
            {webOrder.userName &&
              <tr>
                <th>Buyer:</th>
                <td>{webOrder.userName} ({webOrder.userEmail})</td>
              </tr>}
            {webOrder.orderNotes &&
              <tr>
                <th>Order notes:</th>
                <td>{webOrder.orderNotes}</td>
              </tr>}
          </tbody>
        </table>

        <br />
        <br />

        <table className="table table-striped table-sm table-bordered atlas-detailed-result-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage defaultMessage="Product" id="Product" />
              </th>
              <th>
                <FormattedMessage defaultMessage="Quantity" id="Quantity" />
              </th>
              <th>
                <FormattedMessage defaultMessage="Unit price" id="Unit price" />
              </th>
              <th>
                <FormattedMessage defaultMessage="FX ($)" id="FX ($)" />
              </th>
              <th>
                <FormattedMessage
                  defaultMessage="Extended price"
                  id="Extended price"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {(webOrder.items || [])
              .map(item =>
                <OrderConfirmationItem
                  {...item}
                  key={item.distributorPartNumber}
                />
              )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4} style={{ textAlign: 'right' }}>
                Order subtotal:
              </td>
              <td>
                {numeral(webOrder.orderSubtotal).format('$0,0.00')}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }
})
