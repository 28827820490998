import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import App from '../common/app'
import NewVendors from './components/index'

function render (mountNode) {
    return function () {
      ReactDOM.render(
        <App active="/new-vendors" {...getState()} {...appActions}>
          <NewVendors {...getState()} {...appActions} />
        </App>,
        mountNode
      )
    }
  }
  
  let unsubscribe = function () {}
  
  export default function (app, mountNode) {
    app('/new-vendors', context => {
      unsubscribe = subscribe(render(mountNode))  
      render(mountNode)()
    })
  
    app.exit('/new-vendors', (context, next) => {
      unsubscribe()
      next()
    })
  }