import {
  GET_USER_PENDING,
  GET_USER_SUCCESSFUL,
  GET_USER_ERROR,
  SAVE_USER_PENDING,
  SAVE_USER_SUCCESSFUL,
  SAVE_USER_ERROR,
  DELETE_USER_PENDING,
  DELETE_USER_SUCCESSFUL,
  DELETE_USER_ERROR,
  CREATE_USER
} from './constants'
import { dispatch, getState } from '../store'
// import page from 'page'

export function saveUser (data) {
  const isNew = !data.id

  let url
  if (!isNew) {
    url = `/api/users/${data.id}`
  } else {
    url = '/api/users'
  }

  dispatch({
    type: SAVE_USER_PENDING
  })

  fetch(url, {
    method: 'post',
    headers: {
      'x-session-id': getState().session.id,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: SAVE_USER_SUCCESSFUL,
          response: json
        })
        // page('/users')
        return window.history.back()
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: SAVE_USER_ERROR,
        error
      })
    })
}

export function getUser (userId) {
  if (userId === 'new') {
    dispatch({
      type: CREATE_USER
    })
    return
  }

  dispatch({
    type: GET_USER_PENDING
  })
  
  fetch(`/api/users/${userId}`, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_USER_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: GET_USER_ERROR,
        error
      })
    })
}

export function deleteUser (userId) {
  dispatch({
    type: DELETE_USER_PENDING
  })

  fetch(`/api/users/${userId}`, {
    method: 'delete',
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        // page('/users')
        dispatch({
          type: DELETE_USER_SUCCESSFUL
        })
        return window.history.back()
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: DELETE_USER_ERROR,
        error
      })
    })
}
