import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
  handleFormSubmit (event) {
    event.preventDefault()
    const password = this.refs.password.value
    const password2 = this.refs.password2.value
    if (password !== password2) {
      return alert('Passwords must match')
    }
    this.props.postPasswordReset(this.props.passwordResetToken, password)
  },

  render () {
    if (this.props.getTokenPending) {
      return (
        <div style={{ padding: 46 }}>
          Loading...
        </div>
      )
    }

    if (this.props.getTokenError) {
      return (
        <div style={{ padding: 46 }}>
          Token not found or expired.
        </div>
      )
    }
    // const errors = this.props.errors.map(function (error) {
    //   return (
    //     <div
    //       className='atlas-login-error'
    //       key={error.message}
    //     >
    //       {error.message}
    //     </div>
    //   )
    // })

    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          paddingTop: 150
        }}
      >
        <form
          className="form form-horizontal"
          onSubmit={this.handleFormSubmit}
          style={{
            padding: 12,
            width: 300,
            margin: 'auto',
            backgroundColor: 'white',
            boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.05)',
            border: '1px solid #d3e0e9',
            borderRadius: 4
          }}
        >
          <fieldset style={{ margin: 6 }}>
            <div style={{ textAlign: 'center', padding: 12 }}>
              <img className="atlas-login-logo" src="/img/logo.svg" />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                className="form-control"
                defaultValue={this.props.email}
                readOnly
                type="email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">New password</label>
              <input
                autoFocus
                className="form-control"
                id="password"
                ref="password"
                required
                type="password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password2">Confirm new password</label>
              <input
                className="form-control"
                id="password2"
                ref="password2"
                required
                type="password"
              />
            </div>

            {!this.props.resetPasswordError &&
              !this.props.resetPasswordSuccessful &&
              <button
                className="btn btn-primary"
                disabled={this.props.resetPasswordPending}
                type="submit"
              >
                Save new password
              </button>}

            {this.props.resetPasswordSuccessful &&
              <div className="btn btn-success">
                Password reset ✓
              </div>}

            {this.props.resetPasswordError &&
              <div className="btn btn-danger">
                Error ✗
              </div>}
          </fieldset>
        </form>
      </div>
    )
  }
})
