import React from 'react'
import { findTranslation } from '../../../common/translation'
import createReactClass from 'create-react-class'
import CategoryBreadcrumbs from '../../common/category-breadcrumbs'

function fromFormDataToObject (formData) {
  return Array.from(formData).reduce((previousValue, currentValue) => {
    return Object.assign({}, previousValue, {
      [currentValue[0]]: currentValue[1]
    })
  }, {})
}

export default createReactClass({
  displayName: 'Category',

  handleSubmit (event) {
    event.preventDefault()
    const formData = fromFormDataToObject(new FormData(this.refs.form))
    formData['name'] = []
    if (this.refs.name_en.value.trim()) {
      formData['name'].push({
        languageCode: 'en',
        value: this.refs.name_en.value.trim()
      })
    }
    if (this.refs.name_fr.value.trim()) {
      formData['name'].push({
        languageCode: 'fr',
        value: this.refs.name_fr.value.trim()
      })
    }
    if (this.refs.aimsId.value.trim()) {
      formData['aimsId'] = this.refs.aimsId.value.trim()
    }
    if (this.refs.parentAimsId.value.trim()) {
      formData['parentAimsId'] = this.refs.parentAimsId.value.trim()
    }
    this.props.saveCategory(formData)
  },

  handleDeleteClick (event) {
    event.preventDefault()
    if (
      prompt(
        'Are you sure you want to delete this category? Type YES to proceed.'
      ) === 'YES'
    ) {
      this.props.deleteCategory(this.props.data.id)
    }
  },

  render () {
    const category = this.props.data.attributes
    if (typeof category.name === 'string') {
      category.name = [{ languageCode: 'en', value: category.name }]
    }

    if (this.props.isLoading) {
      return <div>Loading...</div>
    }

    return (
      <div style={{ padding: 24 }}>
        {category._id &&
          <div style={{ marginBottom: 12 }}>
            <a href={`/?categoryId=${category._id}`}>
              Back to category products
            </a>
          </div>}

        {category._id &&
          <CategoryBreadcrumbs
            language={this.props.session.language}
            categories={this.props.categories}
            categoryId={category._id}
          />}

        {!category._id && <h1>New Category</h1>}

        <form onSubmit={this.handleSubmit} ref="form">
          {category._id &&
            <input defaultValue={category._id} name="id" type="hidden" />}

          <fieldset>
            <div>
              <label>
                <div>Name (English)</div>
                <input
                  defaultValue={findTranslation(category.name, 'en', false)}
                  ref="name_en"
                  required
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>
            <div>
              <label>
                <div>Name (French)</div>
                <input
                  defaultValue={findTranslation(category.name, 'fr', false)}
                  ref="name_fr"
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>
          </fieldset>

          <fieldset>
            <div>
              <label>
                <div>AIMS ID</div>
                <input
                  defaultValue={category.aimsId}
                  ref="aimsId"
                  style={{ width: 100 }}
                  type="text"
                />
              </label>
            </div>
          </fieldset>

          <fieldset>
            <div>
              <label>
                <div>Parent AIMS ID</div>
                <input
                  defaultValue={category.parentAimsId}
                  ref="parentAimsId"
                  style={{ width: 100 }}
                  type="text"
                />
              </label>
            </div>
          </fieldset>

          <div style={{ float: 'right' }}>
            <button
              className="btn btn-danger"
              onClick={this.handleDeleteClick}
              type="button"
            >
              Delete
            </button>
          </div>

          <div style={{ marginTop: 12 }}>
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    )
  }
})
