import React, { useState } from "react"
import { Table, useTable } from "ka-table"
import { DataType, PagingPosition, SortingMode } from "ka-table/enums"
import { debounce } from "lodash"
import * as xlsx from "xlsx"
import "ka-table/style.css"

const columns = Array.from(
  { length: new Date().getFullYear() + 1 - 2021 + 1 },
  (_, i) => i + 2021
).reverse() // Start with current year and go back to 2021

const tableColumns = [
  {
    title: "Account Number",
    key: "acctNum",
    dataType: DataType.String
  },
  {
    title: "Account Name",
    key: "acctName",
    dataType: DataType.String
  },
  ...columns.map((column) => ({
    title: column.toString(),
    key: column.toString(),
    dataType: DataType.String
  }))
]

// console.log(tableColumns)

const ReportsTable = (props) => {
  const [reportType, setReportType] = useState("consumernet")
  const [searchText, setSearchText] = useState("")
  const { reportsData: reports = [] } = props

  const handleReportTypeChange = (event) => {
    const selectedValue = event.target.value
    // this.setState({ reportType: selectedValue })
    setReportType(selectedValue)
    if (props.getReports) {
      props.getReports(selectedValue)
    }
  }

  const groupedReports = [].concat(
    reports.data.reduce((acc, report) => {
      const { acctNum, acctName, year } = report
      const existingReport = acc.find((item) => item.acctNum === acctNum)
      if (existingReport) {
        existingReport[year.toString()] = report.isSubscribed ? "Yes" : "No"
      } else {
        acc.push({
          acctNum,
          acctName,
          [year.toString()]: report.isSubscribed ? "Yes" : "No"
        })
      }
      return acc
    }, []) ?? []
  )

  // Add columns for years that don't have a report
  groupedReports.forEach((report) => {
    columns.forEach((column) => {
      if (!report[column.toString()]) {
        report[column.toString()] = "No"
      }
    })
  })

  const handleSearchTextChange = debounce((event) => {
    const selectedValue = event.target.value
    setSearchText(selectedValue)
  }, 500)

  const filteredReports = groupedReports.filter((report) => {
    const { acctNum, acctName } = report
    return (
      acctNum.toLowerCase().includes(searchText.toLowerCase()) ||
      acctName.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  const handleExport = () => {
    const wb = xlsx.utils.book_new()
    const ws = xlsx.utils.json_to_sheet(filteredReports)
    xlsx.utils.book_append_sheet(wb, ws, reportType)
    xlsx.writeFile(wb, `${reportType}.xlsx`)
  }


  const memoizedData = React.useMemo(() => filteredReports, [filteredReports])

  const table = useTable()

  if (reports.error) {
    console.log(reports.error)
    return <div>Error Displaying Reports</div>
  }

  return (
    <div style={{ margin: 32 }}>
      <h1>Reports for {reportType}</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 32, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div>
          <input type="text" placeholder="Search" onChange={handleSearchTextChange} />
          </div>
          <select
            style={{ width: "200px", marginBottom: 32 }}
            className="form-control"
            value={reportType}
            onChange={handleReportTypeChange}
          >
            <option value="consumernet">ConsumerNet</option>
            <option value="booking">Booking</option>
            <option value="show">Show</option>
            <option value="rvpf">RVPF</option>
          </select>
        </div>
        <div>
          <button className="btn btn-success" onClick={handleExport}>Export</button>
        </div>
      </div>
      <Table
        table={table}
        data={memoizedData}
        columns={tableColumns}
        loading={{ enabled: reports.isLoading, text: "Loading" }}
        paging={{
          enabled: true,
          pageIndex: 0,
          pageSize: 100,
          pageSizes: [100, 200, 500],
          position: PagingPosition.TopAndBottom
        }}
        // state={{ sorting: [{ columnKey: "id", direction: "asc" }] }}
        sortingMode={SortingMode.Single}
        rowKeyField="acctNum"
      />
    </div>
  )
}

export default ReportsTable
