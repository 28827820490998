import React from 'react'
import { changeQuickOrderQuery, addCartItem } from '../actions'
import { searchProducts } from '../../actions/product-search'
import createReactClass from 'create-react-class'

export default createReactClass({
  getInitialState () {
    return {
      query: ''
    }
  },

  handleKeyPress (event) {
    const query = this.state.query
    const isExactMatch =
      this.props.products.data.length &&
      (this.props.products.data[0].attributes.distributorPartNumber === query ||
        this.props.products.data[0].attributes
          .distributorPartNumberNormalized === query.replace('-', ''))
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!isExactMatch) {
        return
      }
      changeQuickOrderQuery('')
      this.refs.input.value = ''
      addCartItem(this.props.products.data[0], 1)
      setTimeout(() => {
        try {
          //  atlas-cart-items-tbody
          // if (this.props.focusOnQuantityOfLastItem) {
          //   this.props.focusOnQuantityOfLastItem()
          // }
          const inputs = document.querySelectorAll(
            '#atlas-cart-items-tbody input'
          )
          if (inputs.length > 0) {
            inputs[inputs.length - 1].focus()
            inputs[inputs.length - 1].select()
          }
        } catch (err) {
          console.log({ err })
        }
      })
      return
    }
  },

  handleInput (event) {
    const query = this.refs.input.value
    changeQuickOrderQuery(query)
    searchProducts(query)
    this.setState({
      query
    })
    // send event to GA
    window.gtag &&   window.gtag('event', 'quick_add', {
        event_category: 'quick_add',
        event_action: 'Search with quick order',
        event_value: 1,
        event_label: 'quick order'
      })
  },

  render () {
    const isExactMatch =
      this.props.products.data.length &&
      this.props.products.data[0].attributes.distributorPartNumberNormalized ===
        this.state.query.replace('-', '')

    return (
      <div>
        <input
          onInput={this.handleInput}
          onKeyPress={this.handleKeyPress}
          placeholder="Add part number"
          ref="input"
          type="search"
        />
        {this.state.query.length > 2 &&
          !isExactMatch &&
          <div style={{ color: 'red', fontSize: '0.9rem' }}>
            Not found
          </div>}
      </div>
    )
  }
})
