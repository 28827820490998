/**
 * Compares two dates and returns 1 if date1 is greater than date2, -1 if date1 is less than date2, and 0 if they are equal.
 * @param {*} date1 
 * @param {*} date2 
 * @returns 
 */
export function compareDates (date1, date2) {
  const d1 = new Date(date1).getTime()
  const d2 = new Date(date2).getTime()

  if (d1 > d2) {
    return 1
  } else if (d1 < d2) {
    return -1
  } else {
    return 0
  }
}