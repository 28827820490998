import {
  GET_ACTIVITY_PENDING,
  GET_ACTIVITY_SUCCESSFUL,
  GET_ACTIVITY_ERROR
} from './constants'
import { dispatch, getState } from '../store'

function messageHandler (event) {
  dispatch({
    type: GET_ACTIVITY_SUCCESSFUL,
    response: JSON.parse(event.data)
  })
}

function errorHandler (event) {
  dispatch({
    type: GET_ACTIVITY_ERROR
  })
  console.log(event)
}

let source

export function getActivity (options = {}) {
  dispatch({
    type: GET_ACTIVITY_PENDING
  })

  if (!window.EventSource) {
    alert('Not supported on this browser.')
    return
  }

  source = new EventSource(
    `/api/activity?x-session-id=${getState().session.id}`
  )

  // source.addEventListener('open', openHandler, true)
  source.addEventListener('change', messageHandler)
  source.addEventListener('error', errorHandler)
}

export function exitActivity () {
  if (!window.EventSource) {
    return
  }
  source.removeEventListener('change', messageHandler)
  source.removeEventListener('error', errorHandler)
  source.close()
}
