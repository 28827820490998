import {
  // GET_STATS_PENDING,
  GET_STATS_SUCCESSFUL
  // GET_STATS_ERROR
} from './constants'

var initialState = {
  data: {
    subtotals: {
      today: null
    }
  },
  isLoading: false,
  wasLoadSuccessful: false,
  error: null
}

export default function stats (state = initialState, { type, response }) {
  switch (type) {
    case GET_STATS_SUCCESSFUL:
      return {
        ...state,
        data: response.data,
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }

    default:
      return state
  }
}
