import {
    GET_INSIGHTS_SUCCESSFUL,
    LOAD_INSIGHTS_SUCCESSFUL,
    LOAD_INSIGHTS_PENDING
} from "./constants"
import { dispatch, getState } from '../store'
import moment from 'moment'
import message from "../inbox-message/reducers"

export function changeSearchQuery(query, pagenum, pageSize) {
    pageSize = 8
    query = query || ''

    getInsights(pagenum, pageSize)
}

export function getInsights(pagenum = 1, pageSize = 8, searchType, searchValue, sortBy) {
    const totalData = getState().insights.totalData

    const from = (pagenum - 1) * pageSize
    const to = from + pageSize
    const data = totalData.slice(from, to)

    dispatch({
        type: GET_INSIGHTS_SUCCESSFUL,
        response: data
    })
}

export async function loadInsights() {
    dispatch({
        type: LOAD_INSIGHTS_PENDING
    })

    // get insights from postmark
    const insightsResponse = await fetch('/api/insights', {
        headers: {
            'x-session-id': getState().session.id
        }
    })
    const insights = await insightsResponse.json()

    // get sent messages
    let query = ""
    const path = `/api/messages?q=${query}`
    const messagesResponse = await fetch(path, {
        headers: {
            'x-session-id': getState().session.id
        }
    })
    const messages = await messagesResponse.json()

    // combine messages and insights
    for (const message of messages.data) {
        message.data.undeliveredCnt = 0
        message.data.deliveredCnt = 0

        // check each recipient to see if they have any bounces
        for (const recipient of message.data.recipients) {
            let insight = insights.data.find(insightItem => {
               // console.log(insightItem)
                return insightItem.data.Email === recipient.email
            })

            if (insight) {
                //console.log(insight.data.Email)
                message.data.undeliveredCnt += 1
            }
        }

        // delivered is total recipients - bounces
        message.data.deliveredCnt = message.data.recipients.length - message.data.undeliveredCnt
    }

    dispatch({
        type: LOAD_INSIGHTS_SUCCESSFUL,
        response: messages.data
    })
}



