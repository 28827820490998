import React from 'react'
import { FormattedMessage } from 'react-intl'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'Profile',

  handleSwitchLanguage() {
    this.props.switchLanguage()
  },

  dismissEmailAlert() {
    this.props.dismissEmailAlert()
  },

  handleLogout(event) {
    event.preventDefault()
    this.props.onLogout()
  },

  customBoxClicked() {
    event.preventDefault()
    if (this.refs.usesCustomPricesBox.checked) {
      this.refs.usesRecommendedPricesBox.checked = false
      this.refs.usesRFQPricesBox.checked = false
    }
  },

  recommendedBoxClicked() {
    event.preventDefault()
    if (this.refs.usesRecommendedPricesBox.checked) {
      this.refs.usesCustomPricesBox.checked = false
      this.refs.usesRFQPricesBox.checked = false
    }
    
  },

  rfqBoxClicked() {
    event.preventDefault()
    if (this.refs.usesRFQPricesBox.checked) {
      this.refs.usesCustomPricesBox.checked = false
      this.refs.usesRecommendedPricesBox.checked = false
    }
  },

  handleSubmitPricingButtonClick(event) {
    event.preventDefault()
    var updatedDealer = this.props.session.dealer
    updatedDealer.usesRecommendedPrices = this.refs.usesRecommendedPricesBox.checked
    updatedDealer.usesCustomPrices = this.refs.usesCustomPricesBox.checked
    updatedDealer.customMarkup = [
      this.refs.markup1.value,
      this.refs.markup2.value,
      this.refs.markup3.value,
      this.refs.markup4.value,
      this.refs.markup5.value,
      this.refs.markup6.value,
      this.refs.markup7.value,
      this.refs.markup8.value,
      this.refs.markup9.value,
      this.refs.markup10.value
    ]

    fetch('/api/dealers', {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        for (var i = 0; i < json.data.length; i++) {
          if (json.data[i].attributes.accountNumber == updatedDealer.accountNumber) {
            fetch('/api/dealers/' + json.data[i].attributes._id, {
              method: 'post',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(updatedDealer)
            })
            .then(async function (response) {
              if (response.ok) {
                const json = await response.json()
                window.alert("Custom Markup saved!")
              }
            })
            .catch(function (error) {
              console.log('parsing failed', error)
            })
            break;
          }
        }
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
    })

  },

  render () {
    const language = (this.props.session && this.props.session.language) || {}
    const dealer = (this.props.session && this.props.session.dealer) || {}
    const user = (this.props.session && this.props.session.user) || {}
    var customPrices = ""
    if (dealer.customPrices !== undefined && dealer.customPrices.length > 0) {
      for (var i = 0; i < dealer.customPrices.length; i++) {
        if (dealer.customPrices[i].partNumber !== undefined && dealer.customPrices[i].retailPrice !== undefined) {
          customPrices += dealer.customPrices[i].partNumber + "," + dealer.customPrices[i].retailPrice + "\n";
        }
      }
    }

    /*
     * Switch commented out line to enable/disable the Show pages
     */
    //const isShowDealer = ((this.props.session && this.props.session.user && this.props.session.user.isShowDealer) ? true : false)
    const isShowDealer = false


    /*
     * Switch commented out line to enable/disable the Booking pages
     */
    const isBookingDealer = false
    //const isBookingDealer = ((this.props.session && this.props.session.user && this.props.session.user.isBookingDealer) ? true : false)

    const isAdmin = user && user.roles && user.roles.includes('Administrator')
    console.log({ user , isAdmin })
    return (
      <div style={{ padding: 24 }}>
        <h2>My profile</h2>
        <ul>
           <li>
            <a href="/logout">
              <FormattedMessage defaultMessage="Logout" id="Logout" />
            </a>
          </li>
          <li>
            <a href="/web-orders">
              <FormattedMessage defaultMessage="Web orders" id="Web orders" />
            </a>
          </li>
          <li>
            <a href="/invoices">
              <FormattedMessage defaultMessage="Invoices" id="Invoices" />
            </a>
          </li>
          <li>
            <a href="/statements">
              <FormattedMessage defaultMessage="Statements" id="Statements" />
            </a>
          </li>
          <li>
            <a href="/request-password-reset">
              <FormattedMessage
                defaultMessage="Reset password"
                id="Reset password"
              />
            </a>
          </li>
          <li>
            <a href="#" onClick={this.handleSwitchLanguage}>
              <FormattedMessage
                defaultMessage="Switch language"
                id="Switch language"
              />
              {' → '}
              {language.name === 'English' ? 'Français' : 'English'}
            </a>
          </li>
          <li>
            <a href={`/dealer-users`}>
              <FormattedMessage defaultMessage="Manage Users" id="Dealer users page" />
            </a>
          </li>
          {dealer.catalogUrl &&
            <li>
              <a
                href={dealer.catalogUrl.replace('https://', 'http://')}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage
                  defaultMessage="Retail catalogue"
                  id="Retail catalogue"
                />
                {' '}
                ({dealer.catalogUrl.replace('https://', '')})
              </a>
            </li>}
          {false &&
            dealer.catalogUrl &&
            <li>
              <a href={`/dealer-analytics`}>
                <FormattedMessage
                  defaultMessage="B2C Analytics"
                  id="B2C Analytics"
                />
              </a>
            </li>}
        </ul>
        {isShowDealer &&
          <span>
            <h2>Show Information</h2>
            <ul>
              <li>
                <a href="https://atlas-show-case-2019.s3.amazonaws.com/Show_Floor_Plan.pdf" target="_blank">
                  <FormattedMessage defaultMessage="Show Floor Plan & Exhibitor List" id="floorPlan" />
                </a>
              </li>
              <li>
                <a href="https://atlas-show-case-2019.s3.amazonaws.com/Schedule_Seminars.pdf" target="_blank">
                  <FormattedMessage defaultMessage="Schedule / Seminars" id="schedule" />
                </a>
              </li>
              <li>
                <a href="https://atlas-show-case-2019.s3.amazonaws.com/Electronic_Ordering_Procedures.pdf" target="_blank">
                  <FormattedMessage defaultMessage="Electronic Order Procedures" id="electronicOrder" />
                </a>
              </li>
              <li>
                <a href="https://atlas-show-case-2019.s3.amazonaws.com/Electronic_Ordering_Procedures_FRAN%C3%87AIS.pdf" target="_blank">
                  <FormattedMessage defaultMessage="Electronic Order Procedures [Français]" id="electronicOrder" />
                </a>
              </li>
            </ul>

            <h2>Show Vendor Information</h2>
            <ul>
              <li>
                <a href="/new-vendors">
                  <FormattedMessage
                    defaultMessage="New Vendors"
                    id="New Vendors"
                  />
                </a>
              </li>
              <li>
                <a href="/vendor-list">
                  <FormattedMessage
                    defaultMessage="Vendor List and Work Sheets"
                    id="Vendor List and Order Sheets"
                  />
                </a>
              </li>
            </ul>
            <h2>Show Logo Programs</h2>
            <ul>
              <li>
                <a href="https://atlas-show-case-2019.s3.amazonaws.com/Atlas-Show_Logo_Programs.pdf" target="_blank">
                  <FormattedMessage defaultMessage="Show Logo Programs" id="Show Logo Programs" />
                </a>
              </li>
            </ul>
            <h2>Show Promotions</h2>
            <ul>
              <li>
                <a href="https://atlas-show-case-2019.s3.amazonaws.com/ADCO_Introductory_Special_Offer.pdf" target="_blank">
                  <FormattedMessage defaultMessage="ADCO Introductory Special Offer" id="ADCO Introductory Special Offer" />
                </a>
              </li>
              <li>
                <a href="https://atlastrailer.s3.amazonaws.com/2020_Fillable_ConsumerNet_Marketing_Subscription_Form.pdf" target="_blank">
                  <FormattedMessage defaultMessage="ConsumerNet Marketing Solutions Subscription" id="ConsumerNet Marketing Solutions Subscription" />
                </a>
              </li>
              <li>
                <a href="https://atlastrailer.s3.amazonaws.com/2020_Fillable_Retail_Catalogue_Subscription_Form.pdf" target="_blank">
                  <FormattedMessage defaultMessage="Retail Catalogue Program Subscription" id="Retail Catalogue Program Subscription" />
                </a>
              </li>
              <li>
                <a href="https://atlas-show-case-2019.s3.amazonaws.com/2020_Atlas_RV_Partfinder_Subscription.pdf" target="_blank">
                  <FormattedMessage defaultMessage="Atlas RV Partfinder Subscription" id="Atlas RV Partfinder Subscription" />
                </a>
              </li>
              <li>
                <a href="https://atlas-show-case-2019.s3.amazonaws.com/Carded_Specials.pdf" target="_blank">
                  <FormattedMessage defaultMessage="Carded Special" id="Carded Special" />
                </a>
              </li>
              <li>
                <a href="https://atlas-show-case-2019.s3.amazonaws.com/Festival_of_Lights.pdf" target="_blank">
                  <FormattedMessage defaultMessage="Festival of Lights" id="Festival of Lights" />
                </a>
              </li>
            </ul>
          </span>
        }{isBookingDealer &&
          <span>
            <h2>Booking Information</h2>
            <ul>
              <li>
                <a href="http://atlasbookingprogram.com/" target="_blank">
                  <FormattedMessage defaultMessage="2022 BOOKING PROGRAM" id="2021bookingprogram" />
                </a>
              </li>
              <li>
                <a href="https://rvcatalogue.s3.amazonaws.com/2021BookingOrderForm.pdf" target="_blank">
                  <FormattedMessage defaultMessage="2021 BOOKING ORDER FORM" id="2021bookingorderform" />
                </a>
              </li>
            </ul>
            <h2>Booking Promotions</h2>
            <ul>
              <li>
                <a href="https://rvcatalogue.s3.amazonaws.com/2021promosheets/2eze.pdf" target="_blank">
                  <FormattedMessage defaultMessage="2EZE SKIN COVERS" id="2ezeskincovers" />
                </a>
              </li>
              <li>
                <a href="https://rvcatalogue.s3.amazonaws.com/2021promosheets/MightyCord.pdf" target="_blank">
                  <FormattedMessage defaultMessage="MIGHTY CORD PROMO" id="mightycord" />
                </a>
              </li>
              <li>
                <a href="https://rvcatalogue.s3.amazonaws.com/2021promosheets/RVInlineSanitizer.pdf" target="_blank">
                  <FormattedMessage defaultMessage="RV INLINE SANITIZER" id="inlinesanitizer" />
                </a>
              </li>
              <li>
                <a href="https://rvcatalogue.s3.amazonaws.com/2021promosheets/Prest-O-Fit.pdf" target="_blank">
                  <FormattedMessage defaultMessage="PREST-O-FIT PROMO" id="steprugfirepoker" />
                </a>
              </li>
              <li>
                <a href="https://rvcatalogue.s3.amazonaws.com/2021promosheets/MobileCommunication.pdf" target="_blank">
                  <FormattedMessage defaultMessage="MOBILE COMMUNICATION PROMO" id="mobilecomms" />
                </a>
              </li>
            </ul>
            </span>
            }{dealer.catalogUrl &&
              <span>
                <h2><FormattedMessage defaultMessage="Retail Pricing Options" id="retailPricingOptions"/></h2>
                <input
                defaultChecked={!dealer.usesRecommendedPrices && !dealer.usesCustomPrices}
                onChange={this.rfqBoxClicked}
                name="rfqSelect"
                style={{ width: 24 }}
                type="checkbox"
                value="true"
                ref="usesRFQPricesBox"
                />
                <FormattedMessage defaultMessage="Request For Quote" id="rfq"/>
                <br/>
                <input
                defaultChecked={dealer.usesRecommendedPrices}
                onChange={this.recommendedBoxClicked}
                name="usesRecommendedPrices"
                style={{ width: 24 }}
                type="checkbox"
                value="true"
                ref="usesRecommendedPricesBox"
                />
                <FormattedMessage defaultMessage="Suggested Retail Pricing" id="recommendedPriceCheckBox" />
                <br/>
                <input
                defaultChecked={dealer.usesCustomPrices}
                onChange={this.customBoxClicked}
                name="usesCustomPrices"
                style={{ width: 24 }}
                type="checkbox"
                value="true"
                ref="usesCustomPricesBox"
                />
                <FormattedMessage defaultMessage="Use Custom Markup" id="customPriceCheckBox"/>
                <br/>
                <div className="row">
                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$0.00 - $0.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[0]}
                      placeholder="100"
                      id="markup1"
                      ref="markup1"
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$1.00 - $4.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[1]}
                      placeholder="90"
                      id="markup2"
                      ref="markup2"
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$5.00 - $9.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[2]}
                      placeholder="85"
                      id="markup3"
                      ref="markup3"
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$10.00 - $49.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[3]}
                      placeholder="80"
                      id="markup4"
                      ref="markup4"
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$50.00 - $99.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[4]}
                      placeholder="75"
                      id="markup5"
                      ref="markup5"
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$100.00 - $299.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[5]}
                      placeholder="65"
                      id="markup6"
                      ref="markup6"
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$300.00 - $499.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[6]}
                      placeholder="50"
                      id="markup7"
                      ref="markup7"
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$500.00 - $999.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[7]}
                      placeholder="40"
                      id="markup8"
                      ref="markup8"
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$1,000.00 - $1,999.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[8]}
                      placeholder="35"
                      id="markup9"
                      ref="markup9"
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-6" style={{width: 250}}>
                    <label>$2,000.00 - $99,999.99</label>
                    <input
                      className="form-control"
                      defaultValue={dealer.customMarkup[9]}
                      placeholder="30"
                      id="markup10"
                      ref="markup10"
                    />
                  </div>
                </div>

                <button
                  className="atlas-medium-button"
                  onClick={this.handleSubmitPricingButtonClick}
                  ref="reviewButton"><FormattedMessage defaultMessage="Submit" id="submit"/>
                </button>
                <br/><FormattedMessage defaultMessage="Changes will be visible on B2C immediately. For changes to be visible on your B2B profile, log out and back in." id="pricingChangeNote"/>
              </span>
            }
      </div>
    )
  }
})
