import {
  GET_CATEGORIES_PENDING,
  GET_CATEGORIES_SUCCESSFUL,
  GET_CATEGORIES_ERROR
} from './constants'
import { dispatch, getState } from '../store'

export function getCategories (options = {}) {
  if (getState().categories.isLoading) {
    return
  }

  dispatch({
    type: GET_CATEGORIES_PENDING
  })

  fetch(`/api/categories?q=${options.query || ''}`, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_CATEGORIES_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: GET_CATEGORIES_ERROR,
        error
      })
    })
}
