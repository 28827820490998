import {
  CALGARY_CODE,
  QUEBEC_CODE,
  VANCOUVER_CODE,
  LONDON_CODE
} from './warehouse-codes'

export default [
  {
    warehouseCode: CALGARY_CODE,
    name: [{ languageCode: 'en', value: 'Calgary' }]
  },
  {
    warehouseCode: QUEBEC_CODE,
    name: [
      { languageCode: 'en', value: 'Quebec' },
      { languageCode: 'fr', value: 'Québec' }
    ]
  },
  {
    warehouseCode: VANCOUVER_CODE,
    name: [{ languageCode: 'en', value: 'Vancouver' }]
  },
  {
    warehouseCode: LONDON_CODE,
    name: [{ languageCode: 'en', value: 'London' }]
  }
]
