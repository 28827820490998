import React from 'react'
// import { findTranslation } from '../../common/translation'
// import numeral from 'numeral'
// import makeProductDataPresentable from '../../common/make-product-data-presentable'

export default class Products extends React.Component {
  render () {
    const partNumber = this.props.partNumber
    const product = this.props.products.data.find(product => {
      return product.attributes.distributorPartNumber === partNumber
    })

    /*
    const {
      title,
      shortDescription,
      longDescription,
      manufacturerName
    } = makeProductDataPresentable({
      title: findTranslation(product.attributes.title, language.code),
      shortDescription: findTranslation(product.attributes.shortDescription, language.code),
      longDescription: findTranslation(product.attributes.longDescription, language.code),
      manufacturerName: product.attributes.manufacturerName,
      manufacturerPartNumber: product.attributes.manufacturerPartNumber
    })
    const dealerPrice = product.attributes.dealerPrice && numeral(product.attributes.dealerPrice).format('$0,0.00')
    const retailPrice = product.attributes.retailPrice && numeral(product.attributes.retailPrice).format('$0,0.00')
    const unitOfMeasure = dealerPrice && product.attributes.unitOfMeasure && `(${product.attributes.unitOfMeasure})`
    */

    return (
      <div style={{ margin: 24 }}>
        <pre>{JSON.stringify(product, null, 2)}</pre>
      </div>
    )
  }
}
