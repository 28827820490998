import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import { getFiles, postFile } from './actions'
import App from '../common/app'
import Files from './components/index'
let unsubscribe = function () {}

function render () {
  ReactDOM.render(
    <App active="file" {...getState()} {...appActions}>
      <Files {...getState().files} postFile={postFile} />
    </App>,
    document.querySelector('#app')
  )
}

export function enter (context, next) {
  unsubscribe = subscribe(render)
  getFiles()
  render()
}

export function exit (context, next) {
  unsubscribe()
  next()
}
