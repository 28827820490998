import {
    GET_INSIGHT_MESSAGE_PENDING,
    GET_INSIGHT_MESSAGE_SUCCESSFUL,
    GET_INSIGHT_MESSAGE_ERROR,
    LOAD_INSIGHT_MESSAGES_SUCCESSFUL,
    LOAD_INSIGHT_MESSAGES_PENDING
} from './constants'

var initialState = {
    id: '',
    data: [{ subject: "", body: "", read: false }],
    meta: {
        total: 0,
        limit: 0,
        offset: 0
    },
    isLoading: false,
    wasLoadSuccessful: false,
    error: null
}

export default function insightMessages(state = initialState, { type, response }) {
    switch (type) {
        case GET_INSIGHT_MESSAGE_PENDING:
            return {
                ...state,
                isLoading: true,
                wasLoadSuccessful: false,
                error: null
            }

        case GET_INSIGHT_MESSAGE_SUCCESSFUL:
            return {
                ...state,
                data: response,
                isLoading: false,
                wasLoadSuccessful: true,
                error: null
            }

        case LOAD_INSIGHT_MESSAGES_PENDING:
            return {
                ...state,
                id: response,
                isLoading: true,
                wasLoadSuccessful: false,
                error: null
            }

        case LOAD_INSIGHT_MESSAGES_SUCCESSFUL:
            return {
                ...state,
                data: response,
                isLoading: false,
                wasLoadSuccessful: true,
                error: null
            }

        case GET_INSIGHT_MESSAGE_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                wasLoadSuccessful: false,
                error: response.error
            }

        default:
            return state
    }
}
