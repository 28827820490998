import { switchListView, switchRetailMode, switchLanguage, dismissEmailAlert } from './session'
import { searchProducts, changeSearchQuery } from './product-search'

export default {
  onSwitchListView: switchListView,
  searchProducts,
  changeSearchQuery,
  switchLanguage,
  dismissEmailAlert,
  switchRetailMode
}
