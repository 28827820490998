import {
  GET_UNDELIVERED_MESSAGES_PENDING,
  LOAD_UNDELIVERED_MESSAGES_SUCCESSFUL,
} from './constants'

var initialState = {
  data: [],
}

export default function reports(state = initialState, { type, response }) {

  switch (type) {
    case GET_UNDELIVERED_MESSAGES_PENDING:
      return {
        ...state,
        isLoading: true,
        wasLoadSuccessful: false,
        error: null
      }

    case LOAD_UNDELIVERED_MESSAGES_SUCCESSFUL:
      return {
        ...state,
        data: response,
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }

    default:
      return state
  }
}
