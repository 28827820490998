import React from 'react'
import createReactClass from 'create-react-class'
import EmailAdminMenu from '../../email-admin-menu/components'
import SearchBar from './search-bar'
import moment from 'moment'

export default createReactClass({
    displayName: 'EmailAdminInsightDelMessages',
    getInitialState() {
        return {
            groupedId: ""
        }
    },

    insights(data) {
        if (data) {
            return data.map(insight => {
                //console.log(insight)
                if (data) {
                    const key = insight.email
                    return (
                        <tr key={key} data-item={key} onClick={this.gotoMessage}>
                            <td>{moment(insight.modified).format("YYYY-MM-DD")}</td>
                            <td>{insight.subject}</td>
                            <td>{insight.accountNumber}</td>
                            <td>{insight.accountName}</td>
                            <td>{insight.email}</td>
                            <td hidden ={insight.response === 'unread'} className="textGreen">{insight.response}</td>
                            <td hidden ={insight.response !== 'unread'} className="textRed">{insight.response}</td>
                        </tr>
                    );
                }
            });
        }
    },

    render() {
        // console.log(this.props.insightDelMessages.data)
        return (
            <div>
                <EmailAdminMenu></EmailAdminMenu>
                <div className="atlas-email-admin-main">
                    <SearchBar changeSearchQuery={this.props.changeSearchQuery} isOnline={true}></SearchBar>
                    <div className="atlas-inbox-grid-main">
                        <table hidden={!this.props.insightMessages.data} className="atlas-inbox-table table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Subject</th>
                                    <th>Account</th>
                                    <th>Account Name</th>
                                    <th>Email</th>
                                    <th>Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.insights(this.props.insightDelMessages.data)}
                            </tbody>
                        </table>
                        <div hidden={!this.props.insightDelMessages.isLoading} className="insight-spinner">
                            <img
                                alt="Loading"
                                src="/img/loading-ring.gif"
                                style={{ width: 40, height: 40 }}
                            />
                        </div>
                    </div>
                    <div id="wrapper">
                        <span id="right" className="atlas-email-admin-button" onClick={this.downloadFile}>Download</span>
                    </div>
                </div>
            </div >
        )
    }
})