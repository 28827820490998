import React from 'react'
import TimeAgo from 'react-timeago'
import getCategoryWithAncestors from '../../../common/get-category-with-ancestors'
import 'string.prototype.repeat'
import { findTranslation } from '../../../common/translation'

export default class Activity extends React.Component {
  render () {
    const activity = this.props.activity
    const categories = this.props.categories.data
    const components = Object.values(activity.data)
      .sort((a, b) => {
        return a.timestamp > b.timestamp ? -1 : 1
      })
      .map(entry => {
        if (!entry.session || !entry.session.id) {
          return
        }
        const categoriesInPath = getCategoryWithAncestors(
          categories,
          entry.lastCategoryId
        )
        const categoryComponents = categoriesInPath.map(category => {
          return (
            <div key={category.id}>
              {findTranslation(category.attributes.name, 'en')}
            </div>
          )
        })

        return (
          <tr key={entry.session.id}>
            <td>
              <div>
                {entry.session.user.name}
              </div>
              <div>
                {entry.session.user.customer_id}
              </div>
              <div>
                {entry.session.user.email}
              </div>
            </td>
            <td>
              {categoryComponents}
            </td>
            <td>
              {entry.lastSearchQuery}
              {' '}
              <span style={{ color: 'grey' }}>
                {'.'.repeat(entry.lastSearchQueryOffset / 20)}
              </span>
            </td>
            <td title={entry.timestamp}>
              <TimeAgo date={entry.timestamp} />
            </td>
          </tr>
        )
      })

    return (
      <div style={{ display: 'flex' }}>
        <main className="atlas-cart">
          <h1>Search Activity</h1>
          <table
            className="table table-striped table-bordered table-sm atlas-products-compact"
            style={{ tableLayout: 'fixed' }}
          >
            <thead>
              <tr>
                <th scope="column">
                  Name
                </th>
                <th scope="column">
                  Last category
                </th>
                <th scope="column">
                  Last query
                </th>
                <th scope="column">
                  Last activity
                </th>
              </tr>
            </thead>
            <tbody>
              {components}
            </tbody>
          </table>
        </main>
      </div>
    )
  }
}
