import React from 'react'
import { FormattedMessage } from 'react-intl'
import phoneFormatter from 'phone-formatter'
import moment from 'moment'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'Users',

  handleUserSearch (event) {
    event.preventDefault()
    this.props.getUsers({ query: this.refs.userQuery.value })
  },

  render () {
    const users = this.props.users.data
    const sessionId = this.props.session.id

    const userComponents = users.map(user => {
      let phoneNumber = user.attributes.phone
      try {
        phoneNumber = user.attributes.phone
          ? phoneFormatter.format(user.attributes.phone, '(NNN) NNN-NNNN')
          : ''
      } catch (error) {
        console.error(error)
      }
      return (
        <tr key={user.id}>
          <td>
            <div>
              <a href={`/users/${user.id}`}>
                {user.attributes.name || 'No name'}
              </a>
            </div>
          </td>
          <td>
            <div>{user.attributes.customer_id}</div>
            <div>
              {user.attributes.disabled &&
                <span style={{ color: 'red' }}> (Disabled)</span>}
            </div>
          </td>
          <td>
            <div>
              {user.attributes.email}
            </div>
            <div>
              {phoneNumber}
            </div>
          </td>
          <td>
            <div>
              {user.attributes.browser}
            </div>
            <div>
              {user.attributes.os}
            </div>
          </td>
          <td>
            {(user.attributes.lastLogin || user.attributes.last_login) &&
              moment(
                user.attributes.lastLogin || user.attributes.last_login
              ).format('MMMM Do YYYY, h:mm a')}
          </td>
        </tr>
      )
    })
    return (
      <div style={{ padding: 24 }}>
        <h1>
          <FormattedMessage defaultMessage="Users" id="Users" />
        </h1>
        <div>
          <input
            onChange={this.handleUserSearch}
            placeholder="Search for user"
            ref="userQuery"
            type="search"
          />
        </div>
        <div>
          <a className="btn btn-primary" href="/users/new">
            New user
          </a>
          <a
            className="btn btn-info"
            href={`/api/users.csv?x-session-id=${sessionId}`}
            target="_blank"
          >
            Export
          </a>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>ID</th>
              <th>Contact</th>
              <th>Browser</th>
              <th>Last login</th>
            </tr>
          </thead>
          <tbody>
            {userComponents}
          </tbody>
        </table>
      </div>
    )
  }
})
