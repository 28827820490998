export const GET_CART_PENDING = 'GET_CART_PENDING'
export const GET_CART_SUCCESSFUL = 'GET_CART_SUCCESSFUL'
export const GET_CART_ERROR = 'GET_CART_ERROR'
export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const CHANGE_CART_ITEM_QUANTITY = 'CHANGE_CART_ITEM_QUANTITY'
export const CHANGE_CART_TEXT_FIELD = 'CHANGE_CART_TEXT_FIELD'
export const SUBMIT_CART_PENDING = 'SUBMIT_CART_PENDING'
export const SUBMIT_CART_SUCCESS = 'SUBMIT_CART_SUCCESS'
export const SUBMIT_CART_ERROR = 'SUBMIT_CART_ERROR'
export const CHANGE_QUERY_ORDER_QUERY = 'CHANGE_QUERY_ORDER_QUERY'
export const CART_ITEM_ERROR = 'CART_ITEM_ERROR'
