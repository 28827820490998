import {
  REQUEST_PASSWORD_RESET_PENDING,
  REQUEST_PASSWORD_RESET_SUCCESSFUL,
  REQUEST_PASSWORD_RESET_ERROR
} from './constants'

var initialState = {
  isPending: false,
  wasSuccessful: false,
  error: null
}

export default function dealers (state = initialState, { type, response }) {
  switch (type) {
    case REQUEST_PASSWORD_RESET_PENDING:
      return {
        ...state,
        isPending: true,
        wasSuccessful: false,
        error: null
      }

    case REQUEST_PASSWORD_RESET_SUCCESSFUL:
      return {
        ...state,
        isPending: false,
        wasSuccessful: response,
        error: response ? null : 'User not found'
      }

    case REQUEST_PASSWORD_RESET_ERROR:
      return {
        ...state,
        isPending: false,
        wasSuccessful: false,
        error: response
      }
    default:
      return state
  }
}
