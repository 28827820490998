import {
  // GET_ACTIVITY_PENDING,
  GET_ACTIVITY_SUCCESSFUL
  // GET_ACTIVITY_ERROR
} from './constants'

var initialState = {
  pending: true,
  successful: true,
  error: null,
  data: {}
}

function reducer (activity, entry) {
  const sessionId = entry.session && entry.session.id
  if (!sessionId) return activity
  if (!activity[sessionId]) {
    activity[sessionId] = {
      lastSearchQuery: null
    }
  }
  activity[sessionId].timestamp = entry.timestamp
  activity[sessionId].userAgent = entry.userAgent

  if (entry.method === 'GET' && entry.pathname === '/api/product-search') {
    if (entry.query) {
      if (entry.query.q) {
        activity[sessionId].lastSearchQuery = entry.query.q
        activity[sessionId].lastSearchQueryOffset = entry.query.offset
      }
      if (entry.query.categoryId) {
        activity[sessionId].lastCategoryId = entry.query.categoryId
      }
      if (entry.query.categoryId) {
        activity[sessionId].lastCategoryId = entry.query.categoryId
      }
    }
    activity[sessionId].session = entry.session
  }

  return activity
}

export default function webOrder (
  state = initialState,
  { type, response, error }
) {
  switch (type) {
    case GET_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        pending: true,
        successful: false,
        error: null,
        data: reducer(
          state.data,
          response.change.new_val || response.change.old_val
        )
      }
    default:
      return state
  }
}
