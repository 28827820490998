import {
  CHANGE_BROWSER_STATE,
  CHANGE_ONLINE_STATUS
} from '../constants/action-types'
import { dispatch } from '../store'

export function changeBrowserState (context) {
  dispatch({
    type: CHANGE_BROWSER_STATE,
    context
  })
}

export function changeOnlineStatus (isOnline) {
  dispatch({
    type: CHANGE_ONLINE_STATUS,
    isOnline
  })
}

window.addEventListener('offline', () => {
  changeOnlineStatus(false)
})
