import { GET_CATEGORIES_PENDING, GET_CATEGORIES_SUCCESSFUL } from './constants'
import { GET_AGGREGATED_CATEGORIES } from '../constants/action-types'

var initialState = {
  data: [],
  tree: [],
  meta: {
    total: 0,
    limit: 0,
    offset: 0
  },
  aggregate: null,
  isLoading: false,
  wasLoadSuccessful: false,
  hasMore: false
}

export default function categories (state = initialState, { type, response }) {
  switch (type) {
    case GET_CATEGORIES_PENDING:
      return {
        ...state,
        isLoading: true,
        wasLoadSuccessful: false
      }

    case GET_CATEGORIES_SUCCESSFUL:
      return {
        ...state,
        ...response,
        // tree: getChildrenByParentId(response.data.sort(sortByName), ROOT_CATEGORY_ID),
        isLoading: false,
        wasLoadSuccessful: true
      }
    
    case GET_AGGREGATED_CATEGORIES:
      return {
        ...state,
        aggregate: response,
        isLoading: false,
        wasLoadSuccessful: true
      }

    default:
      return state
  }
}

/*
function getChildrenByParentId (categories, parentId) {
  return categories.filter((category) => {
    return category.attributes.parentId === parentId
  }).map((category) => {
    return {
      ...category,
      children: getChildrenByParentId(categories, category.id)
    }
  })
}

function sortByName (categoryA, categoryB) {
  return (categoryA.attributes.name < categoryB.attributes.name)
    ? -1 : (categoryA.attributes.name > categoryB.attributes.name) ? 1 : 0
}
*/
