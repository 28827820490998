import React from 'react'
import saveAs from 'file-saver'
import createReactClass from 'create-react-class'
import EmailAdminMenu from '../../email-admin-menu/components'
import { download } from '../actions'
import classnames from 'classnames'
import { getState } from '../../store'

let page = 1;
let pageSize = 8;
let lang = ""
let customerIdSortClick = false
let nameSortClick = true
let emailSortClick = false
let dir = true
let column = "name"

export default createReactClass({
    displayName: 'Emails',

    getInitialState() {
        return {
            customerIdSort: false,
            customerIdSortDir: true,
            nameSort: true,
            nameSortDir: true,
            emailSort: false,
            emailSortDir: true,
            focused: false,
            customerIdLabel: "Customer Id",
            nameLabel: "Name",
            emailLabel: "Email"
        }
    },

    sortBy(event) {
        column = event.currentTarget.getAttribute('id')

        this.setState({ customerIdSort: false })
        this.setState({ nameSort: false })
        this.setState({ emailSort: false })
        page = 1

        switch (column) {
            case 'customer_id':
                this.setState({ customerIdSort: true })
                customerIdSortClick = !customerIdSortClick
                this.setState({ customerIdSortDir: customerIdSortClick })
                dir = customerIdSortClick
                this.setState({ nameSortDir: true })
                this.setState({ nameSortClick: true })
                this.setState({ emailSortDir: true })
                this.setState({ emailSortClick: true })
                break;
            case 'name':
                this.setState({ nameSort: true })
                nameSortClick = !nameSortClick
                this.setState({ nameSortDir: nameSortClick })
                dir = nameSortClick
                this.setState({ customerIdSortDir: true })
                this.setState({ customerIdSortClick: true })
                this.setState({ emailSortDir: true })
                this.setState({ emailSortClick: true })
                break;
            case 'email':
                this.setState({ emailSort: true })
                emailSortClick = !emailSortClick
                this.setState({ emailSortDir: emailSortClick })
                dir = emailSortClick
                this.setState({ customerIdSortDir: true })
                this.setState({ customerIdSortClick: true })
                this.setState({ nameSortDir: true })
                this.setState({ nameSortClick: true })
                break;
        }
        //console.log(dir)
        //console.log(column)

        this.props.changeSearchQuery(this.refs.searchQuery.value, page, pageSize, lang, column, dir)
    },

    async downloadFile(event) {
        event.preventDefault()
        const users = await download(lang, this.refs.searchQuery.value)
        const data = users.data.map(user => {
            return {
                customerId: user.attributes.customer_id,
                name: user.attributes.name,
                email: user.attributes.email
            }
        })

        const replacer = (key, value) => value === null ? '' : value;
        const header = Object.keys(data[0]);
        let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');

        var blob = new Blob([csvArray], { type: 'text/csv' })

        saveAs(blob, `clients-${new Date().toISOString().slice(0, 10)}.csv`);
    },

    displayUsers(data) {
        return data.map(user => {
            return (
                <tr key={user.attributes._id} data-item={user.attributes._id} onClick={this.gotoMessage}>
                    <td className="atlas-inbox-thin-column">{user.attributes.customer_id}</td>
                    <td>{user.attributes.name}</td>
                    <td className="atlas-inbox-thin-column">{user.attributes.email}</td>
                </tr>

            );
        });
    },

    onLangSelect(e) {
        lang = encodeURIComponent(e.target.value)
        this.props.changeSearchQuery(this.refs.searchQuery.value, page, pageSize, lang, column, dir)
    },

    handleKeyPress(event) {
        const isEnterKey = event.which === 13
        if (isEnterKey) {
            event.preventDefault()
        }
    },

    handleClickDown(event) {
        if (page > 1) {
            page--;
            this.props.changeSearchQuery(this.refs.searchQuery.value, page, pageSize, lang, column, dir)
        }
    },

    handleClickFirst(event) {
        page = 1;
        this.props.changeSearchQuery(this.refs.searchQuery.value, page, pageSize, lang, column, dir)
    },

    handleSearchInput(event) {
        page = 1;
        this.props.changeSearchQuery(this.refs.searchQuery.value, page, pageSize, lang, column, dir)
    },

    handleClickUp(event) {
        page++;
        this.props.changeSearchQuery(this.refs.searchQuery.value, page, pageSize, lang, column, dir)
    },

    goToPage(targetPage) {
        page = targetPage;
        this.props.changeSearchQuery(this.refs.searchQuery.value, page, pageSize, lang, column, dir)
    },

    handleClickLast(event) {
        let lastPage = (Math.ceil(getState().users.meta.total / getState().users.meta.limit))
        page = lastPage;
        if (page == 0) page = 1;
        this.props.changeSearchQuery(this.refs.searchQuery.value, page, pageSize, lang, column, dir)
    },
    handleFocus() {
        this.setState({ focused: true })
    },

    handleBlur() {
        this.setState({ focused: false })
    },

    render() {
        const users = getState().users
        //console.log(users)
        const lastPage = users.data.length == 0 ? 1 : Math.ceil(users.meta.total / users.meta.limit)
        const isOnline = this.props.isOnline
        const formClassnames = classnames('atlas-primary-nav-search-form', {
            'atlas-primary-nav-search-form--focus': this.state.focused
        })


        return (
            <div>
                <EmailAdminMenu></EmailAdminMenu>

                <div className="atlas-email-admin-main">
                    <form>
                        <div id="wrapper">
                            {/* use regex to find accounts by lang ie starts with 7 is french */}
                            <select name="lang" className="email-admin-language-select" onChange={this.onLangSelect}>
                                <option value="">&nbsp; All</option>
                                <option value="^(?!7).+">&nbsp; English</option>
                                <option value="^7">&nbsp; French</option>
                            </select>
                            <div className={formClassnames} id="left">

                                <img
                                    alt="Search icon"
                                    className="atlas-search-input-icon"
                                    src="/img/search.svg"
                                />
                                <input
                                    autoFocus={true}
                                    className="atlas-search-input"
                                    onBlur={this.handleBlur}
                                    onFocus={this.handleFocus}
                                    onInput={this.handleSearchInput}
                                    onKeyPress={this.handleKeyPress}
                                    placeholder="Search..."
                                    ref="searchQuery"
                                    type="search"
                                />
                            </div>
                            <nav id="right">
                                <ul className="pagination no-margin">
                                    <li className="page-item" onClick={this.handleClickFirst} ><a style={{ color: 'black' }} className="page-link">First</a></li>
                                    <li hidden={!(page == lastPage && lastPage > 4)} onClick={() => this.goToPage(page - 4)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page - 4}</a></li>
                                    <li hidden={!(page + 1 >= lastPage && lastPage > 3)} onClick={() => this.goToPage(page - 3)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page - 3}</a></li>
                                    <li hidden={page <= 2 || 2 > lastPage} onClick={() => this.goToPage(page - 2)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page - 2}</a></li>
                                    <li hidden={page == 1} onClick={this.handleClickDown} className="page-item"><a style={{ color: 'black' }} className="page-link">{page - 1}</a></li>
                                    <li className="page-item active pagination" ><a className="page-link">{page}</a></li>
                                    <li hidden={page == lastPage} onClick={this.handleClickUp} className="page-item"><a style={{ color: 'black' }} className="page-link">{page + 1}</a></li>
                                    <li hidden={page + 2 > lastPage} onClick={() => this.goToPage(page + 2)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page + 2}</a></li>
                                    <li hidden={page > 2 || lastPage <= 3} onClick={() => this.goToPage(page + 3)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page + 3}</a></li>
                                    <li hidden={page > 1 || lastPage <= 4} onClick={() => this.goToPage(page + 4)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page + 4}</a></li>
                                    <li className="page-item" onClick={this.handleClickLast}><a style={{ color: 'black' }} className="page-link">Last</a></li>
                                </ul>
                            </nav>
                        </div>
                    </form>
                    <div className="atlas-inbox-grid-main">
                        <table className="atlas-email-admin-table table">
                            <thead>
                                <tr>
                                    <th onClick={this.sortBy} id="customer_id" className="atlas-inbox-thin-column">{this.state.customerIdSort ? dir ? <span>Customer Id &#5121;</span> : <span>Customer Id &#5123;</span> : "Customer Id"}</th>
                                    <th onClick={this.sortBy} id="name" >{this.state.nameSort ? dir ? <span>Name &#5121;</span> : <span>Name &#5123;</span> : "Name"}</th>
                                    <th onClick={this.sortBy} id="email" className="atlas-inbox-thin-column">{this.state.emailSort ? dir ? <span>Email &#5121;</span> : <span>Email &#5123;</span> : "Email"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.displayUsers(this.props.users.data)}
                            </tbody>
                        </table>

                    </div>
                    <div id="wrapper">
                        <span id="right" className="atlas-email-admin-button" onClick={this.downloadFile}>Download</span>
                    </div>
                </div>
            </div>
        )
    }
})
