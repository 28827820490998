import {
    GET_USERS_PENDING,
    GET_USERS_SUCCESSFUL,
    GET_USERS_ERROR,
    USER_SEARCH_LIMIT
} from './constants'

var initialState = {
    data: [],
    meta: {
        total: 0,
        limit: 0,
        offset: 0
    },
    isLoading: false,
    wasLoadSuccessful: false,
    error: null
}

export default function users(state = initialState, { type, response }) {
    switch (type) {
        case GET_USERS_PENDING:
            return {
                ...state,
                isLoading: true,
                wasLoadSuccessful: false,
                error: null
            }

        case GET_USERS_SUCCESSFUL:
            return {
                ...state,
                ...response,
                data: response.data,
                isLoading: false,
                wasLoadSuccessful: true,
                error: null
            }

        case GET_USERS_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                wasLoadSuccessful: false,
                error: error
            }

        default:
            return state
    }
}
