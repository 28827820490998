import {
  GET_MY_STATEMENTS_PENDING,
  GET_MY_STATEMENTS_SUCCESSFUL,
  GET_MY_STATEMENTS_ERROR
} from './constants'
import { dispatch, getState } from '../store'
export function getMyStatements (options = {}) {
  dispatch({
    type: GET_MY_STATEMENTS_PENDING
  })

  fetch('/api/getFiles/?type=statement', {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_MY_STATEMENTS_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.error(error)
      dispatch({
        type: GET_MY_STATEMENTS_ERROR,
        error
      })
    })
    
}
