import classnames from 'classnames'
import React from 'react'
import Login from './login'
import SearchBar from './nav/search-bar'
import './app.css'
import { FormattedMessage, IntlProvider } from 'react-intl'
// import { addLocaleData, FormattedMessage, IntlProvider } from 'react-intl'
// import en from 'react-intl/locale-data/en'
// import fr from 'react-intl/locale-data/fr'
import frMessages from '../../locales/fr'
import aguid from 'aguid'
// import unopenedNo from '../inbox/actions'
import page from 'page'
import Modal from 'react-modal'
Modal.setAppElement('#app')

// addLocaleData(en)
// addLocaleData(fr)

const messages = {
  en: {},
  fr: frMessages
}

class App extends React.Component {
  handleToggleRetailMode () {
    this.props.switchRetailMode()
  }

  componentDidMount () {
    // inject comm100 chat script
    const script = document.createElement("script");
    script.innerHTML = `var Comm100API=Comm100API||{};(function(t){function e(e){var a=document.createElement("script"),c=document.getElementsByTagName("script")[0];a.type="text/javascript",a.async=!0,a.src=e+t.site_id,c.parentNode.insertBefore(a,c)}t.chat_buttons=t.chat_buttons||[],t.chat_buttons.push({code_plan:"d9906326-5499-4934-bd1a-43de84e904f3",div_id:"comm100-button-d9906326-5499-4934-bd1a-43de84e904f3"}),t.site_id=80000084,t.main_code_plan="d9906326-5499-4934-bd1a-43de84e904f3",e("https://vue.comm100.com/livechat.ashx?siteId="),setTimeout(function(){t.loaded||e("https://standby.comm100vue.com/livechat.ashx?siteId=")},5e3)})(Comm100API||{})`
    document.body.appendChild(script);
  }

  handleSwitchLanguage (event) {
    event.preventDefault()
    this.props.switchLanguage()
  }

  handleCloseModal (event) {
    event.preventDefault()
    this.props.dismissEmailAlert()
  }

  handleGoToInbox (event) {
    event.preventDefault()
    this.props.dismissEmailAlert()
    page('/inbox/')
  }

  getActiveClass (path, query) {
    if (query) {
      return window.location.search === query ? ' active-link' : ''
    }
    return window.location.pathname === path ? ' active-link' : ''
  }

  render () {
      
    if (this.refs.head) {
      let { clientHeight } = this.refs.head;
      this.refs.content.style.marginTop = clientHeight + 'px';
    }
    const isOnline = this.props.browser && this.props.browser.isOnline
    const sessionId = this.props.session && this.props.session.id
    const sessionIsLoading = this.props.session && this.props.session.isLoading
    const isRetailMode = this.props.session && this.props.session.isRetailMode
    const user = this.props.session.user || {}
    const dealer = this.props.session.dealer || {}
    const language = this.props.session && this.props.session.language
    const dismissEmailAlert = this.props.session.dismissEmailAlert
    const isAdmin = this.props.session.user.roles.includes('Administrator')
    

    const cartQuantityTotal = this.props.cart.data.items.reduce(
      (prev, item) => {
        return prev + item.quantity
      },
      0
    )
    const inboxMessages = this.props.messages.meta.unread 
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: '350px',
        width: '600px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
        overflow: 'visible'
      },
    };
  
    if (sessionIsLoading) {
      return (
        <div style={{ margin: 'auto', padding: 100, width: 40 }}>
          <img
            alt="Loading"
            src="/img/loading-ring.gif"
            style={{ width: 40, height: 40 }}
          />
        </div>
      )
    }

    if (!sessionId) {
      return (
        <div>
          <Login {...this.props.session} />
        </div>
      )
    }

    if (!user) {
      return
    }

    // const homeClassnames = classnames('nav-item', {
    //   active: this.props.active === 'home'
    // })
    //
    // const catalogClassnames = classnames('nav-item', {
    //   active: this.props.active === 'catalog'
    // })

    const categoriesClassnames = classnames('nav-item', {
      active: this.props.active === 'categories'
    })

    // const productsClassnames = classnames('nav-item', {
    //   active: this.props.active === 'products'
    // })

    const newProductsClassnames = classnames('nav-item')
    // const suppliersClassnames = classnames('nav-item')
    // const marketingClassnames = classnames('nav-item')
    const clearanceClassnames = classnames('nav-item')
    const eflyersClassnames = classnames('nav-item')
    const aboutClassnames = classnames('nav-item')
    const marketingClassnames = classnames('nav-item')
    const flippdfClassnames = classnames('nav-item')

    // const suppliersClassnames = classnames('nav-item', {
    //   active: this.props.active === 'suppliers'
    // })
    //
    // const adminClassnames = classnames('nav-item', {
    //   active: this.props.active === 'admin'
    // })

    return (
      <IntlProvider
        key={language.code}
        locale={language.code}
        messages={messages[language.code]}
      >
        <div style={{ backgroundColor: 'white' }}>
          {/* Navigation */}
          <div id="comm100-button-d9906326-5499-4934-bd1a-43de84e904f3"></div>
          <div style={{ position:'fixed',top: 0, right: 0, left: 0 }}>
            <nav ref="head" className="navbar-fixed-top">
              <div className="atlas-primary-nav">
                <a className="atlas-primary-nav-logo" href="/">
                  {!isRetailMode &&
                    <img
                      alt="Atlas Trailer logo"
                      src="/img/logo.svg"
                      style={{ height: 52 }}
                    />}
                  {isRetailMode &&
                    <div style={{ height: 52, width: 200 }}>
                      {dealer.logoUrl &&
                        <img
                          alt="Dealer logo"
                          src={dealer.logoUrl}
                          style={{ maxHeight: 52, maxWidth: 200 }}
                        />}
                    </div>}
                </a>
                <SearchBar
                  isOnline={isOnline}
                  onSearchInput={this.props.changeSearchQuery}
                  language={this.props.session.language}
                  query={this.props.browser.query.q}
                  page={this.props.browser.query.pagenum}
                />
                <div className="atlas-primary-nav-inbox-icon-container">
                  <a className="atlas-primary-nav-cart-icon-link" href="/inbox">
                    <img
                      alt="inbox"
                      aria-label="inbox"
                      className="atlas-primary-nav-cart-icon"
                      src="/img/envelope.svg"
                    />
                    {inboxMessages > 0 &&
                      <div className="atlas-inbox-icon-quantity">
                        <div className="atlas-cart-icon-quantity-inner">
                          {inboxMessages}
                        </div>
                      </div>}
                  </a>
                </div>
                <div className="atlas-primary-nav-cart-icon-container">
                  <a className="atlas-primary-nav-cart-icon-link" href="/cart">
                    <img
                      alt="Presentation"
                      className="atlas-primary-nav-cart-icon"
                      src="/img/cart.svg"
                    />
                    {cartQuantityTotal > 0 &&
                      <div className="atlas-cart-icon-quantity">
                        <div className="atlas-cart-icon-quantity-inner">
                          {cartQuantityTotal}
                        </div>
                      </div>}
                  </a>
                </div>
              </div>
              <div className="atlas-secondary-nav">
                <ul className="nav navbar-nav">
                  {/* <li className={homeClassnames}>
                    <a className='atlas-secondary-nav-link' href='#'>Home <span className='sr-only'>(current)</span></a>
                  </li>
                  <li className={catalogClassnames}>
                    <a className='nav-link' href='/catalog'>Catalog</a>
                  </li> */}
                  <li className={categoriesClassnames + `${this.getActiveClass('--', '?categoryId=6b86b273-f34f-419d-8804-ff5a3f5747ad')}`}>
                    <a
                      className="atlas-secondary-nav-link"
                      href="/?categoryId=6b86b273-f34f-419d-8804-ff5a3f5747ad"
                    >
                      <FormattedMessage
                        defaultMessage="Products"
                        id="Products"
                      />
                    </a>
                  </li>
                  <li className={flippdfClassnames + this.getActiveClass('/pages/flippdf', null)}>
                    <a className="atlas-secondary-nav-link" href="/pages/flippdf">
                      <FormattedMessage
                        defaultMessage="Flip PDF Catalogue"
                        id="Flip PDF Catalogue"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <form
                      action="https://www.rvpartfinder.com/rvpartfinderca/AtlasLogin.asp"
                      method="post"
                      ref={this.setForm}
                    >
                      <input type="hidden" name="UID" value={aguid(user._id)} />
                      <input
                        type="hidden"
                        name="CID"
                        value={user.customer_id}
                      />
                      <input
                        type="hidden"
                        name="PK"
                        value={'S4@zR%mv$CRGY&1E%gsK$7Gr&67Bez'}
                      />
                      <button
                        className="atlas-secondary-nav-link"
                        type="submit"
                        target="_blank"
                      >
                        <FormattedMessage
                          defaultMessage="RV Partfinder"
                          id="RV Partfinder"
                        />
                      </button>
                    </form>
                  </li>
                  <li className={newProductsClassnames}>
                    <a
                      target="_blank"
                      className="atlas-secondary-nav-link"
                      href="http://fitlist.ca/"
                      rel="noopener noreferrer"
                    >
                      Fitlist
                    </a>
                  </li>
                  <li className={newProductsClassnames + this.getActiveClass('/products/list/new', null)}>
                    <a
                      className="atlas-secondary-nav-link"
                      href="/products/list/new"
                    >                     
                      <FormattedMessage
                        defaultMessage="New Products"
                        id="New Products"
                      />
                    </a>
                  </li>
                  <li className={eflyersClassnames + this.getActiveClass('/e-flyers', null)}>
                    <a className="atlas-secondary-nav-link" href="/e-flyers">
                      <FormattedMessage
                        defaultMessage="Specials"
                        id="Specials"
                      />
                    </a>
                  </li>
                  <li className={clearanceClassnames + this.getActiveClass('/products/list', null)}>
                   <a
                      className="atlas-secondary-nav-link"
                      href="/products/list/clearance"
                    >
                      <FormattedMessage
                        defaultMessage="Clearance"
                        id="Clearance"
                      />
                    </a>
                  </li>
                  <li className={newProductsClassnames + this.getActiveClass(null,'/?categoryId=19818b31-8ff8-4a31-a4c3-bdbaf8524b66' )}>
                    <a
                      className="atlas-secondary-nav-link"
                      href="/?categoryId=19818b31-8ff8-4a31-a4c3-bdbaf8524b66"
                    >                     
                      <FormattedMessage
                        defaultMessage="Scratch & Dent"
                        id="Scratch & Dent"
                      />
                    </a>
                  </li>        
                  <li className={marketingClassnames + this.getActiveClass('/pages/marketing', null)}>
                    <a
                      className="atlas-secondary-nav-link"
                      href="/pages/marketing"
                    >
                      <FormattedMessage
                        defaultMessage="Marketing"
                        id="Marketing"
                      />
                    </a>
                  </li>

                  <li className={'nav-item' + this.getActiveClass('/terms-of-sale', null)} >
                    <a
                      className="atlas-secondary-nav-link"
                      href="/terms-of-sale"
                    >
                      <FormattedMessage
                        defaultMessage="Terms of Sale"
                        id="Terms of Sale"
                      />
                    </a>
                  </li>
                  <li className={aboutClassnames + this.getActiveClass('/pages/about', null)}>
                    <a className="atlas-secondary-nav-link" href="/pages/about">
                      <FormattedMessage
                        defaultMessage="Contact Us"
                        id="Contact Us"
                      />
                    </a>
                  </li>
                  {/* New products, E-Flyers, Clearance, Suppliers, and Marketing . */}
                  {/* <li className={suppliersClassnames}>
                    <a className='nav-link' href='/suppliers'>Suppliers</a>
                  </li>
                  {isAdmin && <li className={adminClassnames}>
                    <a className='atlas-secondary-nav-link' href='/admin'>Admin</a>
                  </li>} */}
                </ul>

                <ul
                  className="nav navbar-nav pull-right"
                  style={{ marginLeft: 16 }}
                >
                  {isAdmin &&
                    <li className="nav-item">
                      <a className="atlas-secondary-nav-link" href="/admin">
                        <FormattedMessage defaultMessage="Admin" id="Admin" />
                      </a>
                    </li>}
                  <li className="nav-item">
                    <button
                      className="atlas-secondary-nav-link"
                      onClick={this.handleToggleRetailMode.bind(this)}
                    >
                      {isRetailMode ? 'Dealer' : 'Retail'}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="atlas-secondary-nav-link"
                      onClick={this.handleSwitchLanguage.bind(this)}
                    >
                      {language.name === 'English' ? 'Français' : 'English'}
                    </button>
                  </li>
                  <li className="nav-item">
                    <a className="atlas-secondary-nav-link" href="/profile">
                      <FormattedMessage defaultMessage="Profile" id="Profile" />
                      {' '}
                      ({user.email})
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <Modal
            isOpen={inboxMessages > 0 && !dismissEmailAlert}
            onRequestClose={this.handleCloseModal.bind(this)}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            >
              <div className="modalContent">
                <button
                  className="UnreadMessageAlertModalClose"
                  onClick={this.handleCloseModal.bind(this)}
                >&#x2715;</button>
                <div className="UnreadMessageAlertInboxIcon"></div>
                <div className="UnreadMessageAlertPrimaryMessage">
                  <FormattedMessage
                  defaultMessage="YOU HAVE AN IMPORTANT EMAIL FROM ATLAS"
                  id="YOU HAVE AN IMPORTANT EMAIL FROM ATLAS"/>
                </div>
                <div className="UnreadMessageAlertSecondaryMessage">
                  <FormattedMessage
                  defaultMessage="Kindly click on the button below to read the email"
                  id="Kindly click on the button below to read the email"/>
                </div>
                <button 
                  className="UnreadMessageAlertModalGoTo"
                  onClick={this.handleGoToInbox.bind(this)}
                >
                  <FormattedMessage
                  defaultMessage="Go to Inbox"
                  id="Go to inbox"/>
                </button>
              </div>
              <div className="leftModalContainer">
                <div className="leftModalImage"></div>
              </div>
              <div className="rightModalContainer">
                <div className="rightModalPanel"></div>  
              </div>
            </Modal>
          </div>

          <div ref="content" className="atlas-app-inner">
            {this.props.children}
          </div>
        </div>
      </IntlProvider>
    )
  }
}

export default App
