import { GET_REPORTS, GET_REPORTS_ERROR, GET_REPORTS_SUCCESSFUL } from "./constants"
import { dispatch, getState } from "../store"

export function getReports (type = "consumernet") {
    dispatch({
        type: GET_REPORTS,
        response: []
    })
    if (!getState().session.id) {
        return
    }

    fetch(`/api/reports?type=${type}`, {
        headers: {
            'x-session-id': getState().session.id
        }
    }).then(async function (response) {
        if (response.ok) {
            const json = await response.json()
            dispatch({
                type: GET_REPORTS_SUCCESSFUL,
                response: json
            })
        }
    }).catch(function (error) {
        console.log('parsing failed', error)
        dispatch({
            type: GET_REPORTS_ERROR,
            error
        })
    })    
}
