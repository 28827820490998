const defaultLanguageCode = 'en'

function isCurrentLanguage (currentLanguageCode, translation) {
  return translation.languageCode === currentLanguageCode
}

function isDefaultLanguage (translation) {
  return translation.languageCode === defaultLanguageCode
}

export function findTranslation (
  translations,
  currentLanguageCode,
  returnDefault = true
) {
  if (!translations) {
    return ''
  }
  if (typeof translations === 'string') {
    return translations
  }
  try {
    const current = translations.find(
      isCurrentLanguage.bind(null, currentLanguageCode)
    )
    if (current) {
      return current.value
    } else if (returnDefault) {
      const defaultL = translations.find(isDefaultLanguage)
      if (defaultL) {
        return defaultL.value
      }
    }
  } catch (err) {
    console.error(err)
  }
  return ''
}
