import {
    GET_MESSAGE_PENDING,
    GET_MESSAGE_SUCCESSFUL,
    GET_MESSAGE_ERROR
} from './constants'

var initialState = {
    data: [{
        subject: "",
        body: "",
        read: false,
        attachments: []
    }],
    meta: {
        total: 0,
        limit: 0,
        offset: 0
    },
    isLoading: false,
    wasLoadSuccessful: false,
    error: null
}

export default function message(state = initialState, { type, response }) {
    switch (type) {
        case GET_MESSAGE_PENDING:
            return {
                ...state,
                isLoading: true,
                wasLoadSuccessful: false,
                error: null
            }

        case GET_MESSAGE_SUCCESSFUL:
            return {
                ...state,
                data: response.data,
                isLoading: false,
                wasLoadSuccessful: true,
                error: null
            }

        case GET_MESSAGE_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                wasLoadSuccessful: false,
                error: response
            }

        default:
            return state
    }
}
