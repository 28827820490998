import React from 'react'
import createReactClass from 'create-react-class'
import moment from 'moment'

import { FormattedMessage, IntlProvider } from 'react-intl'
// import { addLocaleData, FormattedMessage, IntlProvider } from 'react-intl'
// import en from 'react-intl/locale-data/en'
// import fr from 'react-intl/locale-data/fr'
import frMessages from '../../../locales/fr'

// addLocaleData(en)
// addLocaleData(fr)

const messages = {
    en: {},
    fr: frMessages
}

export default createReactClass({
    displayName: 'InboxMessage',

    getFormattedDate() {
        return moment(this.props.message.data.modified).format('YYYY-MM-DD')
    },

    displayAttachments() {
        if (this.props.message.data.attachments) {
            return this.props.message.data.attachments.map(attachment => {
                const url = "/api/attachments/" + attachment.attachmentId
                return (
                    <tr key={url}>
                        <td><a href={url} download>{attachment.attachmentName}</a>
                        </td>
                    </tr>
                );
            });
        }
    },

    render() {
        const language = this.props.session && this.props.session.language
        return (
            <IntlProvider
                key={language.code}
                locale={language.code}
                messages={messages[language.code]}
            >
                <div>
                    <div className="atlas-inbox-sidenav">
                        <div className="atlas-inbox-sidebar-container atlas-inbox-menu-item-1">
                            <div>
                                <a href='/inbox'>
                                    <svg width="32" height="50" className="chat-svg" xmlns="http://www.w3.org/2000/svg">
                                        <title>UI Icon 2</title>
                                        <g>
                                            <title>Layer 1</title>
                                            <g id="svg_1" data-name="12 Chat">
                                                <path id="svg_2" d="m22.57,28.5a1.47,1.47 0 0 1 -0.83,-0.25l-7.19,-4.75l-8.84,0a3.19,3.19 0 0 1 -3.21,-3.17l0,-13.66a3.19,3.19 0 0 1 3.21,-3.17l20.58,0a3.19,3.19 0 0 1 3.21,3.17l0,13.66a3.19,3.19 0 0 1 -3.21,3.17l-2.22,0l0,3.5a1.5,1.5 0 0 1 -1.5,1.5zm-16.86,-22a0.2,0.2 0 0 0 -0.21,0.17l0,13.66a0.2,0.2 0 0 0 0.21,0.17l9.29,0a1.55,1.55 0 0 1 0.83,0.25l5.24,3.46l0,-2.21a1.5,1.5 0 0 1 1.5,-1.5l3.72,0a0.2,0.2 0 0 0 0.21,-0.17l0,-13.66a0.2,0.2 0 0 0 -0.21,-0.17l-20.58,0z" />
                                            </g>
                                        </g>
                                    </svg></a>
                            </div>

                            <a href='/inbox'><div className="inbox-message-sidenav-text">Messages</div></a>
                        </div>
                    </div>

                    <div className="atlas-inbox-message-main">
                        <div className="atlas-inbox-grid-container">
                            <div className="atlas-inbox-grid-item atlas-inbox-green-heading">Date</div>
                            <div className="atlas-inbox-grid-item">{this.getFormattedDate()}</div>
                            <div className="atlas-inbox-grid-item"></div>
                            <div className="atlas-inbox-grid-item atlas-inbox-green-heading">
                                <FormattedMessage defaultMessage="Subject" id="Subject" />
                            </div>
                            <div className="atlas-inbox-grid-item">{this.props.message.data.subject}</div>
                            <div className="atlas-inbox-grid-item"></div>
                            <div className="atlas-inbox-grid-item atlas-inbox-green-heading">
                                <FormattedMessage defaultMessage="Attachments" id="Attachments" />
                            </div>
                            <div className="atlas-inbox-grid-item">
                                <table className="accounting-address-table">
                                    {this.displayAttachments()}
                                </table>
                            </div>
                            <div className="atlas-inbox-grid-item"></div>
                            <div className="atlas-inbox-grid-item atlas-inbox-green-heading">
                                <FormattedMessage defaultMessage="Body" id="Body" />
                                </div>
                            <div dangerouslySetInnerHTML={{ __html: this.props.message.data.body }} className="grid-item inbox-message-textarea" />
                        </div>
                    </div>
                </div>
            </IntlProvider>

        )
    }
})
