import {
  GET_MESSAGE_PENDING,
  GET_MESSAGE_SUCCESSFUL,
  GET_MESSAGE_ERROR,
  GET_ATTACHMENT_PENDING,
  GET_ATTACHMENT_SUCCESSFUL,
  GET_ATTACHMENT_ERROR
} from './constants'
import { dispatch, getState } from '../store'

export function getMessage (messageId) {
  dispatch({
    type: GET_MESSAGE_PENDING
  })

  fetch("/api/messages/" + messageId, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_MESSAGE_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: GET_MESSAGE_ERROR,
        error
      })
    })
}

export function getAttachment (messageId) {
    dispatch({
      type: GET_ATTACHMENT_PENDING
    })
  
    fetch("/api/attachments/" + id, {
      headers: {
        'x-session-id': getState().session.id
      }
    })
      .then(async function (response) {
        if (response.ok) {
          const json = await response.json()
          dispatch({
            type: GET_ATTACHMENT_SUCCESSFUL,
            response: json
          })
        }
      })
      .catch(function (error) {
        console.log('parsing failed', error)
        dispatch({
          type: GET_MESSAGE_ERROR,
          error
        })
      })
  }
  
