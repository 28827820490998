import React from 'react'
import TimeAgo from 'react-timeago'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'Carts',

  render () {
    const {dealers = []} = this.props
    const getDealerName = (acctNum) => {
      const dealer = dealers.find(dealer => dealer.acctNum === acctNum)
      return dealer ? dealer.name : ''
    }
    const cartComponents = this.props.data.map(cart => {
      if (!cart.modified) {
        return
      }
      return (
        <tr key={cart.id}>
          <td>
            {cart.id}
          </td>
          <td>
            {cart.dealerName || getDealerName(cart.id)}
          </td>
          <td>
            {cart.name}
          </td>
          <td>
            <div>
              <a href={`/carts/${cart.id}`}>
                {cart.customerId}
              </a>
            </div>
            <div>
              {cart.items
                .map(item => `${item.distributorPartNumber} (${item.quantity})`)
                .join(', ')}
            </div>
          </td>
          <td>
            {cart.purchaseOrder}
          </td>
          <td>
            {cart.modified && <TimeAgo date={cart.modified} />}
          </td>
        </tr>
      )
    })
 
    return (
      <div>
        <table className="table" style={{ tableLayout: 'fixed' }}>
          <thead style={{ backgroundColor: '#fefefe' }}>
            <td>
            <b>Account ID</b>
            </td>
            <td>
              <div>
                <b>Dealer Name</b>
              </div>
            </td>
            <td>
              <div>
                <b>Name</b>
              </div>
            </td>
            <td>
            <div>
              <b>Cart Item</b>
            </div>
            </td>
            <td>
            <b>PO</b>
            </td>
            <td>
            <b>Date</b>
            </td>
          </thead>
          <tbody>
            {cartComponents}
          </tbody>
        </table>
      </div>
    )
  }
})
