import React from 'react'
import Item from './list-item'
import DatePicker from 'react-datepicker'
import { getMyInvoices } from './../actions'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import { compareDates } from '../../../common/compare-dates'


const searchDiv = {
  'margin': 'auto',
  'width': '100%',
  'padding': '10px'
}
const searchButton = {
  'margin': 'auto',
  'width': '100%',
  'padding': '10px'
}
const header = {
  'paddingLeft': '10px',
  'float': 'left'
}
const inputStyle = {
  'borderRadius': '5px',
  'height': '30px'
}
var resCount = 0

const initialState = {
  quantity: 0,
  Invoices: [],
  ordDateFrom: moment().subtract(1, 'years').toDate(),
  ordDateTo: new Date(),
  invDateFrom: moment().subtract(1, 'years').toDate(),
  invDateTo: new Date(),
  shipDateFrom: moment().subtract(1, 'years').toDate(),
  shipDateTo: new Date(),
  search: undefined,
  invNum: undefined,
  acctNo: undefined,
  searchPO: undefined,
  partNum: undefined
}


const Invoices = ({ invoices, session }) => {

  const [state, setState] = React.useState(initialState)

  const handleInvoiceClick = (e) => {
    e.preventDefault()
  }

  const handleInvoiceSearch = () => {
    // console.log({ ...state })
    getMyInvoices({
      invNum: state.invNum,
      acctNo: state.acctNo ,
      searchPO: state.searchPO ,
      partNum: state.partNum ,
      ordDateFrom: state.ordDateFrom,
      ordDateTo: state.ordDateTo,
      invDateFrom: state.invDateFrom,
      invDateTo: state.invDateTo,
      shipDateFrom: state.shipDateFrom,
      shipDateto: state.shipDateTo
    })

    resCount = 0
    // setState(state) // TODO: why is this here?
  }

  const generateError = () => {
    let response = ""
    if (state.partNum !== undefined && state.partNum) response += "No Part# found.\n"
    if (state.searchPO !== undefined && state.searchPO) response += "No PO# found.\n"
    if (response) response += "Please revise your search criteria."
    return response
  }

  // React.useEffect(() => {
  //   handleInvoiceSearch()
  // }, [handleInvoiceSearch])

  // const handleOrdDateToSelect = (date) => {
  //   setState({ ...state, ordDateTo: date })
  // }

  const handleInvDateToSelect = (date) => {
    setState({ ...state, invDateTo: date })
  }

  const handleShipDateToSelect = (date) => {
    setState({ ...state, shipDateTo: date })
  }

  // const handleOrdDateFromSelect = (date) => {
  //   setState({ ...state, ordDateFrom: date })
  // }

  const handleInvDateFromSelect = (date) => {
    setState({ ...state, invDateFrom: date })
  }

  const handleShipDateFromSelect = (date) => {
    setState({ ...state, shipDateFrom: date })
  }

  const handleSearchChange = (e) => {
    setState({ ...state, invNum: e.target.value })
  }

  const handleAcctNoChange = (e) => {
    setState({ ...state, acctNo: e.target.value })
  }

  const handleSearchPOChange = (e) => {
    setState({ ...state, searchPO: e.target.value })
  }

  const handlePartNumChange = (e) => {
    setState({ ...state, partNum: e.target.value })
  }

  const { user } = session

  const hasPrivilegedRole = user && user.roles && user.roles.some(role => { return ['DataProvider', 'Administrator', 'Sales'].includes(role) })

  const invoiceData = invoices && invoices.data && invoices.data?.length > 0 ? invoices.data : []

  const __invoiceData = invoiceData
    .sort((a, b) => compareDates(b.attributes.metadata.orderDate, a.attributes.metadata.orderDate))
    .filter(invoice => {
      resCount++
      return true
    })

  return (
    <div className="Invoices">
      <br />
      <h1 style={header}>Invoices</h1>
      <br />
      <div className="row form-group" style={searchDiv}>
        <div className="col-xl-5">
          <div className="row">
            {user.managesMultipleLocales ? <div className="col-lg-3 col-xs-12">Invoice #: <input className="form-control" style={inputStyle} title="search" type="text" onChange={handleSearchChange}/></div> : <div className="col-lg-4 col-xs-12">Invoice #: <input className="form-control" style={inputStyle} title="search" type="text" onChange={handleSearchChange} /></div>}
            {user.managesMultipleLocales ? <div className="col-lg-3 col-xs-12">Account #: <input className="form-control" style={inputStyle} title="acctNo" type="text" onChange={handleAcctNoChange}/></div> : <span />}
            {user.managesMultipleLocales ? <div className="col-lg-3 col-xs-12">PO # <input className="form-control" style={inputStyle} title="searchPO" type="text" onChange={handleSearchPOChange}/></div> : <div className="col-lg-4 col-xs-12">PO # <input className="form-control" style={inputStyle} title="searchPO" type="text" onChange={handleSearchPOChange} /></div>}
            {user.managesMultipleLocales ? <div className="col-lg-3 col-xs-12">Part #: <input className="form-control" style={inputStyle} title="partNum" type="text" onChange={handlePartNumChange}/></div> : <div className="col-lg-4 col-xs-12">Part #: <input className="form-control" style={inputStyle} title="partNum" type="text"onChange={handlePartNumChange} /></div>}
          </div>
        </div>
        <div className="col-xl-7">
          <div className="row">
            <div className="col-md-3 col-sm-6">Invoice Date Start:
              <DatePicker
                className="datePicker"
                selected={state.invDateFrom}
                placeholderText="MM/dd/yyyy"
                onSelect={handleInvDateFromSelect}
                onChange={handleInvDateFromSelect}
                isClearable={true}
                dateFormat="MM/dd/yyyy"
              />
            </div>
            <div className="col-md-3 col-sm-6">Invoice Date End:
              <DatePicker
                className="datePicker"
                selected={state.invDateTo}
                placeholderText="MM/dd/yyyy"
                onSelect={handleInvDateToSelect}
                onChange={handleInvDateToSelect}
                isClearable={true}
                dateFormat="MM/dd/yyyy"
              />
            </div>
            <div className="col-md-3 col-sm-6">Shipping Date Start:
              <DatePicker
                className="datePicker"
                selected={state.shipDateFrom}
                placeholderText="MM/dd/yyyy"
                onSelect={handleShipDateFromSelect}
                onChange={handleShipDateFromSelect}
                isClearable={true}
                dateFormat="MM/dd/yyyy"
              />
            </div>
            <div className="col-md-3 col-sm-6">Shipping Date End:
              <DatePicker
                className="datePicker"
                selected={state.shipDateTo}
                placeholderText="MM/dd/yyyy"
                onSelect={handleShipDateToSelect}
                onChange={handleShipDateToSelect}
                isClearable={true}
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row form-group" style={searchButton}>
        <div className="col-md-1 col-sm-6">
          <button className="form-control" onClick={handleInvoiceSearch}>Search</button>
        </div>
      </div>
      {resCount ? (<table className="table table-striped table-sm table-bordered atlas-detailed-result-table">
        <thead className="InvoicesItem">
          <tr>
            <th className="InvoicesItem-cell">
              Invoice #
            </th>
            {user.managesMultipleLocales ? <th className="InvoicesItem-cell">Account #</th> : null}
            <th className="InvoicesItem-cell">
              PO #
            </th>
            <th className="InvoicesItem-cell">
              Order Date
            </th>
            <th className="InvoicesItem-cell">
              Shipping Date
            </th>
            <th className="InvoicesItem-cell">
              Total
            </th>
          </tr>
        </thead>
        <tbody onClick={handleInvoiceClick}>
          {mapInvoiceData(__invoiceData, session, hasPrivilegedRole)}
        </tbody>
      </table>) : (
        <div>
          <br />
          <h6> {(generateError() ? <pre> {generateError()} </pre> : "No Invoices found.")} </h6>
        </div>)}
    </div>
  )
}



export default Invoices


const mapInvoiceData = (invoiceData, session, hasPrivilegedRole) => {
  if (invoiceData) {
    return invoiceData.map((invoice, index) => {
      return (
        <Item key={index} session={session} hasPrivilegedRole={hasPrivilegedRole} metadata={invoice.attributes.metadata} _id={invoice.attributes._id}/>
      )
    })
  }
  return null
}

// eslint-disable-next-line no-unused-vars
var invoiceSchema = {
 id: 'string',
  type: 'invoice',
  attributes: {
    aliases: null,
    chunkSize: 0,
    contentType: 'binary/octet-stream',
    filename: 'string',
    length: 0,
    md5: 'string',
    uploadDate: '2019-10-10T00:00:00.000Z',
    _id: 'string',
    metadata: {
      acctNo: 'string',
      custPo: 'string',
      invDate: '2019-10-10T00:00:00.000Z',
      invNum: 0,
      orderDate: '2019-10-10T00:00:00.000Z',
      parts : [
        {
          distributorPartNumber: 'string',
          ordered: 0,
          shipped: 0
        }],
      shipDate: '2019-10-10T00:00:00.000Z',
      shipTo: 'string',
      soldTo: 'string',
      subTotal: 0,
      totalPrice: 0,
      type: 'invoice'
    }
  }
}


// import React from 'react'

// export default function Invoices () {
//   return (
//     <div>
//     <p> 
//       Invoice are currently not available. If you require any copies, please contact your local branch.
//     </p>
//     </div>
//   )
// }
