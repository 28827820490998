import React from 'react'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'

export default class ListToggle extends React.Component {
  handleSwitchListView (event) {
    this.props.onSwitchListView()
  }

  render () {
    const listView = this.props.listView

    const compactListViewClassnames = classnames('atlas-list-toggle-option', {
      'atlas-list-toggle-option--active': listView === 'compact'
    })

    const detailedListViewClassnames = classnames('atlas-list-toggle-option', {
      'atlas-list-toggle-option--active': listView === 'detailed'
    })

    const divStyle = {
      position: 'fixed',
      top: 100,
      right: '0',
      width:'100%',
      padding:'20px',
      paddingTop: '40px'
    };
    

    return (
      <nav className="atlas-list-nav" style={divStyle}>
        <button
          className="atlas-list-toggle"
          onClick={this.handleSwitchListView.bind(this)}
        >
          <div className={compactListViewClassnames}>
            <FormattedMessage
              defaultMessage="Compact"
              id="Compact"
            />
          </div>
          <div className={detailedListViewClassnames}>
            <FormattedMessage
              defaultMessage="Detailed"
              id="Detailed"
            />
          </div>
        </button>
      </nav>
    )
  }
}
