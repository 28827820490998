import {
  CREATE_DEALER,
  GET_DEALER_PENDING,
  GET_DEALER_SUCCESSFUL,
  GET_DEALER_ERROR,
  SAVE_DEALER_PENDING,
  SAVE_DEALER_SUCCESSFUL,
  SAVE_DEALER_ERROR,
  DELETE_DEALER_PENDING,
  DELETE_DEALER_SUCCESSFUL,
  DELETE_DEALER_ERROR
} from './constants'
import { dispatch, getState } from '../store'
import page from 'page'

export function saveDealer (data) {
  const isNew = !data.id

  let url
  if (!isNew) {
    url = `/api/dealers/${data.id}`
  } else {
    url = '/api/dealers'
  }

  dispatch({
    type: SAVE_DEALER_PENDING
  })

  fetch(url, {
    method: 'post',
    headers: {
      'x-session-id': getState().session.id,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: SAVE_DEALER_SUCCESSFUL,
          response: json
        })
        page('/dealers')
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: SAVE_DEALER_ERROR,
        error
      })
    })
}

export function getDealer (dealerId) {
  if (dealerId === 'new') {
    dispatch({
      type: CREATE_DEALER
    })
    return
  }

  dispatch({
    type: GET_DEALER_PENDING
  })

  fetch(`/api/dealers/${dealerId}`, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_DEALER_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: GET_DEALER_ERROR,
        error
      })
    })
}

export function deleteDealer (dealerId) {
  dispatch({
    type: DELETE_DEALER_PENDING
  })

  fetch(`/api/dealers/${dealerId}`, {
    method: 'delete',
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        page('/dealers')
        dispatch({
          type: DELETE_DEALER_SUCCESSFUL
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: DELETE_DEALER_ERROR,
        error
      })
    })
}
