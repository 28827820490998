import {
    GET_MESSAGES,
    RECEIVE_MESSAGES,
    CLEAR_MESSAGES,
    EMAIL_SEARCH_LIMIT
} from './constants'

var initialState = {
    data: [],
    meta: {
        total: 0,
        limit: 0,
        offset: 0
    },
    isLoading: false,
    wasLoadSuccessful: false,
    hasMore: false,
    error: null
}

function hasMore(response) {
    return (
        response.data.length !== 1 &&
        response.meta.offset + EMAIL_SEARCH_LIMIT < response.meta.total
    )
}

export default function messages(state = initialState, { type, offset, response }) {
    switch (type) {
        case GET_MESSAGES:
            return {
                ...state,
                wasLoadSuccessful: offset !== 0,
                isLoading: true,
                data: offset === 0 ? [] : state.data,
            }

        case RECEIVE_MESSAGES:
            return {
                ...state,
                ...response,
                isLoading: false,
                wasLoadSuccessful: true,
                hasMore: hasMore(response),
                data: response.meta.offset === 0
                    ? response.data
                    : state.data.concat(response.data)
            }

        case CLEAR_MESSAGES:
            return {
                ...state,
                data: []
            }

        default:
            return state
    }
}
