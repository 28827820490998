import React from 'react'
import createReactClass from 'create-react-class'
import EmailAdminMenu from '../../email-admin-menu/components'
import SearchBar from './search-bar'
import moment from 'moment'
import page from 'page'

export default createReactClass({
    displayName: 'EmailAdminSent',

    gotoMessage(e) {
        const messageId = e.currentTarget.getAttribute('data-item');
        page('/email-admin-sent-message/' + messageId)
    },

    messages(data) {
        return data.map(message => {
            // set up recipient column
            let recipientLanguageValue
            switch (message.data.recipientLanguage) {
                case "english":
                    recipientLanguageValue = "English Customers (" + message.data.noOfRecipients + ")"
                    break
                case "french":
                    recipientLanguageValue = "French Customers (" + message.data.noOfRecipients + ")"
                    break
                case "accounting":
                    recipientLanguageValue = "Accounting Customers (" + message.data.noOfRecipients + ")"
                    break
                default:
                    recipientLanguageValue = "All Customers (" + message.data.noOfRecipients + ")"
            }

            // format modified date
            const date = message.data.modified ? moment(
                message.data.modified
            ).format('YYYY-MM-DD') : 'undefined'
            return (
                <tr key={message.data._id} data-item={message.data._id} onClick={this.gotoMessage}>
                    <td>{date}</td>
                    <td>{message.data.subject}</td>
                    <td>{recipientLanguageValue}</td>
                </tr>

            );
        });
    },

    render() {
        return (
            <div>
                <EmailAdminMenu></EmailAdminMenu>
                <div className="atlas-email-admin-main">
                    <SearchBar changeSearchQuery={this.props.changeSearchQuery} isOnline={true}></SearchBar>
                    <div className="atlas-inbox-grid-main">
                        <table className="atlas-inbox-table table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Subject</th>
                                    <th>Recipient</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.messages(this.props.emails.data)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
})