import uniq from 'lodash/uniq'

// if two lines in cart share same partNumber, combine them into one item
// with cumulative quantity

export default function (items) {
  const partNumbers = uniq(items.map(item => item.distributorPartNumber))
  return partNumbers.map(partNumber => {
    const itemsWithPartNumber = items.filter(
      item => item.distributorPartNumber === partNumber
    )
    const totalQuantity = itemsWithPartNumber.reduce((quantity, item) => {
      return quantity + item.quantity
    }, 0)
    return {
      ...itemsWithPartNumber[0],
      quantity: totalQuantity
    }
  })
}
