import React from 'react'
import classNames from 'classnames'
import getCategoryWithAncestors from '../../common/get-category-with-ancestors'
import { FormattedMessage } from 'react-intl'
import { findTranslation } from '../../common/translation'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'CategoryBreadcrumbs',

  render () {
    const language = this.props.language
    const isAdmin = this.props.isAdmin
    const categories = this.props.categories.data
    const categoryId = this.props.categoryId
    const categoriesInPath = getCategoryWithAncestors(categories, categoryId)
    const breadcrumbs = categoriesInPath.map(category => {
      const classnames = classNames({
        active: categoryId === category.id
      })
      return (
        <li className={classnames} key={category.id}>
          <a href={`/?categoryId=${category.id}`}>
            {findTranslation(category.attributes.name, language.code)}
          </a>
        </li>
      )
    })

    if (!breadcrumbs.length) {
      return null
    }

    return (
      <div>
        {isAdmin &&
          <a
            className="atlas-small-button"
            href={`/categories/${categoryId}`}
            style={{ float: 'right' }}
          >
            <FormattedMessage
              defaultMessage="Edit category"
              id="Edit category"
            />
          </a>}
        <ol className="breadcrumb category-items">
          {breadcrumbs}
        </ol>
      </div>
    )
  }
})
