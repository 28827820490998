import { getState } from '../store'

export async function download(lang, query) {
    const path = `/api/users-search?lang=${lang}&offset=0&limit=3000&q=${JSON.stringify(query)}`
    console.log(path)
    const data = await fetch(path, {
        headers: {
            'x-session-id': getState().session.id
        }
    })
    const result = await data.json();
    return result
}