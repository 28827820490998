import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import { getCategory, saveCategory, deleteCategory } from './actions'
import App from '../common/app'
import Category from './components/index'
let unsubscribe = function () {}

function render () {
  ReactDOM.render(
    <App active="category" {...getState()} {...appActions}>
      <Category
        {...getState().category}
        categories={getState().categories}
        session={getState().session}
        deleteCategory={deleteCategory}
        saveCategory={saveCategory}
      />
    </App>,
    document.querySelector('#app')
  )
}

export function enter (context, next) {
  unsubscribe = subscribe(render)
  if (context.params.categoryId !== 'new') {
    getCategory(context.params.categoryId)
  }
  render()
}

export function exit (context, next) {
  unsubscribe()
  next()
}
