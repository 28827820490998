import {
  REQUEST_PASSWORD_RESET_PENDING,
  REQUEST_PASSWORD_RESET_SUCCESSFUL,
  REQUEST_PASSWORD_RESET_ERROR
} from './constants'
import { dispatch } from '../store'

export function requestPasswordReset (email) {
  dispatch({
    type: REQUEST_PASSWORD_RESET_PENDING
  })

  fetch('/api/request-password-reset', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email })
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: REQUEST_PASSWORD_RESET_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.log('parsing failed', error)
      dispatch({
        type: REQUEST_PASSWORD_RESET_ERROR,
        error
      })
    })
}
