import {
  GET_FILES_PENDING,
  GET_FILES_SUCCESSFUL,
  GET_FILES_ERROR
} from './constants'
import { dispatch, getState } from '../store'

export async function postFile (s3Result, file) {
  console.log('Finished uploading: ', s3Result)
  const data = {
    id: file.name,
    tmpFilename: s3Result.filename,
    size: file.size,
    type: file.type,
    fileLastModified: new Date(file.lastModified).toISOString()
  }

  try {
    const response = await fetch('/api/files', {
      method: 'post',
      headers: {
        'x-session-id': getState().session.id,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    if (!response.ok) {
      throw new Error('Error when post file')
    }
    console.log({ response })
    console.log('File posted')
  } catch (error) {
    console.log({ error })
  }
}

function messageHandler (event) {
  dispatch({
    type: GET_FILES_SUCCESSFUL,
    response: JSON.parse(event.data)
  })
}

function errorHandler (event) {
  dispatch({
    type: GET_FILES_ERROR
  })
  console.log(event)
}

let source

export function getFiles (options = {}) {
  dispatch({
    type: GET_FILES_PENDING
  })

  if (!window.EventSource) {
    alert('Not supported on this browser.')
    return
  }

  source = new EventSource(`/api/files?x-session-id=${getState().session.id}`)

  // source.addEventListener('open', openHandler, true)
  source.addEventListener('change', messageHandler)
  source.addEventListener('error', errorHandler)
}

export function exitFiles () {
  if (!window.EventSource) {
    return
  }
  source.removeEventListener('change', messageHandler)
  source.removeEventListener('error', errorHandler)
  source.close()
}
