/**
 * Opens a blob in a new tab
 * @param {*} blob 
 * @param {*} fileType
 * @param {*} filename
 */
export const openInBlob = (blob,  fileType, filename = 'downloadedfile') => {
   var blobObject = new Blob([blob], { type: fileType })
    var url = URL.createObjectURL(blobObject)
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Microsoft Edge and Internet Explorer 10-11 only
      window.navigator.msSaveOrOpenBlob(blob, filename)  
    }
    else {
      window.open(url)  
    }
      
}