export const GET_CATEGORY_PENDING = 'GET_CATEGORY_PENDING'
export const GET_CATEGORY_SUCCESSFUL = 'GET_CATEGORY_SUCCESSFUL'
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR'

export const SAVE_CATEGORY_PENDING = 'SAVE_CATEGORY_PENDING'
export const SAVE_CATEGORY_SUCCESSFUL = 'SAVE_CATEGORY_SUCCESSFUL'
export const SAVE_CATEGORY_ERROR = 'SAVE_CATEGORY_ERROR'

export const DELETE_CATEGORY_PENDING = 'DELETE_CATEGORY_PENDING'
export const DELETE_CATEGORY_SUCCESSFUL = 'DELETE_CATEGORY_SUCCESSFUL'
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR'
