import {
    GET_INSIGHT_MESSAGE_SUCCESSFUL,
    LOAD_INSIGHT_MESSAGES_PENDING,
    LOAD_INSIGHT_MESSAGES_SUCCESSFUL,
} from './constants'
import { dispatch, getState } from '../store'
import moment from 'moment'

export async function getInsightMessages(pagenum = 1, pageSize = 8, groupedId, sortBy) {
    //save groupedId from params on loading data initiailly
    if (groupedId) {
        getState().insightMessages.groupedId = groupedId
    }

    if (getState().insights.totalData) {
        // get the messages by using a key consisting of subject and date
        const totalData = getState().insights.totalData.filter((insight) => {
            const key = moment(insight.data.BouncedAt).format('YYYY-MM-DD') + insight.data.Subject
            return key === groupedId
        });
        const from = (pagenum - 1) * pageSize
        const to = from + pageSize
        const emailData = totalData.slice(from, to)

        // find the account name and account id from database
        const emailDataWithAccountInfo = await getAccountInfo(emailData)

        dispatch({
            type: GET_INSIGHT_MESSAGE_SUCCESSFUL,
            response: emailDataWithAccountInfo
        })
    }
}

async function getAccountInfo(emails) {
    const dealers = await fetchDealers(emails, 1, 2)

    // find account info for each email
    for (const email of emails) {
        const dealerInfo = dealers.find((dealer) => {
            return dealer.attributes.email === email.data.Email
        })

        email.data.name = "Not found"
        email.data.accountNumber = "Not found"
        if (dealerInfo) {
            email.data.name = dealerInfo.attributes.name
            email.data.accountNumber = dealerInfo.attributes.accountNumber
        }
    }
    return emails
}

async function fetchDealers(emails) {

    //extract the emails
    let dealerEmails = emails.map(email => email.data.Email)
    const path = `/api/users-search?q=${JSON.stringify(dealerEmails)}`

    let response
    try {
        response = await fetch(path, {
            headers: {
                'x-session-id': getState().session.id
            }
        })
        const responseJson = await response.json()
        return responseJson.data;
    } catch (err) {
        console.log(err)
    }
}

/**
 * 
 Get bounces, messages and combine with read stats
 */
export async function loadUndeliveredMessages(page, pageSize, id) {
    if (!page) page = 1;
    if (!pageSize) pageSize = 8;

    dispatch({
        type: LOAD_INSIGHT_MESSAGES_PENDING,
        response: id
    })

    const offset = pageSize * (+page - 1)

    // get bounces from postmark
    const bouncesResponse = await fetch(`/api/insights`, {
        headers: {
            'x-session-id': getState().session.id
        },

    })
    const bounces = await bouncesResponse.json()
    //console.log(bounces)

    // get message
    const path = `/api/messages/${id}`
    const messageResponse = await fetch(path, {
        headers: {
            'x-session-id': getState().session.id
        }
    })
    const message = await messageResponse.json()

    // combine message recipients and read stats
    let lines = []
    const recipients = message.data.recipients.slice(offset, pageSize)
    //console.log(recipients)
    for (let recipient of recipients) {
        //console.log(recipient)
        let bounce = bounces.data.find(bounceItem => {
          //  console.log(bounceItem)
            return bounceItem.data.Email === recipient.email
        })
        //console.log(bounce)

        // create the line
        if (bounce) {
            const line = {
                _id: message.data._id,
                date: moment(message.data.modified).format('YYYY-MM-DD'),
                subject: message.data.subject,
                accountNumber: recipient.accountNumber,
                accountName: recipient.accountName,
                response: 'unread',
                email: recipient.email
            }
            lines.push(line)
        }

    }
    
    dispatch({
        type: LOAD_INSIGHT_MESSAGES_SUCCESSFUL,
        response: lines
    })
}

