const CLEARANCE_CATEGORY_ID = 'ffefca7d-a309-4a7c-8b93-34c7d76a9712'
const NEW_PRODUCTS_CATEGORY_ID = '9daf56fb-e712-4daf-81bb-e28d09377a0d'

/**
 * Sorts products by new or clearance
 * @param {*} product1 
 * @param {*} product2 
 * @param {*} orderBy 
 * @returns 
 */
export function sortByNewOrClearance (product1, product2, orderBy = 'new') {
    if(!orderBy || !product1 || !product2 || orderBy === null || orderBy === undefined){
        return 0
    }
  if (orderBy === 'new') {
    return sortByNew(product1, product2)
  } else if (orderBy === 'clearance') {
    return sortByClearance(product1, product2)
  }
}

function sortByNew (product1, product2) {
    if(product1 && product2){
        const product1IsNew = product1.attributes.categoryIds?.includes(NEW_PRODUCTS_CATEGORY_ID)
        const product2IsNew = product2.attributes.categoryIds?.includes(NEW_PRODUCTS_CATEGORY_ID)

        if (product1IsNew && !product2IsNew) {
            return -1
        }
        if (!product1IsNew && product2IsNew) {
            return 1
        }

        return 0
    }
    return 0
}

function sortByClearance (product1, product2) {
   if(product1 && product2){
        const product1IsClearance = product1.attributes.categoryIds?.includes(CLEARANCE_CATEGORY_ID)
        const product2IsClearance = product2.attributes.categoryIds?.includes(CLEARANCE_CATEGORY_ID)

        if (product1IsClearance && !product2IsClearance) {
            return -1
        }
        if (!product1IsClearance && product2IsClearance) {
            return 1
        }

        return 0
   }
    return 0
}