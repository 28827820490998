import React from 'react'
export default function Eflyers () {
  return (
    <div style={{ margin: 18 }}>
      <h1>E-Flyer : English</h1>
      <tr>

	  <li>

	    No Available Flyer at this time.

          </li>

	
	</tr>
      <div style={{ margin: 16 }}>
        <h1>E-Flyer : French</h1>
        <tr>
          
 	 <li>
           
	  Aucun dépliant disponible pour le moment.
            
        </li>
	  
	 </tr>
      </div>
    </div>
  )
}
