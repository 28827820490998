import React from "react";
import ReactDOM from "react-dom";
import { getState, subscribe } from "../store";
import appActions from "../actions/app";
import App from "../common/app";
import InvoicesComponent from "./components/index";
import { getMyInvoices } from "./actions";

function render(mountNode) {
  return function () {
    ReactDOM.render(
      <App active="invoices" {...getState()} {...appActions}>
        <InvoicesComponent {...getState()} {...appActions} />
      </App>,
      mountNode
    );
  };
}

let unsubscribe = function () {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (app, mountNode) {
  app("/invoices", (context) => {
    unsubscribe = subscribe(render(mountNode));
    if (getState().session.id) {
      getMyInvoices();
    }

    render(mountNode)();
  });

  app.exit("/invoices", (context, next) => {
    unsubscribe();
    next();
  });
}
