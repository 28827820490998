export const CREATE_DEALER = 'CREATE_DEALER'

export const GET_DEALER_PENDING = 'GET_DEALER_PENDING'
export const GET_DEALER_SUCCESSFUL = 'GET_DEALER_SUCCESSFUL'
export const GET_DEALER_ERROR = 'GET_DEALER_ERROR'

export const SAVE_DEALER_PENDING = 'SAVE_DEALER_PENDING'
export const SAVE_DEALER_SUCCESSFUL = 'SAVE_DEALER_SUCCESSFUL'
export const SAVE_DEALER_ERROR = 'SAVE_DEALER_ERROR'

export const DELETE_DEALER_PENDING = 'DELETE_DEALER_PENDING'
export const DELETE_DEALER_SUCCESSFUL = 'DELETE_DEALER_SUCCESSFUL'
export const DELETE_DEALER_ERROR = 'DELETE_DEALER_ERROR'
