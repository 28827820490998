const fr = {
  Products: 'Produits',
  'RV Partfinder': 'RV Partfinder',
  Cart: 'Panier',
  'New Products': 'Nouveaux Produits',
  'E-Flyers': 'Circulaire Électronique',
  Clearance: 'Liquidation',
  About: 'À Propos De Nous',
  Logout: 'Déconnection',
  'Search products': 'Recherche de Pièce',
  Retail: 'Détail',
  'Page Size': 'Taille de la page',
  Availability: 'Disponibilité',
  'Stock #': 'Numéro de Produit',
  'Mfg #': 'Mfg #',
  'Please review your order': 'Vérifier votre commande',
  'Order notes': 'Remarques',
  Quantity: 'Quantité',
  'Fillable subtotal:': 'Prix Calculé (en stock):',
  'Subtotal:': 'Sous-Total:',
  Product: 'Produit',
  'Extended price': 'Prix calculé',
  'Unit price': 'Prix unitaire',
  'Purchase order': 'Numéro de commande',
  Admin: 'Admin',
  Users: 'Users',
  Categories: 'Categories',
  'Edit product': 'Edit product',
  'Edit category': 'Edit category',
  'Web orders': 'Commandes Enligne',
  Remove: 'Retirer',
  'Reset password': 'Réinitialiser le mot de passe',
  Profile: 'Profil',
  'Switch language': 'Changer de langue',
  'Retail catalogue': 'Catalogue',
  'Dealer price': 'Prix de détail',
  'Role': 'Rôle',
  'Action': 'Action',
  Connecting: 'De liaison',
  'B2C Analytics': 'B2C Analytics',
  Specials: 'Specials',
  'Contact Us': 'Nous contacter',
  'Catalogue Page #': 'Page de catalogue #',
  Marketing: 'Commercialisation',
  'API log': 'API log',
  'customPriceCheckBox': 'Atlas Recommended Prices',
  'Terms of Sale': 'Conditions de Vente',
  'retailPricingOptions': 'Options De Prix De Détail',
  'rfq': 'Demande De Prix',
  'recommendedPriceCheckBox':'Prix De Détail Suggéré',
  'submit':'Soumettre',
  'pricingChangeNote':'Les modifications seront immédiatement visibles sur B2C. Pour que les modifications soient visibles sur votre profil B2B, déconnectez-vous et reconnectez-vous.',
  Inbox: 'Inbox',
  'Subject': "Sujet",
  'Date': 'Date',
  'Attachments':'Pièces Jointes',
  'Body': 'Corps',
  'First':'Premier',
  'First Name': 'Prénom',
  'Last Name': 'Nom de famille',
  'Account Number': 'Numéro de compte',
  'User Management': 'Gestion des utilisateurs',
  'Add User': 'Ajouter un utilisateur',
  'Request Delete': 'Demande de suppression',
  Compact: 'Compacte',
  'Add to cart': 'Ajouter au Panier',
  Detailed: 'Détaillée',
  'Close': 'Fermer',
  'YOU HAVE AN IMPORTANT EMAIL FROM ATLAS': 'VOUS AVEZ UN E-MAIL IMPORTANT D\'ATLAS',
  'Kindly click on the button below to read the email': 'Veuillez cliquer sur le bouton ci-dessous pour lire l\'e-mail.',
  'Go to inbox': 'Aller à la boîte de réception',
  'Last': 'Dernier',
  'Fitlist': 'Chartes De Applications',
  'Flip PDF Catalogue': 'Catalogue PDF',
  'Dealer': 'Concessionnaire',
  'Locks': 'Serrures',
  'Brake Controls': 'Contrôles des freins',
  'Jacks': 'Vérins',
  'Tie Downs / Clamps': 'Systéme de arrimage / Attaches',
  'Scissor Jacks / Stabllizers': 'Stabilisateurs Ciseaux / Stabilisateurs',
  'Tailgates / Fender Skirts / Tool Box': 'Hayon / Contour de Aile / Boîte de Rangements',
  '2021 October Antifreeze Flyer (PDF)': 'Brochure Antigel Octobre 2021 (PDF)',
  '2021 August New Products Flyer (PDF)': 'Brochure Nouveaux Produits Aout 2021 (PDF)',
  'Thetford Consumer Rebate (PDF)': 'Rabais Concessionnaire Thetford (PDF)',
  '2021 Generator Special (PDF)': 'Spéciale Génératrice 2021 (PDF)',
  '2022 ConsumerNet Retail Marketing Program (PDF)': 'Programme de Marketing pour vente au détail ConsumerNet 2021 (PDF)',
  '[FR] 2022 RV Partfinder Subscription Agreement (PDF)': '[FR] Formulaire de Inscription 2021 RV Partfinder (PDF)',
  '[FR] Website Access Request Form (PDF)': 'Formulaire de demande de accés au site Internet (PDF)',
  'Carded Products (Brochure)': 'Produits sur Carte',
  'Hyundai 2020 Brochure (English)': '[EN] Hyundai 2020 Brochure',
  'Hyundai 2020 Brochure (French)': '[FR] Hyundai 2020 Brochure',
  'Order List': 'Liste de conmmande',
  'Special Orders': 'Commandes Spéciale',
  'My profile': 'Mon profil',
  'Invoices': 'Factures',
  'Statements': 'Etats de comptes',
  'Add part number': 'Inscrire numéro de piéces',
  'FX Subtotal': 'FX Sous-Total',
  'required': 'Obligatoire'

}

export default fr