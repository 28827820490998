import React from 'react'

export default function RGA () {
  return (
    <div style={{ margin: 18 }}>
      <h1>Return Goods Authorization</h1>
      <div id="content-inner">
        <div>
          <p>
            <a href="http://xcedo1.s3.amazonaws.com/atlas/original/Return-Goods-Authorization-fillin.pdf">
              <img src="http://xcedo1.s3.amazonaws.com/atlas/128x128/Return-Goods-Authorization-fillin.png" alt='' />
              <br />Click Here for English
            </a>
          </p>

          <p>
            <a href="http://xcedo1.s3.amazonaws.com/atlas/original/Return-Goods-Authorization-French-fillin.pdf">
              <img src="http://xcedo1.s3.amazonaws.com/atlas/128x128/Return-Goods-Authorization-French-fillin.png" alt='' />
              <br />Click Here for French
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

