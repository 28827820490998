import {
  GET_MY_WEB_ORDERS_PENDING,
  GET_MY_WEB_ORDERS_SUCCESSFUL,
  GET_MY_WEB_ORDERS_ERROR
} from './constants'

var initialState = {
  pending: true,
  successful: true,
  error: null,
  data: [],
  meta: {
    page: {
      offset: 0,
      limit: 20,
      total: null
    }
  }
}

export default function webOrders (
  state = initialState,
  { type, response, error }
) {
  switch (type) {
    case GET_MY_WEB_ORDERS_PENDING:
      return {
        ...state,
        pending: true,
        successful: false,
        error: null
      }
    case GET_MY_WEB_ORDERS_SUCCESSFUL:
      return {
        ...state,
        data: response.data,
        meta: response.meta,
        pending: false,
        successful: true,
        error: null
      }
    case GET_MY_WEB_ORDERS_ERROR:
      return {
        ...state,
        pending: false,
        successful: false,
        error: response
      }
    default:
      return state
  }
}
