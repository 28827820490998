import getQuantityOfWarehouse from './get-quantity-of-warehouse'

export default function ({
  primaryWarehouseCode,
  inventory,
  quantity,
  warehouses,
  isPrivileged
}) {
  if (primaryWarehouseCode == 3) {
    let resp = []
    resp.push({warehouseCode: warehouses[2].warehouseCode, name: warehouses[2].name, quantity: getQuantityOfWarehouse(inventory, warehouses[2].warehouseCode)})
    resp.push({warehouseCode: warehouses[0].warehouseCode, name: warehouses[0].name, quantity: getQuantityOfWarehouse(inventory, warehouses[0].warehouseCode)})
    return resp
  } else {
  const primaryWarehouseQuantity = getQuantityOfWarehouse(
    inventory,
    primaryWarehouseCode
  )
  const isQuantityOverPrimaryAvailability = quantity > primaryWarehouseQuantity

  const relevantWarehouses = warehouses.reduce(
    (relevantWarehouses, warehouse) => {
      const isPrimaryWarehouse =
        warehouse.warehouseCode === primaryWarehouseCode
      // if (!isAdministrator && !isPrimaryWarehouse) {
      // return relevantWarehouses
      // }
      const isRelevant =
        !primaryWarehouseQuantity ||
        isQuantityOverPrimaryAvailability ||
        isPrimaryWarehouse
      if (isPrivileged || isRelevant) {
        return relevantWarehouses.concat({
          ...warehouse,
          quantity: getQuantityOfWarehouse(inventory, warehouse.warehouseCode)
        })
      } else {
        return relevantWarehouses
      }
    },
    []
  )
  return relevantWarehouses
  }
}
