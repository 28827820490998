import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import { getDealers, switchDealerStatus, activateAllDealers, deactivateAllDealers, uploadDealers } from './actions'
import App from '../common/app'
import Dealers from './components/index'
let unsubscribe = function () {}

function render () {
  ReactDOM.render(
    <App active="admin" {...getState()} {...appActions}>
      <Dealers {...getState().dealers} onSwitch={switchDealerStatus} onActivateAll={activateAllDealers} onDeactivateAll={deactivateAllDealers} onImport={uploadDealers} />
    </App>,
    document.querySelector('#app')
  )
}

export function enter (context, next) {
  unsubscribe = subscribe(render)
  getDealers()
  render()
}

export function exit (context, next) {
  unsubscribe()
  next()
}
