import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import { searchUsers } from '../users/actions'
import App from '../common/app'
import Emails from './components/index'

let unsubscribe = function () {}

function render () {
  ReactDOM.render(
    <App {...getState()} {...appActions}>
      <Emails {...getState()} {...appActions} changeSearchQuery={searchUsers}/>
    </App>,
    document.querySelector('#app')
  )
}

export function enter (context, next) {
  unsubscribe = subscribe(render)
  // query, page no, page size, lang, column, direction
  searchUsers("", 1, 8,"", "name", true)
  render()
}

export function exit (context, next) {
  unsubscribe()
  next()
}
