import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import App from '../common/app'
import ActivityComponent from './components/index'
import { getActivity } from './actions'

function render (mountNode) {
  return function () {
    ReactDOM.render(
      <App active="web-orders" {...getState()} {...appActions}>
        <ActivityComponent {...getState()} {...appActions} />
      </App>,
      mountNode
    )
  }
}

let unsubscribe = function () {}

export default function (app, mountNode) {
  app('/activity', context => {
    unsubscribe = subscribe(render(mountNode))
    if (getState().session.id) {
      getActivity()
    }

    render(mountNode)()
  })

  app.exit('/activity', (context, next) => {
    unsubscribe()
    next()
  })
}
