import {
  CREATE_DEALER,
  GET_DEALER_PENDING,
  GET_DEALER_SUCCESSFUL,
  GET_DEALER_ERROR,
  DELETE_DEALER_SUCCESSFUL
} from './constants'
var initialState = {
  data: {
    attributes: {}
  },
  isLoading: false,
  wasLoadSuccessful: false,
  error: null
}

export default function dealer (state = initialState, { type, response }) {
  switch (type) {
    case GET_DEALER_PENDING:
      return {
        ...state,
        data: { attributes: {} },
        isLoading: true,
        wasLoadSuccessful: false,
        error: null
      }

    case GET_DEALER_SUCCESSFUL:
      return {
        ...state,
        data: response.data,
        isLoading: false,
        wasLoadSuccessful: true,
        error: null
      }

    case GET_DEALER_ERROR:
      return {
        ...state,
        data: { attributes: {} },
        isLoading: false,
        wasLoadSuccessful: false,
        error: response.error
      }

    case DELETE_DEALER_SUCCESSFUL:
      return initialState

    case CREATE_DEALER:
      return Object.assign({}, state, {
        ...initialState,
        isNew: true
      })

    default:
      return state
  }
}
