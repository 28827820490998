import React from 'react'
import { createSession } from '../actions/session'
import createReactClass from 'create-react-class'

export default createReactClass({
  componentDidMount: async function () {
    this.refs.email.focus()
    this.refs.email.select()

    // if (!navigator.credentials) {
    //   return false
    // }
    // navigator.credentials.get({
    //   password: true
    // })
    // .then((foo) => {
    //   console.log({foo})
    // })
    // .catch((bar) => {
    //   console.log({bar})
    // })
  },

  handleFormSubmit (event) {
    event.preventDefault()
    const email = this.refs.email.value
    const password = this.refs.password.value
    createSession(email, password)
  },

  render () {
    var errmsg
    const errors = this.props.errors.map(function (error) {
      errmsg = error.message
      return (
        <div className="atlas-login-error" key={error.message}>
          {error.message}
        </div>
      )
    })

    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          paddingTop: 150
        }}
      >
        <form
          className="form form-horizontal"
          onSubmit={this.handleFormSubmit}
          ref="form"
          style={{
            padding: 12,
            width: 300,
            margin: 'auto',
            backgroundColor: 'white',
            boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.05)',
            border: '1px solid #d3e0e9',
            borderRadius: 4
          }}
        >
          <fieldset style={{ margin: 6 }}>
            <div style={{ textAlign: 'center', padding: 12 }}>
              <img
                alt="Logo"
                className="atlas-login-logo"
                src="/img/logo.svg"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email/Courriel</label>
              <input
                autoComplete="email"
                autoFocus={true}
                className="form-control"
                defaultValue={window.localStorage.email || ''}
                id="email"
                ref="email"
                required
                type="email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password/Mot de passe</label>
              <input
                autoComplete="password"
                className="form-control"
                id="password"
                ref="password"
                required
                type="password"
              />
            </div>
            <button
              className="btn btn-primary"
              disabled={this.props.isLoading}
              type="submit"
            >
              Login/Connexion
            </button>
            {errors}
            {errmsg !== "User is disabled - Please contact the Atlas accounting department." &&
              <div style={{ fontSize: '0.9rem', marginTop: 12 }}>
                Need a new password?/Vous avez besoin d'un nouveau mot de passe ?
                {' '}
                <a href="/request-password-reset">Click here./ Cliquez ici.</a>
              </div>
            }
          </fieldset>
        </form>
      </div>
    )
  }
})
