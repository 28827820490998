import React from 'react'
import { findTranslation } from '../../../common/translation'
import numeral from 'numeral'
import warehouses from '../../../constants/warehouses'
import getRelevantWarehouses from '../../../common/get-relevant-warehouses'
import imageToSrcset from '../../../transforms/image-to-srcset'
import makeProductDataPresentable from '../../../common/make-product-data-presentable'
import Availability from '../../common/availability'
import createReactClass from 'create-react-class'

import Modal from 'react-modal'
Modal.setAppElement('#app')

export default createReactClass({
  displayName: 'ClearanceCardItem',

  getInitialState () {
    return {
      showLightbox: false,
      quantity: 0
    }
  },

  handleQuantityChange (quantity) {
    this.setState({ quantity })
  },

  handleTriggerLightbox (event) {
    event.preventDefault()
    this.setState({
      showLightbox: true
    })
  },

  handleCloseModal (event) {
    this.setState({
      showLightbox: false
    })
  },

  render () {
    const product = this.props.product
    const language = this.props.language
    const inventory = product.attributes.inventory || []
    const warehouseCode = this.props.warehouseCode
    const clearanceQty = this.props.product.attributes.categoryIds.indexOf("ffefca7d-a309-4a7c-8b93-34c7d76a9712") > -1?this.props.product.attributes.clearanceQty:0
    const userRoles = this.props.userRoles
    const { subscribeToProductUpdates } = this.props
    const isPrivileged = Boolean(
      userRoles &&
        (userRoles.includes('Administrator') || userRoles.includes('Sales'))
    )

    const relevantWarehouses = getRelevantWarehouses({
      primaryWarehouseCode: warehouseCode,
      quantity: this.state.quantity || 0,
      inventory,
      warehouses,
      isPrivileged
    })

    const clearanceCardsStyle = {
        paddingLeft:"15px",
        display:'inline-block',
        minHeight:'200px',
        width:'350px',
        border:'1px solid rgba(0, 0, 0, 0.05)',
        whiteSpace:'nowrap',
        overflow:"hidden"
    }

    let defaultImageSrcset = imageToSrcset(
      product.attributes.images && product.attributes.images[0]
    )

    const {
      title,
      shortDescription,
    } = makeProductDataPresentable({
      title: findTranslation(product.attributes.title, language.code),
      shortDescription: findTranslation(
        product.attributes.shortDescription,
        language.code
      )
    })
    const dealerPrice =
      product.attributes &&
      product.attributes.dealerPrice &&
      numeral(product.attributes.dealerPrice).format('0,0.00')
    const clearancePrice =
      product.attributes &&
      product.attributes.clearancePrice &&
      numeral(product.attributes.clearancePrice).format('0,0.00')
    const flyerPrice =
      product.attributes &&
      product.attributes.flyerPrice &&
      numeral(product.attributes.flyerPrice).format('0,0.00')
    const unitOfMeasure =
      dealerPrice &&
      product.attributes &&
      product.attributes.unitOfMeasure &&
      `(${product.attributes.unitOfMeasure})`

    return (
      <div style={clearanceCardsStyle}>
          {(title || shortDescription) &&
            <h3 style={{"margin": "0px", "top" : "0px", "padding": "0px"}} >
              <a
                href={`/?q=${encodeURIComponent(
                    product.attributes.distributorPartNumber
                )}`}
                >{title || shortDescription}</a>
            </h3>}
            <div style={{height: "80px", width:"180px",display: "inline", position: "relative", "top" : "-40px"}}>
            <a
                href={`/?q=${encodeURIComponent(
                    product.attributes.distributorPartNumber
                )}`} target="_blank"
                ><img
                alt=""
                className="atlas-detailed-list-product-image"
                sizes="100px"
                srcSet={defaultImageSrcset}
                style={{ height: "80px", maxWidth:"180px" }}
              /></a>
            </div>
              {(clearancePrice || flyerPrice || '') &&
                <div style={{'display':'inline-block', padding: "30px"}}>
                <div style={{fontWeight: "bold", color: 'blue'}}>
                  <a
                  href={`/?q=${encodeURIComponent(
                      product.attributes.distributorPartNumber
                  )}`}
                  >{product.attributes.distributorPartNumber || ""}
                  </a>
                </div>
                <div>
                    <span className="atlas-clearance-price">
                    {clearancePrice || flyerPrice || ''}
                    </span>
                    {' '}
                    {unitOfMeasure || ''}
                </div>
                <div className="atlas-regular-price">
                    {dealerPrice || ''}
                </div>
                <Availability
                    isPrivileged={isPrivileged}
                    language={language}
                    primaryWarehouseCode={warehouseCode}
                    isCumulativeSummary={Boolean(clearancePrice)}
                    quantity={this.state.quantity}
                    warehouses={relevantWarehouses}
                    clearanceQty={clearanceQty}
                    subscribeToProductUpdates={subscribeToProductUpdates}
                />
                </div>}
      </div>
    )
  }
})
