import {
  SET_SESSION_ID,
  SESSION_LOADING,
  RECEIVE_SESSION,
  SESSION_ERRORS,
  DESTROY_SESSION,
  SWITCH_LANGUAGE,
  DISMISS_EMAIL_ALERT,
  SWITCH_LIST_VIEW,
  SWITCH_RETAIL_MODE
} from '../constants/action-types'

const english = {
  code: 'en',
  name: 'English'
}

const french = {
  code: 'fr',
  name: 'French'
}

const initialLanguage = localStorage.languageCode === 'fr' ||
  (!localStorage.languageCode && navigator.language.startsWith('fr'))
  ? french
  : english

const initialDismissEmailAlert = localStorage.dismissEmailAlert
const initialState = {
  user: {
    roles: []
  },
  id: null,
  isLoading: false,
  isRetailMode: false,
  language: initialLanguage,
  languageCode: initialLanguage.code,
  dismissEmailAlert: initialDismissEmailAlert,
  listView: localStorage.listView || 'detailed',
  errors: []
}

export default function session (state = initialState, action) {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      localStorage.languageCode = state.languageCode === 'en' ? 'fr' : 'en'
      return {
        ...state,
        languageCode: localStorage.languageCode,
        language: state.languageCode === 'en' ? french : english
      }
    
    case DISMISS_EMAIL_ALERT:
      //localStorage.dismissEmailAlert = state.dismissEmailAlert ? true : false
      return {
        ...state,
        dismissEmailAlert: true
      }

    case SWITCH_LIST_VIEW:
      const listView = state.listView === 'compact' ? 'detailed' : 'compact'
      localStorage.listView = listView
      return {
        ...state,
        listView
      }

    case SWITCH_RETAIL_MODE:
      return {
        ...state,
        isRetailMode: !state.isRetailMode
      }

    case SET_SESSION_ID:
      return Object.assign({}, state, {
        id: action.sessionId || null
      })

    case SESSION_LOADING:
      return { ...state, isLoading: true }

    case SESSION_ERRORS:
      return {
        ...state,
        errors: action.response.errors,
        isLoading: false
      }

    case RECEIVE_SESSION:
      localStorage.email = action.response.included[0].attributes.email
      return Object.assign({}, state, {
        id: action.response.data.id,
        user: action.response.included[0].attributes,
        dealer: action.response.included[1]
          ? action.response.included[1].attributes
          : {},
        errors: [],
        isLoading: false
      })

    case DESTROY_SESSION:
      initialState.dismissEmailAlert = false;
      return initialState

    default:
      return state
  }
}
