import {
  GET_CARTS_PENDING,
  GET_CARTS_SUCCESSFUL,
  GET_CARTS_ERROR,
  GET_ALL_CART_DEALERS
} from './constants'
import { dispatch, getState } from '../store'

function messageHandler (event) {
  dispatch({
    type: GET_CARTS_SUCCESSFUL,
    response: JSON.parse(event.data)
  })
}

function errorHandler (event) {
  dispatch({
    type: GET_CARTS_ERROR
  })
  console.log(event)
}

let source

export function getCarts (options = {}) {
  dispatch({
    type: GET_CARTS_PENDING
  })

  if (!window.EventSource) {
    alert('Not supported on this browser.')
    return
  }

  source = new EventSource(`/api/carts?x-session-id=${getState().session.id}`)

  // source.addEventListener('open', openHandler, true)
  source.addEventListener('change', messageHandler)
  source.addEventListener('error', errorHandler)
}

export function exitCarts () {
  if (!window.EventSource) {
    return
  }
  source.removeEventListener('change', messageHandler)
  source.removeEventListener('error', errorHandler)
  source.close()
}

export function getDealers () {

  if (!getState().session.id) {
    return
  }

  fetch(`/api/user-data`, {
    headers: {
      'x-session-id': getState().session.id
    }
  }).then(async function (response) {
    if (response.ok) {
      const json = await response.json()
      dispatch({
        type: GET_ALL_CART_DEALERS,
        response: json
      })
    }
  }).catch(function (error) {
    console.log('parsing failed', error)
  })
}