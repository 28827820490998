import {
  GET_WEB_ORDER_PENDING,
  GET_WEB_ORDER_SUCCESSFUL,
  GET_WEB_ORDER_ERROR
} from './constants'
import { dispatch, getState } from '../store'

export function getWebOrder (referenceNumber) {
  dispatch({
    type: GET_WEB_ORDER_PENDING
  })

  fetch(`/api/web-orders/${referenceNumber}`, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: GET_WEB_ORDER_SUCCESSFUL,
          response: json
        })
      }
    })
    .catch(function (error) {
      console.error(error)
      dispatch({
        type: GET_WEB_ORDER_ERROR,
        error
      })
    })
}
