import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { FormattedMessage } from 'react-intl'
import Modal from 'react-modal'

export default function UserManagement ({ session, usersData, loadUsers, deleteUser, onRequestUser, onConfirmUpToDate }) {

  const { isLoading, data, dealer } = usersData
  const { user } = session

  // Set to true 'if the dealer data is up to date or they have users to delete
  const dealerDataIsUpToDate = (dealer && dealer.dataIsUpToDate) || (data && data.length > 0 && data.some(user => user.to_delete))

  console.log({ dealer })

  React.useEffect(() => {
    if (loadUsers && user)
      loadUsers(user.email)
  }, [loadUsers, user])

  let subtitle
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal () {
    setIsOpen(true)
  }

  function afterOpenModal () {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00'
  }

  function closeModal () {
    setIsOpen(false)
  }

  function handleUpToDate () {
    Promise.resolve(onConfirmUpToDate(user.email)).then(() => {
      // alert('Thank you for confirming that your data is up to date') ? "" :   window.location.reload()
      if (!alert('Thank you for confirming that your data is up to date')) {
        window.location.reload() // reload the page after the alert
      }
    })
  }

  async function handleCoreDelete (id) {

    var response = await deleteUser(id)
    if (response.success) {
      alert('User deletion request sent successfully')
      loadUsers(user.email)
      return true
    }
    else {
      alert(response.error)
      return false
    }
  }



  const handleDelete = (_user) => {
    confirmAlert({
      title: 'Confirm you want to delete this user',
      message: 'Are you sure you want to delete ' + _user.email + '?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleCoreDelete(_user._id)
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    })
  }
  if (isLoading) {
   return  <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    </div>
  }
  
  return data && (
    <>
      <div className='container mt-4'>
        <div className='row m-4 mt-4' style={{marginTop: '20px'}}>
          <div className='col-lg-10 col-sm-12'>
            <h3 className='mb-1 px-1'>
              <FormattedMessage defaultMessage="User Management" id="User Management" />
            </h3>
            <div class="alert alert-success alert-dismissible show" role="alert">
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <p>
              This page provides you with the ability to manage the users within your organization. In the event that a user is no longer associated with your company, please use the "Request Deletion" button, and Atlas will promptly remove the user from your company's records.<br/>  
              If you recognize that a user is missing or wish to add a new user, please use the "Add New User" button to submit the request. Atlas will then proceed to process the request, and upon approval, the new user will receive a welcoming email from Atlas
              </p>
            </div>
          </div>
          <div className='col-lg-2 col-sm-12'>
            <button type="button" className="btn btn-success float-right" onClick={openModal}>
              <FormattedMessage defaultMessage="Add User" id="Add User" />
            </button>
          </div>
        </div>
        <div className='row'>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">
                  <FormattedMessage defaultMessage="Name" id="Name" />
                </th>
                <th scope="col">
                  <FormattedMessage defaultMessage="Email" id="Email" />
                </th>
                <th scope="col">
                  <FormattedMessage defaultMessage="Account Number" id="Account Number" />
                </th>
                <th scope="col">
                  <FormattedMessage defaultMessage="Department" id="Department" />
                </th>
                <th scope="col">
                  <FormattedMessage defaultMessage="Last Login" id="Last Login" />
                </th>
                <th scope="col">
                  <FormattedMessage defaultMessage="Action" id="Action" />
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data.map((_user, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{_user.name}</td>
                      <td>
                        <a href={`mailto:${_user.email}`}>
                          {_user.email}
                        </a>
                      </td>
                      <td>{_user.customer_id}</td>
                      <td>
                        {_user.department ?? (_user.contactCode?.toUpperCase().includes('AR') ? 'Accounting' : 'Parts')}
                      </td>
                      <td>{new Date(_user.lastLogin).toLocaleDateString()}</td>
                      <td>
                        <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(_user)} disabled={_user.to_delete}>
                          {
                            _user.to_delete ? <FormattedMessage defaultMessage={"Deletion requested"} id="Deletion requested" /> : <FormattedMessage defaultMessage="Request Deletion" id="Request Deletion" />
                          }
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        {
        !dealerDataIsUpToDate && (<div className='row'>
          <div className='col-12'>
            <button type="button" className="btn btn-success" onClick={handleUpToDate}>
              <FormattedMessage defaultMessage="Everything is ok" id="Everything is ok" />
            </button>
          </div>
         </div>)
         }
        <ShowModal modalIsOpen={modalIsOpen} afterOpenModal={afterOpenModal} closeModal={closeModal} subtitle={subtitle} accountNumber={user.customer_id} onSubmit={onRequestUser} additionalAccts={user.additionalAccts} requester={user} dealer={dealer}/>
      </div>
    </>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

const ShowModal = ({ modalIsOpen, afterOpenModal, closeModal, subtitle, accountNumber, onSubmit, requester, dealer }) => {

  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')
  const [department, setDepartment] = React.useState('Parts')
  const [additionalAccts, setAdditionalAccts] = React.useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      var result = await onSubmit({ requesterEmail: requester.email, requesterName: requester.name, email, name, customer_id: accountNumber, department, additionalAccts })
      if (result && result.success) {
        alert('Account Creation Request Sent Successfully')
        closeModal()
      } else {
        alert(result.error)
      }
      return

    } catch (err) {
      console.log(err)
    }

    alert('Something went wrong, please try again later')
    return

  }


  return (
    <Modal
      isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Account Request">
      <div className='container position-relative'>
        <button type='button' onClick={closeModal} className="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Web Access Request Form</h2>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor="exampleInputac1">Account Number</label>
            <input type="text" className="form-control" id="exampleInputac1" placeholder="Account Number" value={accountNumber} readOnly />
          </div>
          <div className='form-group'>
            <label htmlFor="exampleInputac2">Account Name</label>
            <input type="text" className="form-control" id="exampleInputac2" placeholder="Account Name" value={dealer?.name ?? 'Atlas'} readOnly />
          </div>
          <div class="form-group">
            <label htmlFor="department">Department</label>
            <select class="form-control" id="department" value={department} onChange={(e) => setDepartment(e.target.value)} required>
              <option value="Parts">Parts</option>
              <option value="Accounting">Accounting</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email address</label>
            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter the email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputName1">Name</label>
            <input type="text" className="form-control" id="exampleInputName1" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} required/>
          </div>
          <div className="form-group">
            <label htmlFor="exampleAddition1">Additional Accounts</label>
            <input type="text" className="form-control" id="exampleAddition1" aria-describedby="emailHelp" placeholder="Enter the addtional accounts" value={additionalAccts} onChange={(e) => setAdditionalAccts(e.target.value)} />
          </div>
          <button type="submit" class="btn btn-success">Submit</button>
        </form>
      </div>
    </Modal>
  )
}