import {
  GET_PRODUCTS,
  RECEIVE_PRODUCTS,
  SUBMIT_CART_SUCCESS,
  CLEAR_PRODUCTS,
  GET_CLEARANCE_PRODUCTS,
  RECEIVE_CLEARANCE_PRODUCTS,
  CLEAR_CLEARANCE_PRODUCTS,
  RECEIVE_AGGREGATED_PRODUCTS
} from '../constants/action-types'
import { PRODUCT_SEARCH_LIMIT } from '../../constants/search-query'

var initialState = {
  data: [],
  meta: {
    total: 0,
    limit: 0,
    offset: 0
  },
  clearanceData: [],
  aggregateData: [],
  isLoading: false,
  wasLoadSuccessful: false,
  hasMore: false
}

function hasMore (response) {
  return (
    response.data.length !== 1 &&
    response.meta.offset + PRODUCT_SEARCH_LIMIT < response.meta.total
  )
}

export default function products (
  state = initialState,
  { type, offset, response }
) {
  switch (type) {
    case GET_PRODUCTS:
      return {
        ...state,
        wasLoadSuccessful: offset !== 0,
        isLoading: true,
        data: offset === 0 ? [] : state.data,
        clearanceData: state.clearanceData
      }
    
    case GET_CLEARANCE_PRODUCTS:
      return {
        ...state,
        clearanceData: state.clearanceData
      }

    case RECEIVE_PRODUCTS:
      return {
        ...state,
        ...response,
        isLoading: false,
        wasLoadSuccessful: true,
        hasMore: hasMore(response),
        data: response.data
      }

      case RECEIVE_CLEARANCE_PRODUCTS:
        return {
          ...state,
          ...response,
          clearanceData: response.data,
          data: state.data
        }

      case CLEAR_PRODUCTS:
        return {
          ...state,
          data: []
        }

      case CLEAR_CLEARANCE_PRODUCTS:
        return {
          ...state,
          clearanceData: []
        }

    case SUBMIT_CART_SUCCESS:
      return initialState 
      
    case RECEIVE_AGGREGATED_PRODUCTS:
      return {
        ...state,
        aggregateData: response.data
      }
    default:
      return state
  }
}
