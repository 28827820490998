import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import { searchMessages } from './actions'
import App from '../common/app'
import EmailSent from './components/index'
let unsubscribe = function () { }

function render() {
    ReactDOM.render(
        <App {...getState()} {...appActions}>
            <EmailSent {...getState()} {...appActions} changeSearchQuery={searchMessages} />
        </App>,
        document.querySelector('#app')
    )
}

export function enter(context, next) {
    unsubscribe = subscribe(render)
    searchMessages(1, 8, "byEmail")
    render()
}

export function exit(context, next) {
    unsubscribe()
    next()
}
