import React from 'react'
import createReactClass from 'create-react-class'
import SearchBar from './search-bar'
import page from 'page'
import moment from 'moment'
import { isRead, markAsRead } from '../actions'

import { FormattedMessage, IntlProvider } from 'react-intl'
// import { addLocaleData, FormattedMessage, IntlProvider } from 'react-intl'
// import en from 'react-intl/locale-data/en'
// import fr from 'react-intl/locale-data/fr'
import frMessages from '../../../locales/fr'

// addLocaleData(en)
// addLocaleData(fr)

const messages = {
  en: {},
  fr: frMessages
}

export default createReactClass({
    displayName: 'Inbox',

    gotoMessage(e) {
        const messageId = e.currentTarget.getAttribute('data-item');
        markAsRead(messageId)
        page('/inbox-message/' + messageId)
    },

    messages(data) {
        return data.map(message => {
            // format modified date
            const date = message.data.modified ? moment(
                message.data.modified
            ).format('YYYY-MM-DD') : 'undefined'

            if (isRead(message.data.recipients)) {
                return (
                    <tr key={message.data._id} data-item={message.data._id} onClick={this.gotoMessage} className="inbox-row-read">
                        <td>{date}</td>
                        <td>{message.data.subject}</td>
                    </tr>
                )
            } else {
                return (
                    <tr key={message.data._id} data-item={message.data._id} onClick={this.gotoMessage} className="inbox-row-unread">
                        <td>{date}</td>
                        <td>{message.data.subject}</td>
                    </tr>
                )

            }


        });
    },

    render() {
        const language = this.props.session && this.props.session.language
        return (
            <IntlProvider
            key={language.code}
            locale={language.code}
            messages={messages[language.code]}
          >
            <div>
                <div className="atlas-inbox-sidenav">
                    <div className="atlas-inbox-sidebar-container atlas-inbox-menu-item-1">
                        <div>
                            <a href='/inbox'>
                                <svg width="32" height="50" className="chat-svg" xmlns="http://www.w3.org/2000/svg">
                                    <title>UI Icon 2</title>
                                    <g>
                                        <title>Layer 1</title>
                                        <g id="svg_1" data-name="12 Chat">
                                            <path id="svg_2" d="m22.57,28.5a1.47,1.47 0 0 1 -0.83,-0.25l-7.19,-4.75l-8.84,0a3.19,3.19 0 0 1 -3.21,-3.17l0,-13.66a3.19,3.19 0 0 1 3.21,-3.17l20.58,0a3.19,3.19 0 0 1 3.21,3.17l0,13.66a3.19,3.19 0 0 1 -3.21,3.17l-2.22,0l0,3.5a1.5,1.5 0 0 1 -1.5,1.5zm-16.86,-22a0.2,0.2 0 0 0 -0.21,0.17l0,13.66a0.2,0.2 0 0 0 0.21,0.17l9.29,0a1.55,1.55 0 0 1 0.83,0.25l5.24,3.46l0,-2.21a1.5,1.5 0 0 1 1.5,-1.5l3.72,0a0.2,0.2 0 0 0 0.21,-0.17l0,-13.66a0.2,0.2 0 0 0 -0.21,-0.17l-20.58,0z" />
                                        </g>
                                    </g>
                                </svg></a>
                        </div>

                        <a href='/inbox'><div className="inbox-message-sidenav-text">Messages</div></a>
                    </div>
                </div>

                <div className="atlas-inbox-main">
                    <div className="atlas-inbox-grid-leftHeader"></div>
                    <div id="right-header" className="atlas-inbox-grid-rightHeader">
                        <SearchBar changeSearchQuery={this.props.changeSearchQuery} session={this.props.session} isOnline={true}></SearchBar>
                    </div>
                    <div className="atlas-inbox-grid-main">
                        <table className="atlas-inbox-table table">
                            <thead>
                                <tr>
                                    <th className="atlas-inbox-thin-column">Date</th>
                                    <th>
                                        <FormattedMessage
                                        defaultMessage="Subject"
                                        id="Subject"
                                    />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.messages(this.props.messages.data)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </IntlProvider>

        )
    }
})
