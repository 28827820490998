import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import aguid from 'aguid'
import createReactClass from 'create-react-class'

function fromFormDataToObject (formData) {
  return Array.from(formData).reduce((previousValue, currentValue) => {
    return Object.assign({}, previousValue, {
      [currentValue[0]]: currentValue[1]
    })
  }, {})
}

export default createReactClass({
  displayName: 'User',

  handleSubmit (event) {
    event.preventDefault()
    const data = fromFormDataToObject(new FormData(this.refs.form))
    data.roles = data.roles.split(',').map(role => role.trim())
    data.disabled = Boolean(data.disabled)
    data.hasOwnCart = Boolean(data.hasOwnCart)
    data.managesMultipleLocales = Boolean(data.managesMultipleLocales)
    if (this.props.data.attributes.dealerPatterns) {
      data.dealerPatterns = this.props.data.attributes.dealerPatterns
    }
    if (this.props.data.attributes.additionalAccts) {
      data.additionalAccts = this.props.data.attributes.additionalAccts
    }
    this.props.saveUser(data)
  },

  handleDeleteClick (event) {
    event.preventDefault()
    if (window.confirm('Are you sure you want to delete this user?')) {
      this.props.deleteUser(this.props.data.attributes._id)
    }
  },
  
  handleNewAccount (event) {
    event.preventDefault()
    if (this.props.data.attributes.additionalAccts === undefined) this.props.data.attributes.additionalAccts = []
    this.props.data.attributes.additionalAccts.push("")
    this.forceUpdate()
  },


  handleAccountChange (i, event) {
    event.preventDefault()
    this.props.data.attributes.additionalAccts[i] = event.target.value
    this.forceUpdate()
  },

  removeAccount (event) {
    event.preventDefault()
    this.props.data.attributes.additionalAccts.splice(event.target.value, 1)
    this.forceUpdate()
  },

  handleNewPattern (event) {
    event.preventDefault()
    if (this.props.data.attributes.dealerPatterns === undefined) this.props.data.attributes.dealerPatterns = []
    this.props.data.attributes.dealerPatterns.push("")
    this.forceUpdate()
  },

  handlePatternChange (i, event) {
    event.preventDefault()
    this.props.data.attributes.dealerPatterns[i] = event.target.value
    this.forceUpdate()
  },

  removePattern (event) {
    event.preventDefault()
    this.props.data.attributes.dealerPatterns.splice(event.target.value, 1)
    this.forceUpdate()
  },

  render () {
    const user = this.props.data.attributes

    if (this.props.isLoading) {
      return <div>Loading...</div>
    }

    return (
      <div style={{ padding: 24 }}>
        <div style={{ marginBottom: 12 }}>
          <button  onClick={() => window.history.back()} className="btn btn-secondary">
            Back to users
          </button>
        </div>
        <form onSubmit={this.handleSubmit} ref="form">
          <input defaultValue={user._id} name="id" type="hidden" />

          <div>
            <label>
              <div>UUID</div>
              <input
                defaultValue={aguid(user._id)}
                readOnly
                style={{ width: 450 }}
                type="text"
              />
            </label>
          </div>
          <div>
            <label>
              <div>Account Number</div>
              <input
                defaultValue={user.customer_id}
                name="customer_id"
                style={{ width: 450 }}
                type="text"
              />
            </label>
          </div>
          <div>
            <label>
              <div>Dealer Name</div>
              <input
                defaultValue={user.dealerName}
                name="name"
                required
                style={{ width: 450 }}
                type="text"
              />
            </label>
          </div>
          <div>
            <label>
              <div>Customer Name</div>
              <input
                defaultValue={user.name}
                name="name"
                required
                style={{ width: 450 }}
                type="text"
              />
            </label>
          </div>
          <div>
            <label>
              <div>Email Address</div>
              <input
                defaultValue={user.email}
                name="email"
                style={{ width: 450 }}
                type="email"
              />
            </label>
          </div>

          <div>
            <label>
              <div>New password</div>
              <input name="password" style={{ width: 450 }} type="password" />
            </label>
          </div>

          <div>
            <label>
              <div>Phone</div>
              <input
                defaultValue={user.phone}
                name="phone"
                style={{ width: 450 }}
                type="text"
              />
            </label>
          </div>

          <div>
            <label>
              <div>User manages own cart (not general to dealer)</div>
              <input
                defaultChecked={user.hasOwnCart}
                name="hasOwnCart"
                style={{ width: 450 }}
                type="checkbox"
                value="true"
              />
            </label>
          </div>

          <div>
            <label>
              <div>User manages multiple locations (general to dealer)</div>
              <input
                defaultChecked={user.managesMultipleLocales}
                name="managesMultipleLocales"
                style={{ width: 450 }}
                type="checkbox"
                value="true"
              />
            </label>
          </div>

          <div>
              <div>Additional Account Numbers</div>
              <div>
                <input type="button" className="btn btn-secondary" onClick={(e) => this.handleNewAccount(e)} value="+"/> Add account
              </div>
              {user.additionalAccts !== undefined ? user.additionalAccts.map((account,i) => 
                <div key={i}>
                  <input style={{ marginTop : '10px', marginRight : '10px' }} value={account} onChange={(event) => this.handleAccountChange(i, event)}/>  
                  <button 
                  className="btn btn-danger" 
                  type="button"
                  value={i}
                  onClick={(e) => this.removeAccount(e)}>X</button>
                </div>
              ): ""}
          </div>

          <div>
            <label>
              <div>Roles</div>
              <input
                defaultValue={
                  user.roles && user.roles.length
                    ? user.roles.map(role => role.trim()).join(',')
                    : 'Customer'
                }
                name={'roles'}
                style={{ width: 450 }}
                type="text"
              />
            </label>
          </div>

          {(user.roles !== undefined && user.roles.indexOf('Sales') > -1) &&
            <div>
                <div>Dealer Pattern</div>
                <div>
                  <input type="button" className="btn btn-secondary" onClick={(e) => this.handleNewPattern(e)} value="+"/> Add pattern
                </div>
                {user.dealerPatterns !== undefined ? user.dealerPatterns.map((dealerPattern,i) => 
                  <div key={i}>
                    <input style={{ marginTop : '10px', marginRight : '10px' }} value={dealerPattern} onChange={(event) => this.handlePatternChange(i, event)}/>  
                    <button 
                    className="btn btn-danger" 
                    type="button"
                    value={i}
                    onClick={(e) => this.removePattern(e)}>X</button>
                  </div>
                ): ""}
            </div>
          }

          <div>
            <label>
              <div>Disabled</div>
              <input
                defaultChecked={user.disabled}
                name="disabled"
                style={{ width: 450 }}
                type="checkbox"
                value="true"
              />
            </label>
          </div>

          <fieldset>
            <legend>Stats</legend>
            {(user.lastLogin || user.last_login) &&
              <div>
                <label>
                  <div>Last login</div>
                  <input
                    defaultValue={moment(
                      user.lastLogin || user.last_login
                    ).format('MMMM Do YYYY, h:mm a')}
                    readOnly
                    style={{ width: 450 }}
                    type="text"
                  />
                </label>
              </div>}

            {user.subtotals &&
              <div>
                <label>
                  <div>Total since December 1 (on B2B 2.0)</div>
                  <input
                    defaultValue={numeral(user.subtotals.sinceDecember1).format(
                      '$0,0.00'
                    )}
                    readOnly
                    style={{ width: 450 }}
                    type="text"
                  />
                </label>
              </div>}

            <div>
              <label>
                <div>Browser</div>
                <input
                  defaultValue={user.browser}
                  readOnly
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>

            <div>
              <label>
                <div>OS</div>
                <input
                  defaultValue={user.os}
                  readOnly
                  style={{ width: 450 }}
                  type="text"
                />
              </label>
            </div>
          </fieldset>
{/* 
          <div style={{ float: 'right' }}>
          </div> */}

          <div style={{ marginTop: 12, display: 'flex', gap: '1rem' }}>
            <button className="btn btn-primary" type="submit">
              Save
            </button>
            <button
              className="btn btn-danger ml-4"
              onClick={this.handleDeleteClick}
              type="button"
            >
              Delete
            </button>
          </div>
        </form>
      </div>
    )
  }
})
