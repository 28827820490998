import { GET_ALL_DEALERS, GET_ALL_DEALERS_SUCCESSFUL, GET_ALL_DEALERS_ERROR } from "./constants"

var initialState = {
    data: [],
    isLoading: false,
    wasLoadSuccessful: false,
    error: null
}


export default function allDealersData (state = initialState, { type, response, error }) {
    switch (type) {
        case GET_ALL_DEALERS:
            return {
                ...state,
                isLoading: true,
                wasLoadSuccessful: false,
                error: null
            }

        case GET_ALL_DEALERS_SUCCESSFUL:
            return {
                ...state,
                data: response ?? [],
                isLoading: false,
                wasLoadSuccessful: true,
                error: null
            }

        case GET_ALL_DEALERS_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                wasLoadSuccessful: false,
                error: error
            }

        default:
            return state
    }
}