import {
  GET_DEALERS_PENDING,
  GET_DEALERS_SUCCESSFUL,
  GET_DEALERS_ERROR,
  CLEAR_DEALERS,
  DEALER_SEARCH_LIMIT,
  UPDATE_SOME_DEALERS,
  UPDATING_DEALERS,
} from './constants'

var initialState = {
  data: [],
  meta: {
    total: 0,
    limit: 0,
    offset: 0
  },
  isLoading: false,
  isUpdating: false,
  wasLoadSuccessful: false,
  error: null
}

function hasMore(response) {
    return (
        response.data.length !== 1 &&
        response.meta.offset + DEALER_SEARCH_LIMIT < response.meta.total
    )
}

export default function dealers (state = initialState, { type, offset, response }) {
  switch (type) {
    case GET_DEALERS_PENDING:
      return {
        ...state,
        isLoading: true,
        wasLoadSuccessful: false,
        error: null
      }

    case GET_DEALERS_SUCCESSFUL:
    //   return {
    //     ...state,
    //     data: response.data,
    //     isLoading: false,
    //     wasLoadSuccessful: true,
    //     error: null
    //   }

      return {
        ...state,
        ...response,
        isLoading: false,
        wasLoadSuccessful: true,
        error: null,
        hasMore: hasMore(response),
        data: response.data,
        // data: response.meta.page.offset === 0
        //     ? response.data
        //     : state.data.concat(response.data)
    }

    case GET_DEALERS_ERROR:
      return {
        ...state,
        data: [],
        isLoading: false,
        wasLoadSuccessful: false,
        error: response.error
      }

      case CLEAR_DEALERS:
        return {
            ...state,
            data: []
        }
    
    case UPDATING_DEALERS: 
        return {
            ...state,
            isUpdating: true
        }
    case UPDATE_SOME_DEALERS:
      return {
        ...state,
        isUpdating: false,
        data: state.data.map(dealer => {
          if (dealer._id === response._id) {
            return {
              ...dealer,
              ...response
            }
          }
          return dealer
        })
      }

    default:
      return state
  }
}
