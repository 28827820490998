import React from 'react'
import DetailedListItem from './detailed-list-item'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'DetailedList',

  render () {
    const isRetailMode = this.props.isRetailMode
    const products = this.props.products
    const language = this.props.language
    const warehouseCode = this.props.warehouseCode
    const singleView = this.props.singleView
    const userRoles = this.props.userRoles
    const { subscribeToProductUpdates } = this.props
    const listStyle = {
     paddingTop:'40px'
    }

    var productComponents = products.map(product => {
      return (
        <DetailedListItem
          session={this.props.session}
          isOnline={this.props.isOnline}
          isRetailMode={isRetailMode}
          key={product.id}
          language={language}
          product={product}
          singleView={singleView}
          userRoles={userRoles}
          warehouseCode={warehouseCode}
          isOnly={products.length===1}
          subscribeToProductUpdates={subscribeToProductUpdates}
        />
      )
    })

    return (
      <div className="atlas-detailed-list" style={listStyle}>
        {productComponents}
      </div>
    )
  }
})