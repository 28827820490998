import React from 'react'

export default function FlipPDF () {
        return (
            <div style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '84px', width:'100%' }}>
                <h1 style={{ textAlign: 'center' }}>2024 Product Catalogue in Flip PDF   |    Catalogue de produits 2024 en Flip PDF</h1>
                    <div style={{ textAlign:'center' }}>
                        <div style={{ display: 'inline-block', margin: 'auto', maxWidth:"500px" }}>
                            <a href="https://online.fliphtml5.com/wwxal/kecu/" target="_blank">
                                <img src="https://rvcatalogue.s3.amazonaws.com/cover2024/2024+Atlas+Cover+WEB.jpg" style={{ display: 'inline-block', margin: 'auto', maxWidth:"500px" }} alt=''/>
                            </a>
                            <h1 style={{ textAlign: 'center' }}>English</h1>
                        </div>
                        <div style={{ display: 'inline-block', margin: 'auto', width:"100px" }}></div>
                        <div style={{ display: 'inline-block', margin: 'auto', maxWidth:"500px" }}>
                            <a href="https://online.fliphtml5.com/wwxal/dzzh/" target="_blank">
                                <img src="https://rvcatalogue.s3.amazonaws.com/cover2024/2024+Atlas+Cover_French+WEB.jpg" style={{ display: 'inline-block', margin: 'auto', maxWidth:"500px" }} alt='' />
                            </a>
                            <h1 style={{ textAlign: 'center' }}>Français</h1> 

                        </div>
                    </div>
            </div>
        )
}
