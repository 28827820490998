import {
  RECEIVE_SESSION,
  SESSION_ERRORS,
  SESSION_LOADING,
  SET_SESSION_ID,
  DESTROY_SESSION,
  SWITCH_LANGUAGE,
  DISMISS_EMAIL_ALERT,
  SWITCH_LIST_VIEW,
  SWITCH_RETAIL_MODE
} from '../constants/action-types'
import { dispatch } from '../store'
import { searchProducts } from './product-search'
import { getCategories } from '../categories/actions'
import { subscribeToCart, unsubscribeFromCart } from '../cart/actions'
import { searchMessages } from '../inbox/actions'

export function switchListView () {
  dispatch({
    type: SWITCH_LIST_VIEW
  })
}

export function switchLanguage () {
  dispatch({
    type: SWITCH_LANGUAGE
  })
}

export function dismissEmailAlert () {
  dispatch({
    type: DISMISS_EMAIL_ALERT
  })
}

export function switchRetailMode () {
  dispatch({
    type: SWITCH_RETAIL_MODE
  })
}

export function findSession (sessionId) {
  dispatch({
    type: SESSION_LOADING
  })

  fetch(`/api/session/${sessionId}`)
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch({
          type: RECEIVE_SESSION,
          response: json
        })
        getCategories()
        subscribeToCart()
        // Check if user is disabled, and destroy their session
        fetch(`api/users/${json.included[0].attributes._id}`)
          .then(async function (response) {
            if (response.ok) {
              const user = await response.json()
              if (user.data.attributes.disabled) {
                destroySession(sessionId)
              }
            } else {
              destroySession(sessionId)
            }
          })
      } else {
        destroySession(sessionId)
      }
    })
    .catch(function (err) {
      console.log('parsing failed', err)
    })
}

export function useSessionId (sessionId) {
  dispatch({
    type: SET_SESSION_ID,
    sessionId
  })
  findSession(sessionId)
}

export function destroySession () {
  const sessionId = localStorage.sessionId
  localStorage.removeItem('sessionId')
  // if (navigator.credentials) {
  //   navigator.credentials.requireUserMediation()
  // }

  dispatch({
    type: DESTROY_SESSION
  })

  fetch(`/api/session/${sessionId}`, {
    method: 'delete'
  })
    .then(async function (response) {
      if (response.ok) {
        console.info('Session deleted')
      }
    })
    .catch(function (err) {
      console.log('Session deletion failed', err)
    })

  unsubscribeFromCart()
}

export function createSession (email, password) {
  dispatch({
    type: SESSION_LOADING
  })

  fetch('/api/session', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password
    })
  })
    .then(async function (response) {
      const json = await response.json()
      if (response.ok) {
        localStorage.sessionId = json.data.id
        dispatch({
          type: RECEIVE_SESSION,
          response: json
        })
        // if (navigator.credentials && window.PasswordCredential) {
        //   const PasswordCredential = window.PasswordCredential
        //   const cred = new PasswordCredential({
        //     name: 'User', -- get from json
        //     id: email,
        //     email: email,
        //     idName: 'email',
        //     password
        //   })
        //   navigator.credentials.store(cred).then((test) => {
        //     // window.alert('creds saved')
        //   }).catch(() => {
        //     // window.alert('error')
        //   })
        // }
        searchProducts()
        getCategories()
        subscribeToCart()
        searchMessages(email)

      } else {
        dispatch({
          type: SESSION_ERRORS,
          response: json
        })
      }
    })
    .catch(function (err) {
      console.log('parsing failed', err)
      dispatch({
        type: SESSION_ERRORS,
        response: {
          message: 'An error occurred. Please try again.'
        }
      })
    })
}
