import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import App from '../common/app'
import About from './components/about'
import Marketing from './components/marketing'
import FlipPDF from './components/flippdf'
import RGA from './components/rga'
import Eflyers from './components/eflyers'
import TermsOfSale from './components/terms-of-sale'

function renderAbout (mountNode) {
  return function () {
    ReactDOM.render(
      <App {...getState()} {...appActions}>
        <About />
      </App>,
      mountNode
    )
  }
}

function renderMarketing (mountNode) {
  return function () {
    ReactDOM.render(
      <App {...getState()} {...appActions}>
        <Marketing />
      </App>,
      mountNode
    )
  }
}

function renderFlipPDF (mountNode) {
  return function () {
    ReactDOM.render(
      <App {...getState()} {...appActions}>
        <FlipPDF />
      </App>,
      mountNode
    )
  }
}

function renderRga (mountNode) {
  return function () {
    ReactDOM.render(
      <App {...getState()} {...appActions}>
        <RGA />
      </App>,
      mountNode
    )
  }
}

function renderEflyers (mountNode) {
  return function () {
    ReactDOM.render(
      <App {...getState()} {...appActions}>
        <Eflyers />
      </App>,
      mountNode
    )
  }
}

function renderTermsOfSale (mountNode) {
  return function () {
    ReactDOM.render(
      <App {...getState()} {...appActions}>
        <TermsOfSale />
      </App>,
      mountNode
    )
  }
}

let unsubscribe = function () {}

export default function (app, mountNode) {
  app('/pages/about', function (context) {
    unsubscribe = subscribe(renderAbout(mountNode))
    renderAbout(mountNode)()
  })

  app.exit('/pages/about', (context, next) => {
    unsubscribe()
    next()
  })

  app.exit('/rvpf', (context, next) => {
    unsubscribe()
    next()
  })

  app('/e-flyers', function (context) {
    unsubscribe = subscribe(renderEflyers(mountNode))
    renderEflyers(mountNode)()
  })

  app.exit('/e-flyers', (context, next) => {
    unsubscribe()
    next()
  })

  app('/terms-of-sale', function (context) {
    unsubscribe = subscribe(renderTermsOfSale(mountNode))
    renderTermsOfSale(mountNode)()
  })

  app.exit('/terms-of-sale', (context, next) => {
    unsubscribe()
    next()
  })

  app('/pages/marketing', function (context) {
    unsubscribe = subscribe(renderMarketing(mountNode))
    renderMarketing(mountNode)()
  })

  app('/pages/flippdf', function (context) {
    unsubscribe = subscribe(renderFlipPDF(mountNode))
    renderFlipPDF(mountNode)()
  })

  app.exit('/pages/marketing', (context, next) => {
    unsubscribe()
    next()
  })

  app('/pages/rga', function (context) {
    unsubscribe = subscribe(renderRga(mountNode))
    renderRga(mountNode)()
  })

  app.exit('/pages/rga', (context, next) => {
    unsubscribe()
    next()
  })
}
