import {
  GET_CART_SUCCESSFUL,
  ADD_CART_ITEM,
  CHANGE_CART_ITEM_QUANTITY,
  CHANGE_CART_TEXT_FIELD,
  SUBMIT_CART_PENDING,
  SUBMIT_CART_SUCCESS,
  SUBMIT_CART_ERROR,
  CHANGE_QUERY_ORDER_QUERY
} from './constants'
import compactCartItems from '../../transforms/compact-cart-items'
import round from '../../common/round'

const initialState = {
  getPending: false,
  getSuccessful: false,
  getError: null,
  submitPending: false,
  submitError: null,
  orderSubtotal: 0,
  data: {
    purchaseOrder: '',
    orderNotes: '',
    items: []
  },
  quickOrderQuery: ''
}

function calculateTotals (state) {
  let orderSubtotal = state.data.items.reduce((total, item) => {
    return (
      total +
      (item.quantity || 0) *
        ((item.product && item.product.attributes.clearancePrice) ||
          (item.product && item.product.attributes.flyerPrice) ||
          (item.product && item.product.attributes.dealerPrice) ||
          0) +
      (item.quantity || 0) *
      (item.product.attributes.FXSurcharge || 0)
    )
  }, 0)
  orderSubtotal = parseFloat(round(orderSubtotal, 2))
  return {
    ...state,
    orderSubtotal
  }
}

export default function cart (
  state = initialState,
  { data, type, item, distributorPartNumber, quantity, key, value, error }
) {
  switch (type) {
    case GET_CART_SUCCESSFUL: {
      const newState = {
        ...state,
        data: data.attributes
      }
      return calculateTotals(newState)
    }

    case ADD_CART_ITEM: {
      const newState = {
        ...state,
        data: {
          ...state.data,
          items: compactCartItems(state.data.items.concat(item))
        }
      }
      return calculateTotals(newState)
    }

    case CHANGE_CART_ITEM_QUANTITY: {
      const newState = {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.reduce((items, item) => {
            if (item.distributorPartNumber === distributorPartNumber) {
              return items.concat({
                ...item,
                quantity
              })
            } else {
              return items.concat(item)
            }
          }, [])
        }
      }
      return calculateTotals(newState)
    }

    case CHANGE_CART_TEXT_FIELD: {
      const newState = {
        ...state,
        data: {
          ...state.data,
          [key]: value
        }
      }
      return newState
    }

    case CHANGE_QUERY_ORDER_QUERY: {
      const newState = {
        ...state,
        quickOrderQuery: value
      }
      return newState
    }

    case SUBMIT_CART_ERROR: {
      const newState = {
        ...state,
        submitPending: false,
        submitError: error
      }
      return newState
    }

    case SUBMIT_CART_PENDING: {
      const newState = {
        ...state,
        submitPending: true,
        submitError: null
      }
      return newState
    }

    case SUBMIT_CART_SUCCESS: {
      return initialState
    }

    default:
      return state
  }
}
