import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import { getFile, deleteFile } from './actions'
import App from '../common/app'
import File from './components/index'
let unsubscribe = function () {}

function render () {
  ReactDOM.render(
    <App active="file" {...getState()} {...appActions}>
      <File {...getState().file} deleteFile={deleteFile} />
    </App>,
    document.querySelector('#app')
  )
}

export function enter (context, next) {
  unsubscribe = subscribe(render)
  getFile(context.params.fileId)
  render()
}

export function exit (context, next) {
  unsubscribe()
  next()
}
