import React from 'react'
import numeral from 'numeral'
import { FormattedMessage } from 'react-intl'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'Users',
  render () {
    return (
      <div style={{ padding: 24 }}>
        <h2>Administration</h2>
        <ul>
          <li>
            <a href="https://www.hostedgraphite.com/0caeaf20/14190b80-90db-4749-8c12-56cc4d2ec6cc/grafana/dashboard/db/atlas-trailer">
              API Health Dashboard
            </a>
          </li>
          <li>
            <a href="/carts">Carts</a>
          </li>
          <li>
            <a href="/categories">Categories</a>
          </li>
          <li>
            <a href="/dealers">ConsumerNet Dealers</a>
          </li>
          <li>
            <a href="/all-dealers">All Dealers</a>
          </li>
          <li>
                <a href="/report">
                  <FormattedMessage defaultMessage="Dealer Subscriptions" id="Dealer Subscriptions" />
                </a>
          </li>
          <li>
            <a href="/files">Files</a>
          </li>
          <li>
            <a href="/activity">Search Activity</a>
          </li>
          <li>
            <a href="/users">Users</a>
          </li>
          <li>
            <a href="/email-admin">Broadcast Email</a>
          </li>
          <li>
            <a href="/web-orders">
              <FormattedMessage defaultMessage="Web orders" id="Web orders" />
            </a>
          </li>
          <li>
            Latest product backup: {' '}
            <a href="https://s3.amazonaws.com/atlastrailer-backups/products/products-latest.csv">
              CSV
            </a>
            ,{' '}
            <a href="https://s3.amazonaws.com/atlastrailer-backups/products/products-latest.jsonl">
              JSONL
            </a>
          </li>
        </ul>
        {Boolean(this.props.stats.data.subtotals.today) &&
          <div>
            <h2>Today's total</h2>
            <div>
              {numeral(this.props.stats.data.subtotals.today).format('$0,0.00')}
            </div>
          </div>}
      </div>
    )
  }
})
