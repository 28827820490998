import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import { loadInsightMessages, getInsightMessages } from './actions'
import App from '../common/app'
import EmailAdminInsightDelMessages from './components/index'

let unsubscribe = function () { }

function render() {
    ReactDOM.render(
        <App active="cart" {...getState()} {...appActions}>
            <EmailAdminInsightDelMessages {...getState()} {...appActions} changeSearchQuery={loadInsightMessages} />
        </App>,
        document.querySelector('#app')
    )
}

export function enter(context, next) {
    unsubscribe = subscribe(render)
    loadInsightMessages(1, 8, context.params.id)
    render()
}

export function exit(context, next) {
    unsubscribe()
    next()
}
