import { SAVE_PRODUCT_SUCCESS, DELETE_PRODUCT_SUCCESS } from './constants'
import page from 'page'
import { dispatch, getState } from '../store'

export function saveProduct (product) {
  const data = {
    title: product.title,
    shortDescription: product.shortDescription,
    longDescription: product.longDescription,
    distributorPartNumber: product.distributorPartNumber,
    categoryIds: product.categoryIds || [],
    images: product.images,
    clearanceQty: product.clearanceQty
  }

  fetch('/api/products', {
    method: 'post',
    headers: {
      'x-session-id': getState().session.id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data: { attributes: data } })
  })
    .then(async function (response) {
      if (response.ok) {
        dispatch({
          type: SAVE_PRODUCT_SUCCESS
        })
        page.redirect(`/?q=${data.distributorPartNumber}`)
      }
    })
    .catch(function (err) {
      console.log('parsing failed', err)
    })
}

export function deleteProduct (productId) {
  fetch(`/api/products/${productId}`, {
    method: 'delete',
    headers: {
      'x-session-id': getState().session.id,
      'Content-Type': 'application/json'
    }
  })
    .then(async function (response) {
      if (response.ok) {
        dispatch({
          type: DELETE_PRODUCT_SUCCESS
        })
        page.redirect('/')
      }
    })
    .catch(function (err) {
      console.log('delete product failed', err)
    })
}
