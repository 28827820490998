import React from 'react'
import CartTable from './table'
import { changeTextField, changeTextFieldFocusOut, submitOrder } from '../actions'
import page from 'page'
import { FormattedMessage } from 'react-intl'
import createReactClass from 'create-react-class'

export default createReactClass({
  getInitialState () {
    return {
      isReviewing: false
    }
  },

  handleSubmit (event) {
    event.preventDefault()
  },

  handleTextFieldInput (key, event) {
    changeTextField(key, event.target.value)
  },

  handleTextFieldFocusOut (key, event) {
    changeTextFieldFocusOut(key, event.target.value)
  },

  handleReviewButtonClick (event) {
    event.preventDefault()
    this.refs.reviewButton.blur()
    this.setState({
      isReviewing: true
    })
    document.body.scrollTop = 0
  },

  handleSubmitButtonClick: async function (event) {
    event.preventDefault()
    try {
      const response = await submitOrder()
      this.setState({
        reviewing: false
      })
      if (response.errors) {
        throw response.errors
      }
      page(`/web-orders/${response.data.attributes.referenceNumber}`)
    } catch (error) {
      console.error(error)
      window.alert('There was an error submitting your order.')
    }
  },

  handleGoBackButtonClick (event) {
    event.preventDefault()
    this.setState({
      isReviewing: false
    })
  },

  render () {
    const cart = this.props.cart
    const isOnline = this.props.browser.isOnline
    const isReviewing = this.state.isReviewing

    if (this.props.session.isLoading) {
      return <div style={{ margin: 24 }}>Loading...</div>
    }

    const reviewButton = (
      <button
        className="atlas-medium-button"
        onClick={this.handleReviewButtonClick}
        ref="reviewButton"
      >
        Review your order
      </button>
    )

    const goBackButton = (
      <button
        className="atlas-medium-button"
        onClick={this.handleGoBackButtonClick}
        style={{
          backgroundColor: 'orangered',
          marginRight: 8
        }}
      >
        Go back
      </button>
    )

    let submitButton
    if (cart.submitPending) {
      submitButton = (
        <button
          className="atlas-medium-button"
          disabled
          style={{ opacity: 0.5 }}
        >
          Submitting order
        </button>
      )
    } else {
      submitButton = (
        <button
          className="atlas-medium-button"
          onClick={this.handleSubmitButtonClick}
        >
          Submit order
        </button>
      )
    }

    return (
      <div style={{ display: 'flex' }}>
        <main className="atlas-cart" id="atlas-cart">
          <form onSubmit={this.handleSubmit}>
            {isReviewing &&
              <h1>
                <FormattedMessage
                  defaultMessage="Please review your order"
                  id="Please review your order"
                />
              </h1>}
            <CartTable {...this.props} isReviewing={isReviewing} />

            <div>
              <label>
                <div>
                  <FormattedMessage
                    defaultMessage="Purchase order"
                    id="Purchase order"
                  />
                  {' '}
                  <span style={{ color: 'red' }}>(required)</span>
                </div>
                <input
                  onChange={this.handleTextFieldInput.bind(
                    this,
                    'purchaseOrder'
                  )}
                  onBlur={this.handleTextFieldFocusOut.bind(
                    this,
                    'purchaseOrder')
                  }
                  readOnly={isReviewing || !isOnline}
                  required
                  style={{ width: 150 }}
                  type="text"
                  value={cart.data.purchaseOrder}
                />
              </label>
            </div>

            <div>
              <label>
                <div>
                  <FormattedMessage
                    defaultMessage="Order notes"
                    id="Order notes"
                  />
                </div>
                <input
                  onChange={this.handleTextFieldInput.bind(this, 'orderNotes')}
                  onBlur={this.handleTextFieldFocusOut.bind(
                    this,
                    'orderNotes')
                  }
                  readOnly={isReviewing || !isOnline}
                  style={{ width: 400 }}
                  type="text"
                  value={cart.data.orderNotes}
                />
              </label>
            </div>

            <div style={{ marginTop: 6 }}>
              {!isReviewing &&
                isOnline &&
                Boolean(cart.data.items.length) &&
                cart.data.purchaseOrder &&
                reviewButton}

              {isReviewing && goBackButton}

              {isReviewing && submitButton}
            </div>
          </form>
        </main>
      </div>
    )
  }
})
