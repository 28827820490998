import {
  GET_CART_PENDING,
  GET_CART_SUCCESSFUL,
  // GET_CART_ERROR,
  ADD_CART_ITEM,
  CHANGE_CART_TEXT_FIELD,
  CART_ITEM_ERROR,
  SUBMIT_CART_PENDING,
  SUBMIT_CART_SUCCESS,
  SUBMIT_CART_ERROR,
  CHANGE_QUERY_ORDER_QUERY
} from './constants'
import { CHANGE_ONLINE_STATUS } from '../constants/action-types'
import { dispatch, getState } from '../store'
import Favico from 'favico.js'
import throttle from 'lodash/throttle'

const favicon = new Favico({
  animation: 'none'
})

function updateFavicon () {
  const count = getState().cart.data.items.reduce((prev, item) => {
    return prev + item.quantity
  }, 0)
  favicon.badge(count)
}
updateFavicon()

let cartSource = null
export function subscribeToCart () {
  dispatch({
    type: GET_CART_PENDING
  })

  const sessionId = getState().session.id
  cartSource = new window.EventSource(`/api/cart?x-session-id=${sessionId}`)
  cartSource.addEventListener('message', handleCartUpdate)
  cartSource.addEventListener('error', () => {
    dispatch({
      type: CHANGE_ONLINE_STATUS,
      isOnline: false
    })
  })
}

export function unsubscribeFromCart () {
  if (!window.EventSource) {
    return
  }
  if (cartSource) {
    cartSource.removeEventListener('message', handleCartUpdate)
    cartSource.close()
  }
  cartSource = null
}

window.addEventListener('offline', unsubscribeFromCart)
window.addEventListener('online', subscribeToCart)

// not convinced this line is meaningful, but trying it out to
// prevent max connection error
window.addEventListener('onbeforeunload', unsubscribeFromCart)

function handleCartUpdate (event) {
  const response = JSON.parse(event.data)
  dispatch({
    type: GET_CART_SUCCESSFUL,
    data: response.data
  })
  updateFavicon()
}

function _saveCart (data) {
  return fetch('/api/cart', {
    method: 'post',
    headers: {
      'x-session-id': getState().session.id,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(function (response) {
      if (!response.ok) {
        throw new Error('Error when saving cart')
      }
      return response.json()
    })
    .catch(function (error) {
      unsubscribeFromCart()
      subscribeToCart()
      console.error('Error when saving cart', error)
    })
}

export const saveCart = throttle(_saveCart, 1500, {
  leading: false,
  trailing: true
})

export function addCartItem (product, quantity = 1) {
  delete product.attributes.longDescription
  dispatch({
    type: ADD_CART_ITEM,
    item: {
      distributorPartNumber: product.attributes.distributorPartNumber,
      quantity: parseInt(quantity, 10) || 1,
      product,
      isAdding: true
    }
  })
  return fetch('/api/cart-items', {
    method: 'post',
    headers: {
      'x-session-id': getState().session.id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      distributorPartNumber: product.attributes.distributorPartNumber,
      quantity: parseInt(quantity, 10) || 1,
      dealerName: getState().session?.user?.dealerName ?? "N/A",
      isAdditive: true
    })
  }).catch(error => {
    console.error(error)
  })
}

export function removeCartItem (distributorPartNumber) {
  return fetch('/api/cart-items', {
    method: 'post',
    headers: {
      'x-session-id': getState().session.id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      distributorPartNumber,
      quantity: 0
    })
  })
}

export function changeCartItemQuantity (distributorPartNumber, quantity) {
  return fetch('/api/cart-items', {
    method: 'post',
    headers: {
      'x-session-id': getState().session.id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      distributorPartNumber,
      quantity
    })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('changeCartItemQuantity error')
      }
    })
    .catch(error => {
      dispatch({
        type: CART_ITEM_ERROR,
        distributorPartNumber,
        quantity
      })
      throw error
    })
}

export function changeQuickOrderQuery (value) {
  dispatch({
    type: CHANGE_QUERY_ORDER_QUERY,
    value
  })
}

export function changeTextField (key, value) {
  dispatch({
    type: CHANGE_CART_TEXT_FIELD,
    key,
    value
  })
}

export function changeTextFieldFocusOut (key, value) {
  dispatch({
    type: CHANGE_CART_TEXT_FIELD,
    key,
    value
  })
  saveCart({
    [key]: value
  })
}

export async function submitOrder () {
  const { cart } = getState()

  const postBody = {
    data: {
      type: 'web-orders',
      attributes: {
        items: cart.data.items.map(item => ({
          distributorPartNumber: item.distributorPartNumber,
          quantity: item.quantity
        })),
        orderSubtotal: cart.orderSubtotal,
        purchaseOrder: cart.data.purchaseOrder,
        orderNotes: cart.data.orderNotes
      }
    }
  }

  dispatch({
    type: SUBMIT_CART_PENDING
  })

  try {
    const response = await fetch('/api/web-orders', {
      method: 'post',
      headers: {
        'x-session-id': getState().session.id,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postBody)
    })
    const responseData = await response.json()
    if (response.ok) {
      dispatch({
        type: SUBMIT_CART_SUCCESS,
        response: responseData
      })
      updateFavicon()
    } else {
      dispatch({
        type: SUBMIT_CART_ERROR,
        error: responseData
      })
    }
    return responseData
  } catch (error) {
    dispatch({
      type: SUBMIT_CART_ERROR,
      error
    })
    console.error('SUBMIT_CART_ERROR', error)
    return { errors: [error] }
  }
}
