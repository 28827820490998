export default function imageToSrcset(image) {
  let imageSourceset = ''
  if (image) {
    const filteredSources = image.sources.filter(notALargeImage)
    imageSourceset = filteredSources
      .map(source => {
        // Temporary solution until image transfer completed
        const tmpTransformedUrl = source.url.replace(
          'http://c0670182.cdn.cloudfiles.rackspacecloud.com',
          'https://dbtucfjfgxbph.cloudfront.net'
        )
        return `${tmpTransformedUrl} ${source.width}w`
      })
      .join(', ')
  }
  return imageSourceset
}

function notALargeImage (image) {
  return !image.size || image.size < 1000000 // 2mb
}
