export const CREATE_USER = 'CREATE_USER'

export const GET_USER_PENDING = 'GET_USER_PENDING'
export const GET_USER_SUCCESSFUL = 'GET_USER_SUCCESSFUL'
export const GET_USER_ERROR = 'GET_USER_ERROR'

export const SAVE_USER_PENDING = 'SAVE_USER_PENDING'
export const SAVE_USER_SUCCESSFUL = 'SAVE_USER_SUCCESSFUL'
export const SAVE_USER_ERROR = 'SAVE_USER_ERROR'

export const DELETE_USER_PENDING = 'DELETE_USER_PENDING'
export const DELETE_USER_SUCCESSFUL = 'DELETE_USER_SUCCESSFUL'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
