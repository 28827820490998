import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'TranslatedField',

  handleInputChange (event) {
    this.props.onChange(event.target.dataset.languageCode, event.target.value)
  },

  render () {
    const values = this.props.values
    const name = this.props.name
    const fieldComponents = this.props.languages.map(language => {
      const translation =
        values.find(value => value.languageCode === language.code) || {}
      return (
        <div className="form-group row" key={language.code}>
          <label
            className="col-xs-2 col-form-label"
            htmlFor={`form-${name}-${language.code}`}
          >
            {language.name}
          </label>
          <div className="col-xs-10">
            <input
              className="form-control"
              data-language-code={language.code}
              defaultValue={translation.value || ''}
              name={name}
              onChange={this.handleInputChange}
              id={`form-${name}-${language.code}`}
              type="text"
            />
          </div>
        </div>
      )
    })
    return (
      <div>
        {fieldComponents}
      </div>
    )
  }
})
