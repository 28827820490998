import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'EmailAdminMenu',

    render() {
        return (
            <div className="atlas-email-admin-sidenav">
                <a href="/email-admin" className="atlas-menu-item-1">Emails </a>
                <a href="/email-admin-compose" className="atlas-menu-item-2">&nbsp;&nbsp;Compose</a>
                <a href="/email-admin-sent" className="atlas-menu-item-2">&nbsp;&nbsp;Sent</a>
                <a href="/email-admin-insight" className="atlas-menu-item-2">&nbsp;&nbsp;Insight</a>
                <a href="/email-admin-reports" className="atlas-menu-item-2">&nbsp;&nbsp;Reports</a>
              {/* <a href="/email-admin" className="atlas-menu-item-1">Parts - Fr </a>
                <a href="/email-admin-compose" className="atlas-menu-item-2">&nbsp;&nbsp;Compose</a>
                <a href="/email-admin-sent" className="atlas-menu-item-2">&nbsp;&nbsp;Sent</a>
        <a href="/email-admin-insight" className="atlas-menu-item-2">&nbsp;&nbsp;Insight</a>
                <a href="/email-admin" className="atlas-menu-item-1">Accounting </a>
                <a href="/email-admin-compose" className="atlas-menu-item-2">&nbsp;&nbsp;Compose</a>
                <a href="/email-admin-sent" className="atlas-menu-item-2">&nbsp;&nbsp;Sent</a>
                <a href="/email-admin-insight" className="atlas-menu-item-2">&nbsp;&nbsp;Insight</a>
                <a href="/email-admin" className="atlas-menu-item-1">Accounting - Fr </a>
                <a href="/email-admin-compose" className="atlas-menu-item-2">&nbsp;&nbsp;Compose</a>
                <a href="/email-admin-sent" className="atlas-menu-item-2">&nbsp;&nbsp;Sent</a>
    <a href="/email-admin-insight" className="atlas-menu-item-2">&nbsp;&nbsp;Insight</a>*/}
            </div>
        )
    }
})
