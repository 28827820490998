import React from "react";
import numeral from "numeral";
import { findTranslation } from "../../../common/translation";
import createReactClass from "create-react-class";

export default createReactClass({
  dipslayName: "OrderConfirmationItem",

  render() {
    const clearance = "ffefca7d-a309-4a7c-8b93-34c7d76a9712";
    const product = this.props.product || { attributes: {} };
    const title =
      product.attributes.title &&
      findTranslation(product.attributes.title, "en");
    const shortDescription =
      product.attributes.shortDescription &&
      findTranslation(product.attributes.shortDescription, "en");

    return (
      <tr>
        <td>
          <div>
            <strong>{title || shortDescription}</strong>
          </div>
          <div>
            Atlas #:{" "}
            <a
              href={`https://b2b.atlastrailer.com/?q=${this.props.distributorPartNumber}`}
            >
              {this.props.distributorPartNumber}
            </a>
          </div>
        </td>
        <td>{this.props.quantity}</td>
        <td>
          {product.attributes.categoryIds.indexOf(clearance) > -1 &&
          product.attributes.clearancePrice !== 0
            ? numeral(product.attributes.clearancePrice).format("$0,0.00")
            : product.attributes.flyerPrice !== 0
            ? numeral(product.attributes.flyerPrice).format("$0,0.00")
            : numeral(product.attributes.dealerPrice).format("$0,0.00")}
        </td>
        <td>
          {numeral(
            this.props.quantity * product.attributes.FXSurcharge || 0
          ).format("$0,0.00")}
        </td>
        <td>
          {product.attributes.categoryIds.indexOf(clearance) > -1 &&
          product.attributes.clearancePrice !== 0
            ? product.attributes.clearancePrice &&
              numeral(
                this.props.quantity * product.attributes.clearancePrice +
                  (this.props.quantity * product.attributes.FXSurcharge || 0)
              ).format("$0,0.00")
            : product.attributes.flyerPrice !== 0
            ? product.attributes.flyerPrice &&
              numeral(
                this.props.quantity * product.attributes.flyerPrice +
                  (this.props.quantity * product.attributes.FXSurcharge || 0)
              ).format("$0,0.00")
            : product.attributes.dealerPrice &&
              numeral(
                this.props.quantity * product.attributes.dealerPrice +
                  (this.props.quantity * product.attributes.FXSurcharge || 0)
              ).format("$0,0.00")}
        </td>
      </tr>
    );
  },
});
