// browser
export const CHANGE_BROWSER_STATE = "CHANGE_BROWSER_STATE";
export const CHANGE_ONLINE_STATUS = "CHANGE_ONLINE_STATUS";

// products
export const CHANGE_SEARCH_QUERY = "CHANGE_SEARCH_QUERY";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_CLEARANCE_PRODUCTS = "GET_CLEARANCE_PPRODUCTS";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const RECEIVE_CLEARANCE_PRODUCTS = "RECEIVE_CLEARANCE_PRODUCTS";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";
export const CLEAR_CLEARANCE_PRODUCTS = "CLEAR_CLEARANCE_PRODUCTS";

// categories
export const GET_AGGREGATED_CATEGORIES = "GET_AGGREGATED_CATEGORIES";
export const RECEIVE_AGGREGATED_PRODUCTS = "RECEIVE_AGGREGATED_PRODUCTS";

// product
export const GET_PRODUCT = "GET_PRODUCT";
export const RECEIVE_PRODUCT = "RECEIVE_PRODUCT";
export const SUBMIT_CART_SUCCESS = "SUBMIT_CART_SUCCESS";

// sessions
export const SET_SESSION_ID = "SET_SESSION_ID";
export const RECEIVE_SESSION = "RECEIVE_SESSION";
export const SESSION_LOADING = "SESSION_LOADING";
export const SESSION_ERRORS = "SESSION_ERRORS";
export const DESTROY_SESSION = "DESTROY_SESSION";
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
export const DISMISS_EMAIL_ALERT = "DISMISS_EMAIL_ALERT";
export const SWITCH_LIST_VIEW = "SWITCH_LIST_VIEW";
export const SWITCH_RETAIL_MODE = "SWITCH_RETAIL_MODE";

// users
export const GET_USERS = "GET_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";

//reports
export const GET_REPORTS = "FETCH_REPORT";
export const RECEIVE_REPORTS = "FETCH_REPORT";
