import {
    CLEAR_MESSAGES,
    RECEIVE_MESSAGES,
    GET_MESSAGES,
    MARK_AS_READ
} from './constants'
import { dispatch, getState } from '../store'

function fetchMessages(path) {
    fetch(path, {
        headers: {
            'x-session-id': getState().session.id
        }
    }).then(function (response) {
        if (response.ok) {
            return response.json()
        }
        throw new Error('Message search failed')
    }).then(function (response) {
        dispatch({
            type: RECEIVE_MESSAGES,
            response
        })
    }).catch(function (err) {
        console.log('parsing failed', err)
    })
}

export function searchMessages(email, pagenum, pageSize, searchType, searchValue, sortBy) {

    if (!pagenum) pagenum = 1;
    if (!pageSize) pageSize = 8;
    if (!searchType) searchType = "byEmail";
    if (!searchValue) searchValue = "";
    if (!sortBy) sortBy = '';

    dispatch({
        type: CLEAR_MESSAGES
    })
    let offset = pageSize * (+pagenum - 1)

    const path = `/api/messages?email=${email}&searchType=${searchType}&searchValue=${searchValue}&offset=${offset}&limit=${pageSize}&sortBy=${sortBy}`
    console.log(path)

    dispatch({
        type: GET_MESSAGES,
        offset
    })

    fetchMessages(path)
}

export function markAsRead(messageId) {
    const email = getState().session.user.email

    const path = 'api/messages/markAsRead/' + messageId + '/' + email

    fetch(path, {
        headers: {
            'x-session-id': getState().session.id
        }
    }).then(function (response) {
        if (response.ok) {
            dispatch({
                type: MARK_AS_READ
            })
        }
        throw new Error('Mark as read failed')
    }).catch(function (err) {
        console.log('parsing failed', err)
    })
}

export function isRead(recipients) {
    const email = getState().session.user.email

    for (const recipient of recipients) {
        if (recipient.email === email && recipient.read) {
            return true
        }
    }
    return false;
}
