import { GET_REPORTS, GET_REPORTS_ERROR, GET_REPORTS_SUCCESSFUL } from "./constants"

var initialState = {
    data: [],
    isLoading: false,
    wasLoadSuccessful: false,
    error: null
}


export default function reports (state = initialState, { type, response, error }) {
    switch (type) {
        case GET_REPORTS:
            return {
                ...state,
                isLoading: true,
                wasLoadSuccessful: false,
                error: null
            }

        case GET_REPORTS_SUCCESSFUL:
            return {
                ...state,
                data: response ?? [],
                isLoading: false,
                wasLoadSuccessful: true,
                error: null
            }

        case GET_REPORTS_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                wasLoadSuccessful: false,
                error: error
            }

        default:
            return state
    }
}