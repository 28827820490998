import React from 'react'
import CompactList from './compact-list'
import DetailedList from './detailed-list'
import ClearanceCards from './clearance-cards'
import ListToggle from './list-toggle'
import CategoryBreadcrumbs from '../../common/category-breadcrumbs'
import CategoryChildren from '../../common/category-children'

const PRODUCTS_CATEGORY = '6b86b273-f34f-419d-8804-ff5a3f5747ad'

export default class Products extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    const hasQueryChanged =
      this.props.browser.query.q !== prevProps.browser.query.q
    if (hasQueryChanged) {
    }
  }

  handleSwitchListView() {
    this.props.onSwitchListView()
  }

  render() {
    const session = this.props.session
    const isOnline = this.props.browser.isOnline
    const listView = this.props.session.listView
    const products = this.props.products
    const language = this.props.session.language
    const isRetailMode = this.props.session.isRetailMode
    const browser = this.props.browser
    const categories = this.props.categories
    const warehouseCode = this.props.session.user.warehouseCode
    const user = this.props.session.user
    const { subscribeToProductUpdates } = this.props

    const currentCategory =
      browser.query.categoryId &&
      categories.data.find(category => {
        return category.id === browser.query.categoryId
      })
    const currentCategoryHasParent =
      currentCategory && Boolean(currentCategory.attributes.parentId)

    const hasCategoryChildren =
      browser.query.categoryId &&
      categories.data.some(category => {
        return category.attributes.parentId === browser.query.categoryId
      })

    const showCategories =
      browser.query.categoryId || (browser.pathname === '/' && !browser.query.q)

    const showProducts = !hasCategoryChildren
    // (!products.wasLoadSuccessful && products.meta.offset === 0) ||
    // !categories.wasLoadSuccessful ||
    // !products.data.length

    const showBreadcrumbs =
      browser.query.categoryId && !browser.query.q && currentCategoryHasParent

    const showNoResultsFound =
      browser.query.q &&
      !products.isLoading &&
      products.meta.total === 0 &&
      showProducts &&
      browser.query.categoryId !== PRODUCTS_CATEGORY

    const showClearance = products.data.length === 1
    const showDetailed = products.data.length === 1 || listView === 'detailed'
    const showListToggle = showProducts && products.data.length > 1
    const singleView = products.data.length > 1
    const isScratchAndDentPage = browser.query.categoryId === '19818b31-8ff8-4a31-a4c3-bdbaf8524b66' // Scratch and Dent

    return (
      <div style={{ display: 'flex', backgroundColor: '#F7F7F9' }}>

        <main className="atlas-products">

          {showBreadcrumbs &&
            <CategoryBreadcrumbs
              categories={categories}
              categoryId={
                browser.query.categoryId ||
                '6b86b273-f34f-419d-8804-ff5a3f5747ad'
              }
              isAdmin={user.roles.some(role => role === 'Administrator')}
              language={language}
            />}


          {
            isScratchAndDentPage &&
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div class="alert alert-danger" role="alert">
                {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> */}
                <p className='text-center'>
                  <center>Please note that the following items are sold as "Scratch & Dent" and may have scratches, dents, scuffs, or other visible marks.<br />
                These products are sold on an "as-is" basis and are not eligible for return or exchange and do not include the manufacturer's warranty.<br />
                All freight on the Scratch & Dent items are FOB nearest Atlas warehouse.<br /></center>
                </p>
              </div>
            </div>
          }
          {showCategories &&
            <CategoryChildren
              categories={categories}
              categoryId={
                browser.query.categoryId ||
                '6b86b273-f34f-419d-8804-ff5a3f5747ad'
              }
              language={language}
            />}

          {showListToggle &&
            <ListToggle
              listView={listView}
              onSwitchListView={this.handleSwitchListView.bind(this)}
            />}

          {showNoResultsFound &&
            <div className="atlas-no-results">
              No results found.
            </div>}

          {showProducts &&
            !showDetailed &&
            <CompactList
              session={session}
              isOnline={isOnline}
              isRetailMode={isRetailMode}
              language={language}
              products={products.data}
              userRoles={user.roles}
              warehouseCode={warehouseCode}
              subscribeToProductUpdates={subscribeToProductUpdates}
            />}

          {showDetailed &&
            <br />
          }
          {showDetailed &&
            <DetailedList
              session={session}
              isOnline={isOnline}
              isRetailMode={isRetailMode}
              language={language}
              products={products.data}
              singleView={singleView}
              userRoles={user.roles}
              warehouseCode={warehouseCode}
              subscribeToProductUpdates={subscribeToProductUpdates}
            />}

          {showClearance &&
            <ClearanceCards
              isOnline={isOnline}
              isRetailMode={isRetailMode}
              language={language}
              clearanceProducts={products.clearanceData}
              singleView={singleView}
              userRoles={user.roles}
              warehouseCode={warehouseCode}
              subscribeToProductUpdates={subscribeToProductUpdates}
            />}
        </main>
      </div>
    )
  }
}
