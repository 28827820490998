import { combineReducers } from 'redux'
import product from './product'
import products from './products'
import session from './session'
import browser from './browser'

import activity from '../activity/reducers'
import cart from '../cart/reducers'
import categories from '../categories/reducers'
import category from '../category/reducers'
import carts from '../carts/reducers'
import dealer from '../dealer/reducers'
import dealers from '../dealers/reducers'
import user from '../user/reducers'
import users from '../users/reducers'
import file from '../file/reducers'
import files from '../files/reducers'
import emails from '../email-admin-sent/reducers'
import messages from '../inbox/reducers'
import insights from '../email-admin-insight/reducers'
import insightMessages from '../email-admin-insight-messages/reducers'
import insightDelMessages from '../email-admin-insight-del-messages/reducers'
import reports from '../email-admin-reports/reducers'
import message from '../inbox-message/reducers'
import stats from '../stats/reducers'
import resetPassword from '../reset-password/reducers'
import requestPasswordReset from '../request-password-reset/reducers'
import webOrder from '../web-order/reducers'
import webOrders from '../web-orders/reducers'
import invoices from '../invoices/reducers'
import statements from '../statements/reducers'
import usersData from '../user-management/reducers'
import reportsData from '../reports/reducers'
import allDealersData from '../all-dealers/reducers'

const rootReducer = combineReducers({
  activity,
  browser,
  cart,
  carts,
  categories,
  category,
  dealer,
  dealers,
  file,
  files,
  product,
  products,
  session,
  stats,
  resetPassword,
  requestPasswordReset,
  user,
  users,
  emails,
  reports,
  reportsData,
  message,
  messages,
  insights,
  insightMessages,
  insightDelMessages,
  webOrders,
  webOrder,
  invoices,
  statements,
  usersData,
  allDealersData
})

export default rootReducer
