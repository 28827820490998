import * as types from '../constants/action-types'
import { dispatch, getState } from '../store'

function receiveProducts (response) {
  return {
    type: types.RECEIVE_PRODUCTS,
    response
  }
}

function receiveAggregatedProducts (response) {
  return {
    type: types.RECEIVE_AGGREGATED_PRODUCTS,
    response
  }
}

export function getProducts (options = {}) {
  fetch('/api/products', {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch(receiveProducts(json))
      }
    })
    .catch(function (err) {
      console.log('parsing failed', err)
    })
}


/**
 * Aggregates products by category - new or clearance. Defaults to clearance.
 * @param {*} categoryType 
 * @param {*} categoryId 
 */
export function aggregateProducts (categoryType, categoryId){
  fetch(`/api/products/aggregate?categoryType=${categoryType}&categoryId=${categoryId}`, {
    headers: {
      'x-session-id': getState().session.id
    }
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        dispatch(receiveAggregatedProducts(json))
      }
    })
    .catch(function (err) {
      console.log('parsing failed', err)
    })
}

/**
 * Subscribes to product updates
 * @param {*} email 
 * @param {*} customer_id 
 * @param {*} productId 
 */
export async function subscribeToProductUpdates (productId){
  const { user = {} } = getState().session
  const { email, customer_id } = user
  fetch('/api/subscriptions', {
    method: 'POST',
    headers: {
      'x-session-id': getState().session.id,
      'content-type': 'application/json'
    },
    body: JSON.stringify({ email, customer_id, productId })
  })
    .then(async function (response) {
      if (response.ok) {
        const json = await response.json()
        if(json?.saved){
          alert('Out Of Stock request has been received. We will notify you once the product arrives.')
        }        
      }
      if(response.status === 400){
        alert('You have already subscribed to this product updates.')
      }
      if(response.status === 415){
        alert('Invalid request.')
      }
    })
    .catch(function (err) {
      console.log('parsing failed', err)
    })
}