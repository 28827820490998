import React from 'react'
import {filesize} from 'filesize'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'FilesListItem',

  render () {
    const file = this.props.file
    const sourceUrl = `https://s3.amazonaws.com/atlastrailer/${encodeURIComponent(
      file.id
    )}`
    // const imageUrl = `https://image.webservices.ft.com/v1/images/raw/${encodeURIComponent(
    //   sourceUrl
    // )}?source=atlastrailer&width=256&height=256&fit=scale-down`


    return (
      <tr key={file.id}>
        <td>
          {file.type.includes('image') &&
            <div style={{ width: 128, height: 128 }}>
              <img src={sourceUrl} style={{ maxHeight: 128, maxWidth: 128 }} height={128} width={128} alt={file.id} />
            </div>}
        </td>
        <td>
          <a href={`/files/${file.id}`}>
            {file.id}
          </a>
        </td>
        <td>
          {filesize(file.size, { round: 0 })}
        </td>
      </tr>
    )
  }
})
