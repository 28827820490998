import React from 'react'
import ReactDOM from 'react-dom'
import { getState, subscribe } from '../store'
import appActions from '../actions/app'
import App from '../common/app'
import Admin from './components/index'
import { getStats } from '../stats/actions'
let unsubscribe = function () {}

function render () {
  ReactDOM.render(
    <App active="cart" {...getState()} {...appActions}>
      <Admin {...getState()} />
    </App>,
    document.querySelector('#app')
  )
}

export function enter (context, next) {
  getStats()
  unsubscribe = subscribe(render)
  render()
}

export function exit (context, next) {
  unsubscribe()
  next()
}
