import React from 'react'
import imageToSrcset from '../../../transforms/image-to-srcset'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'EditProductImage',

  handleRemoveClick (event) {
    event.preventDefault()
    this.props.onRemove(this.props.data)
  },

  handleShiftUp (event) {
    event.preventDefault()
    this.props.onShift(this.props.index, true)
  },

  handleShiftDown (event) {
    event.preventDefault()
    this.props.onShift(this.props.index, false)
  },

  render () {
    const image = this.props.data
    const index = this.props.index
    const length = this.props.length
    const srcset = imageToSrcset(image)
    return (
      <div>
        <h3>{image.name}</h3>
        <img
          alt="Product"
          className="atlas-detailed-list-product-image"
          sizes="140px"
          srcSet={srcset}
          style={{ maxHeight: 120 }}
        />
        <button onClick={this.handleRemoveClick}>[Remove image]</button>&nbsp;
        {index !== 0 && length > 1 && <span><button onClick={this.handleShiftUp}>[Move up]</button>&nbsp;</span>}
        {index !== length - 1 && length > 1 && <span><button onClick={this.handleShiftDown}>[Move down]</button></span>}
      </div>
    )
  }
})
