import React from 'react'
import createReactClass from 'create-react-class'
import { getState } from '../../store'

import { FormattedMessage, IntlProvider } from 'react-intl'
// import { addLocaleData, FormattedMessage, IntlProvider } from 'react-intl'
// import en from 'react-intl/locale-data/en'
// import fr from 'react-intl/locale-data/fr'
import frMessages from '../../../locales/fr'

// addLocaleData(en)
// addLocaleData(fr)

const messages = {
    en: {},
    fr: frMessages
}

let page = 1;
let pageSize = 8;

export default createReactClass({
    displayName: 'SearchBar',

    getInitialState() {
        return {
            focused: false,
            page: page,
            pageSize: pageSize,
        }
    },

    handleKeyPress(event) {
        const isEnterKey = event.which === 13
        if (isEnterKey) {
            event.preventDefault()
        }
    },

    handleClickDown(event) {
        if (page > 1) {
            page--;
            this.props.changeSearchQuery(getState().session.user.email, page, pageSize)
        }
    },

    handleClickFirst(event) {
        page = 1;
        this.props.changeSearchQuery(getState().session.user.email, page, pageSize)
    },

    handleClickUp(event) {
        page++;
        this.props.changeSearchQuery(getState().session.user.email, page, pageSize)
    },

    goToPage(targetPage) {
        page = targetPage;
        this.props.changeSearchQuery(getState().session.user.email, page, pageSize)
    },

    handleClickLast(event) {
        let lastPage = (Math.ceil(getState().messages.meta.total / getState().messages.meta.limit))
        page = lastPage;
        if (page == 0) page = 1;
        this.props.changeSearchQuery(getState().session.user.email, page, pageSize)
    },
    handlePageSize(event) {
        page = 1;
        pageSize = pageSize;
        this.props.changeSearchQuery(getState().session.user.email, page, pageSize)
    },
    handleFocus() {
        this.setState({ focused: true })
    },

    handleBlur() {
        this.setState({ focused: false })
    },

    render() {

        const language = this.props.session && this.props.session.language
        const emails = getState().messages
        const lastPage = emails.data.length == 0 ? 1 : Math.ceil(emails.meta.total / emails.meta.limit)
        const isOnline = this.props.isOnline

        if (!isOnline) {
            return (
                <div style={{ display: 'flex' }}>
                    <div style={{ width: 40, height: 40 }}>
                        {/* CC credit for SVG: https://thenounproject.com/search/?q=reconnect&i=206796 */}
                        <svg
                            enableBackground="new 0 0 100 100"
                            version="1.1"
                            viewBox="0 0 100 100"
                            x="0px"
                            xmlns="http://www.w3.org/2000/svg"
                            y="0px"
                        >
                            <path
                                clipRule="evenodd"
                                d="M78.5,78.5H47c-0.3,0-0.5,0-0.7,0c-0.3,0-0.5,0-0.8,0H23  c-9.9,0-18-8.1-18-18c0-7.9,5.1-14.6,12.1-17c0.6-5.6,5.3-10,11.1-10c2.1,0,4.1,0.6,5.8,1.7c3.7-8.1,11.7-13.7,21.2-13.7  c12.8,0,23.2,10.4,23.2,23.2c0,0.3,0,0.5,0,0.8h0C87.6,45.5,95,52.9,95,62C95,71.1,87.6,78.5,78.5,78.5L78.5,78.5z M78.5,48.5h-0.3  h-3c0.2-1.2,0.4-2.5,0.4-3.8c0-11.2-9.1-20.3-20.3-20.3c-9.2,0-16.9,6.1-19.4,14.5c-0.4,0.2-0.8,0.5-1.1,0.8c-1-1.3-2.3-2.3-3.9-2.8  c0,0,0,0-0.1,0c-0.3-0.1-0.7-0.2-1.1-0.3c-0.5-0.1-0.9-0.1-1.4-0.1c-4.6,0-8.2,3.7-8.2,8.2c0,0.3,0,0.7,0.1,1c-1,0.2-2,0.6-2.8,1.2  c0-0.1,0-0.2-0.1-0.3C11.8,48.9,8,54.3,8,60.5c0,8.3,6.7,15,15,15h9h13.5h9.9h20.1h3C86,75.5,92,69.5,92,62  C92,54.5,86,48.5,78.5,48.5L78.5,48.5z M50,65c5.8,0,10.5-4.7,10.5-10.5h3C63.5,62,57.5,68,50,68c-7.5,0-13.5-6-13.5-13.5  C36.5,47,42.5,41,50,41v-4.5l12,6l-12,6V44c-5.8,0-10.5,4.7-10.5,10.5C39.5,60.3,44.2,65,50,65L50,65z"
                                fill="#000000"
                                fillRule="evenodd"
                            />
                        </svg>
                    </div>
                    <div style={{ flex: 1, paddingLeft: 8, display: 'flex' }}>
                        <div style={{ margin: 'auto', color: 'red' }}>
                            <FormattedMessage
                                defaultMessage="Connecting"
                                id="Connecting"
                            />...
            </div>
                    </div>
                </div>
            )
        }

        return (
            <IntlProvider
                key={language.code}
                locale={language.code}
                messages={messages[language.code]}
            >
            <form>
                <nav id="right">
                    <ul className="pagination atlas-inbox-pagination">
                        <li className="page-item" onClick={this.handleClickFirst} ><a style={{ color: 'black' }} className="page-link"><FormattedMessage defaultMessage="First" id="First" /></a></li>
                        <li hidden={!(page == lastPage && lastPage > 4)} onClick={() => this.goToPage(page - 4)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page - 4}</a></li>
                        <li hidden={!(page + 1 >= lastPage && lastPage > 3)} onClick={() => this.goToPage(page - 3)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page - 3}</a></li>
                        <li hidden={page <= 2 || 2 > lastPage} onClick={() => this.goToPage(page - 2)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page - 2}</a></li>
                        <li hidden={page == 1} onClick={this.handleClickDown} className="page-item"><a style={{ color: 'black' }} className="page-link">{page - 1}</a></li>
                        <li className="page-item active pagination" ><a className="page-link">{page}</a></li>
                        <li hidden={page == lastPage} onClick={this.handleClickUp} className="page-item"><a style={{ color: 'black' }} className="page-link">{page + 1}</a></li>
                        <li hidden={page + 2 > lastPage} onClick={() => this.goToPage(page + 2)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page + 2}</a></li>
                        <li hidden={page > 2 || lastPage <= 3} onClick={() => this.goToPage(page + 3)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page + 3}</a></li>
                        <li hidden={page > 1 || lastPage <= 4} onClick={() => this.goToPage(page + 4)} className="page-item"><a style={{ color: 'black' }} className="page-link">{page + 4}</a></li>
                        <li className="page-item" onClick={this.handleClickLast}><a style={{ color: 'black' }} className="page-link"><FormattedMessage defaultMessage="Last" id="Last" /></a></li>
                    </ul>
                </nav>
            </form>
            </IntlProvider>
        )
    }
})
