import {
  GET_UNDELIVERED_MESSAGES_PENDING,
  LOAD_UNDELIVERED_MESSAGES_SUCCESSFUL,
  GET_UNREAD_MESSAGES_PENDING,
  LOAD_UNREAD_MESSAGES_SUCCESSFUL,
} from './constants'



import { dispatch, getState } from '../store'

export async function getUndeliveredMessages() {

  dispatch({
    type: GET_UNDELIVERED_MESSAGES_PENDING,
  })

  // get bounces from postmark
  const bouncesResponse = await fetch(`/api/insights`, {
    headers: {
      'x-session-id': getState().session.id
    },

  })
  const bounces = await bouncesResponse.json()
  const result = []
  for(const bounce of bounces.data){
    result.push(bounce.data)
  }
  console.log(result)
  
  dispatch({
    type: LOAD_UNDELIVERED_MESSAGES_SUCCESSFUL,
    response: result
  })
  
}

export async function getUnReadMessages() {

  dispatch({
    type: GET_UNREAD_MESSAGES_PENDING,
  })

  const readStatsResponse = await fetch(`/api/email-read-stats?offset=0&limit=500`, {
    headers: {
        'x-session-id': getState().session.id
    },
  
  })
  const readStats = await readStatsResponse.json()  
  
  dispatch({
    type: LOAD_UNREAD_MESSAGES_SUCCESSFUL,
    response: readStats
  })
  console.log(readStats.data)

  return readStats.data
}




